import React, { useEffect, useState } from "react";

import { Chart as ChartJS } from "chart.js/auto";
import { Bar, Chart, Doughnut, Line, Pie } from "react-chartjs-2";
import { BsFillBoxSeamFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { HiChevronRight, HiUser } from "react-icons/hi";
import { DashboardBox, DashboardChart } from "../Utility/DashboardBox";
import { getDashboardAverageRate, getDashboardForAdmin } from "../../services/index.service";
import { toastError } from "../Utility/ToastUtils";
import moment from "moment";
import Select from "react-select";
import { getProducts, getProductsByCategoryName, getProductsForSelect } from "../../services/product.service";
import CustomButton from "../Utility/Button";
import { getPurchaseOrderProduct } from "../../services/order.service";
import { getSalesOrderProduct } from "../../services/creditNote.service";
import { handleGetMonthFromIndex } from "../../utils/DateUtils";
import AverageRate from "./AverageRate";
import TotalSales from "./TotalSale";
import TotalCustomers from "./TotalCustomers";

function Dashboard() {
   const [dashboardObj, setDashboardObj] = useState({});
   const barLabels = [
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
      "January",
      "February",
      "March",
   ];

   const [lineData, setLineData] = useState({});

   const [pie1Data, setPie1Data] = useState({});

   const [pie12Data, setPie12Data] = useState({});

   const [productWiseSaleComodity, setProductWiseSaleComodity] = useState({});
   const [productWiseSaleSpeciality, setProductWiseSaleSpeciality] = useState({});
   const [selectedProduct, setSelectedProduct] = useState("");

   const [startdate, setStartdate] = useState(new Date());
   const [enddate, setEnddate] = useState(new Date());

   const [averagePriceDateStart, setAveragePriceDateStart] = useState(new Date());
   const [averagePriceDateEnd, setAveragePriceDateEnd] = useState(new Date());

   const [averagePriceProduct, setAveragePriceProduct] = useState("");
   const [averageSaleProduct, setAverageSaleProduct] = useState("");
   const [averagePriceProductName, setAveragePriceProductName] = useState("");
   const [averageSaleProductName, setAverageSaleProductName] = useState("");

   const [salesStartDate, setSalesStartDate] = useState(new Date());
   const [salesEndDate, setSalesEndDate] = useState(new Date());

   const [productsPurchaseOrderArr, setProductsPurchaseOrderArr] = useState([]);
   const [salesOrderProductsArr, setSalesOrderProductsArr] = useState([]);

   const [averageObj, setAverageObj] = useState({});

   const [productsArr, setProductsArr] = useState([]);

   const [comparisionProductId, setComparisionProductId] = useState({});

   const handleGetDashboardData = async (startDate, endDate, productId) => {
      try {
         let query = `startDate=${new Date(startDate).toISOString()}&endDate=${new Date(endDate).toISOString()}`;
         if (productId && productId != "") {
            query = `${query}&productId=${productId}`;
         }
         let { data: res } = await getDashboardForAdmin(query);
         if (res.data) {
            setDashboardObj(res.data);
            if (res.data.purchaseOrderArrComodity && res.data.purchaseOrderArrComodity.length) {
               setProductWiseSaleComodity({
                  // labels: barLabels,
                  labels: res.data.purchaseOrderArrComodity.map(
                     (el) => String(handleGetMonthFromIndex(el.month)) + " " + el.year,
                  ),
                  datasets: [
                     {
                        label: `Comodity sales from ${moment(startdate).format("DD-MM-YYYY")} to ${moment(
                           enddate,
                        ).format("DD-MM-YYYY")} `,
                        backgroundColor: "#2e4765",
                        borderColor: "#2e4765",
                        data: res?.data?.purchaseOrderArrComodity?.map((el) => el?.value),
                     },
                  ],
               });
            } else {
               setProductWiseSaleComodity({
                  labels: "",
                  datasets: [],
               });
            }
            if (res?.data?.purchaseOrderArrSpeciality && res?.data?.purchaseOrderArrSpeciality.length) {
               setProductWiseSaleSpeciality({
                  labels: res.data.purchaseOrderArrSpeciality.map(
                     (el) => String(handleGetMonthFromIndex(el.month)) + " " + el.year,
                  ),
                  // labels: barLabels,
                  datasets: [
                     {
                        label: `Speciality sales from ${moment(startdate).format("DD-MM-YYYY")} to ${moment(
                           enddate,
                        ).format("DD-MM-YYYY")} `,
                        backgroundColor: "#2e4765",
                        borderColor: "#2e4765",
                        data: res?.data?.purchaseOrderArrSpeciality?.map((el) => el?.value),
                     },
                  ],
               });
            } else {
               setProductWiseSaleSpeciality({
                  labels: "",
                  // labels: barLabels,
                  datasets: [],
               });
            }

            function getRandomRgb() {
               var num = Math.round(0xffffff * Math.random());
               var r = num >> 16;
               var g = (num >> 8) & 255;
               var b = num & 255;
               return "rgb(" + r + ", " + g + ", " + b + ")";
            }

            if (res?.data?.materialInArr && res?.data?.materialInArr.length > 0) {
               setPie1Data({
                  labels: res?.data?.materialInArr?.map((el) => String(handleGetMonthFromIndex(el.month)) + " " + el.year),
                  datasets: [
                     {
                        label: selectedProduct,
                        backgroundColor: res.data.materialInArr.map((el) =>
                           getRandomRgb()
                        ),
                        // borderColor: "rgba(75, 192, 192, 0.2)",
                        data: res?.data?.materialInArr?.map((el) => el?.value),
                     },
                  ],
               });
            }

            setLineData({
               labels: res?.data?.topFiveBestSellingProductsArr?.map((el) =>
                  el?._id ? `(${el?._id} ${el?.quantity})` : "Not added",
               ),
               datasets: [
                  {
                     label: res?.data?.topFiveBestSellingProductsArr?.map((el) =>
                        el?._id ? `(${el?._id})` : "Not added",
                     ),
                     data: res?.data?.topFiveBestSellingProductsArr?.map((el) => el?.quantity),
                     fill: false,
                     backgroundColor: res.data.topFiveBestSellingProductsArr.map((el) => getRandomRgb()),
                     tension: 0.1,
                  },
               ],
            });

            setPie12Data({
               labels: res?.data?.topFiveBestSellingProductsArr?.map((el) =>
                  el?._id ? `(${el?._id} ${el?.quantity} ${el?.uom})` : "Not added",
               ),
               datasets: [
                  {
                     label: res?.data?.topFiveBestSellingProductsArr?.map((el) =>
                        el?.name ? el?.name : "Not added",
                     ),
                     data: res?.data?.topFiveBestSellingProductsArr?.map((el) => el?.quantity),
                     backgroundColor: res.data.topFiveBestSellingProductsArr.map((el) => getRandomRgb()),
                     hoverOffset: 4,
                  },
               ],
            });
         }
      } catch (error) {
         toastError(error);
      }
   };

   const HandleGetProducts = async () => {
      try {
         let query = {};
         let { data: res } = await getProductsByCategoryName(query);
         if (res.data) {
            setProductsArr(res.data);
         }
      } catch (error) {
         toastError(error);
      }
   };

   useEffect(() => {
      let currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const financialYearStartDate = new Date(currentYear, 3, 1); // April is month 3 (zero-indexed)
      if (currentDate < financialYearStartDate) {
         financialYearStartDate.setFullYear(currentYear - 1);
      }

      const nextYear = financialYearStartDate.getFullYear() + 1;
      const financialYearEndDate = new Date(nextYear, 2, 31); // March 31st of the following year
      setStartdate(financialYearStartDate);
      setEnddate(financialYearEndDate);

      HandleGetProducts();
      handleGetDashboardData(financialYearStartDate, financialYearEndDate);
   }, []);

   const handleGetAverage = async (startDate, endDate, productId) => {
      try {
         let query = `startDate=${startDate}&endDate=${endDate}&salestartDate=${startDate}&saleendDate=${endDate}`;

         if (productId && productId != "") {
            query = `${query}&productId=${productId}`;
         }
         if (productId && productId != "") {
            query = `${query}&SaleproductId=${productId}`;
         }
         if (productId && productId != "") {
            query = `${query}&comparisionProductId=${productId}`;
         }

         let { data: res } = await getDashboardAverageRate(query);
         if (res.data) {
            setAverageObj(res.data);
            console.log(res.data, "res.data");
         }
      } catch (error) {
         toastError(error);
      }
   };

   return (
      <main className="bg-white">
         <section className="dashboard-head mb-3">
            <div className="row">
               <div className="col-xl-10"></div>
               <div className="col-xl-2">
                  {/* <div className="user_header">
                     <p className="breadcrumb">
                        <span className="parent">Dashboard </span>{" "}
                        <span>
                           {" "}
                           <HiChevronRight />
                        </span>{" "}
                        <span className="child">Attendance</span>
                     </p>
                  </div> */}
               </div>
            </div>
         </section>

         <section className="mb-5">
            <div className="container-fluid">
               <div className="row">
                  <div className="col-12 col-lg-3">
                     <DashboardBox className="dashboard-summary">
                        <div className="row align-items-center">
                           <div className="col-xl-9">
                              <div className="right_content_box">
                                 <h5 className="blue-1">Start Date</h5>
                                 <input
                                    value={moment(startdate).format("YYYY-MM-DD")}
                                    type="date"
                                    className="form-control"
                                    onChange={(e) => {
                                       setStartdate(e.target.value);
                                       handleGetAverage(new Date(e.target.value), enddate, selectedProduct);
                                       handleGetDashboardData(new Date(e.target.value), enddate, selectedProduct);
                                    }}
                                 />
                              </div>
                           </div>
                        </div>
                     </DashboardBox>
                  </div>
                  <div className="col-12 col-lg-3">
                     <DashboardBox className="dashboard-summary">
                        <div className="row align-items-center">
                           <div className="col-xl-9">
                              <div className="right_content_box">
                                 <h5 className="blue-1">End Date</h5>
                                 <input
                                    value={moment(enddate).format("YYYY-MM-DD")}
                                    type="date"
                                    className="form-control"
                                    onChange={(e) => {
                                       setEnddate(e.target.value);
                                       handleGetDashboardData(startdate, new Date(e.target.value), selectedProduct);
                                       handleGetAverage(startdate, new Date(e.target.value), selectedProduct);
                                    }}
                                 />
                              </div>
                           </div>
                        </div>
                     </DashboardBox>
                  </div>
                  <div className="col-12 col-lg-3">
                     <DashboardBox className="dashboard-summary">
                        <div className="row align-items-center">
                           <div className="col-xl-9">
                              <div className="right_content_box">
                                 <h5 className="blue-1">Products</h5>
                                 <Select
                                    options={productsArr}
                                    onChange={(e) => {
                                       setSelectedProduct(e.value);
                                       handleGetDashboardData(startdate, enddate, e.value);
                                       handleGetAverage(startdate, enddate, e.value);
                                       // setAveragePriceProductName(e.label);
                                    }}
                                 />
                              </div>
                           </div>
                        </div>
                     </DashboardBox>
                  </div>
               </div>
               <div className="row ">
                  <div className="col-12 col-lg-3">
                     <DashboardBox className="dashboard-summary">
                        <div className="row align-items-center">
                           <div className="col-xl-3">
                              <HiUser />
                           </div>
                           <div className="col-xl-9">
                              <div className="right_content_box">
                                 <h5 className="blue-1">Total User</h5>
                                 <h4 className=" ">{dashboardObj?.userCount}</h4>
                              </div>
                           </div>
                        </div>
                     </DashboardBox>
                  </div>
                  <div className="col-12 col-lg-3">
                     <DashboardBox className="dashboard-summary">
                        <div className="row align-items-center">
                           <div className="col-xl-3">
                              <FaUsers />
                           </div>
                           <div className="col-xl-9">
                              <div className="right_content_box">
                                 <h5 className="blue-1">Total Retailers</h5>
                                 <h4 className=" ">{dashboardObj?.retailerCount}</h4>
                                 {/* <p className="dashboard_user_time">
                        {" "}
                        <span className="buttn">+ $20.9k</span>{" "}
                        <span className="text">Since last week</span>
                      </p> */}
                              </div>
                           </div>
                        </div>
                     </DashboardBox>
                  </div>
                  <div className="col-12 col-lg-3">
                     <DashboardBox className="dashboard-summary">
                        <div className="row align-items-center">
                           <div className="col-xl-4">
                              <BsFillBoxSeamFill />
                           </div>
                           <div className="col-xl-8">
                              <div className="right_content_box">
                                 <h5 className="blue-1">Total Product</h5>
                                 <h4 className=" ">{dashboardObj?.productCount}</h4>
                              </div>
                           </div>
                        </div>
                     </DashboardBox>
                  </div>
                  <div className="col-12 col-lg-3">
                     <DashboardBox className="dashboard-summary">
                        <div className="row align-items-center">
                           <div className="col-xl-4">
                              <BsFillBoxSeamFill />
                           </div>
                           <div className="col-xl-8">
                              <div className="right_content_box">
                                 <h5 className="blue-1">Total Orders</h5>
                                 <h4 className=" ">{dashboardObj?.orderCount}</h4>
                              </div>
                           </div>
                        </div>
                     </DashboardBox>
                  </div>
               </div>
            </div>
         </section>

         <section>
            <div className="container-fluid">
               <div className="row">
                  {(productWiseSaleSpeciality &&
                     productWiseSaleSpeciality.datasets &&
                     productWiseSaleSpeciality.datasets.length > 0) && (
                        <div className="col-12 col-md-12 mb-5 p-5">
                           <h5 className="blue-1 mb-4">Total Sales (Speciality)</h5>
                           <Bar options={{ responsive: true }} data={productWiseSaleSpeciality} />
                        </div>
                     )}
                  {(productWiseSaleComodity &&
                     productWiseSaleComodity.datasets &&
                     productWiseSaleComodity.datasets.length > 0) && (
                        <div className="col-12 col-md-12 mb-5  p-5">
                           <h5 className="blue-1 mb-4">Total Sales (Comodity)</h5>
                           <Bar options={{ responsive: true }} data={productWiseSaleComodity} />
                        </div>
                     )}
                  <div className="col-12 col-md-6 mb-5" style={{ height: 450 }}>
                     <h5 className="blue-1 mb-4">Top 5 products</h5>
                     {pie12Data && pie12Data.datasets && <Pie data={pie12Data} />}
                  </div>

                  <div className="col-12 col-md-6 mb-5" style={{ height: 450 }}>
                     <h5 className="blue-1 mb-4">Monthly Purchases</h5>
                     {pie1Data && pie1Data.datasets && <Doughnut data={pie1Data} />}
                  </div>
                 
                  <div className="col-4 col-md-4 mb-5 p-5 border rounded">
                     <div className="row">
                        <h3 className="blue-1">Purchase</h3>
                     </div>
                     <div className="row">
                        <div className="col-12 col-lg-12">
                           <DashboardChart>
                              <h5 className="blue-1">
                                 Average Rate :{" "}
                                 {averageObj?.purchaseOrder && averageObj?.purchaseOrder.length > 0
                                    ? (averageObj?.purchaseOrder[0]?.averageRate).toFixed(2)
                                    : 0}
                              </h5>
                           </DashboardChart>
                        </div>

                        <div className="col-12 col-lg-12">
                           {averageObj && averageObj?.purchaseOrder && averageObj?.purchaseOrder.length > 0 && (
                              <Doughnut
                                 data={{
                                    labels: [
                                       `Average Rate of ${selectedProduct} from ${moment(startdate).format(
                                          "DD-MM-YYYY",
                                       )} to ${moment(enddate).format("DD-MM-YYYY")}`,
                                    ],
                                    datasets: [
                                       {
                                          label: `Average Rate of ${selectedProduct} from ${moment(
                                             startdate,
                                          ).format("DD-MM-YYYY")} to ${moment(enddate).format("DD-MM-YYYY")}`,
                                          backgroundColor: "purple",
                                          data: averageObj?.purchaseOrder?.map((el) =>
                                             el?.averageRate ? el?.averageRate : 0,
                                          ),
                                       },
                                    ],
                                 }}
                              />
                           )}
                        </div>

                     </div>
                  </div>
                  <div className="col-4 col-md-4 mb-5 p-5 border rounded">
                     <div className="row">
                        <h3 className="blue-1">Sale</h3>
                     </div>
                     <div className="row d-flex" style={{ display: "flex", flexDirection: "row" }}>
                        {/* <div className="col-5"> */}
                        <div className="row">
                           <div className="col-12 col-lg-12">
                              <DashboardChart>
                                 <h5 className="blue-1">
                                    Sale count :{" "}
                                    {averageObj?.creditNote && averageObj?.creditNote.length > 0
                                       ? averageObj?.creditNote[0].totalQuantity
                                       : 0}
                                 </h5>
                              </DashboardChart>
                           </div>
                           <div className="row">

                              <div className="col-12 col-lg-12">
                                 {averageObj && averageObj?.creditNote && averageObj?.creditNote.length > 0 && (
                                    <Doughnut
                                       data={{
                                          labels: [
                                             `Average Rate of ${averagePriceProductName} from ${moment(
                                                startdate,
                                             ).format("DD-MM-YYYY")} to ${moment(enddate).format("DD-MM-YYYY")}`,
                                          ],
                                          datasets: [
                                             {
                                                label: `Average Rate of ${averagePriceProductName} from ${moment(
                                                   startdate,
                                                ).format("DD-MM-YYYY")} to ${moment(enddate).format(
                                                   "DD-MM-YYYY",
                                                )}`,
                                                backgroundColor: "purple",
                                                data: averageObj?.creditNote?.map((el) => el?.totalQuantity),
                                             },
                                          ],
                                       }}
                                    />
                                 )}
                              </div>
                           </div>

                        </div>
                     </div>
                  </div>
                  <div className="col-4 col-md-4 mb-5 p-5 border rounded">
                     <div className="row">
                        <h3 className="blue-1">Sale</h3>
                     </div>
                     <div className="row d-flex" style={{ display: "flex", flexDirection: "row" }}>
                        {/* <div className="col-5"> */}
                        <div className="row">
                           <div className="col-12 col-lg-12">
                              <DashboardChart>
                                 <h5 className="blue-1">
                                    Average Rate :{" "}
                                    {averageObj?.creditNote && averageObj?.creditNote.length > 0
                                       ? averageObj?.creditNote[0].averageRate.toFixed(2)
                                       : 0}
                                 </h5>
                              </DashboardChart>
                           </div>
                           <div className="col-12 col-lg-12">
                              {averageObj && averageObj?.creditNote && averageObj?.creditNote.length > 0 && (
                                 <Doughnut
                                    data={{
                                       labels: [
                                          `Average Rate of ${selectedProduct} from ${moment(startdate).format(
                                             "DD-MM-YYYY",
                                          )} to ${moment(enddate).format("DD-MM-YYYY")}`,
                                       ],
                                       datasets: [
                                          {
                                             label: `Average Rate of ${selectedProduct} from ${moment(
                                                startdate,
                                             ).format("DD-MM-YYYY")} to ${moment(enddate).format(
                                                "DD-MM-YYYY",
                                             )}`,
                                             backgroundColor: "maroon",
                                             data: averageObj?.creditNote?.map((el) =>
                                                el?.averageRate ? el?.averageRate : 0,
                                             ),
                                          },
                                       ],
                                    }}
                                 />
                              )}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <AverageRate />
                  <TotalSales />
                  <TotalCustomers />
               </div>
            </div>
         </section>
      </main>
   );
}

export default Dashboard;
