
// 
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/lab";


export const addLab = (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};


export const getLab = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/?${query.toString()}`);
};

export const downloadLabExcel = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/download-excel?${query.toString()}`);
};
export const deleteLab = (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};


export const getById = (id) => {
    return axiosApiInstance.get(`${serverUrl}/getById/${id}`);
};



export const downloadPDFById = (id) => {
    return axiosApiInstance.get(`${serverUrl}/downloadPDF/${id}`);
};


export const updateLab = (formData, id) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};


export const bulkUploadLab = (formData) => {
    return axiosApiInstance.post(`${serverUrl}/bulkUpload`, formData);
};

