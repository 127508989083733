import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import Select from "react-select";
import { ZoneWiseCustomerCountApi } from "../../services/dispatchingInputSaleInvoice.service";
import { getProductsByCategoryName } from "../../services/product.service";
import { getZone } from "../../services/zone.service";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import { toastError } from "../Utility/ToastUtils";
import { returnFormatedDateTypeObject } from "../../utils/DateUtils";
import moment from "moment";
import { getTotalCustomersForZoneAndDateRangesWise } from "../../services/index.service";
export default function ZoneWiseCustomerCount() {
   const [productsArr, setProductsArr] = useState([]);
   const [yearsArr, setYearsArr] = useState([]);
   const [selectedSecondYear, setSelectedSecondYear] = useState("");
   const [selectedYear, setSelectedYear] = useState("");
   const [selectedProducts, setSelectedProducts] = useState("");
   const [selectedReportType, setSelectedReportType] = useState("Monthly");

   const [startDate, setStartDate] = useState("");
   const [endDate, setEndDate] = useState("");

   const [secondStartDate, setSecondStartDate] = useState("");
   const [secondEndDate, setSecondEndDate] = useState("");

   const [selectedZone, setSelectedZone] = useState("");

   const [displayYear, setDisplayYear] = useState("");
   const [displayProduct, setDisplayProduct] = useState("");

   const [reportType, setReportType] = useState([
      { label: "Quarterly", value: "Quarterly" },
      { label: "Monthly", value: "Monthly" },
   ]);

   const [previousYearData, setPreviousYearData] = useState([]);
   const [currentYearData, setCurrentYearData] = useState([]);

   const [unitOfMeasurement, setUnitOfMeasurement] = useState("");
   const [requestedYearSalesArr, setRequestedYearSalesArr] = useState([]);
   const [previousYearSalesArr, setPreviousYearSalesArr] = useState([]);

   const [zoneArr, setZoneArr] = useState([]);

   const HandleGetComodityProducts = async () => {
      try {
         let query = {};
         let { data: res } = await getProductsByCategoryName(query);
         if (res.data) {
            setProductsArr(res.data);
         }
      } catch (error) {
         toastError(error);
      }
   };

   const HandleGetComodityProductsSalesYearWise = async () => {
      try {
         let query = {};
         if (startDate == "") {
            toastError("Please select a Start Date");
            return;
         }

         if (endDate == "") {
            toastError("Please select a End Date");
            return;
         }

         if (secondStartDate == "") {
            toastError("Please select a Second Start Date");
            return;
         }

         if (secondEndDate == "") {
            toastError("Please select a Second End Date");
            return;
         }

         if (startDate && startDate != "") {
            let strtDate = returnFormatedDateTypeObject(startDate);
            query = { ...query, startDate: strtDate.toISOString() };
         }

         if (endDate && endDate != "") {
            let edDate = returnFormatedDateTypeObject(endDate);
            edDate.setDate(edDate.getDate() + 1);
            edDate.setTime(edDate.getTime() - 1);

            query = { ...query, endDate: edDate.toISOString() };
         }

         if (secondStartDate && secondStartDate != "") {
            let strtDate = returnFormatedDateTypeObject(secondStartDate);
            query = { ...query, secondStartDate: strtDate.toISOString() };
         }

         if (secondEndDate && secondEndDate != "") {
            let edDate = returnFormatedDateTypeObject(secondEndDate);
            edDate.setDate(edDate.getDate() + 1);
            edDate.setTime(edDate.getTime() - 1);
            query = { ...query, secondEndDate: edDate.toISOString() };
         }

         // if (selectedYear && selectedYear != "") {
         //     query = { ...query, selectedYear: selectedYear };
         // }
         // if (selectedSecondYear && selectedSecondYear != "") {
         //     query = { ...query, selectedSecondYear: selectedSecondYear };
         // }

         if (selectedProducts && selectedProducts.length > 0) {
            query = {
               ...query,
               selectedProducts: selectedProducts.map((el) => el.value),
            };
         }
         if (selectedZone && selectedZone.length > 0) {
            query = {
               ...query,
               selectedZone: selectedZone.map((el) => el.value),
            };
         }
         if (selectedReportType && selectedReportType != "") {
            query = { ...query, selectedReportType: selectedReportType };
         }

         setDisplayYear(selectedYear);
         setDisplayProduct(selectedProducts);

         let { data: res } = await getTotalCustomersForZoneAndDateRangesWise(query);
         if (res.data) {
            console.log(res.data, "res.datares.data");
            setPreviousYearData(res.data.secondDateRangeData);
            setCurrentYearData(res.data.firstDateRangeData);
         }
      } catch (error) {
         toastError(error);
      }
   };

   useEffect(() => {
      console.log(currentYearData, "CURRENT YEAR DATA");
   }, [currentYearData]);

   const handleCalculateLastFiveYears = () => {
      let tempArr = [];
      let today = new Date();

      let totalYears = today.getFullYear() - 2018;

      for (let index = 0; index < totalYears; index++) {
         let obj = {};
         let year = today.getFullYear() - index;
         obj.label = `${year}-${year + 1}`;
         obj.value = year;
         tempArr.push(obj);
      }

      setYearsArr(tempArr);
   };

   const HandleGetZone = async () => {
      try {
         let { data: res } = await getZone({ pageIndex: 0, pageSize: 10000000 }, { forSelectInput: true });
         if (res.data) {
            setZoneArr(res.data);
         }
      } catch (error) {
         toastError(error);
      }
   };

   useEffect(() => {
      HandleGetZone();
      HandleGetComodityProducts();
      handleCalculateLastFiveYears();
   }, []);

   return (
      <main className="bg-white">
         <section className="dashboard-head mb-3">
            <div className="row">
               <div className="col-xl-10"></div>
               <div className="col-xl-2">
                  {/* <div className="user_header">
                     <p className="breadcrumb">
                        <span className="parent">Dashboard </span>{" "}
                        <span>
                           {" "}
                           <HiChevronRight />
                        </span>{" "}
                        <span className="child">Reports</span>
                     </p>
                  </div> */}
               </div>
            </div>
         </section>

         <section>
            <div className="container-fluid">
               <div className="row">
                  <div className="col-12 col-md-12 mb-5 p-5 border rounded">
                     <div className="row">
                        <h3 className="blue-1">Zone wise customer count</h3>
                        <div className="col-12 col-lg-12">
                           <DashboardBox className="dashboard-summary">
                              <div className="row align-items-center">
                                 <div className="col-xl-12">
                                    <div className="right_content_box">
                                       <div
                                          style={{
                                             display: "flex",
                                             flexDirection: "row",
                                             alignItems: "center",
                                             justifyContent: "space-between",
                                             marginBottom: 15,
                                          }}
                                       >
                                          <h5 className="blue-1">Products</h5>
                                          <CustomButton
                                             isBtn
                                             btntype="button"
                                             ClickEvent={() => setSelectedProducts([...productsArr])}
                                             iconName="fa-solid fa-check"
                                             extraClass={"pe-3"}
                                             btnName="Select all"
                                          />
                                       </div>
                                       <Select
                                          options={productsArr}
                                          isMulti
                                          value={selectedProducts}
                                          onChange={(e) => setSelectedProducts(e)}
                                       />
                                    </div>
                                 </div>
                              </div>
                           </DashboardBox>
                        </div>
                        <div className="col-12 col-lg-12">
                           <DashboardBox className="dashboard-summary">
                              <div className="row align-items-center">
                                 <div className="col-xl-12">
                                    <div className="right_content_box">
                                       <div
                                          style={{
                                             display: "flex",
                                             flexDirection: "row",
                                             alignItems: "center",
                                             justifyContent: "space-between",
                                             marginBottom: 15,
                                          }}
                                       >
                                          <h5 className="blue-1">Zone</h5>
                                          <CustomButton
                                             isBtn
                                             btntype="button"
                                             ClickEvent={() => setSelectedZone([...zoneArr])}
                                             iconName="fa-solid fa-check"
                                             extraClass={"pe-3"}
                                             btnName="Select all"
                                          />
                                       </div>
                                       <Select
                                          options={zoneArr}
                                          value={selectedZone}
                                          isMulti
                                          closeMenuOnSelect={false}
                                          onChange={(e) => setSelectedZone(e)}
                                       />
                                    </div>
                                 </div>
                              </div>
                           </DashboardBox>
                        </div>
                        <div className="col-12 col-lg-5">
                           <DashboardBox className="dashboard-summary">
                              <div className="row align-items-center">
                                 <div className="col-xl-12">
                                    <div className="right_content_box">
                                       <h5 className="blue-1">First Range</h5>

                                       <div className="mt-1">
                                          <label>Start Date</label>
                                          <input
                                             type="date"
                                            className="form-control"
                                             value={moment(startDate).format("YYYY-MM-DD")}
                                             onChange={(e) => setStartDate(e.target.value)}
                                          />
                                       </div>

                                       <div className="mt-1">
                                          <label className="form-label">End Date</label>
                                          <input
                                             type="date"
                                            className="form-control"
                                             value={moment(endDate).format("YYYY-MM-DD")}
                                             onChange={(e) => setEndDate(e.target.value)}
                                          />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </DashboardBox>
                        </div>
                        <div className="col-12 col-lg-5">
                           <DashboardBox className="dashboard-summary">
                              <div className="row align-items-center">
                                 <div className="col-xl-12">
                                    <div className="right_content_box">
                                       <h5 className="blue-1">Second Range</h5>

                                       <div className="mt-1">
                                          <label>Second Start Date</label>
                                          <input
                                             type="date"
                                            className="form-control"
                                             value={moment(secondStartDate).format("YYYY-MM-DD")}
                                             onChange={(e) => setSecondStartDate(e.target.value)}
                                          />
                                       </div>

                                       <div className="mt-1">
                                          <label className="form-label">Second End Date</label>
                                          <input
                                             type="date"
                                            className="form-control"
                                             value={moment(secondEndDate).format("YYYY-MM-DD")}
                                             onChange={(e) => setSecondEndDate(e.target.value)}
                                          />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </DashboardBox>
                        </div>
                        <div className="col-12 col-lg-1 mt-5">
                           <CustomButton
                              isBtn
                              btntype="button"
                              ClickEvent={HandleGetComodityProductsSalesYearWise}
                              iconName="fa-solid fa-check"
                              extraClass={"mt-3"}
                              btnName="Submit"
                           />
                        </div>
                     </div>

                     {currentYearData && currentYearData.length > 0 && (
                        <div className="mt-5">
                           <div
                              style={{
                                 display: "flex",
                                 flexDirection: "column",
                              }}
                           >
                              <div
                                 style={{
                                    display: "flex",
                                    flexDirection: "row",
                                 }}
                              >
                                 <div
                                    style={{
                                       width: 160,
                                       maxWidth: 160,
                                       fontSize: 11,
                                       textAlign: "center",
                                       border: "solid 1px grey",
                                       display: "grid",
                                       placeItems: "center",
                                    }}
                                 >
                                    Product Category
                                 </div>
                                 <div
                                    style={{
                                       width: 160,
                                       maxWidth: 160,
                                       fontSize: 11,
                                       textAlign: "center",
                                       border: "solid 1px grey",
                                       display: "grid",
                                       placeItems: "center",
                                    }}
                                 >
                                    Grand Total
                                 </div>

                                 {selectedZone &&
                                    selectedZone.length &&
                                    selectedZone &&
                                    selectedZone.length > 0 &&
                                    selectedZone.map((ele, indexX) => {
                                       return (
                                          <div
                                             style={{
                                                width: 160,

                                                maxWidth: 160,
                                                fontSize: 11,
                                                textAlign: "center",
                                                border: "solid 1px grey",
                                                display: "grid",
                                                placeItems: "center",
                                             }}
                                             key={indexX}
                                          >
                                             {ele?.label}
                                          </div>
                                       );
                                    })}
                              </div>
                           </div>
                           <div>
                              {currentYearData &&
                                 currentYearData?.length &&
                                 currentYearData?.map((el, index) => {
                                    return (
                                       <div
                                          key={index}
                                          style={{
                                             display: "flex",
                                             flexDirection: "column",
                                          }}
                                       >
                                          <div
                                             style={{
                                                display: "flex",
                                                flexDirection: "row",
                                             }}
                                          >
                                             <div
                                                style={{
                                                   width: 160,
                                                   fontSize: 11,
                                                   textAlign: "center",
                                                   border: "solid 1px grey",
                                                   display: "grid",
                                                   wordBreak: "break-all",
                                                   placeItems: "center",
                                                }}
                                             >
                                                {el.name}
                                             </div>
                                             <div
                                                style={{
                                                   width: 160,
                                                   fontSize: 11,
                                                   textAlign: "center",
                                                   border: "solid 1px grey",
                                                   display: "grid",
                                                   wordBreak: "break-all",
                                                   placeItems: "center",
                                                }}
                                             >
                                                {el.zonesArr.reduce((acc, el) => acc + el.quantity, 0)}
                                             </div>
                                             {el.zonesArr &&
                                                el.zonesArr?.length > 0 &&
                                                el.zonesArr.map((ele, indexX) => {
                                                   return (
                                                      <div
                                                         style={{
                                                            width: 160,
                                                            fontSize: 11,
                                                            textAlign: "center",
                                                            border: "solid 1px grey",
                                                            display: "grid",
                                                            placeItems: "center",
                                                         }}
                                                         key={indexX}
                                                      >
                                                         {ele?.quantity}
                                                      </div>
                                                   );
                                                })}
                                          </div>
                                       </div>
                                    );
                                 })}
                           </div>
                        </div>
                     )}











                     {previousYearData && previousYearData.length > 0 && (
                        <div className="mt-5">
                           <div
                              style={{
                                 display: "flex",
                                 flexDirection: "column",
                              }}
                           >
                              <div
                                 style={{
                                    display: "flex",
                                    flexDirection: "row",
                                 }}
                              >
                                 <div
                                    style={{
                                       width: 160,
                                       maxWidth: 160,
                                       fontSize: 11,
                                       textAlign: "center",
                                       border: "solid 1px grey",
                                       display: "grid",
                                       placeItems: "center",
                                    }}
                                 >
                                    Product Category
                                 </div>
                                 <div
                                    style={{
                                       width: 160,
                                       maxWidth: 160,
                                       fontSize: 11,
                                       textAlign: "center",
                                       border: "solid 1px grey",
                                       display: "grid",
                                       placeItems: "center",
                                    }}
                                 >
                                    Grand Total
                                 </div>

                                 {selectedZone &&
                                    selectedZone.length &&
                                    selectedZone &&
                                    selectedZone.length > 0 &&
                                    selectedZone.map((ele, indexX) => {
                                       return (
                                          <div
                                             style={{
                                                width: 160,

                                                maxWidth: 160,
                                                fontSize: 11,
                                                textAlign: "center",
                                                border: "solid 1px grey",
                                                display: "grid",
                                                placeItems: "center",
                                             }}
                                             key={indexX}
                                          >
                                             {ele?.label}
                                          </div>
                                       );
                                    })}
                              </div>
                           </div>
                           <div>
                              {previousYearData &&
                                 previousYearData?.length &&
                                 previousYearData?.map((el, index) => {
                                    return (
                                       <div
                                          key={index}
                                          style={{
                                             display: "flex",
                                             flexDirection: "column",
                                          }}
                                       >
                                          <div
                                             style={{
                                                display: "flex",
                                                flexDirection: "row",
                                             }}
                                          >
                                             <div
                                                style={{
                                                   width: 160,
                                                   fontSize: 11,
                                                   textAlign: "center",
                                                   border: "solid 1px grey",
                                                   display: "grid",
                                                   wordBreak: "break-all",
                                                   placeItems: "center",
                                                }}
                                             >
                                                {el.name}
                                             </div>
                                             <div
                                                style={{
                                                   width: 160,
                                                   fontSize: 11,
                                                   textAlign: "center",
                                                   border: "solid 1px grey",
                                                   display: "grid",
                                                   wordBreak: "break-all",
                                                   placeItems: "center",
                                                }}
                                             >
                                                {el.zonesArr.reduce((acc, el) => acc + el.quantity, 0)}
                                             </div>
                                             {el.zonesArr &&
                                                el.zonesArr?.length > 0 &&
                                                el.zonesArr.map((ele, indexX) => {
                                                   return (
                                                      <div
                                                         style={{
                                                            width: 160,
                                                            fontSize: 11,
                                                            textAlign: "center",
                                                            border: "solid 1px grey",
                                                            display: "grid",
                                                            placeItems: "center",
                                                         }}
                                                         key={indexX}
                                                      >
                                                         {ele?.quantity}
                                                      </div>
                                                   );
                                                })}
                                          </div>
                                       </div>
                                    );
                                 })}
                           </div>
                        </div>
                     )}

























                  </div>
               </div>
            </div>
         </section>
      </main>
   );
}
