import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "../assets/scss/main.css";
import Profile from "../components/Admin/Profile";
import Aging from "../components/Aging/Aging.jsx";
import AddCustomer from "../components/Customer/AddCustomer";
import AddCustomerCategory from "../components/Customer/AddCustomerCategory/AddCustomerCategory";
import ViewCustomerCategory from "../components/Customer/AddCustomerCategory/ViewCustomerCategory";
import CustomerDetail from "../components/Customer/CustomerDetail";
import Customer from "../components/Customer/Customers";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import AddMenu from "../components/Menus/AddMenu";
import EditMenu from "../components/Menus/EditMenu";
import MenuItem from "../components/Menus/MenuItem";
import Menus from "../components/Menus/Menus";
import AddLabSample from "../components/NewComponents/AddLabSample";
import AddLogistics from "../components/NewComponents/AddLogistics.jsx";
import AttendanceListPage from "../components/NewComponents/AttendanceListPage";
import BankPaymentsAdd from "../components/NewComponents/BankPaymentsAdd";
import BankPaymentsList from "../components/NewComponents/BankPaymentsList";
import BankReceiptAdd from "../components/NewComponents/BankReceiptAdd";
import BankReceiptList from "../components/NewComponents/BankReceiptList";
import BaseIndemandProduct from "../components/NewComponents/BaseIndemandProduct.jsx";
import BaseIndemandProductList from "../components/NewComponents/BaseIndemandProductList.jsx";
import CartPage from "../components/NewComponents/CartPage";
import CartPage1 from "../components/NewComponents/CartPage1";
import CategoryListPage from "../components/NewComponents/CategoryListPage";
import CityAddPage from "../components/NewComponents/City";
import CityListPage from "../components/NewComponents/CityList";
import CreditNoteAdd from "../components/NewComponents/CreditNoteAdd";
import CreditNoteList from "../components/NewComponents/CreditNoteList";
import DispatchinginputssaleinvoiceAdd from "../components/NewComponents/DispatchinginputssaleinvoiceAdd";
import DispatchinginputssaleinvoiceList from "../components/NewComponents/DispatchinginputssaleinvoiceList";
import IndemandProduct from "../components/NewComponents/IndemandProduct";
import IndemandProductList from "../components/NewComponents/IndemandProductList";
import JVAdd from "../components/NewComponents/JVAdd.jsx";
import JVList from "../components/NewComponents/JVList.jsx";
import LabPDF from "../components/NewComponents/LabPdf.jsx";
import LabSample from "../components/NewComponents/LabSample";
import Leave from "../components/NewComponents/Leave";
import LeaveListPage from "../components/NewComponents/LeaveList";
import LocationListPage from "../components/NewComponents/LocationListPage";
import LocationPage from "../components/NewComponents/LocationPage";
import Logistics from "../components/NewComponents/Logistics.jsx";
import MaterialInAdd from "../components/NewComponents/MaterialInAdd";
import MaterialInList from "../components/NewComponents/MaterialInList";
import Meeting from "../components/NewComponents/Meeting";
import MeetingListPage from "../components/NewComponents/MeetingsListPage";
import Order from "../components/NewComponents/Order.jsx";
import OrderHistory from "../components/NewComponents/OrderHistory";
import PVAdd from "../components/NewComponents/PVAdd.jsx";
import PVList from "../components/NewComponents/PVList.jsx";
import Product from "../components/NewComponents/Product";
import ProductListPage from "../components/NewComponents/ProductListPage";
import PurchaseOrder from "../components/NewComponents/PurchaseOrder";
import PurchaseOrderHistory from "../components/NewComponents/PurchaseOrderHistory.jsx";
import RawMaterialAddPage from "../components/NewComponents/RawMaterial.jsx";
import RawMaterialListPage from "../components/NewComponents/RawMaterialList.jsx";
import StateAddPage from "../components/NewComponents/State";
import StateListPage from "../components/NewComponents/StateList";
import SupportListPage from "../components/NewComponents/SupportListPage";
import SupportPage from "../components/NewComponents/SupportPage";
import UserAttendance from "../components/NewComponents/UserAttendance";
import UserCategory from "../components/NewComponents/UserCategory";
import Userpage from "../components/NewComponents/UserPage";
import UsersListPage from "../components/NewComponents/UsersListPage";
import WarehouseAddPage from "../components/NewComponents/WarehouseAdd";
import WarehouseListPage from "../components/NewComponents/WarehouseList";
import ZoneAddPage from "../components/NewComponents/Zone.jsx";
import ZoneListPage from "../components/NewComponents/ZoneList.jsx";
import CancleReason from "../components/Order/CancleReason/CancleReason";
import DeliveryProcess from "../components/Order/DeliveryProcess/DeliveryProcess";
import SaleDetail from "../components/Order/Detail/SaleDetail";
import InHouse from "../components/Order/InhouseOrder/Inhouse";
import TotalOrder from "../components/Order/TotalOrder/TotalOrder";
import TrackOrder from "../components/Order/TrackOrder/TrackOrder";
import AddProduct from "../components/Products/AddProduct/AddProduct";
import Attribute from "../components/Products/Attribute/Attribute";
import AttributeValue from "../components/Products/Attribute/AttributeValue";
import BulkBrandUpload from "../components/Products/Brand/BulkBrandUpload";
import BulkProductUpload from "../components/Products/BulkProductUpload";
import BulkCategoryUpload from "../components/Products/Category/BulkCategoryUpload";
import Category from "../components/Products/Category/Category";
import Config from "../components/Products/Config";
import AddProductCategory from "../components/Products/ProductCategory/AddProductCategory";
import ProductCategory from "../components/Products/ProductCategory/ProductCategory";
import ProductList from "../components/Products/ProductList";
import ChangeInRevenueShare from "../components/Reports/ChangeInRevenueShare.jsx";
import ComodityProductWiseSales from "../components/Reports/ComodityProductWiseSales.jsx";
import CouponSale from "../components/Reports/CouponSale";
import ProductOrdersBySpecificCustomers from "../components/Reports/ProductOrdersBySpecificCustomers";
import ProductSearches from "../components/Reports/ProductSearches";
import ProductWiseSoldQuantity from "../components/Reports/ProductWiseSoldQuantity.jsx";
import ProductsSale from "../components/Reports/ProductsSale";
// import RetailerPage from "../components/NewComponents/RetailerPage.jsx";
import RetailerListPage from "../components/NewComponents/RetailerListPage.jsx";
import SaleAndCollectionComparision from "../components/Reports/SaleAndCollectionComparision.jsx";
import SegmentWiseTurnoverComparision from "../components/Reports/SegmentWiseTurnoverComparision.jsx";
import SpecialityProductWiseSales from "../components/Reports/SpecialityProductWiseSales.jsx";
import TotalIncentiveVsNonIncentiveSales from "../components/Reports/TotalIncentiveVsNonIncentiveSales.jsx";
import UserRegisteredToday from "../components/Reports/UserRegisteredToday";
import YearOnYearRevenue from "../components/Reports/YearOnYearRevenue.jsx";
import YearWiseSaleRevenue from "../components/Reports/YearWiseSaleRevenue.jsx";
import ZoneAndYearWiseIncentiveProductSale from "../components/Reports/ZoneAndYearWiseIncentiveProductSale.jsx";
import ZoneAndYearWiseSaleRevenue from "../components/Reports/ZoneAndYearWiseSaleRevenue.jsx";
import ZoneWiseCustomerCount from "../components/Reports/ZoneWiseCustomerCount.jsx";
import ProductReview from "../components/Review/ProductReview";
import ReviewConfig from "../components/Review/ReviewConfig";
import AddCurrency from "../components/SetUp/CurrencyList/AddCurrency";
import CurrencyList from "../components/SetUp/CurrencyList/CurrencyList";
import Location from "../components/SetUp/Location/Location";
import Tags from "../components/SetUp/Tags/Tags";
import SideBar from "../components/Sidebar/SideBar";
import CreateUserOrder from "../components/UserOrders/CreateUserOrder";
import UserOrder from "../components/UserOrders/UserOrder";
import Dashboard from "../components/dashboard/Dashboard";
import ReversalList from "../components/NewComponents/ReversalList.jsx";
import ReversalAdd from "../components/NewComponents/ReversalAdd.jsx";
import RetailerPage from "../components/NewComponents/RetailerPage.jsx";
import LabAddPage from "../components/NewComponents/LabAdd.jsx";
import LabListPage from "../components/NewComponents/LabList.jsx";
export default function AuthorizedRoutes() {
    // let token = useSelector((state) => state.auth.token);

    return (
        <section style={{ backgroundColor: "#fff" }}>
            <div className="row g-0">
                <div
                    className="col_auto_15 noprint sidebar_box"
                >
                    <SideBar />
                </div>
                <div
                    className="col_auto_85 main_content_box"
                >
                    <Header />
                    <Routes>
                        <Route exact path="/" element={<Dashboard />}></Route>

                        <Route
                            exact
                            path="/User-Order/Create"
                            element={<CreateUserOrder />}></Route>
                        <Route
                            exact
                            path="/LabPDF"
                            element={<LabPDF />}></Route>

                        {/* ///////reports////// */}
                        <Route
                            exact
                            path="/reports/SpecialityProductWiseSales"
                            element={<SpecialityProductWiseSales />}></Route>
                        <Route
                            exact
                            path="/reports/ChangeInRevenueShare"
                            element={<ChangeInRevenueShare />}></Route>
                        <Route
                            exact
                            path="/reports/YearOnYearRevenue"
                            element={<YearOnYearRevenue />}></Route>
                        <Route
                            exact
                            path="/reports/YearWiseSaleRevenue"
                            element={<YearWiseSaleRevenue />}></Route>
                        <Route
                            exact
                            path="/reports/ComodityProductWiseSales"
                            element={<ComodityProductWiseSales />}></Route>
                        <Route
                            exact
                            path="/reports/TotalIncentiveVsNonIncentiveSales"
                            element={
                                <TotalIncentiveVsNonIncentiveSales />
                            }></Route>
                        <Route
                            exact
                            path="/reports/SaleAndCollectionComparision"
                            element={<SaleAndCollectionComparision />}></Route>
                        <Route
                            exact
                            path="/reports/ProductWiseSoldQuantity"
                            element={<ProductWiseSoldQuantity />}></Route>
                        <Route
                            exact
                            path="/reports/SegmentWiseTurnoverComparision"
                            element={
                                <SegmentWiseTurnoverComparision />
                            }></Route>
                        <Route
                            exact
                            path="/reports/ZoneAndYearWiseSaleRevenue"
                            element={<ZoneAndYearWiseSaleRevenue />}></Route>
                        <Route
                            exact
                            path="/reports/ZoneAndYearWiseIncentiveProductSale"
                            element={
                                <ZoneAndYearWiseIncentiveProductSale />
                            }></Route>
                        <Route
                            exact
                            path="/reports/ZoneWiseCustomerCount"
                            element={<ZoneWiseCustomerCount />}></Route>
                        {/* ///////reports////// */}
                        <Route
                            exact
                            path="/User-Order/Create"
                            element={<CreateUserOrder />}></Route>
                        <Route
                            exact
                            path="/User-Order/View-All"
                            element={<UserOrder />}></Route>
                        <Route
                            exact
                            path="/Product/Category"
                            element={<Category />}></Route>
                        <Route
                            exact
                            path="/Category-list"
                            element={<CategoryListPage />}></Route>
                        <Route
                            exact
                            path="/Product/ProductCategory"
                            element={<ProductCategory />}></Route>
                        <Route
                            exact
                            path="/Product/AddProductCategory"
                            element={<AddProductCategory />}></Route>
                        <Route
                            exact
                            path="/Product/Attribute"
                            element={<Attribute />}></Route>
                        <Route
                            exact
                            path="/Product/Attribute-Value"
                            element={<AttributeValue />}></Route>
                        <Route
                            exact
                            path="/Product/AddProduct"
                            element={<AddProduct />}></Route>
                        <Route
                            exact
                            path="/Product/Bulk-Product-Upload"
                            element={<BulkProductUpload />}></Route>
                        <Route
                            exact
                            path="/Product/Bulk-Category-Upload"
                            element={<BulkCategoryUpload />}></Route>
                        <Route
                            exact
                            path="/Product/Bulk-Brand-Upload"
                            element={<BulkBrandUpload />}></Route>
                        <Route
                            exact
                            path="/Product/Product-List"
                            element={<ProductList />}></Route>
                        <Route
                            exact
                            path="/Product/Config"
                            element={<Config />}></Route>
                        <Route
                            exact
                            path="/Admin/Profile"
                            element={<Profile />}></Route>
                        <Route
                            exact
                            path="/Order/Total-Order"
                            element={<TotalOrder />}></Route>
                        <Route
                            exact
                            path="/Order/Inhouse-Order"
                            element={<InHouse />}></Route>
                        <Route
                            exact
                            path="/Order/Delivery-Process"
                            element={<DeliveryProcess />}></Route>
                        <Route
                            exact
                            path="/Order/Cancle-Reason"
                            element={<CancleReason />}></Route>
                        <Route
                            exact
                            path="/Order/Track-Order"
                            element={<TrackOrder />}></Route>
                        <Route
                            exact
                            path="/Order/Sale-Detail"
                            element={<SaleDetail />}></Route>
                        <Route
                            exact
                            path="/Review/Product-Review"
                            element={<ProductReview />}></Route>
                        <Route
                            exact
                            path="/Review/Review-Configuration"
                            element={<ReviewConfig />}></Route>
                        <Route exact path="/Menus" element={<Menus />}></Route>
                        <Route
                            exact
                            path="/Menus/Menus-Create"
                            element={<AddMenu />}></Route>
                        <Route
                            exact
                            path="/Menus/Menus-Edit"
                            element={<EditMenu />}></Route>
                        <Route
                            exact
                            path="/Menus/Menus-Item"
                            element={<MenuItem />}></Route>

                        <Route
                            exact
                            path="/Add-Customer-Category"
                            element={<AddCustomerCategory />}></Route>
                        <Route
                            exact
                            path="/Customer-Category"
                            element={<ViewCustomerCategory />}></Route>
                        <Route
                            exact
                            path="/Customer-list"
                            element={<Customer />}></Route>
                        <Route
                            exact
                            path="/Customer-Create"
                            element={<AddCustomer />}></Route>
                        <Route
                            exact
                            path="/Customer-Detail"
                            element={<CustomerDetail />}></Route>

                        <Route
                            exact
                            path="/SetUp/Currency-List"
                            element={<CurrencyList />}></Route>
                        <Route
                            exact
                            path="/SetUp/Currency-Create"
                            element={<AddCurrency />}></Route>
                        <Route
                            exact
                            path="/SetUp/Location"
                            element={<Location />}></Route>
                        <Route
                            exact
                            path="/SetUp/Tags"
                            element={<Tags />}></Route>
                        <Route
                            exact
                            path="/Report/Product-Sale"
                            element={<ProductsSale />}></Route>
                        <Route
                            exact
                            path="/Report/Coupon-Sale"
                            element={<CouponSale />}></Route>
                        <Route
                            exact
                            path="/Report/New-Customers"
                            element={<UserRegisteredToday />}></Route>
                        <Route
                            exact
                            path="/Report/Customers-Orders"
                            element={
                                <ProductOrdersBySpecificCustomers />
                            }></Route>
                        <Route
                            exact
                            path="/Report/Prouduct-Searches"
                            element={<ProductSearches />}></Route>
                        <Route
                            exact
                            path="/UserPage"
                            element={<Userpage />}></Route>
                        <Route
                            exact
                            path="/UserCategory"
                            element={<UserCategory />}></Route>
                        <Route
                            exact
                            path="/Product"
                            element={<Product />}></Route>
                        <Route
                            exact
                            path="/Support"
                            element={<SupportPage />}></Route>
                        <Route
                            exact
                            path="/BaseIndemandProduct"
                            element={<BaseIndemandProduct />}></Route>
                        <Route
                            exact
                            path="/IndemandProduct"
                            element={<IndemandProduct />}></Route>
                        <Route
                            exact
                            path="/UserAttendance"
                            element={<UserAttendance />}></Route>
                        <Route
                            exact
                            path="/UserAttendanceList"
                            element={<AttendanceListPage />}></Route>
                        <Route
                            exact
                            path="/Meeting"
                            element={<Meeting />}></Route>
                        <Route
                            exact
                            path="/LocationPage"
                            element={<LocationPage />}></Route>
                        <Route
                            exact
                            path="/CartPage"
                            element={<CartPage />}></Route>
                        <Route
                            exact
                            path="/LeavePage"
                            element={<Leave />}></Route>
                        <Route
                            exact
                            path="/Order"
                            element={<Order />}></Route>
                        <Route
                            exact
                            path="/PurchaseOrder"
                            element={<PurchaseOrder />}></Route>
                        <Route
                            exact
                            path="/UserListPage"
                            element={<UsersListPage />}></Route>
                        <Route
                            exact
                            path="/ProductListPage"
                            element={<ProductListPage />}></Route>
                        <Route
                            exact
                            path="/SupportListPage"
                            element={<SupportListPage />}></Route>
                        <Route
                            exact
                            path="/IndemandProductList"
                            element={<IndemandProductList />}></Route>
                        <Route
                            exact
                            path="/BaseIndemandProductList"
                            element={<BaseIndemandProductList />}></Route>
                        <Route
                            exact
                            path="/MeetingListPage"
                            element={<MeetingListPage />}></Route>
                        <Route
                            exact
                            path="/LocationListPage"
                            element={<LocationListPage />}></Route>
                        <Route
                            exact
                            path="/CartPage1"
                            element={<CartPage1 />}></Route>
                        <Route
                            exact
                            path="/OrderHistory"
                            element={<OrderHistory />}></Route>
                        <Route
                            exact
                            path="/PurchaseOrderHistory"
                            element={<PurchaseOrderHistory />}></Route>
                        <Route
                            exact
                            path="/LeaveListPage"
                            element={<LeaveListPage />}></Route>
                        <Route
                            exact
                            path="/LabSample"
                            element={<LabSample />}></Route>
                        <Route
                            exact
                            path="/AddLabSample"
                            element={<AddLabSample />}></Route>
                        <Route
                            exact
                            path="/DispatchinginputssaleinvoiceAdd"
                            element={
                                <DispatchinginputssaleinvoiceAdd />
                            }></Route>
                        <Route
                            exact
                            path="/DispatchinginputssaleinvoiceList"
                            element={
                                <DispatchinginputssaleinvoiceList />
                            }></Route>
                        <Route
                            exact
                            path="/CreditNoteList"
                            element={<CreditNoteList />}></Route>
                        <Route
                            exact
                            path="/CreditNoteAdd"
                            element={<CreditNoteAdd />}></Route>
                        <Route
                            exact
                            path="/MaterialInAdd"
                            element={<MaterialInAdd />}></Route>
                        <Route
                            exact
                            path="/MaterialInList"
                            element={<MaterialInList />}></Route>
                        <Route
                            exact
                            path="/BankReceiptList"
                            element={<BankReceiptList />}></Route>
                        <Route
                            exact
                            path="/BankReceiptAdd"
                            element={<BankReceiptAdd />}></Route>
                        <Route
                            exact
                            path="/BankPaymentsList"
                            element={<BankPaymentsList />}></Route>
                        <Route
                            exact
                            path="/BankPaymentsAdd"
                            element={<BankPaymentsAdd />}></Route>

                        <Route exact path="/JVAdd" element={<JVAdd />}></Route>
                        <Route
                            exact
                            path="/JVList"
                            element={<JVList />}></Route>

                        <Route exact path="/PVAdd" element={<PVAdd />}></Route>
                        <Route
                            exact
                            path="/PVList"
                            element={<PVList />}></Route>

                        <Route
                            exact
                            path="/BankPaymentsAdd"
                            element={<BankPaymentsAdd />}></Route>
                        <Route
                            exact
                            path="/StateList"
                            element={<StateListPage />}></Route>
                        <Route
                            exact
                            path="/StateAdd"
                            element={<StateAddPage />}></Route>
                        <Route
                            exact
                            path="/CityList"
                            element={<CityListPage />}></Route>
                        <Route
                            exact
                            path="/CityAdd"
                            element={<CityAddPage />}></Route>

                        <Route
                            exact
                            path="/ZoneList"
                            element={<ZoneListPage />}></Route>
                        <Route
                            exact
                            path="/ZoneAdd"
                            element={<ZoneAddPage />}></Route>


                        <Route
                            exact
                            path="/RawMaterialList"
                            element={<RawMaterialListPage />}></Route>
                        <Route
                            exact
                            path="/RawMaterialAdd"
                            element={<RawMaterialAddPage />}></Route>

                        <Route
                            exact
                            path="/WarehouseList"
                            element={<WarehouseListPage />}></Route>
                        <Route
                            exact
                            path="/WarehouseAdd"
                            element={<WarehouseAddPage />}></Route>
                        <Route
                            exact
                            path="/Logistics"
                            element={<Logistics />}></Route>
                        <Route
                            exact
                            path="/AddLogistics"
                            element={<AddLogistics />}></Route>
                        <Route
                            exact
                            path="/Reversal"
                            element={<ReversalList />}></Route>
                        <Route
                            exact
                            path="/AddReversal"
                            element={<ReversalAdd />}></Route>
                        <Route
                            exact
                            path="/Aging"
                            element={<Aging />}></Route>
                        <Route
                            exact
                            path="/RetailerPage"
                            element={<RetailerPage />}></Route>
                        <Route
                            exact
                            path="/RetailerListPage"
                            element={<RetailerListPage />}></Route>
                        <Route
                            exact
                            path="/LabAddPage"
                            element={<LabAddPage />}></Route>
                        <Route
                            exact
                            path="/LabList"
                            element={<LabListPage />}></Route>

                    </Routes>
                    <Footer />
                </div>
            </div>
        </section>
    );
}

// {
//     productArr: [
//         {
//             $group: {
//                 _id: "$productsArr.productName",
//                 productCode: {
//                     $first: "$productsArr.productCode",
//                 },
//                 zoneId: {
//                     $addToSet: {
//                         unitCode: "$unitCode",
//                         unitName: "$unitName",
//                         customerCount: {
//                             pipeline: [
//                                 {
//                                     $group: {
//                                         _id: "$customerCode",
//                                         count: {
//                                             $sum: 1,
//                                         },
//                                     },
//                                 },
//                             ],
//                         },
//                     },
//                 },
//             },
//         },
//     ]
// }

// {
//     productArr: [
//         {
//             $group: {
//                 _id: "$productsArr.productName",
//                 productCode: { $first: "$productsArr.productCode" },
//                 zoneId: {
//                     $addToSet: { unitCode: "$unitCode", unitName: "$unitName" },
//                 },
//             },
//         },
//     ];
// }






// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NTZlZjg1M2VmNTZiNDI2NDhmNjJmZTMiLCJyb2xlIjoiQURNSU4iLCJ1c2VyIjp7Im5hbWUiOiJhZG1pbiIsImVtYWlsIjoiYWRtaW5AYWRtaW4uY29tIiwicm9sZSI6IkFETUlOIiwiX2lkIjoiNjU2ZWY4NTNlZjU2YjQyNjQ4ZjYyZmUzIn0sImV4cCI6MTcxNjE4NDgyNiwiaWF0IjoxNzE1NTgwMDI2fQ.E5DuxeeJpTg56YKbG5RI__ZoaqMNJnLWjlkoQ1m993M



// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NTZlZjg1M2VmNTZiNDI2NDhmNjJmZTMiLCJyb2xlIjoiQURNSU4iLCJ1c2VyIjp7Im5hbWUiOiJhZG1pbiIsImVtYWlsIjoiYWRtaW5AYWRtaW4uY29tIiwicm9sZSI6IkFETUlOIiwiX2lkIjoiNjU2ZWY4NTNlZjU2YjQyNjQ4ZjYyZmUzIn0sImV4cCI6MTcxNjgwNzA1MSwiaWF0IjoxNzE2MjAyMjUxfQ.bXC3Db8H5ml1oNpwuUTL5iwAHZeSZ8qPTKGRUFzdiww