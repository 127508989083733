export const ROLES = {
    USER: "USER",
    ADMIN:"ADMIN",
    ZSM:"ZSM",
    RSM:"RSM",
    SM:"SM",
}
export const leaveTypes = {
    EL: "EL",
    CL: "CL"
}
export const leaveStatus = {
    APPROVED: "APPROVED",
    PENDING: "PENDING"
}


export const attendanceTypes = {
    FULLDAY: "FULLDAY",
    HALFDAY: "HALFDAY"
}


export const orderTypes = {
    PREPAID: "PREPAID",
    POSTPAID: "POSTPAID"
}


export const paymentTypes = {
    UPI: "UPI",
    NETBANKING: "NETBANKING",
    NEFT: "NEFT",
    RTGS: "RTGS",
    CASH: "CASH"
}