import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import moment from "moment";
import { addLabSample, getById, updateLabSample, downloadLabExcel } from "../../services/labSample.service";
import LabSampleTable from "./LabSampleTable";
import { getRetailer } from "../../services/retailers.service";
import Select from "react-select";
import { getLab } from "../../services/lab.service";
import { getUser } from "../../services/users.service";
import { departmentObj } from "../../utils/roles";
function AddLabSample() {
   const [testNo, setTestNo] = useState("");
   const [sampleReceivedBy, setSampleReceivedBy] = useState("");
   const [sampleBy, setSampleBy] = useState("");
   const [receivedDate, setReceivedDate] = useState(new Date());
   const [analysisStarted, setAnalysisStarted] = useState(new Date());
   const [completedOn, setCompletedOn] = useState(new Date());
   const [packingDetails, setPackingDetails] = useState("");
   const [material, setMaterial] = useState("");
   const [remarks, setRemarks] = useState("");
   const [refNo, setRefNo] = useState("");
   const [moisture, setMoisture] = useState("");
   const [cProtein, setCProtein] = useState("");
   const [cFat, setCFat] = useState("");
   const [cFibre, setCFibre] = useState("");
   const [tAsh, setTAsh] = useState("");
   const [sandSilica, setSandSilica] = useState("");
   const [salt, setSalt] = useState("");
   const [tphosphorus, setTphosphorus] = useState("");
   const [calcium, setCalcium] = useState("");
   const [vehicleNo, setVehicleNo] = useState("");
   const [customerName, setCustomerName] = useState("");
   const [glCode, setGlCode] = useState("");
   const [customerCode, setCustomerCode] = useState("");
   const [labName, setLabName] = useState("");
   const [user, setUser] = useState("");
   const [customerAddress, setCustomerAddress] = useState("");
   const [page, setPage] = useState(1);
   const [limit, setLimit] = useState(10);
   const [totalElements, setTotalElements] = useState(0);
   const [vendorArr, setVendorArr] = useState([]);
   const [mainArr, setMainArr] = useState([]);
   const [userArr, setUserArr] = useState([]);
   const [query, setQuery] = useState("");
   const [codeArr, setCodeArr] = useState([]);
   const [code, setCode] = useState("");
   console.log("labName", labName)
   const [itemsArr, setItemsArr] = useState([
      {
         material: "",
         remarks: "",
         refNo: "",
         moisture: "",
         cProtein: "",
         cFat: "",
         cFibre: "",
         tAsh: "",
         sandSilica: "",
         salt: "",
         tphosphorus: "",
         calcium: "",
         vehicleNo: "",
         soluProtein: "",
         sediment: "",
         purity: "",
         magn: "",
         thirium: "",
         ffa: "",
         ua: "",
         afl: "",
         ocr: "",
         t2: "",
         zea: "",
      },
   ]);

   const [searchParams, setSearchParams] = useSearchParams();
   const handleSubmit = async () => {
      try {
         if (testNo == "") {
            toastError("Test No is mandatory !!!");
            return;
         }
         if (sampleReceivedBy == "") {
            toastError("Sample Received By is mandatory !!!");
            return;
         }
         if (sampleBy == "") {
            toastError("Sample collected By is mandatory !!!");
            return;
         }
         if (packingDetails == "") {
            toastError("Packing Details is mandatory !!!");
            return;
         }
         // if (labName == "") {
         //    toastError("Lab is mandatory !!!");
         //    return;
         // }

         let obj = {
            testNo,
            sampleReceivedBy,
            sampleBy,
            receivedDate,
            analysisStarted,
            completedOn,
            packingDetails,
            material,
            remarks,
            customerName: customerName.label,
            glCode : glCode.label,
            customerId: customerName.value,
            customerAddress,
            refNo,
            moisture,
            cProtein,
            cFat,
            cFibre,
            tAsh,
            sandSilica,
            salt,
            tphosphorus,
            calcium,
            vehicleNo,
            labId: labName?.value,
            userId: user?.value,
            itemsArr: itemsArr.map((el) => ({ ...el, material: el.material.value })),
         };

         if (searchParams.get("id")) {
            let { data: res } = await updateLabSample(obj, searchParams.get("id"));
            if (res.message) {
               toastSuccess(res.message);
            }
         } else {
            let { data: res } = await addLabSample(obj);
            if (res.message) {
               toastSuccess(res.message);
            }
         }

         console.log(obj, "asdasdasd");
      } catch (err) {
         toastError(err);
      }
   };

   const handleGet = async (pageValue, limitValue, queryValue) => {
      try {
         let query = "";
         if (queryValue && queryValue != "") {
            query = `${queryValue}`
         }
         console.log(query, "query")
         let { data: res } = await getLab({ pageIndex: pageValue, pageSize: limitValue }, { query });
         if (res.message) {
            setMainArr(res.data);
         }
      } catch (err) {
         toastError(err);
      }
   };
   const handleGetUser = async (pageValue, limitValue, queryValue) => {
      try {
         let query = "";
         if (queryValue && queryValue != "") {
            query = `${queryValue}`
         }
         console.log(query, "query")
         let { data: res } = await getUser({ pageIndex: 0, pageSize: 99999, department:departmentObj.SALES }, { query });
         if (res.message) {
            setUserArr(res.data);
         }
      } catch (err) {
         toastError(err);
      }
   };

   const handleGetForUpdate = async () => {
      try {
         if (typeof searchParams?.get("id") != "string") return;
         const res = await getById(searchParams?.get("id"));
         if (res.data.data) {
            console.log(res.data, "data");

            if (res.data.data.testNo) {
               setTestNo(res.data.data.testNo);
            }
            if (res.data.data.sampleReceivedBy) {
               setSampleReceivedBy(res.data.data.sampleReceivedBy);
            }
            if (res.data.data.sampleBy) {
               setSampleBy(res.data.data.sampleBy);
            }
            if (res.data.data.receivedDate) {
               setReceivedDate(res.data.data.receivedDate);
            }
            if (res.data.data.analysisStarted) {
               setAnalysisStarted(res.data.data.analysisStarted);
            }
            if (res.data.data.completedOn) {
               setCompletedOn(res.data.data.completedOn);
            }
            if (res.data.data.packingDetails) {
               setPackingDetails(res.data.data.packingDetails);
            }
            if (res.data.data.material) {
               setMaterial(res.data.data.material);
            }
            if (res.data.data.remarks) {
               setRemarks(res.data.data.remarks);
            }
            if (res.data.data.refNo) {
               setRefNo(res.data.data.refNo);
            }
            if (res.data.data.customerName) {
               setCustomerName({ label: res.data.data.customerName, value: res.data.data.customerId });
            }
            if (res.data.data.code) {
               setGlCode({ label: res.data.data.code, value: res.data.data.code });
            }
            if (res.data.data.code) {
               setCustomerCode({ label: res.data.data.clientId, value: res.data.data.clientId });
            }
            if (res.data.data.labId) {
               setLabName(res.data.data.labId);
            }
            if (res.data.data.userId) {
               setUser(res.data.data.userId);
            }
            if (res.data.data.customerAddress) {
               setCustomerAddress(res.data.data.customerAddress);
            }
            if (res.data.data.moisture) {
               setMoisture(res.data.data.moisture);
            }
            if (res.data.data.cProtein) {
               setCProtein(res.data.data.cProtein);
            }
            if (res.data.data.cFat) {
               setCFat(res.data.data.cFat);
            }
            if (res.data.data.cFibre) {
               setCFibre(res.data.data.cFibre);
            }
            if (res.data.data.tAsh) {
               setTAsh(res.data.data.tAsh);
            }
            if (res.data.data.sandSilica) {
               setSandSilica(res.data.data.sandSilica);
            }
            if (res.data.data.salt) {
               setSalt(res.data.data.salt);
            }
            if (res.data.data.tphosphorus) {
               setTphosphorus(res.data.data.tphosphorus);
            }
            if (res.data.data.calcium) {
               setCalcium(res.data.data.calcium);
            }
            if (res.data.data.vehicleNo) {
               setVehicleNo(res.data.data.vehicleNo);
            }
            if (res.data.data.itemsArr) {
               setItemsArr(res.data.data.itemsArr);
            }
         }
      } catch (error) {
         toastError(error);
      }
   };

   const HandleGetVendor = async () => {
      try {
         let { data: res } = await getRetailer({ isForSelectInput: true });
         console.log(res.data.cityName,"cityNamecityName")
         if (res.data) {
            setVendorArr(res.data);
         }
      } catch (error) {
         toastError(error);
      }
   };
   const HandleGetCode = async () => {
      try {
         let { data: res } = await getRetailer({ isForCodeSelectInput: true });
         console.log(res,"dataCode")
         if (res.data) {
            setCodeArr(res.data);
         }
      } catch (error) {
         toastError(error);
      }
   };

   useEffect(() => {
      if (searchParams) {
         const query = searchParams.get("query") || "";
         const limit = parseInt(searchParams.get("limit") || "10");
         const pageValue = parseInt(searchParams.get("page") || "1");
         setLimit(limit);
         setPage(pageValue);
         setQuery(query);
         console.log(searchParams.toString(), "searchParams")
         handleGet(pageValue, limit, query);
         handleGetUser(1, 999999, query)
      }
   }, [searchParams]);

   useEffect(() => {
      HandleGetVendor()
      HandleGetCode();
   }, []);

   useEffect(() => {
      if (searchParams?.get("id")) {
         handleGetForUpdate();
      }
   }, [searchParams.get("id")]);

   const updateItemsArr = (value) => {
      setItemsArr(value);
   };
   return (
      <>
         <main className="p-0">
            <section className="product-category">
               <div className="row align-items-center mx-0 py_15 px-1">
                  <div className="col-xl-12">
                     <div className="user_header_left_text">
                        <h5> Add Lab Sample</h5>
                     </div>
                  </div>
               </div>

               <div className="row px-3">
                  <div className="col-xl-6">
                     <div className="user_form_ip_box">
                        <p className="ip_label">Test No*</p>
                        <input
                           onChange={(e) => setTestNo(e.target.value)}
                           value={testNo}
                           type="text"
                           placeholder="Test No"
                        />
                     </div>
                  </div>
                  <div className="col-xl-6">
                     <div className="user_form_ip_box">
                        <p className="ip_label">Sample Drawn By*</p>
                        <input
                           onChange={(e) => setSampleBy(e.target.value)}
                           value={sampleBy}
                           type="text"
                           placeholder="Sample Drawn By"
                        />
                     </div>
                  </div>
                  <div className="col-xl-6">
                     <div className="user_form_ip_box">
                        <p className="ip_label">Customer Name *</p>

                        <Select
                           options={vendorArr}
                           // onChange={(e) => console.log(e,"eeeeeeeeeee")}
                           onChange={(e) => {setCustomerName(e); setGlCode(e.code) ; setCustomerAddress(e.cityName); setCustomerCode(e.clientId)}}
                           value={customerName}
                        />
                     </div>
                  </div>
                  <div className="col-xl-6">
                     <div className="user_form_ip_box">
                        <p className="ip_label">GL Code *</p>

                        <input type="text"
                           // options={codeArr}
                           onChange={(e) => setGlCode(e.target.value)}
                           value={glCode}
                        />
                     </div>
                  </div>
                  <div className="col-xl-6">
                     <div className="user_form_ip_box">
                        <p className="ip_label">Customer Address*</p>
                        <input
                           onChange={(e) => setCustomerAddress(e.target.value)}
                           value={customerAddress}
                           type="text"
                           placeholder="Sample Drawn By"
                        />
                     </div>
                  </div>
                  <div className="col-xl-6">
                     <div className="user_form_ip_box">
                        <p className="ip_label">Customer Code*</p>
                        <input
                           onChange={(e) => setCustomerCode(e.target.value)}
                           value={customerCode}
                           type="text"
                           placeholder="Customer Code"
                        />
                     </div>
                  </div>
               </div>
               <div className="row px-3">
                  <div className="col-xl-6">
                     <div className="user_form_ip_box">
                        <p className="ip_label">Sample Received By*</p>
                        <input
                           onChange={(e) => setSampleReceivedBy(e.target.value)}
                           value={sampleReceivedBy}
                           type="text"
                           placeholder="Sample Received By"
                        />
                     </div>
                  </div>
                  <div className="col-xl-6">
                     <div className="user_form_ip_box">
                        <p className="ip_label">Received Date*</p>
                        <input
                           onChange={(e) => setReceivedDate(e.target.value)}
                           value={moment(receivedDate).format("YYYY-MM-DD")}
                           type="date"
                           placeholder="Received Date"
                        />
                     </div>
                  </div>
               </div>
               <div className="row px-3">
                  <div className="col-xl-6">
                     <div className="user_form_ip_box">
                        <p className="ip_label">Analysis Started*</p>
                        <input
                           onChange={(e) => setAnalysisStarted(e.target.value)}
                           value={moment(analysisStarted).format("YYYY-MM-DD")}
                           type="date"
                           placeholder="Analysis Started"
                        />
                     </div>
                  </div>
                  <div className="col-xl-6">
                     <div className="user_form_ip_box">
                        <p className="ip_label">Completed On*</p>
                        <input
                           onChange={(e) => setCompletedOn(e.target.value)}
                           value={moment(completedOn).format("YYYY-MM-DD")}
                           type="date"
                           placeholder="Completed On"
                        />
                     </div>
                  </div>
               </div>
               <div className="row px-3">
                  <div className="col-xl-6">
                     <div className="user_form_ip_box">
                        <p className="ip_label">Packing Details*</p>
                        <input
                           onChange={(e) => setPackingDetails(e.target.value)}
                           value={packingDetails}
                           type="text"
                           placeholder="Packing Details"
                        />
                     </div>
                  </div>
                  {/* <div className="col-xl-6">
                     <div className="user_form_ip_box">
                        <p className="ip_label">Labs *</p>

                        <Select
                           options={mainArr?.map((el) => ({ label: el.name, value: el._id }))}
                           onChange={(e) => setLabName(e)}
                           value={labName}
                        />
                     </div>
                  </div> */}
                  <div className="col-xl-6">
                     <div className="user_form_ip_box">
                        <p className="ip_label">User *</p>

                        <Select
                           options={userArr?.map((el) => ({ label: el.name, value: el._id }))}
                           onChange={(e) => setUser(e)}
                           value={user}
                        />
                     </div>
                  </div>
               </div>

               <LabSampleTable productArr={itemsArr} updateProductArr={updateItemsArr} />
            </section>
            <div className="row">
               <button onClick={() => handleSubmit()} className="submit_buttn mb-5">
                  Submit
               </button>
            </div>
         </main>
      </>
   );
}

export default AddLabSample;
