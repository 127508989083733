import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";
import { addCreditNote, getById, updateCreditNote } from "../../services/creditNote.service";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import CreditNoteProductDetailsTable from "./CreditNoteProductDetailsTable";
import moment from "moment";
import Select from "react-select";
import { getWarehouse } from "../../services/warehouse.service";

function CreditNoteAdd() {
    const [searchParams, setSearchParams] = useSearchParams();



    const [warehouseArr, setWarehouseArr] = useState([]);
    const [documentNumber, setDocumentNumber] = useState("");

    const [customerAddress, setCustomerAddress] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [customerCode, setCustomerCode] = useState("");
    const [remarks, setRemarks] = useState("");
    const [warehouseId, setWarehouseId] = useState("");
    const [documentType, setDocumentType] = useState("");
    const [documentDescription, setDocumentDescription] = useState("");
    const [rekoCode, setRekoCode] = useState("");
    const [reason, setReason] = useState("");
    const [on, setOn] = useState(new Date());
    const [productArr, setProductArr] = useState([]);




    const [cmpId, setCmpId] = useState("");
    const [docId, setdocId] = useState("");
    const [clSuppCode, setclSuppCode] = useState("");
    const [vehicleNumber, setvehicleNumber] = useState("");
    const [headerAcno, setheaderAcno] = useState("");
    const [finYrCode, setfinYrCode] = useState("");

    const [sysNoId, setsysNoId] = useState("");
    const [docTotal, setdocTotal] = useState("");
    const [gstNumber, setgstNumber] = useState("");
    const [spocName, setspocName] = useState("");
    const [spocCode, setspocCode] = useState("");


    const handleGetWarehouse = async () => {
        try {
            let query = { forSelectInput: true }
            let { data: res } = await getWarehouse({ pageIndex: 0, pageSize: 10000000 }, query)
            if (res.data) {
                console.log(res.data, "warehouse arr")
                setWarehouseArr(res.data);
            }
        }
        catch (err) {
            toastError(err)
        }
    }

    useEffect(() => {
        handleGetWarehouse()
    }, [])











    const handleSubmit = async () => {
        try {

            if (documentType == "") {
                toastError("Document Type is mandatory !!!");
                return;
            }

            if (customerCode == "") {
                toastError("Customer Code is mandatory !!!");
                return;
            }
            if (customerName == "") {
                toastError("Customer Name is mandatory !!!");
                return;
            }
            if (customerAddress == "") {
                toastError("Customer Address is mandatory !!!");
                return;
            }
            if (warehouseId == "") {
                toastError("Warehouse is mandatory !!!");
                return;
            }
            if (documentNumber == "") {
                toastError("Document Number is mandatory !!!");
                return;
            }
            if (productArr && productArr.length == 0) {
                toastError("Product are mandatory !!!");
                return;
            }



            let obj = {
                documentType,
                documentDescription,
                on,
                customerCode,
                customerName,
                customerAddress,
                rekoCode,
                remarks,
                warehouseId,
                documentNumber,
                reason,
                cmpId,
                docId,
                clSuppCode,
                vehicleNumber,
                headerAcno,
                finYrCode,
                productsArr: productArr,
            }
            if (searchParams.get("id")) {
                let { data: res } = await updateCreditNote(obj, searchParams.get("id"))
                if (res.message) {
                    toastSuccess(res.message)
                }
            }

            else {
                let { data: res } = await addCreditNote(obj)
                if (res.message) {
                    toastSuccess(res.message)
                }
            }
        }
        catch (err) {
            toastError(err)
        }
    }




    const handleGetForUpdate = async () => {
        try {
            if (typeof searchParams?.get("id") != 'string')
                return;
            const { data: res } = await getById(searchParams?.get("id"));
            if (res.data) {

                if (res?.data?.documentType) {
                    setDocumentType(res?.data?.documentType)
                }
                if (res?.data?.documentNumber) {
                    setDocumentNumber(res?.data?.documentNumber);
                }
                if (res?.data?.documentDescription) {
                    setDocumentDescription(res?.data?.documentDescription)
                }
                if (res?.data?.on) {
                    setOn(res?.data?.on)
                }
                if (res?.data?.customerCode) {
                    setCustomerCode(res?.data?.customerCode)
                }
                if (res?.data?.customerName) {
                    setCustomerName(res?.data?.customerName)
                }
                if (res?.data?.customerAddress) {
                    setCustomerAddress(res?.data?.customerAddress)
                }
                if (res?.data?.rekoCode) {
                    setRekoCode(res?.data?.rekoCode)
                }
                if (res?.data?.remarks) {
                    setRemarks(res?.data?.remarks)
                }
                if (res?.data?.warehouseId) {
                    setWarehouseId(res?.data?.warehouseId)
                }
                if (res?.data?.reason) {
                    setReason(res?.data?.reason)
                }
                if (res?.data?.productsArr) {
                    setProductArr(res?.data?.productsArr)
                }
                if (res?.data?.cmpId) {
                    setCmpId(res?.data?.cmpId);
                }
                if (res?.data?.docId) {
                    setdocId(res?.data?.docId);
                }
                if (res?.data?.clSuppCode) {
                    setclSuppCode(res?.data?.clSuppCode);
                }
                if (res?.data?.vehicleNumber) {
                    setvehicleNumber(res?.data?.vehicleNumber);
                }
                if (res?.data?.headerAcno) {
                    setheaderAcno(res?.data?.headerAcno);
                }
                if (res?.data?.finYrCode) {
                    setfinYrCode(res?.data?.finYrCode);
                }
                if (res?.data?.sysNoId) {
                    setsysNoId(res?.data?.sysNoId);
                }
                if (res?.data?.docTotal) {
                    setdocTotal(res?.data?.docTotal);
                }
                if (res?.data?.gstNumber) {
                    setgstNumber(res?.data?.gstNumber);
                }
                if (res?.data?.spocName) {
                    setspocName(res?.data?.spocName);
                }
                if (res?.data?.spocCode) {
                    setspocCode(res?.data?.spocCode);
                }

            }
        } catch (error) {
            toastError(error)
        }
    }
    useEffect(() => {
        if (searchParams?.get("id")) {
            handleGetForUpdate()
        }

    }, [searchParams.get("id")])


    return (
        <main className="bg-white">
            <section className="user_page_sec">
                <div className="user_header">
                    <span className="page_name">Credit Note</span>
                    {/* <p className="breadcrumb">
            <span className="parent">Dashboard </span>{" "}
            <span>
              {" "}
              <HiChevronRight />
            </span>{" "}
            <span className="child">Credit Note</span>
          </p> */}
                </div>

                <div className="user_form_box">

                    <div className="form_header">
                        <h6 className="heading">Credit Note</h6>
                        <span className="sub_heading"> Add Credit Note</span>
                    </div>


                    <div className="form_box">
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">Document Type* </p>
                                    <input
                                        onChange={(e) =>
                                            setDocumentType(e.target.value)
                                        }
                                        value={documentType}
                                        type="text"
                                        placeholder="Document Type"
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">
                                        Document Number*{" "}
                                    </p>
                                    <input
                                        onChange={(e) =>
                                            setDocumentNumber(e.target.value)
                                        }
                                        value={documentNumber}
                                        type="text"
                                        placeholder="Document Number"
                                    />
                                </div>
                            </div>
                            {/* <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">
                                        Document Description*{" "}
                                    </p>
                                    <input
                                        onChange={(e) =>
                                            setDocumentDescription(
                                                e.target.value
                                            )
                                        }
                                        value={documentDescription}
                                        type="text"
                                        placeholder="Document Description"
                                    />
                                </div>
                            </div> */}
                            <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">Date * </p>
                                    <input
                                        onChange={(e) => setOn(e.target.value)}
                                        value={moment(on).format("YYYY-MM-DD")}
                                        type="date"
                                        placeholder="Date"
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">Customer Code * </p>
                                    <input
                                        onChange={(e) =>
                                            setCustomerCode(e.target.value)
                                        }
                                        value={customerCode}
                                        type="text"
                                        placeholder="Customer Code"
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">Customer Name * </p>
                                    <input
                                        onChange={(e) =>
                                            setCustomerName(e.target.value)
                                        }
                                        value={customerName}
                                        type="text"
                                        placeholder="Customer Name"
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">
                                        Customer Address *{" "}
                                    </p>
                                    <input
                                        onChange={(e) =>
                                            setCustomerAddress(e.target.value)
                                        }
                                        value={customerAddress}
                                        type="text"
                                        placeholder="Customer Address"
                                    />
                                </div>
                            </div>

                            {/* <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">
                                        GL (REKO) Code *{" "}
                                    </p>
                                    <input
                                        onChange={(e) =>
                                            setRekoCode(e.target.value)
                                        }
                                        value={rekoCode}
                                        type="text"
                                        placeholder="GL (REKO) Code"
                                    />
                                </div>
                            </div> */}
                            <div className="col-xl-12">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">Remarks * </p>
                                    <input
                                        onChange={(e) =>
                                            setRemarks(e.target.value)
                                        }
                                        value={remarks}
                                        type="text"
                                        placeholder="Remarks"
                                    />
                                </div>
                            </div>
                            <div className="col-xl-12">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">
                                        Store (Warehouse) *{" "}
                                    </p>
                                    <Select
                                        options={warehouseArr}
                                        name="Types"
                                        onChange={(e) =>
                                            setWarehouseId(e.value)
                                        }
                                        value={warehouseArr.find(
                                            (el) => el._id == warehouseId
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">Reason * </p>
                                    <input
                                        onChange={(e) =>
                                            setReason(e.target.value)
                                        }
                                        value={reason}
                                        type="text"
                                        placeholder="Reason"
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">cmpId * </p>
                                    <input
                                        onChange={(e) =>
                                            setCmpId(e.target.value)
                                        }
                                        value={cmpId}
                                        type="text"
                                        placeholder="cmpId"
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">docId * </p>
                                    <input
                                        onChange={(e) =>
                                            setdocId(e.target.value)
                                        }
                                        value={docId}
                                        type="text"
                                        placeholder="docId"
                                    />
                                </div>
                            </div>

                            <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">clSuppCode * </p>
                                    <input
                                        onChange={(e) =>
                                            setclSuppCode(e.target.value)
                                        }
                                        value={clSuppCode}
                                        type="text"
                                        placeholder="clSuppCode"
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">vehicleNumber * </p>
                                    <input
                                        onChange={(e) =>
                                            setvehicleNumber(e.target.value)
                                        }
                                        value={vehicleNumber}
                                        type="text"
                                        placeholder="vehicleNumber"
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">headerAcno * </p>
                                    <input
                                        onChange={(e) =>
                                            setheaderAcno(e.target.value)
                                        }
                                        value={headerAcno}
                                        type="text"
                                        placeholder="headerAcno"
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">finYrCode * </p>
                                    <input
                                        onChange={(e) =>
                                            setfinYrCode(e.target.value)
                                        }
                                        value={finYrCode}
                                        type="text"
                                        placeholder="finYrCode"
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">sysNoId * </p>
                                    <input
                                        onChange={(e) =>
                                            setsysNoId(e.target.value)
                                        }
                                        value={sysNoId}
                                        type="text"
                                        placeholder="sysNoId"
                                    />
                                </div>
                            </div>

                            <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">docTotal * </p>
                                    <input
                                        onChange={(e) =>
                                            setdocTotal(e.target.value)
                                        }
                                        value={docTotal}
                                        type="text"
                                        placeholder="docTotal"
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">gstNumber * </p>
                                    <input
                                        onChange={(e) =>
                                            setgstNumber(e.target.value)
                                        }
                                        value={gstNumber}
                                        type="text"
                                        placeholder="gstNumber"
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">spocName * </p>
                                    <input
                                        onChange={(e) =>
                                            setspocName(e.target.value)
                                        }
                                        value={spocName}
                                        type="text"
                                        placeholder="spocName"
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">spocCode * </p>
                                    <input
                                        onChange={(e) =>
                                            setspocCode(e.target.value)
                                        }
                                        value={spocCode}
                                        type="text"
                                        placeholder="spocCode"
                                    />
                                </div>
                            </div>

                            <CreditNoteProductDetailsTable
                                productArr={productArr}
                                chats_data_value={(value) =>
                                    setProductArr(value)
                                }
                            />
                        </div>
                        <div className="row">
                            <button onClick={() => handleSubmit()} className="submit_buttn">Submit</button>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default CreditNoteAdd
