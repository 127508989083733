import React, { useEffect, useState } from "react";
import { DashboardTable } from "../Utility/DashboardBox";
import { useSearchParams } from "react-router-dom";
import { getById } from "../../services/labSample.service";
import { toastError } from "../Utility/ToastUtils";
import moment from "moment";

const LabPDF = () => {
   const [searchParams, setSearchParams] = useSearchParams();
   const [labSample, setLabSample] = useState({});
   const handleGetForUpdate = async () => {
      try {
         if (typeof searchParams?.get("id") != "string") return;
         const res = await getById(searchParams?.get("id"));
         if (res.data.data) {
            console.log(res.data, "data");
            setLabSample(res.data.data);
         }
      } catch (error) {
         toastError(error);
      }
   };
   useEffect(() => {
      if (searchParams?.get("id")) {
         handleGetForUpdate();
      }
   }, [searchParams.get("id")]);

   return (
      <section style={{ minHeight: "" }}>
         <div
            style={{
               border: "solid 2px grey",
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               flexDirection: "column",
            }}
         >
            <h3 style={{ color: "rgb(64, 128, 224)" }}>Uttara Impex PVT.LTD</h3>
            <h4
               style={{
                  color: "rgb(224, 83, 64)",
                  fontWeight: "700",
                  fontSize: "17px",
                  textAlign: "center",
               }}
            >
               ADVANCED NUTRITION LAB
            </h4>
            <h5 style={{ fontSize: "13px" }}>
               BSR ARCHADE DOOR NO-4-3-109/33, SRI SAI COLONY,PHASE-II, OLD HIGHWAY, HAYATHNAGAR, R.R.DIST-501505.
            </h5>
            <h5 style={{ fontSize: "13px" }}>TEL:9390557629 EMAIL ID: RAJENDER PRASAD@VENKYS.IN</h5>
            <h4
               style={{
                  color: "rgb(224, 83, 64)",
                  fontWeight: "700",
                  fontSize: "17px",
                  textAlign: "center",
                  borderTop: "solid 2px grey",
                  width: "100%",
                  display: "grid",
                  placeItems: "center",
                  padding: "15px",
                  borderBottom: "solid 2px grey",
               }}
            >
               LAB TEST REPORT
            </h4>

            <div style={{ width: "100%",  display:"flex",flexDirection:"row"}}>
               <div  style={{flex:1 }}>S.NO : {labSample?.testNo}</div>
               <div style={{flex:1 }} >Date: {moment().format("DD-MM-YYYY")}</div>
            </div>
            <div style={{ width: "100%", borderTop: "solid grey 2px", height: 100 , display:"flex",flexDirection:"row"}}>
               <div style={{ borderRight: "solid grey 2px", flex:1 }}>
                  Customer Name : {labSample?.customerName}
               </div>
               <div style={{flex:1}}>Address: {labSample?.customerAddress}</div>
            </div>
            <div style={{ width: "100%", borderTop: "solid grey 2px",display:"flex",flexDirection:"row" }}>
               <div style={{flex:1}}>
                  <h4>TEST</h4>{" "}
               </div>
               <div style={{flex:1}}>
                  <h4>RESULT</h4>{" "}
               </div>
            </div>
            <div
               style={{ width: "100%", borderTop: "solid grey 2px", borderBottom: "solid grey 2px",display:"flex",flexDirection:"row" }}
            >
               <div style={{flex:1}}>SAMPLE DRAWN BY : {labSample?.sampleBy}</div>
               <div style={{flex:1}}>REC DATE : {moment(labSample?.receivedDate).format("DD-MM-YYYY")}</div>
               <div style={{flex:1}}>REPORT DATE : {moment(labSample?.completedOn).format("DD-MM-YYYY")}</div>
            </div>
            {labSample.itemsArr &&
               labSample.itemsArr.length > 0 &&
               labSample.itemsArr.map((el, index) => {
                  return (
                     <div key={index} style={{ width: "100%", margin: 0, padding: 0 }}>
                        <div
                           className="row"
                           style={{
                              width: "100%",
                              borderBottom: "dotted grey 2px",
                              borderTop: `solid grey ${index != 0 ? 2 : 0}px`,
                              marginLeft: 0,
                              display:"flex",
                              flexDirection:"row"
                           }}
                        >
                           <div style={{flex:1}}>Sample: {el?.material?.label}</div>
                           <div style={{flex:1}}>Remarks: {el?.remarks}</div>
                        </div>
                        <div className="row" style={{ width: "100%", marginLeft: 0 , display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
                           <div style={{width:"50%"}}>Moisture (%):</div>
                           <div style={{width:"50%"}}>{el?.moisture}</div>
                           <div style={{width:"50%"}}>C. Protein (%):</div>
                           <div style={{width:"50%"}}>{el?.cProtein}</div>
                           <div style={{width:"50%"}}>T. ash (%):</div>
                           <div style={{width:"50%"}}>{el?.tAsh}</div>
                           <div style={{width:"50%"}}>Sand Silica (%):</div>
                           <div style={{width:"50%"}}>{el?.sandSilica}</div>
                        </div>
                     </div>
                  );
               })}
            <div
           
               style={{ width: "100%", borderTop: "solid grey 2px", borderBottom: "solid grey 2px",display:"flex", flexDirection:"column",justifyContent:"flex-end", alignItems:"flex-end"  }}
            >
               <div style={{width:"30%", textAlign:"right", paddingRight:15}}>For Impex Lab</div>
               <div style={{width:"30%",marginTop:150, textAlign:"right", paddingRight:15}}>
                  Authorise Signatory
               </div>
            </div>
         </div>
      </section>
   );
};

export default LabPDF;