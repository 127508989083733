import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import userimg from "../../assets/images/avatar.png";
import { downloadAgingExcelFile, getAging } from "../../services/aging.service";
import { toastError } from "../../utils/toastUtils";
import { DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import { uploadAgingExcel } from "../../services/index.service";
import { toastSuccess } from "../Utility/ToastUtils";

function Aging() {
  const customStyles = {
    rows: {
        style: {
            // minWidth: "150px",
        },
    },
    headCells: {
        style: {
            // minWidth: "150px",
        },
    },
    cells: {
        style: {
          border:"solid 1px grey",
          margin:0,
          padding:0
          // border: "1px solid black",
        },
    },
}

  const [query, setQuery] = useState("");
  const [displayUserArr, setDisplayUserArr] = useState([]);
  const [mainArr, setMainArr] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const [excel, setExcel] = useState("");
  const navigate = useNavigate();


 
  const users_columns = [
    {
      name: "Sr No.",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Acno code",
      selector: (row) => `${row?.acnoCode ? row?.acnoCode : ""}`,
    },
    {
      name: "Party Code",
      selector: (row) => `${row?.partyCode ? row?.partyCode : ""}`,
    },
    {
      name: "Description",
      selector: (row) => `${row?.description ? row?.description : ""}`,
    },
    {
      name: "90 Days",
      selector: (row) => `${row?.ninetyDays ? row?.ninetyDays : ""}`,
    },
    {
      name: "Total",
      selector: (row) => `${row?.total ? row?.total : ""}`,
    },
    {
      name: "Above 365 days",
      selector: (row) => `${row?.above365Days ? row?.above365Days : ""}`,
    },
    {
      name: "00000-00030",
      selector: (row) => `${row?.zeroTothirty ? row?.zeroTothirty : ""}`,
    },
    {
      name: "00031-00060",
      selector: (row) => `${row?.thirtyOneToSixty ? row?.thirtyOneToSixty : ""}`,
    },
    {
      name: "00061-00075",
      selector: (row) => `${row?.sixtyOneToSeventyFive ? row?.sixtyOneToSeventyFive : ""}`,
    },
    {
      name: "00076-00090",
      selector: (row) => `${row?.seventySixToNinety ? row?.seventySixToNinety : ""}`,
    },
    {
      name: "00091-00120",
      selector: (row) => `${row?.ninetyOneToOneTwenty ? row?.ninetyOneToOneTwenty : ""}`,
    },
    {
      name: "00121-00150",
      selector: (row) => `${row?.oneTwentyOneToOneFifty ? row?.oneTwentyOneToOneFifty : ""}`,
    },
    {
      name: "00151-00180",
      selector: (row) => `${row?.oneFiftyOneToOneEighty ? row?.oneFiftyOneToOneEighty : ""}`,
    },
    {
      name: "00181-00365",
      selector: (row) => `${row?.oneEightyOneToThreeSixtyFive ? row?.oneEightyOneToThreeSixtyFive : ""}`,
    },
    {
      name: "00366-09999",
      selector: (row) => `${row?.threeSixtySixToNineNinetyNine ? row?.threeSixtySixToNineNinetyNine : ""}`,
    },
    {
      name: "UNADJ",
      selector: (row) => `${row?.unadj ? row?.unadj : ""}`,
    },
  ];

  const handleGet = async (pageValue, limitValue, queryValue) => {
    try {
      let query = "";
      if (queryValue && queryValue != "") {
        query = `${queryValue}`
      }
      console.log(query, "query")
      let { data: res } = await getAging({ pageIndex: pageValue, pageSize: limitValue }, { query });
      if (res.message) {
        setDisplayUserArr(res.data);
        setTotalElements(res.total);
        setMainArr(res.data);
      }
    } catch (err) {
      toastError(err);
    }
  };


  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalElements, setTotalElements] = useState(0);

  const createQueryString = useCallback(
    (arr) => {
      const params = new URLSearchParams(searchParams.toString());
      for (let i = 0; i < arr.length; i++) {
        const element = arr[i];
        params.set(element.name, element.value);
      }
      console.log(params.toString(), "params.toString()");
      return params.toString();
    },
    [searchParams]
  );

  const handlePerRowsChange = (newPerPage, page) => {
    navigate(location.pathname + "?" + createQueryString([{ name: "limit", value: newPerPage }])
    );
    setLimit(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
    navigate(location.pathname + "?" + createQueryString([{ name: "page", value: page }])
    );
  };

  useEffect(() => {
    if (searchParams) {
      const query = searchParams.get("query") || "";
      const limit = parseInt(searchParams.get("limit") || "10");
      const pageValue = parseInt(searchParams.get("page") || "1");
      setLimit(limit);
      setPage(pageValue);
      setQuery(query);
      handleGet(pageValue, limit, query);
    }
  }, [searchParams]);


 

 

  const handleUploadAgingExcel = async () => {
    try{
      if(excel == ""){
        toastError("Please upload a file");
        return
      }
      console.log(excel)
      let formData = new FormData()
      formData.append("excel", excel);
      
      let {data:res}=  await uploadAgingExcel(formData);
      if(res.message){
        toastSuccess(res.message)
      }
    }
    catch(err){
      toastError(err)
    }
  }


  const HandleDownloadAgingExcelFile =async ()=> {
    try {
      let {data:res} = await downloadAgingExcelFile();
      console.log(res)
    } catch (error) {
      toastError(error)
    }
  }


  return (
    <main className="p-0">
      <section className="product-category">
        <div className="row align-items-center">
          <div className="col-xl-12">
            <div className="user_header_left">
              <div className="user_img">
                <img src={userimg} alt="" />
              </div>

              <span>Aging</span>
            </div>
          </div>
         
        </div>
        <DashboardTable>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <button onClick={()=> HandleDownloadAgingExcelFile()} className="copy_excel_buttn">Copy to Excel</button>
            <div className="d-flex align-items-center gap-3 border p-4 ">
              <input onChange={(e)=> setExcel(e.target.files[0])} type="file"/>
            <button onClick={()=> handleUploadAgingExcel()} className="copy_excel_buttn">Upload Excel</button>
              {/* <SearchBox
                query={query}
                setQuery={handleChange}
                extraClass="bg-white"
              /> */}
            </div>
          </div>

          <DataTable columns={users_columns} data={displayUserArr} pagination
            paginationPerPage={limit}
            paginationDefaultPage={page}
            paginationServer={true}
            customStyles={customStyles}
            paginationRowsPerPageOptions={[10, 20, 30, 50, 100, 200, 500]}
            paginationTotalRows={totalElements}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange} />
        </DashboardTable>
      </section>
    </main>
  );
}

export default Aging;
