import React from 'react'
import { HiChevronRight } from 'react-icons/hi'
import {CiLock} from 'react-icons/ci'

function CartPage() {
  return (
    <main className='bg-white'>
      <section className="user_page_sec">
      <div className="user_header">
      <span className='page_name'>Cart</span>
      <p className="breadcrumb">
      <span className='parent'>Dashboard </span> <span> <HiChevronRight  /></span> <span className='child'>Cart</span>
      </p>
    </div>

    <div className="user_form_box">
      <div className="form_header">
      <h6 className="heading">Cart</h6>
<span className='sub_heading'>Create Cart</span>
      </div>

      <div className="form_box">
      <div className="row">
  <div className="col-xl-6">
  <div className="user_form_ip_box">
    <p className="ip_label">Retailer Name*</p>
   <input type="text" placeholder='Retailer Name' />
  </div>
  </div>
  <div className="col-xl-6">
  <div className="user_form_ip_box">
    <p className="ip_label">Product Name*</p>
   <input type="text" placeholder='Product Name' />
  </div>
  </div>

</div>
      <div className="row">
  <div className="col-xl-6">
  <div className="user_form_ip_box">
    <p className="ip_label">Quantity*</p>
   <input type="number" placeholder='Qty' />
  </div>
  </div>
  <div className="col-xl-6">
  <div className="user_form_ip_box">
    <p className="ip_label">Price*</p>
   <input type="number" placeholder='Price' />
   {/* <CiLock className='lock_icn'/> */}
  </div>
  </div>

</div>



 

<div className="row">

<button className="submit_buttn">Submit</button>
</div>



      </div>




    </div>
      </section>

    
    
    </main>
  )
}

export default CartPage