import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { MdOutlineAdd } from "react-icons/md";
import { DashboardTable } from "../Utility/DashboardBox";
import { toastError } from "../Utility/ToastUtils";
import { getProducts } from "../../services/product.service";
import Select from "react-select";

export default function CreditNoteProductDetailsTable({
    productArr,
    chats_data_value,
}) {
    const [query, setQuery] = useState("");
    const chats_columns = [
        {
            name: "SR.No",
            selector: (row, index) => index + 1,
            sortable: true,
            width: "5%",
        },
        {
            name: "Product Code",
            cell: (row, index) => (
                <input
                    className="form-control"
                    name="productCode"
                    onChange={(e) => handleChangeValue(e, "productCode", index)}
                    value={row?.productCode}
                />
            ),
            width: "15%",
          },
          {
            name: "Product Name",
            cell: (row, index) => (
              <input
                    className="form-control"
                    name="productName"
                    onChange={(e) => handleChangeValue(e, "productName", index)}
                    value={row?.productName}
                />
            ),
            width: "25%",
          },
          {
            name: "SLTYPE",
            cell: (row, index) => (
              <input
                    className="form-control"
                    name="SLTYPE"
                    onChange={(e) => handleChangeValue(e, "SLTYPE", index)}
                    value={row?.SLTYPE}
                />
            ),
            width: "15%",
          },
          {
            name: "Product Acno",
            cell: (row, index) => (
              <input
                    className="form-control"
                    name="productAcno"
                    onChange={(e) => handleChangeValue(e, "productAcno", index)}
                    value={row?.productAcno}
                />
            ),
            width: "15%",
        },
        {
            name: "GL (REKO) Code",
            cell: (row, index) => (
                <input
                    className="form-control"
                    name="rekoCode"
                    onChange={(e) => handleChangeValue(e, "rekoCode", index)}
                    value={row?.drAcnoCode}
                />
            ),
        },
        {
            name: "Amount",
            cell: (row, index) => (
                <input
                    className="form-control"
                    type="number"
                    name="amount"
                    onChange={(e) => handleChangeValue(e, "total", index)}
                    value={row?.total}
                />
            ),
        },
        {
            name: "Quantity",
            cell: (row, index) => (
                <input
                    className="form-control"
                    type="number"
                    name="quantity"
                    onChange={(e) => handleChangeValue(e, "quantity", index)}
                    value={row?.quantity}
                />
            ),
        },
        {
            name: "UOM",
            cell: (row, index) => (
                <input
                    className="form-control"
                    type="text"
                    name="uom2"
                    onChange={(e) => handleChangeValue(e, "uom2", index)}
                    value={row?.uom2}
                />
            ),
        },
        {
            name: "Store Code",
            cell: (row, index) => (
                <input
                    className="form-control"
                    type="text"
                    name="storeCode"
                    onChange={(e) => handleChangeValue(e, "storeCode", index)}
                    value={row?.storeCode}
                />
            ),
        },
        {
            name: "HSN Number",
            cell: (row, index) => (
                <input
                    className="form-control"
                    type="text"
                    name="hsnNumber"
                    onChange={(e) => handleChangeValue(e, "hsnNumber", index)}
                    value={row?.hsnNumber}
                />
            ),
            width:"10%",
          },
          {
            name: "GR Code",
            cell: (row, index) => (
              <input
                    className="form-control"
                    type="text"
                    name="grCode"
                    onChange={(e) => handleChangeValue(e, "grCode", index)}
                    value={row?.grCode}
                />
            ),
            width:"10%",
          },
          {
            name: "Rate",
            cell: (row, index) => (
              <input
                    className="form-control"
                    type="number"
                    name="rate"
                    onChange={(e) => handleChangeValue(e, "rate", index)}
                    value={row?.rate}
                />
            ),
            width:"10%",
          },
        {
            name: "GLAMOUNT",
            cell: (row, index) => (
                <input
                    className="form-control"
                    type="number"
                    name="GLAMOUNT"
                    onChange={(e) => handleChangeValue(e, "GLAMOUNT", index)}
                    value={row?.GLAMOUNT}
                />
            ),
            width:"10%"
        },
        // {
        //   name: "GST",
        //   grow: 0,
        //   cell: (row, index) => <input className="form-control" type="number" name="gst" onChange={(e) => handleChangeValue(e, "", index)} value={row?.gst} />,
        // },
    ];
    const [chats_data, setChats_data] = useState([]);

    useEffect(() => {
        console.log(productArr, "productArr");
        setChats_data([...productArr]);
    }, [productArr]);

    const handleChangeValue = (e, name, index) => {
        // let tempArr = chats_data;
        console.log(e, "event", name);

        // tempArr[index][e.target.name] = e.target.value;
        setChats_data((prev) => {
            let tempArr = prev;
            if (name == "productObj") {
                prev[index][name] = e;
                prev[index].productId = e.value;
                prev[index].productName = e.label;
                prev[index].rekoCode = e.rekoCode;
            } else if (
                e.target.name == "price" ||
                e.target.name == "quantity"
            ) {
                prev[index][e.target.name] = e.target.value;
                prev[index]["subTotal"] =
                    prev[index].quantity * prev[index].price;
            } else {
                prev[index][e.target.name] = e.target.value;
            }
            return [...tempArr];
        });
        chats_data_value(chats_data);
    };

    const handleAddItem = () => {
        setChats_data((prev) => [
            ...prev,
            {
                category: "",
                productObj: {},
                description: "",
                price: 0,
                quantity: 0,
                subTotal: 0,
            },
        ]);
    };

    return (
        <section className="product-category" style={{ minHeight: "" }}>
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-12">
                        <DashboardTable>
                            <DataTable
                                columns={chats_columns}
                                data={chats_data}
                            />
                            <div className="row f_j_c_e">
                                <button
                                    onClick={() => handleAddItem()}
                                    className="add_item_buttn">
                                    Add item <MdOutlineAdd />
                                </button>
                            </div>
                        </DashboardTable>
                    </div>
                </div>
            </div>
        </section>
    );
}
