import moment from "moment";
import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";
import { addJV, getById, updateJV } from "../../services/jv.service";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import JVProductDetailsTable from "./JVProductDetailsTable";
function JVAdd() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [partnerCode, setPartnerCode] = useState("");
  const [partnerName, setPartnerName] = useState("");
  const [unitCode, setUnitCode] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [documentNumber, setDocumentNumber] = useState("");
  const [documentDate, setDocumentDate] = useState(new Date());
  const [currency, setCurrency] = useState("");
  const [description, setDescription] = useState("");
  const [productsArr, setProductsArr] = useState([]);
  const handleSubmit = async () => {
    try {
      let obj = {
        unitCode,
        documentType,
        documentNumber,
        documentDate,
        description,
        partnerName,
        partnerCode,
        currency,
        itemArr: productsArr
      }
      if (searchParams.get("id")) {
        let { data: res } = await updateJV(obj, searchParams.get("id"))
        if (res.message) {
          toastSuccess(res.message)
        }
      }

      else {
        let { data: res } = await addJV(obj)
        if (res.message) {
          toastSuccess(res.message)
        }
      }
    }
    catch (err) {
      toastError(err)
    }
  }




  const handleGetForUpdate = async () => {
    try {
      if (typeof searchParams?.get("id") != 'string')
        return;
      const { data: res } = await getById(searchParams?.get("id"));
      if (res.data) {
        console.log(res.data, "data")

        if (res.data.unitCode) {
          setUnitCode(res.data.unitCode)
        }
        if (res.data.partnerCode) {
          setPartnerCode(res.data.partnerCode)
        }
        if (res.data.partnerName) {
          setPartnerName(res.data.partnerName)
        }
        if (res.data.documentType) {
          setDocumentType(res.data.documentType)
        }
        if (res.data.documentNumber) {
          setDocumentNumber(res.data.documentNumber)
        }
        if (res.data.documentDate) {
          setDocumentDate(res.data.documentDate)
        }
        if (res.data.currency) {
          setCurrency(res.data.currency)
        }
        if (res.data.description) {
          setDescription(res.data.description)
        }
        if (res.data.itemArr) {
          setProductsArr(res.data.itemArr)
        }

      }

    } catch (error) {
      toastError(error)
    }

  }
  useEffect(() => {
    if (searchParams?.get("id")) {
      handleGetForUpdate()
    }

  }, [searchParams.get("id")])


  return (
    <main className="bg-white">
      <section className="user_page_sec">
        <div className="user_header">
          <span className="page_name">JV</span>
          {/* <p className="breadcrumb">
            <span className="parent">Dashboard </span>{" "}
            <span>
              {" "}
              <HiChevronRight />
            </span>{" "}
            <span className="child">JV</span>
          </p> */}
        </div>

        <div className="user_form_box">
          <div className="form_header">
            <h6 className="heading">JV</h6>
            <span className="sub_heading"> Add JV</span>
          </div>

          <div className="form_box">
            <div className="row">

              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Document Type*</p>
                  <input onChange={(e) => setDocumentType(e.target.value)} value={documentType} type="text" placeholder="Document type" />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Document Number*</p>
                  <input onChange={(e) => setDocumentNumber(e.target.value)} value={documentNumber} type="text" placeholder="Document Number" />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Document Date*</p>
                  <input onChange={(e) => setDocumentDate(e.target.value)} value={moment(documentDate).format("YYYY-MM-DD")} type="date" placeholder="Document Date" />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Partner Code*</p>
                  <input onChange={(e) => setPartnerCode(e.target.value)} value={partnerCode} type="text" placeholder="Partner Code" />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Partner Name*</p>
                  <input onChange={(e) => setPartnerName(e.target.value)} value={partnerName} type="text" placeholder="Partner Name" />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Unit Code*</p>
                  <input onChange={(e) => setUnitCode(e.target.value)} value={unitCode} type="text" placeholder="Unit Code" />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Currency *</p>
                  <input onChange={(e) => setCurrency(e.target.value)} value={currency} type="text" placeholder="Currency" />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Narration *</p>
                  <input onChange={(e) => setDescription(e.target.value)} value={description} type="text" placeholder="Narration" />
                </div>
              </div>

            </div>


            <JVProductDetailsTable productArr={productsArr} chats_data_value={(value) => setProductsArr(value)} />

            <div className="row">
              <button onClick={() => handleSubmit()} className="submit_buttn">Submit</button>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
export default JVAdd
