import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AiFillEdit } from "react-icons/ai";
import { HiChevronRight } from "react-icons/hi";
import { MdOutlineAdd } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteSupport, downloadSupportExcel, getSupport } from "../../services/support.service";
import { toastError } from "../../utils/toastUtils";
import { DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import { generateFilePath } from "../Utility/utils";

function SupportListPage() {
  const dispatch = useDispatch();
  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalBox, setModalBox] = useState(false);
  const [usersArr, setUsersArr] = useState([]);
  const [customersArr, setCustomersArr] = useState([]);
  const [retailersArr, setRetailersArr] = useState([]);
  const [wholesalersArr, setWholesalersArr] = useState([]);
  const [subAdminArr, setSubAdminArr] = useState([]);
  const userArr = useSelector((state) => state.users.users);
  const [selectedData, setSelectedData] = useState(null);
  const [query, setQuery] = useState("");
  const [displayUserArr, setDisplayUserArr] = useState([]);
  const [mainArr, setMainArr] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [customerCategoryObj, setCustomerCategoryObj] = useState({});
  const [customerCategoryId, setCustomerCategoryId] = useState("");
  const [customerCatArr, setCustomerCatArr] = useState([]);

  const handleGetOnInit = () => {
    // dispatch(CustomerCategoryGet());
  };

  const navigate = useNavigate();



  const handleget = async () => {
    try {
      let { data: res } = await getSupport();
      if (res) {
        setDisplayUserArr([...res.data]);
        setMainArr([...res.data]);
      }
    } catch (err) {
      toastError(err);
    }
  };
  const downloadMailListExcel = async () => {
    try {
      let { data: res } = await downloadSupportExcel();
      if (res) {
        var link = document.createElement("a");
        link.download = res.filename;
        link.href = generateFilePath(res.filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err) {
      toastError(err);
    }
  }

  const handleDelete = async (id) => {
    try {
      if (window.confirm("Are you sure ?")) {
        let { data: res } = await deleteSupport(id);
        if (res) {
          handleget()
        }
      }
    } catch (err) {
      toastError(err);
    }
  };


  useEffect(() => {
    handleget()
  }, []);

  const handleEdit = (row) => {
    navigate("/Support" + `?id=${row}`);
  };




  const users_columns = [
    {
      name: "Sr No.",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "10%",
    },

    {
      name: "NAME",
      selector: (row) => `${row?.name}`,
    },
    {
      name: "EMAIL",
      selector: (row) => row?.email,
    },
    {
      name: "Message",
      selector: (row) => row?.message,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button onClick={() => handleEdit(row._id)} className="edit_buttn">
            <AiFillEdit />
          </button>

          <div style={{ marginLeft: 15 }}>
            <button onClick={() => handleDelete(row._id)} className="delete_buttn">
              <RiDeleteBin5Line />
            </button>
          </div>

        </>
      ),
      width: "10%",
    },
  ];





  const handleSearch = (queryValue) => {
    setQuery(queryValue);
    let tempArr = mainArr;
    tempArr = tempArr.filter(
      (el) =>
        `${el?.name}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
        `${el?.phone}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
        `${el?.email}`.toLowerCase().includes(`${queryValue}`.toLowerCase())
    );
    setDisplayUserArr([...tempArr]);
  };


  return (
    <main className="p-0">
      <section className="product-category">
        <div className="user_header">
          <span className="page_name">Mailing List</span>
          <p className="breadcrumb">
            <span className="parent">Dashboard </span>{" "}
            <span>
              {" "}
              <HiChevronRight />
            </span>{" "}
            <span className="child">Mailing List</span>
          </p>
        </div>

        <DashboardTable>
          <div className="row align-items-center border_with_color mx-0">
            <div className="col-xl-10">
              <div className="user_header_left_text">
                <h6>Mailing List</h6>

                <span>Mailing List List</span>
              </div>
            </div>
            <div className="col-xl-2">
              <div className="user_header_right">
                {/* <select>
                  <option value="">All (56)</option>
                  <option value="ssl">SSL</option>
                  <option value="tls">TLS</option>
                </select> */}

                <button onClick={() => navigate("/Support")} className="">
                  Add <MdOutlineAdd />
                </button>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-0 border_with_color_1">
            {/* <h5 className="blue-1 m-0">All Customers</h5> */}
            <button onClick={downloadMailListExcel} className="copy_excel_buttn">Copy to Excel</button>
            <div className="d-flex align-items-center gap-3">
              <SearchBox
                query={query}
                setQuery={handleSearch}
                extraClass="bg-white"
              />
              {/* <CustomButton isLink iconName="fa-solid fa-download" btnName="Customer CSV" path="/" small roundedPill downloadAble ClickEvent={() => downloadCSV(usersArr)} /> */}
            </div>
          </div>

          <DataTable columns={users_columns} data={displayUserArr} pagination />
        </DashboardTable>
      </section>


    </main>
  );
}

export default SupportListPage;
