import moment from "moment";
import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";
import { addMaterialIn, getById, updateMaterialIn } from "../../services/materialIn.service";
import { toastError, toastSuccess } from "../Utility/ToastUtils";

function MaterialInAdd() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [on, setOn] = useState(new Date());
  const [storeName, setStoreName] = useState("");
  const [itemName, setItemName] = useState("");
  const [GRNType, setGRNType] = useState("");
  const [no, setNo] = useState("");
  const [partner, setPartner] = useState("");
  const [remarks, setRemarks] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [truckNo, setTruckNo] = useState("");
  const [acceptQty, setAcceptQty] = useState(0);
  const [rejectQty, setRejectQty] = useState(0);
  const [itemCode, setItemCode] = useState("");




  const handleSubmit = async () => {
    try {





      let obj = {
        on,
        storeName,
        itemName,
        GRNType,
        no,
        partner,
        remarks,
        invoiceNo,
        invoiceDate,
        truckNo,
        acceptQty,
        rejectQty,
      }
      if (searchParams.get("id")) {
        let { data: res } = await updateMaterialIn(obj, searchParams.get("id"))
        if (res.message) {
          toastSuccess(res.message)
        }
      }

      else {
        let { data: res } = await addMaterialIn(obj)
        if (res.message) {
          toastSuccess(res.message)
        }
      }
    }
    catch (err) {
      toastError(err)
    }
  }




  const handleGetForUpdate = async () => {
    try {
      if (typeof searchParams?.get("id") != 'string')
        return;
      const { data: res } = await getById(searchParams?.get("id"));
      if (res.data) {
        console.log(res.data, "data")
        if (res?.data?.on) {
          setOn(res?.data?.on)
        }
        if (res?.data?.storeName) {
          setStoreName(res?.data?.storeName)
        }
        if (res?.data?.itemName) {
          setItemName(res?.data?.itemName)
        }
        if (res?.data?.GRNType) {
          setGRNType(res?.data?.GRNType)
        }
        if (res?.data?.no) {
          setNo(res?.data?.no)
        }
        if (res?.data?.partner) {
          setPartner(res?.data?.partner)
        }
        if (res?.data?.remarks) {
          setRemarks(res?.data?.remarks)
        }
        if (res?.data?.invoiceNo) {
          setInvoiceNo(res?.data?.invoiceNo)
        }
        if (res?.data?.invoiceDate) {
          setInvoiceDate(res?.data?.invoiceDate)
        }
        if (res?.data?.truckNo) {
          setTruckNo(res?.data?.truckNo)
        }
        if (res?.data?.acceptQty) {
          setAcceptQty(res?.data?.acceptQty)
        }
        if (res?.data?.rejectQty) {
          setRejectQty(res?.data?.rejectQty)
        }
      }

    } catch (error) {
      toastError(error)
    }

  }
  useEffect(() => {
    if (searchParams?.get("id")) {
      handleGetForUpdate()
    }

  }, [searchParams.get("id")])


  return (
    <main className="bg-white">
      <section className="user_page_sec">
        <div className="user_header">
          <span className="page_name">GRN</span>
          {/* <p className="breadcrumb">
            <span className="parent">Dashboard </span>{" "}
            <span>
              {" "}
              <HiChevronRight />
            </span>{" "}
            <span className="child">GRN</span>
          </p> */}
        </div>

        <div className="user_form_box">
          <div className="form_header">
            <h6 className="heading">GRN</h6>
            <span className="sub_heading"> Add GRN</span>
          </div>

          <div className="form_box">
            <div className="row">

              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">GRN Type * </p>
                  <input onChange={(e) => setGRNType(e.target.value)} value={GRNType} type="text" placeholder="GRN Type" />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">No * </p>
                  <input onChange={(e) => setNo(e.target.value)} value={no} type="text" placeholder="No" />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Date * </p>
                  <input min={moment(new Date()).format("YYYY-MM-DD")} onChange={(e) => setOn(e.target.value)} value={moment(on).format("YYYY-MM-DD")} type="date" />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Partner * </p>
                  <input onChange={(e) => setPartner(e.target.value)} value={partner} type="text" placeholder="Partner" />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Partner Code* </p>
                  <input onChange={(e) => setPartner(e.target.value)} value={partner} type="text" placeholder="Partner" />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Partner Address* </p>
                  <input onChange={(e) => setPartner(e.target.value)} value={partner} type="text" placeholder="Partner" />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">InvoiceNo * </p>
                  <input onChange={(e) => setInvoiceNo(e.target.value)} value={invoiceNo} type="text" placeholder="InvoiceNo" />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Invoice Date * </p>
                  <input min={moment(new Date()).format("YYYY-MM-DD")} onChange={(e) => setInvoiceDate(e.target.value)} value={moment(invoiceDate).format("YYYY-MM-DD")} type="date" />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Truck No * </p>
                  <input onChange={(e) => setTruckNo(e.target.value)} value={truckNo} type="text" placeholder="Truck No" />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Store Name * </p>
                  <input onChange={(e) => setStoreName(e.target.value)} value={storeName} type="text" placeholder="Store Name" />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Item Code * </p>
                  <input onChange={(e) => setItemCode(e.target.value)} value={itemCode} type="text" placeholder="Item Code" />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Item Name * </p>
                  <input onChange={(e) => setItemName(e.target.value)} value={itemName} type="text" placeholder="Item Name" />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Reko Code* </p>
                  <input onChange={(e) => setPartner(e.target.value)} value={partner} type="text" placeholder="Partner" />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Remarks * </p>
                  <input onChange={(e) => setRemarks(e.target.value)} value={remarks} type="text" placeholder="Remarks" />
                </div>
              </div>



              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Accept Qty * </p>
                  <input onChange={(e) => setAcceptQty(e.target.value)} value={acceptQty} type="number" placeholder="Accept Qty" />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Reject Qty * </p>
                  <input onChange={(e) => setRejectQty(e.target.value)} value={rejectQty} type="number" placeholder="Reject Qty" />
                </div>
              </div>


            </div>




            <div className="row">
              <button onClick={() => handleSubmit()} className="submit_buttn">Submit</button>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default MaterialInAdd
