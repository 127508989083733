import { url } from "../../services/url.service";

export const generateFilePath = (fileName) => {
  return `${url}/uploads/${fileName}`;
};

export const formatter = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
  notation: 'compact',
  compactDisplay: 'long',
})
export const quantityFormatter = new Intl.NumberFormat('en-IN', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
  notation: 'compact',
  compactDisplay: 'short',
})