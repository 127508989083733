import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AiFillEdit } from "react-icons/ai";
import { MdOutlineAdd } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import userimg from "../../assets/images/avatar.png";
import {
  usersGet
} from "../../redux/actions/Users/users.actions";
import {
  deleteUser,
  downloadUserExcel,
  updateUserCategory
} from "../../services/users.service";
import { rolesObj } from "../../utils/roles";
import { toastError } from "../../utils/toastUtils";
import { images } from "../Images/Images";
import CustomButton from "../Utility/Button";
import { DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import { toastSuccess } from "../Utility/ToastUtils";
import { generateFilePath } from "../Utility/utils";

function UsersListPage() {
  const dispatch = useDispatch();
  const [ModalBox, setModalBox] = useState(false);
  const [usersArr, setUsersArr] = useState([]);
  const userArr = useSelector((state) => state.users.users);
  const [query, setQuery] = useState("");
  const [displayUserArr, setDisplayUserArr] = useState([]);
  const [mainArr, setMainArr] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [customerCategoryId, setCustomerCategoryId] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (userArr) {
      setDisplayUserArr([...userArr.filter(el => el.role != rolesObj.ADMIN)]);
      setMainArr([...userArr.filter(el => el.role != rolesObj.ADMIN)]);
    }
  }, [userArr]);

  const handleEdit = (e, id) => {
    e.preventDefault();
    // dispatch(SetCustomerCategoryObj(id));
    navigate("/UserPage" + `?id=${id}`);
  };


  const handleDelete = async (id) => {
    try {
      if (!window.confirm("Are you sure")) {
        return
      }

      let { data: res } = await deleteUser(id)
      if (res.message) {
        toastSuccess(res.message);
        handleGetAllUsers()
      }
    }
    catch (err) {
      toastError(err)
    }
  }


  const users_columns = [
    {
      name: "Sr No.",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "8%",
    },
    {
      name: "NAME",
      selector: (row) => `${row?.name}`,
      width: "12%",
    },
    {
      name: "EMAIL",
      selector: (row) => row?.email,
      width: "20%",
    },
    {
      name: "PHONE",
      selector: (row) => row?.phone,
      width: "12%",
    },
    {
      name: "ROLE",
      selector: (row) => row?.role,
      width: "8%",
    },
    {
      name: "DEPARTMENT",
      selector: (row) => row?.department,
      width: "8%",
    },
    {
      name: "IMAGE",
      cell: (row) => <img style={{ height: 100, width: 100 }} src={(row.image && row.image != "") ? generateFilePath(row.image) : images?.customer} alt={row?.name} />,
      width: "10%",
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button onClick={(e) => handleEdit(e, row._id)} className="edit_buttn">
            <AiFillEdit />
          </button>
          <div style={{ marginLeft: 15 }}>
            <button onClick={() => handleDelete(row._id)} className="delete_buttn">
              <RiDeleteBin5Line />
            </button>
          </div>
        </>
      ),
      width: "15%",
    },
  ];

  const handleGetAllUsers = () => {
    dispatch(usersGet({ pageIndex: 0, pageSize: 10000000 }, ""));
  };
  const handleDownloadUserExcel = async () => {
    let { data: res } = await downloadUserExcel();
    console.log(res, "hih")
    // dispatch(({ pageIndex: 0, pageSize: 10000000 }, ""));
    if (res) {
      console.log(res, "res in download")
      var link = document.createElement("a");
      link.download = res.filename;
      link.href = generateFilePath(res.filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  useEffect(() => {
    handleGetAllUsers();
  }, []);
  useEffect(() => {
    if (userArr && userArr.length) {
      setUsersArr(userArr.filter((el) => el.role != rolesObj.ADMIN));
    }
  }, [userArr]);




  const handleSearch = (queryValue) => {
    setQuery(queryValue);
    let tempArr = mainArr;
    tempArr = tempArr.filter(
      (el) =>
        `${el?.name}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
        `${el?.phone}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
        `${el?.email}`.toLowerCase().includes(`${queryValue}`.toLowerCase())
    );
    setDisplayUserArr([...tempArr]);
  };

  const handleUpdateUserCategory = async () => {
    try {
      let obj = {
        customerCategoryId: customerCategoryId,
      };
      let { data: res } = await updateUserCategory(selectedUser._id, obj);
      if (res.message) {
        toastSuccess(res.message);
        setModalBox(false);
      }
      // dispatch(updateSpecificUser(obj, selectedUser._id))
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <main className="p-0">
      <section className="product-category">
        <div className="row align-items-center">
          <div className="col-xl-10">
            <div className="user_header_left">
              <div className="user_img">
                <img src={userimg} alt="" />
              </div>

              <span>User</span>
            </div>
          </div>
          <div className="col-xl-2">
            <div className="user_header_right">
              {/* <select>
                <option value="">All (56)</option>
                <option value="ssl">SSL</option>
                <option value="tls">TLS</option>
              </select> */}

              <button onClick={() => navigate("/UserPage")} className="">
                Add <MdOutlineAdd />
              </button>
            </div>
          </div>
        </div>
        <DashboardTable>
          <div className="d-flex align-items-center justify-content-between mb-4">
            {/* <h5 className="blue-1 m-0">All Customers</h5> */}
            <button onClick={handleDownloadUserExcel} className="copy_excel_buttn">Copy to Excel</button>
            <div className="d-flex align-items-center gap-3">
              <SearchBox
                query={query}
                setQuery={handleSearch}
                extraClass="bg-white"
              />
              {/* <CustomButton isLink iconName="fa-solid fa-download" btnName="Customer CSV" path="/" small roundedPill downloadAble ClickEvent={() => downloadCSV(usersArr)} /> */}
            </div>
          </div>

          <DataTable columns={users_columns} data={displayUserArr} pagination />

        </DashboardTable>
      </section>


    </main>
  );
}

export default UsersListPage;
