import moment from "moment";
import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";
import { addReversal, getById, updateReversal } from "../../services/reversal.service";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { getWarehouse } from "../../services/warehouse.service";
import Select from "react-select";
import ReversalTable from "./ReversalTable";
import { useNavigate } from "react-router-dom";

function ReversalAdd() {
   const [searchParams, setSearchParams] = useSearchParams();

   const [warehouseArr, setWarehouseArr] = useState([]);
   const [unitCode, setUnitCode] = useState("");
   const [documentDate, setDocumentDate] = useState(new Date());
   const [documentNumber, setDocumentNumber] = useState("");
   const [documentType, setDocumentType] = useState("");
   const [documentDescription, setDocumentDescription] = useState("");
   const [partCode, setPartCode] = useState("");
   const [partName, setPartName] = useState("");
   const [documentTotal, setDocumentTotal] = useState(0);
   const [refDocType, setRefDocType] = useState("");
   const [refDocNumber, setRefDocNumber] = useState("");
   const [chequeType, setChequeType] = useState("");
   const [chequeNumber, setChequeNumber] = useState("");
   const [chequeDate, setChequeDate] = useState("");
   const [payTo, setPayTo] = useState("");
   const [acnoCode, setAcnoCode] = useState("");
   const [acnoDescription, setAcnoDescription] = useState("");
   const [slType, setSlType] = useState("");
   const [slName, setSlName] = useState("");
   const [amount, setAmount] = useState(0);
   const [documentRemarks, setDocumentRemarks] = useState("");
   const [acnoRemarks, setAcnoRemarks] = useState("");
   const [itemsArr, setItemsArr] = useState([]);
   const navigate = useNavigate();

   const handleSubmit = async () => {
      try {
         let obj = {
            unitCode,
            documentDate,
            documentNumber,
            documentType,
            documentDescription,
            partCode,
            partName,
            documentTotal,
            refDocType,
            refDocNumber,
            chequeType,
            itemsArr,
            chequeNumber,
            chequeDate,
            payTo,
            acnoCode,
            acnoDescription,
            slType,
            slName,
            amount,
            documentRemarks,
            acnoRemarks,
         };
         if (searchParams.get("id")) {
            let { data: res } = await updateReversal(obj, searchParams.get("id"));
            if (res.message) {
               toastSuccess(res.message);
               navigate(-1)
            }
         } else {
            let { data: res } = await addReversal(obj);
            if (res.message) {
               toastSuccess(res.message);
               navigate(-1)
            }
         }
      } catch (err) {
         toastError(err);
      }
   };

   const handleGetForUpdate = async () => {
      try {
         if (typeof searchParams?.get("id") != "string") return;
         const { data: res } = await getById(searchParams?.get("id"));
         if (res.data) {
            console.log(res.data, "data");

            if (res.data.unitCode) {
               setUnitCode(res.data.unitCode);
            }
            if (res.data.documentDate) {
               setDocumentDate(res.data.documentDate);
            }
            if (res.data.documentNumber) {
               setDocumentNumber(res.data.documentNumber);
            }
            if (res.data.documentType) {
               setDocumentType(res.data.documentType);
            }
            if (res.data.documentDescription) {
               setDocumentDescription(res.data.documentDescription);
            }
            if (res.data.partCode) {
               setPartCode(res.data.partCode);
            }
            if (res.data.partName) {
               setPartName(res.data.partName);
            }
            if (res.data.documentTotal) {
               setDocumentTotal(res.data.documentTotal);
            }
            if (res.data.itemsArr) {
               setItemsArr(res.data.itemsArr);
            }
            if (res.data.refDocType) {
               setRefDocType(res.data.refDocType);
            }
            if (res.data.refDocNumber) {
               setRefDocNumber(res.data.refDocNumber);
            }
            if (res.data.chequeType) {
               setChequeType(res.data.chequeType);
            }
            if (res.data.chequeNumber) {
               setChequeNumber(res.data.chequeNumber);
            }
            if (res.data.chequeDate) {
               setChequeDate(res.data.chequeDate);
            }
            if (res.data.payTo) {
               setPayTo(res.data.payTo);
            }
            if (res.data.acnoCode) {
               setAcnoCode(res.data.acnoCode);
            }
            if (res.data.acnoDescription) {
               setAcnoDescription(res.data.acnoDescription);
            }
            if (res.data.slType) {
               setSlType(res.data.slType);
            }
            if (res.data.slName) {
               setSlName(res.data.slName);
            }
            if (res.data.amount) {
               setAmount(res.data.amount);
            }
            if (res.data.documentRemarks) {
               setDocumentRemarks(res.data.documentRemarks);
            }
            if (res.data.acnoRemarks) {
               setAcnoRemarks(res.data.acnoRemarks);
            }
         }
      } catch (error) {
         toastError(error);
      }
   };
   useEffect(() => {
      if (searchParams?.get("id")) {
         handleGetForUpdate();
      }
   }, [searchParams.get("id")]);

   const handleGetWarehouse = async () => {
      try {
         let query = {};
         let { data: res } = await getWarehouse({ pageIndex: 0, pageSize: 10000000 }, query);
         if (res.data) {
            console.log(res.data, "warehouse arr");
            setWarehouseArr(res.data.map((el) => ({ label: el.name + " | " + el.storeCode, value: el.storeCode })));
         }
      } catch (err) {
         toastError(err);
      }
   };

   useEffect(() => {
      handleGetWarehouse();
   }, []);

   return (
      <main className="bg-white">
         <section className="user_page_sec">
            <div className="user_header">
               <span className="page_name">Reversal</span>
               {/* <p className="breadcrumb">
                  <span className="parent">Dashboard </span>{" "}
                  <span>
                     {" "}
                     <HiChevronRight />
                  </span>{" "}
                  <span className="child">Reversal</span>
               </p> */}
            </div>

            <div className="user_form_box">
               <div className="form_header">
                  <h6 className="heading">Reversal</h6>
                  <span className="sub_heading"> Add Reversal</span>
               </div>

               <div className="form_box">
                  <div className="row">
                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">Document Type*</p>
                           <input
                              onChange={(e) => setDocumentType(e.target.value)}
                              value={documentType}
                              type="text"
                              placeholder="Document type"
                           />
                        </div>
                     </div>
                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">Document Number*</p>
                           <input
                              onChange={(e) => setDocumentNumber(e.target.value)}
                              value={documentNumber}
                              type="text"
                              placeholder="Document Number"
                           />
                        </div>
                     </div>
                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">Document Date*</p>
                           <input
                              onChange={(e) => setDocumentDate(e.target.value)}
                              value={moment(documentDate).format("YYYY-MM-DD")}
                              type="date"
                              placeholder="Document Date"
                           />
                        </div>
                     </div>
                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">Partner Code*</p>
                           <input
                              onChange={(e) => setPartCode(e.target.value)}
                              value={partCode}
                              type="text"
                              placeholder="Partner Code"
                           />
                        </div>
                     </div>
                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">Partner Name*</p>
                           <input
                              onChange={(e) => setPartName(e.target.value)}
                              value={partName}
                              type="text"
                              placeholder="Partner Name"
                           />
                        </div>
                     </div>
                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">Document Total*</p>
                           <input
                              onChange={(e) => setDocumentTotal(e.target.value)}
                              value={documentTotal}
                              type="number"
                              placeholder="Document Total"
                           />
                        </div>
                     </div>
                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">Document Description *</p>
                           <input
                              onChange={(e) => setDocumentDescription(e.target.value)}
                              value={documentDescription}
                              type="text"
                              placeholder="Document Description"
                           />
                        </div>
                     </div>
                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">Ref DocType *</p>
                           <input
                              onChange={(e) => setRefDocType(e.target.value)}
                              value={refDocType}
                              type="text"
                              placeholder="Ref DocType"
                           />
                        </div>
                     </div>
                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">Ref DocNumber *</p>
                           <input
                              onChange={(e) => setRefDocNumber(e.target.value)}
                              value={refDocNumber}
                              type="text"
                              placeholder="Ref DocNumber"
                           />
                        </div>
                     </div>





























                     {/* <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">Cheque Type *</p>
                           <input
                              onChange={(e) => setChequeType(e.target.value)}
                              value={chequeType}
                              type="text"
                              placeholder="Cheque Type"
                           />
                        </div>
                     </div>
                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">Cheque Number *</p>
                           <input
                              onChange={(e) => setChequeNumber(e.target.value)}
                              value={chequeNumber}
                              type="text"
                              placeholder="Cheque Number"
                           />
                        </div>
                     </div>
                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">Cheque Date *</p>
                           <input
                              onChange={(e) => setChequeDate(e.target.value)}
                              value={moment(chequeDate).format("YYYY-MM-DD")}
                              type="date"
                              placeholder="Cheque Date"
                           />
                        </div>
                     </div>
                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">Pay To *</p>
                           <input
                              onChange={(e) => setPayTo(e.target.value)}
                              value={payTo}
                              type="text"
                              placeholder="Pay To"
                           />
                        </div>
                     </div>
                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">ACNO Code *</p>
                           <input
                              onChange={(e) => setAcnoCode(e.target.value)}
                              value={acnoCode}
                              type="text"
                              placeholder="ACNO Code"
                           />
                        </div>
                     </div>
                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">ACNO Description *</p>
                           <input
                              onChange={(e) => setAcnoDescription(e.target.value)}
                              value={acnoDescription}
                              type="text"
                              placeholder="ACNO Description"
                           />
                        </div>
                     </div>
                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">Sl Type *</p>
                           <input
                              onChange={(e) => setSlType(e.target.value)}
                              value={slType}
                              type="text"
                              placeholder="Sl Type"
                           />
                        </div>
                     </div>
                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">Sl Name *</p>
                           <input
                              onChange={(e) => setSlName(e.target.value)}
                              value={slName}
                              type="text"
                              placeholder="Sl Name"
                           />
                        </div>
                     </div>
                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">Amount *</p>
                           <input
                              onChange={(e) => setAmount(e.target.value)}
                              value={amount}
                              type="number"
                              placeholder="Amount"
                           />
                        </div>
                     </div>
                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">Document Remarks *</p>
                           <input
                              onChange={(e) => setDocumentRemarks(e.target.value)}
                              value={documentRemarks}
                              type="text"
                              placeholder="Document Remarks"
                           />
                        </div>
                     </div>
                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">ACNO Remarks *</p>
                           <input
                              onChange={(e) => setAcnoRemarks(e.target.value)}
                              value={acnoRemarks}
                              type="text"
                              placeholder="ACNO Remarks"
                           />
                        </div>
                     </div> */}






















                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">Store (Warehouse) * </p>
                           <Select
                              options={warehouseArr}
                              name="Types"
                              onChange={(e) => setUnitCode(e.value)}
                              value={warehouseArr.find((el) => el.value == unitCode)}
                           />
                        </div>
                     </div>
                     <div className="col-xl-12">
                        <ReversalTable productArr={itemsArr} chats_data_value={(value) => setItemsArr(value)} />
                     </div>
                  </div>


                  <div className="row">
                     <button onClick={() => handleSubmit()} className="submit_buttn">
                        Submit
                     </button>
                  </div>
               </div>
            </div>
         </section>
      </main>
   );
}
export default ReversalAdd;
