import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/aging";


export const getAging = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/?${query.toString()}`);
};

export const downloadAgingExcelFile = () => {
    window.open(`${serverUrl}/downloadAgingExcelFile`);
};

