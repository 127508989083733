import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/warehouse";

export const addWarehouse = (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getWarehouse = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/?${query.toString()}`);
};
export const downloadWareHouseExcel = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/download-excel?${query.toString()}`);
};

export const deleteWarehouse = (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const getById = (id) => {
    return axiosApiInstance.get(`${serverUrl}/getById/${id}`);
};

export const updateWarehouse = (formData, id) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};