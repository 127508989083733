import React, { useEffect, useState } from 'react'
import { HiChevronRight } from 'react-icons/hi'
import { useSearchParams } from 'react-router-dom';
import { getProducts } from '../../services/product.service';
import { addInDemandProducts, getById, updateInDemandProducts } from '../../services/InDemandProducts.service';
import Select from 'react-select';
import { toastError, toastSuccess } from '../Utility/ToastUtils';
import { getBaseInDemandProducts } from '../../services/baseInDemandProducts.service';

function IndemandProduct() {


  const [productArr, setProductArr] = useState([]);
  const [productId, setProductId] = useState("");
  const [note, setNote] = useState("");
  const [price, setPrice] = useState("");
  const [trend, setTrend] = useState("");
  const [infoSource, setInfoSource] = useState("");
  const [ourPrice, setOurPrice] = useState(0);


  const [marketTrendsArr, setMarketTrendsArr] = useState([
    {
      label: "Increase",
      value: "INCREASE"
    },
    {
      label: "Decrease",
      value: "DECREASE"
    },
    {
      label: "stable",
      value: "STABLE"
    }
  ])



  const [searchParams, setSearchParams] = useSearchParams();


  const handleGetProducts = async () => {
    try {
      let query = { forSelectInput: true }
      let { data: res } = await getBaseInDemandProducts({ pageIndex: 0, pageSize: 10000000 }, query)
      if (res.data) {
        setProductArr(res.data);
      }
    }
    catch (err) {
      toastError(err)
    }
  }

  useEffect(() => {
    handleGetProducts()
  }, [])

  const handleSubmit = async () => {
    try {

      if (productId == "") {
        toastError("Product is mandatory !!!");
        return
      }

      if (price <= 0) {
        toastError("Price is mandatory !!!");
        return
      }

      if (note == "") {
        toastError("Note is mandatory !!!");
        return
      }
      if (trend == "") {
        toastError("Trend is mandatory !!!");
        return
      }
      if (infoSource == "") {
        toastError("Info Source is mandatory !!!");
        return
      }
      if (ourPrice == "" || ourPrice <= 0) {
        toastError("Our Price is mandatory !!!");
        return
      }

      let obj = {
        productId: productId.value,
        note,
        price,
        trend,
        infoSource,
        ourPrice,
      }
      if (searchParams.get("id")) {
        let { data: res } = await updateInDemandProducts(obj, searchParams.get("id"))
        if (res.message) {
          toastSuccess(res.message)
        }
      }

      else {
        let { data: res } = await addInDemandProducts(obj)
        if (res.message) {
          toastSuccess(res.message)
        }
      }
    }
    catch (err) {
      toastError(err)
    }
  }




  const handleGetForUpdate = async () => {
    try {
      if (typeof searchParams?.get("id") != 'string')
        return;
      const res = await getById(searchParams?.get("id"));
      if (res.data.data) {
        console.log(res.data, "data")
        if (res.data?.data?.productId) {
          setProductId(prev => ({ ...prev, value: res.data?.data?.productId }))
        }
        if (res.data?.data?.label) {
          setProductId(prev => ({ ...prev, label: res.data?.data?.label }))
        }
        if (res.data?.data?.price) {
          setPrice(res.data?.data?.price)
        }
        if (res.data?.data?.note) {
          setNote(res.data?.data?.note)
        }
        if (res.data?.data?.trend) {
          setTrend(res.data?.data?.trend)
        }
        if (res.data?.data?.infoSource) {
          setInfoSource(res.data?.data?.infoSource)
        }
        if (res.data?.data?.ourPrice) {
          setOurPrice(res.data?.data?.ourPrice)
        }


      }

    } catch (error) {
      toastError(error)
    }

  }
  useEffect(() => {
    if (searchParams?.get("id")) {
      handleGetForUpdate()
    }

  }, [searchParams.get("id")])






  return (
    <main className='bg-white'>
      <section className="user_page_sec">
        <div className="user_header">
          <span className='page_name'>Associated product</span>
          {/* <p className="breadcrumb">
            <span className='parent'>Dashboard </span> <span> <HiChevronRight /></span> <span className='child'>Associated product</span>
          </p> */}
        </div>

        <div className="user_form_box">
          <div className="form_header">
            <h6 className="heading">Associated product</h6>
            <span className='sub_heading'>Create Associated product</span>
          </div>

          <div className="form_box">
            <div className="row">
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Product Name*</p>
                  <Select options={productArr} onChange={(e) => setProductId(e)} value={productId} />
                  {/* <input type="text" placeholder='Product Name' /> */}
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Source of Information*</p>
                  <input onChange={(e) => setInfoSource(e.target.value)} value={infoSource} type="text" placeholder='Information' />
                </div>
              </div>

            </div>
            <div className="row">
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Market Price*</p>
                  <input onChange={(e) => setPrice(e.target.value)} value={price} type="number" placeholder='Market Price' />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Market Trend*</p>
                  <Select options={marketTrendsArr} onChange={(e) => setTrend(e)} value={trend} />
                  {/* <input onChange={(e) => setTrend(e.target.value)} value={trend} type="text" placeholder='Market Trend' /> */}
                  {/* <CiLock className='lock_icn'/> */}
                </div>
              </div>

            </div>


            <div className="row">
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Our Price*</p>
                  <input onChange={(e) => setOurPrice(e.target.value)} value={ourPrice} type="number" placeholder='Our Price' />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Note*</p>
                  <input onChange={(e) => setNote(e.target.value)} value={note} type="text" placeholder='Note' />
                </div>
              </div>


            </div>

            <div className="row">

              <button onClick={() => handleSubmit()} className="submit_buttn">Submit</button>
            </div>



          </div>




        </div>
      </section>



    </main>
  )
}

export default IndemandProduct