import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { CiLock } from "react-icons/ci";
import { getUser } from "../../services/users.service";
import { addLeave, getById, updateLeave } from "../../services/leave.service";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import Select from 'react-select';
import { leaveStatus, leaveTypes } from "../../utils/constants";

function Leave() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [userId, setUserId] = useState("");
  const [userArr, setUserArr] = useState([]);
  const [typeOfLeave, setTypeOfLeave] = useState("");
  const [cause, setCause] = useState("");
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());
  const [status, setStatus] = useState("APPROVED");
  const [notes, setNotes] = useState("");

  const [statusArr, setStatusArr] = useState([]);
  const [leaveTypeArr, setLeaveTypeArr] = useState([]);


  useEffect(() => {
    let tempArr = []
    for (const [key, value] of Object.entries(leaveTypes)) {
      tempArr.push({ label: key, value: key });
      console.log(`${key}: ${value}`);
    }
    setLeaveTypeArr(tempArr)

    let tempArr2 = []
    for (const [key, value] of Object.entries(leaveStatus)) {
      tempArr2.push({ label: key, value: key });
      console.log(`${key}: ${value}`);
    }
    setStatusArr(tempArr2)
  }, [])

  const handleGetAllUsers = async () => {
    try {
      let query = { isForSelectInput: true }
      let { data: response } = await getUser({ pageIndex: 0, pageSize: 10000000 }, query)
      if (response) {
        setUserArr(response.data)
      }
    } catch (error) {
      toastError(error)
    }
  };
  useEffect(() => {
    handleGetAllUsers();
  }, []);



  const handleSubmit = async () => {
    try {

      if (userId == "") {
        toastError("User is mandatory !!!");
        return
      }
      if (cause == "") {
        toastError("Cause is mandatory !!!");
        return
      }
      if (notes == "") {
        toastError("Notes is mandatory !!!");
        return
      }

      let tempFrom = new Date(from);
      tempFrom.setHours(0, 0, 0, 0);

      let tempTo = new Date(to);
      tempTo.setHours(23, 59, 59, 59);


      let obj = {
        userId: userId.value,
        cause,
        typeOfLeave,
        from: tempFrom,
        to: tempTo,
        status,
        notes
      }
      if (searchParams.get("id")) {
        let { data: res } = await updateLeave(obj, searchParams.get("id"))
        if (res.message) {
          toastSuccess(res.message)
        }
      }

      else {
        let { data: res } = await addLeave(obj)
        if (res.message) {
          toastSuccess(res.message)
        }
      }
    }
    catch (err) {
      toastError(err)
    }
  }




  const handleGetForUpdate = async () => {
    try {
      if (typeof searchParams?.get("id") != 'string')
        return;
      const res = await getById(searchParams?.get("id"));
      if (res.data.data) {
        console.log(res.data, "data")
        if (res.data?.data?.userId) {
          setUserId(prev => ({ ...prev, value: res.data?.data?.userId }))
        }
        if (res.data?.data?.label) {
          setUserId(prev => ({ ...prev, label: res.data?.data?.label }))
        }
        if (res.data?.data?.typeOfLeave) {
          setTypeOfLeave(res.data?.data?.typeOfLeave)
        }
        if (res.data?.data?.cause) {
          setCause(res.data?.data?.cause)
        }
        if (res.data?.data?.from) {
          setFrom(res.data?.data?.from)
        }
        if (res.data?.data?.to) {
          setTo(res.data?.data?.to)
        }
        if (res.data?.data?.status) {
          setStatus(res.data?.data?.status)
        }
        if (res.data?.data?.notes) {
          setNotes(res.data?.data?.notes)
        }

      }

    } catch (error) {
      toastError(error)
    }

  }
  useEffect(() => {
    if (searchParams?.get("id")) {
      handleGetForUpdate()
    }

  }, [searchParams.get("id")])


  return (
    <main className="bg-white">
      <section className="user_page_sec">
        <div className="user_header">
          <span className="page_name">Leave</span>
          {/* <p className="breadcrumb">
            <span className="parent">Dashboard </span>{" "}
            <span>
              {" "}
              <HiChevronRight />
            </span>{" "}
            <span className="child">Leave</span>
          </p> */}
        </div>

        <div className="user_form_box">
          <div className="form_header">
            <h6 className="heading">Leave</h6>
            <span className="sub_heading"> Add Leave</span>
          </div>

          <div className="form_box">
            <div className="row">
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">User Name*</p>
                  <Select options={userArr} onChange={(e) => setUserId(e)} value={userId} />

                </div>
              </div>

              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Type of Leave*</p>
                  <Select options={leaveTypeArr} onChange={(e) => setTypeOfLeave(e.value)} value={{ label: typeOfLeave, value: leaveTypes }} />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Status of Leave*</p>
                  <Select options={statusArr} onChange={(e) => setStatus(e.value)} value={{ label: status, value: status }} />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Notes*</p>
                  <input onChange={(e) => setNotes(e.target.value)} value={notes} type="text" placeholder="Notes" />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Cause*</p>
                  <input onChange={(e) => setCause(e.target.value)} value={cause} type="text" placeholder="Leave Cause" />
                  {/* <CiLock className='lock_icn'/> */}
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">From*</p>
                  <input min={moment(new Date()).format("YYYY-MM-DD")} onChange={(e) => setFrom(e.target.value)} value={moment(from).format("YYYY-MM-DD")} type="date" />
                </div>
              </div>

            </div>

            <div className="row">
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">To*</p>
                  <input min={moment(from).format("YYYY-MM-DD")} onChange={(e) => setTo(e.target.value)} value={moment(to).format("YYYY-MM-DD")} type="date" />
                </div>
              </div>
            </div>

            <div className="row">
              <button onClick={() => handleSubmit()} className="submit_buttn">Submit</button>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Leave
