import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/order";


export const addOrder = (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};


export const getOrder = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/?${query.toString()}`);
};
export const getOrderProduct = () => {
    return axiosApiInstance.get(`${serverUrl}/getOrderProduct`);
};

export const downloadExcel = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/download-excel?${query.toString()}`);
};

export const deleteOrder = (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};


export const getById = (id) => {
    return axiosApiInstance.get(`${serverUrl}/getById/${id}`);
};


export const updateOrder = (formData, id) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};

