import React, { useEffect, useState } from 'react'
import { HiChevronRight } from 'react-icons/hi'
import { toastError } from '../../utils/toastUtils';
import { addSupport, getById, updateSupport } from '../../services/support.service';
import { useSearchParams } from 'react-router-dom';
import { toastSuccess } from '../Utility/ToastUtils';


function SupportPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async () => {
    try {

      if (name == "") {
        toastError("Name is mandatory !!!");
        return
      }

      if (email == "") {
        toastError("Email is mandatory !!!");
        return
      }

      if (message == "") {
        toastError("Message is mandatory !!!");
        return
      }


      let obj = {

        name,
        email,
        message,
      }
      if (searchParams.get("id")) {
        let { data: res } = await updateSupport(obj, searchParams.get("id"))
        if (res.message) {
          toastSuccess(res.message)
        }
      }

      else {
        let { data: res } = await addSupport(obj)
        if (res.message) {
          toastSuccess(res.message)
        }
      }
    }
    catch (err) {
      toastError(err)
    }
  }




  const handleGetForUpdate = async () => {
    try {
      if (typeof searchParams?.get("id") != 'string')
        return;
      const res = await getById(searchParams?.get("id"));
      if (res.data.data) {
        console.log(res.data, "data")
        if (res.data?.data?.name) {
          setName(res.data?.data?.name)
        }
        if (res.data?.data?.email) {
          setEmail(res.data?.data?.email)
        }
        if (res.data?.data?.message) {
          setMessage(res.data?.data?.message)
        }
      }

    } catch (error) {
      toastError(error)
    }

  }
  useEffect(() => {
    if (searchParams?.get("id")) {
      handleGetForUpdate()
    }

  }, [searchParams.get("id")])
  return (
    <main className='bg-white'>
      <section className="user_page_sec">
        {/* <div className="user_header">
          <span className='page_name'>Mailing List</span>
          <p className="breadcrumb">
            <span className='parent'>Dashboard </span> <span> <HiChevronRight /></span> <span className='child'>Mailing List</span>
          </p>
        </div> */}

        <div className="user_form_box">
          <div className="form_header">
            <h6 className="heading">Mailing List</h6>
            <span className='sub_heading'>Create Mailing List</span>
          </div>

          <div className="form_box">

            <div className="row">
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Name*</p>
                  <input onChange={(e) => setName(e.target.value)} value={name} type="text" placeholder='Enter Name' />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Email*</p>
                  <input onChange={(e) => setEmail(e.target.value)} value={email} type="text" placeholder='Enter Email' />
                </div>
              </div>
              <div className="col-xl-12">
                <div className="user_form_ip_box">
                  <p className="ip_label">Message*</p>
                  <input multiple onChange={(e) => setMessage(e.target.value)} value={message} type="text" placeholder='Enter message' />
                </div>
              </div>
            </div>

            <div className="row">
              <button onClick={() => handleSubmit()} className="submit_buttn">Submit</button>
            </div>

          </div>

        </div>

      </section>

    </main>
  )
}

export default SupportPage