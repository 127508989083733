import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { CiLock } from "react-icons/ci";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import { addUser, getById, getUser, updateUser } from "../../services/users.service";
import { useSearchParams } from "react-router-dom";
import { generateFilePath } from "../Utility/utils";
import { departmentObj, rolesObj } from "../../utils/roles";
import Select from "react-select";
import { getZone } from "../../services/zone.service";
import { getState } from "../../services/state.service";
import { getWarehouse } from "../../services/warehouse.service";
import { getLab } from "../../services/lab.service";
function Userpage() {
   const [name, setName] = useState("");
   const [image, setImage] = useState("");
   const [email, setEmail] = useState("");
   const [phone, setPhone] = useState("");
   const [password, setPassword] = useState("");

   const [manageUser, setManageUser] = useState(false);
   const [manageProducts, setManageProducts] = useState(false);

   const [searchParams, setSearchParams] = useSearchParams();

   const [role, setRole] = useState({});
   const [department, setDepartment] = useState({});

   const [labId, setLabId] = useState({ label: "", value: "" });
   const [storeCodeArr, setStoreCodeArr] = useState([]);

   const [mainLabArr, setMainLabArr] = useState([]);

   const [displayStoreCodeArr, setDisplayStoreCodeArr] = useState([]);

   const [rolesArr, setRolesArr] = useState([]);
   const [departmentsArr, setDepartmentsArr] = useState([]);

   useEffect(() => {
      handleGetWarehouseForStoreCodes();
      let tempRolesArr = [];

      for (const key in rolesObj) {
         if (Object.hasOwnProperty.call(rolesObj, key)) {
            const element = rolesObj[key];
            if (element != "ADMIN") {
               tempRolesArr.push({ label: element, value: element });
            }
         }
      }

      setRolesArr([...tempRolesArr]);

      let tempDepartmentArr = [];

      for (const key in departmentObj) {
         if (Object.hasOwnProperty.call(departmentObj, key)) {
            const element = departmentObj[key];
            tempDepartmentArr.push({ label: element, value: element });
         }
      }

      setDepartmentsArr([...tempDepartmentArr]);
   }, []);

   const handleRegister = async () => {
      try {
         if (name == "") {
            toastError("Name is mandatory !!!");
            return;
         }

         if (email == "") {
            toastError("Email is mandatory !!!");
            return;
         }
         if (!searchParams.get("id")) {
            if (password == "") {
               toastError("Password is mandatory !!!");
               return;
            }
         }

         if (phone == "") {
            toastError("Phone is mandatory !!!");
            return;
         }

         // if (image == "") {
         //   toastError("Image is mandatory !!!");
         //   return
         // }

         let obj = {
            name,
            email,
            phone,
            image,
            role: role.value,
            department: department.value,
            storeCodeArr: storeCodeArr.map((el) => ({ storeCode: el.value })),
            accessObj: {
               manageUsers: manageUser,
               manageProducts: manageProducts,
            },
         };

         if (password && password != "") {
            obj.password = password;
         }

         if (department.value == departmentObj.LAB) {
            obj.labId = labId;
         }

         if (searchParams.get("id")) {
            delete obj.password;

            let { data: res } = await updateUser(obj, searchParams.get("id"));
            if (res.message) {
               toastSuccess(res.message);
            }
         } else {
            let { data: res } = await addUser(obj);
            if (res.message) {
               toastSuccess(res.message);
            }
         }
      } catch (err) {
         toastError(err);
      }
   };

   const HandleGetByIdOnInit = async (id) => {
      try {
         let { data: res } = await getById(id);
         if (res.data) {
            setName(res.data.name);
            setEmail(res.data.email);
            setPhone(res.data.phone);
            setLabId(res.data.labId);
            setImage(res.data.image);
            console.log(res?.data?.accessObj?.manageUsers, "res?.data?.accessObj?.manageUser");
            setManageUser(res?.data?.accessObj?.manageUsers ? res?.data?.accessObj?.manageUsers : false);
            setManageProducts(res?.data?.accessObj?.manageProducts ? res?.data?.accessObj?.manageProducts : false);
            setRole({ label: res.data.role, value: res.data.role });
            if (res.data.department) {
               setDepartment({ label: res.data.department, value: res.data.department });
            }

            // TODO: need to refactor this to be more efficient
            if (res.data?.storeCodeArr?.length) {
               let { data: warehouseRes } = await getWarehouse({ pageIndex: 0, pageSize: 10000000 }, "");
               if (warehouseRes.data) {
                  let finalArr = res.data?.storeCodeArr
                     .map((el) => {
                        let data = warehouseRes.data.find((ele) => ele.storeCode === el.storeCode);
                        if (data) {
                           return { label: data.name, value: data.storeCode };
                        }
                        return null;
                     })
                     .filter((el) => el);
                  setStoreCodeArr(finalArr);
               }
            }
         }
      } catch (err) {
         toastError(err);
      }
   };

   const handleGetWarehouseForStoreCodes = async () => {
      try {
         let { data: res } = await getWarehouse({ pageIndex: 0, pageSize: 10000000 }, "");
         if (res.message) {
            setDisplayStoreCodeArr(res.data.map((el) => ({ label: el.name, value: el.storeCode })));
            console.log(res.data, "warehouses");
         }
      } catch (err) {
         toastError(err);
      }
   };

   useEffect(() => {
      if (searchParams.get("id")) {
         HandleGetByIdOnInit(searchParams.get("id"));
      }
   }, [searchParams.get("id")]);

   const getBase64 = (file, cb) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
         cb(reader.result);
      };
      reader.onerror = function (error) {};
   };
   const handleFileSelection = (event) => {
      if (event.target.files[0]) {
         getBase64(event.target.files[0], (result) => {
            // setImage(event.target.files[0]);
            setImage(result);
         });
      }
   };



   const handleGet = async (pageValue, limitValue, queryValue) => {
    try {
       let query = "forSelectInput=true";
       if (queryValue && queryValue != "") {
          query = `${queryValue}`
       }
       console.log(query, "query")
       let { data: res } = await getLab({ pageIndex: pageValue, pageSize: limitValue,forSelectInput:true }, { query });
       if (res.message) {
          setMainLabArr(res.data);
       }
    } catch (err) {
       toastError(err);
    }
 };

 useEffect(() => {
   
  handleGet(1,1999999, "")
 }, [])
 
   return (
      <main className="bg-white">
         <section className="user_page_sec">
            {/* <div className="user_header">
          <span className='page_name'>User</span>
          <p className="breadcrumb">
            <span className='parent'>Dashboard </span> <span> <HiChevronRight /></span> <span className='child'>User</span>
          </p>
        </div> */}

            <div className="user_form_box">
               <div className="form_header">
                  <h6 className="heading">User</h6>
                  <span className="sub_heading">Create User</span>
               </div>

               <div className="form_box">
                  <div className="row">
                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">Name*</p>
                           <input
                              onChange={(e) => setName(e.target.value)}
                              value={name}
                              type="text"
                              placeholder="Enter Name"
                           />
                        </div>
                     </div>
                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">Email*</p>
                           <input
                              onChange={(e) => setEmail(e.target.value)}
                              value={email}
                              type="text"
                              placeholder="Enter Email"
                           />
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">Mobile no*</p>
                           <input
                              onChange={(e) => setPhone(e.target.value)}
                              value={phone}
                              type="text"
                              placeholder="Enter Mobile no"
                           />
                        </div>
                     </div>
                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">Password*</p>
                           <input
                              onChange={(e) => setPassword(e.target.value)}
                              value={password}
                              type="password"
                              placeholder="Enter Password"
                           />
                           <CiLock className="lock_icn" />
                        </div>
                     </div>

                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">Role*</p>
                           <Select onChange={(e) => setRole(e)} value={role} options={rolesArr} />
                        </div>
                     </div>

                     {(role.value === rolesObj.AGM ||
                        role.value === rolesObj.M ||
                        role.value === rolesObj.AM ||
                        role.value === rolesObj.USER) && (
                        <div className="col-xl-6">
                           <div className="user_form_ip_box">
                              <p className="ip_label">Locations*</p>
                              <Select
                                 onChange={(e) => {
                                    setStoreCodeArr(e);
                                    console.log(e);
                                 }}
                                 value={storeCodeArr}
                                 isMulti
                                 closeMenuOnSelect={false}
                                 options={displayStoreCodeArr}
                              />
                           </div>
                        </div>
                     )}

                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">Department*</p>
                           <Select onChange={(e) => setDepartment(e)} value={department} options={departmentsArr} />
                        </div>
                     </div>
                     {
                      department && department.value == departmentObj?.LAB && 
                     <div className="col-xl-6">
                        <div className="user_form_ip_box">
                           <p className="ip_label">Lab*</p>
                           <Select onChange={(e) => setLabId(e)} value={labId} options={mainLabArr} />
                        </div>
                     </div>
                     }
                  </div>

                  <div className="row">
                     <div className="col-xl-6">
                        <div className="user_form_img_box">
                           <p className="ip_label">Image*</p>
                           <div>
                              {image && image.includes("base64") ? (
                                 <img src={image} style={{ height: 100, width: 100 }} alt="" srcset="" />
                              ) : (
                                 <img
                                    src={generateFilePath(image)}
                                    style={{ height: 100, width: 100 }}
                                    alt=""
                                    srcset=""
                                 />
                              )}
                           </div>
                           <button className="choose_img_buttn">Choose File</button>
                           <input
                              onChange={(e) => handleFileSelection(e)}
                              type="file"
                              placeholder="No file Chosen"
                           />
                        </div>
                     </div>
                  </div>

                  {/* <div className="row">
                     <div className="col-xl-6">
                        <div className="user_form_img_box">
                           <p className="ip_label">Manage*</p>
                           <div className="row align-items-center">
                              <div className="col-1">
                                 <input
                                    style={{ height: 20 }}
                                    onChange={(e) => setManageUser(e.target.checked)}
                                    checked={manageUser}
                                    type="checkbox"
                                 />
                              </div>
                              <div className="col-8">Manage Users</div>
                           </div>
                           <div className="row align-items-center">
                              <div className="col-1">
                                 <input
                                    style={{ height: 20 }}
                                    onChange={(e) => setManageProducts(e.target.checked)}
                                    checked={manageProducts}
                                    type="checkbox"
                                 />
                              </div>
                              <div className="col-8">Manage Products</div>
                           </div>
                        </div>
                     </div>
                  </div> */}
                  <div className="row">
                     <button onClick={() => handleRegister()} className="submit_buttn">
                        Submit
                     </button>
                  </div>
               </div>
            </div>
         </section>
      </main>
   );
}

export default Userpage;
