import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { HiChevronRight } from "react-icons/hi";
import Select from "react-select";
import { getComodityProductsSalesYearWise, getComodityVsSpecialityProductsSalesYearWise } from "../../services/dispatchingInputSaleInvoice.service";
import { getProductsByCategoryName } from "../../services/product.service";
import CustomButton from "../Utility/Button";
import { DashboardBox, DashboardChart } from "../Utility/DashboardBox";
import { toastError } from "../Utility/ToastUtils";
import { quantityFormatter } from "../Utility/utils";
import { handleReturnBarGraphColorArr } from "../Utility/barGraphColorArray";
import moment from "moment";
import { returnFormatedDateTypeObject } from "../../utils/DateUtils";
export default function TotalIncentiveVsNonIncentiveSales() {
  const [comodityProductsArr, setComodityProductsArr] = useState([]);
  const [specialityProductsArr, setSpecialityProductsArr] = useState([]);
  const [barLabels, setBarLabels] = useState([
    "",
  ]);
  const [barLabels2, setBarLabels2] = useState([
    "",
  ]);
  const [yearsArr, setYearsArr] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [selectedYear, setSelectedYear] = useState("");
  const [selectedReportType, setSelectedReportType] = useState("Monthly");

  const [reportType, setReportType] = useState([
    { label: "Quarterly", value: "Quarterly" },
    { label: "Monthly", value: "Monthly" },
  ]);
  const [selectedComodityProductsArr, setSelectedComodityProductsArr] =
    useState("");
  const [selectedSpecialityProductsArr, setSelectedSpecialityProductsArr] =
    useState([]);

  const [displayYear, setDisplayYear] = useState("");
  const [displayProduct, setDisplayProduct] = useState("");

  const [unitOfMeasurement, setUnitOfMeasurement] = useState("");
  const [comoditySalesArr, setComoditySalesArr] = useState([]);
  const [specialitySalesArr, setSpecialitySalesArr] = useState([]);

  const HandleGetComodityProducts = async () => {
    try {
      let query = { name: "Commodity" };
      let { data: res } = await getProductsByCategoryName(query);
      if (res.data) {
        setComodityProductsArr(res.data);
      }
    } catch (error) {
      toastError(error);
    }
  };

  const HandleGetSpecialityProducts = async () => {
    try {
      let query = { name: "speciality" };
      let { data: res } = await getProductsByCategoryName(query);
      if (res.data) {
        setSpecialityProductsArr(res.data);
      }
    } catch (error) {
      toastError(error);
    }
  };

  const HandleGetComodityProductsSalesYearWise = async () => {
    try {
      let query = {};
      // if(selectedYear == ""){
      //   toastError("Please select an year");
      //   return
      // }

      if (startDate == "") {
        toastError("Please select a Start Date");
        return
      }

      if (endDate == "") {
        toastError("Please select a End Date");
        return
      }

      if (startDate && startDate != "") {
        let strtDate = returnFormatedDateTypeObject(startDate)
        query = { ...query, startDate: strtDate.toISOString() };
      }

      if (endDate && endDate != "") {
        let edDate = returnFormatedDateTypeObject(endDate)
        edDate.setDate(edDate.getDate() + 1)
        edDate.setTime(edDate.getTime() - 1)

        query = { ...query, endDate: edDate.toISOString() };
      }

      // if (selectedYear && selectedYear != "") {
      //   query = { ...query, selectedYear: selectedYear };
      // }
      if (selectedReportType && selectedReportType != "") {
        query = { ...query, selectedReportType: selectedReportType };
      }
      if (
        selectedComodityProductsArr &&
        selectedComodityProductsArr.length > 0
      ) {
        query = {
          ...query,
          selectedComodityProductsArr: JSON.stringify(selectedComodityProductsArr.map(el => el.value)),
        };
      }
      else {
        toastError("Please select a Non-Incentive Product");
        return
      }
      if (
        selectedSpecialityProductsArr &&
        selectedSpecialityProductsArr.length > 0
      ) {
        query = {
          ...query,
          selectedSpecialityProductsArr: JSON.stringify(selectedSpecialityProductsArr.map(el => el.value)),
        };
      } else {
        toastError("Please select an Incentive Product");
        return
      }

      setDisplayYear(selectedYear);
      let { data: res } = await getComodityVsSpecialityProductsSalesYearWise(query);
      if (res.data) {


        setBarLabels(res.data.comodityOrderCount.map(el => el.label));
        setComoditySalesArr(res.data.comodityOrderCount);


        setBarLabels2(res.data.specialityOrderCount.map(el => el.label));
        setSpecialitySalesArr(res.data.specialityOrderCount);

        setUnitOfMeasurement(res.data.unitOfMeasurement);

        // if (selectedReportType == "Quarterly") {
        //
        //   // setBarLabels(["Q1", "Q2", "Q3", "Q4"]);
        //
        //
        //   setComoditySalesArr(res.data.comodityOrderCount);
        //   setSpecialitySalesArr(res.data.specialityOrderCount);
        // } else {
        //   setBarLabels([
        //     "April",
        //     "May",
        //     "June",
        //     "July",
        //     "August",
        //     "September",
        //     "October",
        //     "November",
        //     "December",
        //     "January",
        //     "February",
        //     "March",
        //   ]);
        //   setComoditySalesArr(res.data.comodityOrderCount);
        //   setSpecialitySalesArr(res.data.specialityOrderCount);
        // }
        // setUnitOfMeasurement(res.data.unitOfMeasurement);
      }
    } catch (error) {
      toastError(error);
    }
  };

  const handleCalculateLastFiveYears = () => {
    let tempArr = [];
    let today = new Date();

    let totalYears = today.getFullYear() - 2017;

    for (let index = 0; index < totalYears; index++) {
      let obj = {};
      let year = today.getFullYear() - index;
      obj.label = `${year}-${parseInt(year) + 1}`;
      obj.value = year;
      tempArr.push(obj);
    }

    setYearsArr(tempArr);
  };

  useEffect(() => {
    HandleGetComodityProducts();
    handleCalculateLastFiveYears();
    HandleGetSpecialityProducts();
  }, []);

  return (
    <main className="bg-white">
      <section className="dashboard-head mb-3">
        <div className="row">
          <div className="col-xl-10"></div>
          <div className="col-xl-2 d-flex justify-content-end">
            {/* <div className="user_header">
              <p className="breadcrumb">
                <span className="parent">Dashboard </span>{" "}
                <span>
                  {" "}
                  <HiChevronRight />
                </span>{" "}
                <span className="child">Reports</span>
              </p>
            </div> */}
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-12 mb-5 p-5 border rounded">
              <div className="row">
                <h3 className="blue-1 mb-5">
                  Total Incentive vs Non-Incentive Sales Comparision
                </h3>
                <div className="row  d-flex justify-content-between">
                  <div className="col-lg-11">
                    <DashboardBox className="dashboard-summary">
                      <div className="row align-items-center">
                        <div className="col-xl-12">
                          <h5 className="blue-1">Incentive Products</h5>
                          <Select
                            options={specialityProductsArr}
                            isMulti
                            value={selectedSpecialityProductsArr}
                            closeMenuOnSelect={false}
                            onChange={(e) =>
                              setSelectedSpecialityProductsArr(e)
                            }
                          />
                        </div>
                      </div>
                    </DashboardBox>
                  </div>
                  <div className="col-12 col-lg-1">
                    <CustomButton
                      isBtn
                      btntype="button"
                      ClickEvent={() =>
                        setSelectedSpecialityProductsArr([
                          ...specialityProductsArr,
                        ])
                      }
                      iconName="fa-solid fa-check"
                      extraClass={"mt-3 pe-3"}
                      btnName="Select all"
                    />
                  </div>
                </div>
                <div className="row  d-flex justify-content-between">
                  <div className="col-12 col-lg-11">
                    <DashboardBox className="dashboard-summary">
                      <div className="row align-items-center">
                        <div className="col-xl-12">
                          <h5 className="blue-1">Non-Incentive Products</h5>
                          <Select
                            options={comodityProductsArr}
                            isMulti
                            value={selectedComodityProductsArr}
                            closeMenuOnSelect={false}
                            onChange={(e) => setSelectedComodityProductsArr(e)}
                          />
                        </div>
                      </div>
                    </DashboardBox>
                  </div>
                  <div className="col-12 col-lg-1">
                    <CustomButton
                      isBtn
                      btntype="button"
                      ClickEvent={() =>
                        setSelectedComodityProductsArr([
                          ...comodityProductsArr,
                        ])
                      }
                      iconName="fa-solid fa-check"
                      extraClass={"mt-3 pe-3"}
                      btnName="Select all"
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <DashboardBox className="dashboard-summary">
                    <div className="row align-items-center">
                      <div className="col-12">
                        <h5 className="blue-1">Dates:</h5>
                        {/* <Select
                          options={yearsArr}
                          onChange={(e) => setSelectedYear(e.value)}
                        /> */}
                        <div className="row  d-flex">


                          <div className="col-6 mt-1">
                            <label className="form-label">Start Date</label>
                            <input type="date"className="form-control" value={startDate} onChange={e => setStartDate(e.target.value)} />
                          </div>

                          <div className="col-6 mt-1">
                            <label className="form-label">End Date</label>
                            <input type="date"className="form-control" value={endDate} onChange={e => setEndDate(e.target.value)} />
                          </div>

                        </div>
                      </div>
                    </div>
                  </DashboardBox>
                </div>

                <div className="col-12 col-lg-3">
                  <DashboardBox className="dashboard-summary">
                    <div className="row align-items-center">
                      <div className="col-xl-9">
                        <div className="right_content_box">
                          <h5 className="blue-1">Type</h5>
                          <Select
                            options={reportType}
                            value={{
                              label: selectedReportType,
                              value: selectedReportType,
                            }}
                            onChange={(e) => setSelectedReportType(e.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </DashboardBox>
                </div>
                <div className="col-12 col-lg-3 mt-5">
                  <CustomButton
                    isBtn
                    btntype="button"
                    ClickEvent={HandleGetComodityProductsSalesYearWise}
                    iconName="fa-solid fa-check"
                    extraClass={"mt-3"}
                    btnName="Submit"
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-12 col-lg-6">
                  <DashboardChart>
                    {comoditySalesArr &&
                      comoditySalesArr.length > 0 && (
                        <>
                          <h5 className="blue-1 my-4">
                            Non-Incentive products sales in (
                            {
                              !!startDate &&
                              <>{moment(startDate).format('DD/MM/YYYY')}</>
                            }
                            {" "}-{" "}
                            {
                              !!endDate &&
                              <>{moment(endDate).format('DD/MM/YYYY')}</>
                            }
                            )
                          </h5>
                          <Bar
                            data={{
                              labels: barLabels,
                              datasets: [
                                {
                                  label: "",
                                  backgroundColor: handleReturnBarGraphColorArr(comoditySalesArr?.map((el) => el?.value)),
                                  borderColor: "#2e4765",
                                  data: comoditySalesArr?.map(
                                    (el) => el?.value
                                  ),
                                },
                              ],
                            }}
                          />
                          <h5 className="blue-1 my-4">
                            Total Commodity products sales in ({
                              !!startDate &&
                              <>{moment(startDate).format('DD/MM/YYYY')}</>
                            }
                            {" "}-{" "}
                            {
                              !!endDate &&
                              <>{moment(endDate).format('DD/MM/YYYY')}</>
                            }) is{" "}
                            {quantityFormatter.format(comoditySalesArr.reduce(
                              (acc, el, index) => acc + el.value,
                              0
                            ))}{" "}
                            QTY
                          </h5>
                        </>
                      )}
                  </DashboardChart>
                </div>
                <div className="col-12 col-lg-6">
                  <DashboardChart>
                    {specialitySalesArr &&
                      specialitySalesArr.length > 0 && (
                        <>
                          <h5 className="blue-1 my-4">
                            Incentive products sales in (
                            {
                              !!startDate &&
                              <>{moment(startDate).format('DD/MM/YYYY')}</>
                            }
                            {" "}-{" "}
                            {
                              !!endDate &&
                              <>{moment(endDate).format('DD/MM/YYYY')}</>
                            }
                            )
                          </h5>
                          <Bar
                            data={{
                              labels: barLabels2,
                              datasets: [
                                {
                                  label: "",
                                  backgroundColor: handleReturnBarGraphColorArr(specialitySalesArr?.map((el) => el?.value)),
                                  borderColor: "#2e4765",
                                  data: specialitySalesArr?.map(
                                    (el) => el?.value
                                  ),
                                },
                              ],
                            }}
                          />
                          <h5 className="blue-1 my-4">
                            Total Speciality products sales in (
                            {
                              !!startDate &&
                              <>{moment(startDate).format('DD/MM/YYYY')}</>
                            }
                            {" "}-{" "}
                            {
                              !!endDate &&
                              <>{moment(endDate).format('DD/MM/YYYY')}</>
                            }
                            ) is{" "}
                            {quantityFormatter.format(specialitySalesArr.reduce(
                              (acc, el, index) => acc + el.value,
                              0
                            ))}{" "}
                            QTY
                          </h5>
                        </>
                      )}
                  </DashboardChart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
