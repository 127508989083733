import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AuthorizedRoutes from "./AuthorizedRoutes";

import { useSelector } from "react-redux";
import UnauthorizedRoutes from "./UnauthorizedRoutes";
import { logoutUser } from "../redux/actions/auth/auth.actions";
import { axiosApiInstance } from "../App";

export default function RootRouter() {
  const authObj = useSelector((state) => state.auth);
  const token1 = useSelector((state) => state.auth.token);
    
  if(token1){

      axiosApiInstance.interceptors.request.use(
          async (config) => {
              if (token1) {
                  config.headers["authorization"] = "Bearer " + token1;
                 }
                 console.log(config,"config")
                 return config;
             },
             async (error) => {
                 console.log("INterceptor error", error);
                 Promise.reject(error);
                 await logoutUser();
             }
         );
         axiosApiInstance.interceptors.response.use(
             (res) => {
                 // Add configurations here
                 console.log(res)
                 return res;
             },
             async (err) => {
                 console.log("INterceptor error", err);
                 
                 await logoutUser();
                 
                 return Promise.reject(err);
             }
         );
     }
//   useEffect(() => {
//       console.log("triggred", token1)
//   }, [token1]);

  return <Router>{authObj?.isAuthorized ? <AuthorizedRoutes /> : <UnauthorizedRoutes />}</Router>;
}
