import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { MdOutlineAdd } from "react-icons/md";
import { DashboardTable } from "../Utility/DashboardBox";
import { toastError } from "../Utility/ToastUtils";
import { getProducts } from "../../services/product.service";
import Select from 'react-select';
import { getWarehouse } from "../../services/warehouse.service";
import moment from "moment";

export default function ReversalTable({ productArr, chats_data_value }) {
  const [query, setQuery] = useState("");


  const chats_columns = [
    {
      name: "SR.No",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "5%",
    },
    
    {
      name: "Cheque Type",
      cell: (row, index) => <input className="form-control" name="chequeType" onChange={(e) => handleChangeValue(e, "chequeType", index)} value={row?.chequeType} />,
      width:"10%"
    },
    {
      name: "Cheque Number",
      cell: (row, index) => <input className="form-control" name="chequeNumber" onChange={(e) => handleChangeValue(e, "chequeNumber", index)} value={row?.chequeNumber} />,
    },
    {
      name: "Cheque Date",
      cell: (row, index) => <input className="form-control" type="date" name="chequeDate" onChange={(e) => handleChangeValue(e, "chequeDate", index)} value={moment(row?.chequeDate).format("YYYY-MM-DD") } />,
    },
    {
      name: "payTo",
      grow: 1,
      cell: (row, index) => <input className="form-control" type="text" name="payTo" onChange={(e) => handleChangeValue(e, "payTo", index)} value={row?.payTo} />,
    },
    {
      name: "Acno Code",
      grow: 1,
      cell: (row, index) => <input className="form-control" type="text" name="acnoCode" onChange={(e) => handleChangeValue(e, "acnoCode", index)} value={row?.acnoCode} />,
    },
    {
      name: "Acno Description",
      cell: (row, index) => <input className="form-control" type="text" name="acnoDescription" onChange={(e) => handleChangeValue(e, "acnoDescription", index)} value={row?.acnoDescription} />,
    },
    {
      name: "SL Type",
      grow: 1,
      cell: (row, index) => <input className="form-control" type="text" name="slType" onChange={(e) => handleChangeValue(e, "slType", index)} value={row?.slType} />,
    },
    {
      name: "SL Name",
      grow: 1,
      cell: (row, index) => <input className="form-control" type="text" name="slName" onChange={(e) => handleChangeValue(e, "slName", index)} value={row?.slName} />,
    },
    {
      name: "Amount",
      grow: 1,
      cell: (row, index) => <input className="form-control" type="number" name="amount" onChange={(e) => handleChangeValue(e, "amount", index)} value={`${row?.amount}`} />,
    },
    {
      name: "Document Remarks",
      grow: 1,
      cell: (row, index) => <input className="form-control" type="text" name="documentRemarks" onChange={(e) => handleChangeValue(e, "documentRemarks", index)} value={row?.documentRemarks} />,
    },
    {
      name: "Acno Remarks",
      grow: 1,
      cell: (row, index) => <input className="form-control" type="text" name="acnoRemarks" onChange={(e) => handleChangeValue(e, "acnoRemarks", index)} value={row?.acnoRemarks} />,
    },
  ];
  const [chats_data, setChats_data] = useState([]);


  useEffect(() => {
    console.log(productArr, "productArr")
    setChats_data([...productArr])
  }, [productArr])



  const handleChangeValue = (e, name, index) => {
    // let tempArr = chats_data;
    console.log(e, "event", name)


    // tempArr[index][e.target.name] = e.target.value;
    setChats_data(prev => {
      let tempArr = prev
      if (name == "warehouseObj") {
        prev[index][name] = e
        prev[index].warehouseId = e.value
        // prev[index].productName = e.label
      }
      else if (name == "productObj") {
        prev[index][name] = e
        prev[index].productId = e.value
        prev[index].rekoCode = e.rekoCode
        prev[index].productName = e.label
      }
      else if (e.target.name == "price" || e.target.name == "quantity") {
        prev[index][e.target.name] = e.target.value
        prev[index]["subTotal"] = (prev[index].quantity * prev[index].price)
      }
      else {
        prev[index][e.target.name] = e.target.value
      }
      return [...tempArr]
    })
    chats_data_value(chats_data)
  }









  const handleAddItem = () => {
    setChats_data(prev => [...prev, { category: "", productObj: {}, description: "", price: 0, quantity: 0, subTotal: 0 }])
  }

  return (
    <section className="product-category" style={{ minHeight: "" }}>
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-12">
            <DashboardTable>
              <DataTable
                columns={chats_columns}
                data={chats_data}
              />
              <div className="row f_j_c_e">
                <button onClick={() => handleAddItem()} className="add_item_buttn">
                  Add item <MdOutlineAdd />
                </button>
              </div>
            </DashboardTable>
          </div>
        </div>
      </div>
    </section>
  );
}
