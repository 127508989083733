import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";
import { addState, getById, updateState } from "../../services/state.service";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { getZone } from "../../services/zone.service";
import Select from "react-select";

function StateAddPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [name, setName] = useState("");
  const [fullForm, setFullForm] = useState("");
  const [zoneId, setZoneId] = useState("");
  const [ZoneArr, setZoneArr] = useState([]);

  const handleSubmit = async () => {
    try {

      if (name == "") {
        toastError("Short Code is mandatory !!!");
        return
      }
      if (fullForm == "") {
        toastError("Name is mandatory !!!");
        return
      }

      if (zoneId == "") {
        toastError("Zone is mandatory !!!");
        return
      }

      let obj = {
        name,
        fullForm, 
        zoneId
      }
      
      if (searchParams.get("id")) {
        let { data: res } = await updateState(obj, searchParams.get("id"))
        if (res.message) {
          toastSuccess(res.message)
        }
      }

      else {
        let { data: res } = await addState(obj)
        if (res.message) {
          toastSuccess(res.message)
        }
      }
    }
    catch (err) {
      toastError(err)
    }
  }




  const handleGetForUpdate = async () => {
    try {
      if (typeof searchParams?.get("id") != 'string')
        return;
      const res = await getById(searchParams?.get("id"));
      if (res.data.data) {
        console.log(res.data, "data")
        if (res.data?.data?.name) {
          setName(res.data?.data?.name);
        }
        if (res.data?.data?.zoneId) {
          setZoneId(res.data?.data?.zoneId);
        }
        if (res.data?.data?.fullForm) {
          setFullForm(res.data?.data?.fullForm);
        }
      }

    } catch (error) {
      toastError(error)
    }

  }


  const handleGetZones = async () => {
    try {
      const { data: res } = await getZone({ pageIndex: 0, pageSize: 10000000 }, { forSelectInput: true });
      if (res?.data) {
        console.log(res.data, "data")
        setZoneArr([{ label: "Please select a zone", value: "" }, ...res?.data]);
      }
    } catch (error) {
      toastError(error)
    }

  }
  useEffect(() => {
    if (searchParams?.get("id")) {
      handleGetForUpdate()
    }
    handleGetZones()
  }, [searchParams.get("id")])


  return (
    <main className="bg-white">
      <section className="user_page_sec">
        <div className="user_header">
          {/* <span className="page_name">State</span>
          <p className="breadcrumb">
            <span className="parent">Dashboard </span>{" "}
            <span>
              {" "}
              <HiChevronRight />
            </span>{" "}
            <span className="child">State</span>
          </p> */}
        </div>

        <div className="user_form_box">
          <div className="form_header">
            <h6 className="heading">State</h6>
            <span className="sub_heading"> Add State</span>
          </div>

          <div className="form_box">
            <div className="row">
            <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Name*</p>
                  <input onChange={(e) => setFullForm(e.target.value)} value={fullForm} type="text" placeholder="Name" />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Short Code*</p>
                  <input onChange={(e) => setName(e.target.value)} value={name} type="text" placeholder="Short code" />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Zone*</p>
                  <Select onChange={(e) => setZoneId(e.value)} value={ZoneArr.find(el => el.value == zoneId)} options={ZoneArr} />
                  {/* <input onChange={(e) => setName(e.target.value)} value={name} type="text" placeholder="Name" /> */}
                </div>
              </div>
            </div>

            <div className="row">
              <button onClick={() => handleSubmit()} className="submit_buttn">Submit</button>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default StateAddPage
