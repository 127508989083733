import React from 'react'

export default function Loading() {
    return (
    <div style={{ backgroundColor: "rgba(0,0,0,0.9)", zIndex: 15000, height: "100vh", width: "100vw", position: "fixed", top: 0, left: 0, display:"flex", justifyContent:"center", alignItems:"center", fontSize:"8vh", color:"white" }}>

    <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            width="200"
            height="200"
            style={{ shapeRendering: 'auto', display: 'block', background: "transparent" }}
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g>
              <circle strokeWidth="2" stroke="#e90c59" fill="none" r="0" cy="50" cx="50">
                <animate
                  begin="0s"
                  calcMode="spline"
                  keySplines="0 0.2 0.8 1"
                  keyTimes="0;1"
                  values="0;40"
                  dur="1s"
                  repeatCount="indefinite"
                  attributeName="r"
                />
                <animate
                  begin="0s"
                  calcMode="spline"
                  keySplines="0.2 0 0.8 1"
                  keyTimes="0;1"
                  values="1;0"
                  dur="1s"
                  repeatCount="indefinite"
                  attributeName="opacity"
                />
              </circle>
              <circle strokeWidth="2" stroke="#46dff0" fill="none" r="0" cy="50" cx="50">
                <animate
                  begin="-0.5s"
                  calcMode="spline"
                  keySplines="0 0.2 0.8 1"
                  keyTimes="0;1"
                  values="0;40"
                  dur="1s"
                  repeatCount="indefinite"
                  attributeName="r"
                />
                <animate
                  begin="-0.5s"
                  calcMode="spline"
                  keySplines="0.2 0 0.8 1"
                  keyTimes="0;1"
                  values="1;0"
                  dur="1s"
                  repeatCount="indefinite"
                  attributeName="opacity"
                />
              </circle>
              <g></g>
            </g>
          </svg>
    
    Loading please wait ...
            </div>
            );
}
