import moment from "moment";
import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import PVProductDetailsTable from "./PVProductDetailsTable";
import { addPaymentVoucher, getById, updatePaymentVoucher } from "../../services/paymentVoucher.service";
function PVAdd() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [documentType, setDocumentType] = useState("");
  const [documentNumber, setDocumentNumber] = useState("");
  const [documentDate, setDocumentDate] = useState(new Date());
  const [currency, setCurrency] = useState("");
  const [grnType, setGrnType] = useState("");
  const [grnDescription, setGrnDescription] = useState("");
  const [grnName, setGrnName] = useState("");
  const [grnNumber, setGrnNumber] = useState("");
  const [grnDate, setGrnDate] = useState(new Date());
  const [partnerCode, setPartnerCode] = useState("");
  const [partnerName, setPartnerName] = useState("");
  const [billNumber, setBillNumber] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [rekoCode, setRekoCode] = useState("");
  const [partnerAddress, setPartnerAddress] = useState("");
  const [narration, setNarration] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");

  const [productsArr, setProductsArr] = useState([
    {
      warehouseId: "",
      productId: "",
      productCode: "",
      productName: "",
      rekoCode: "",
      rekoCodeDescription: "",
      quantity: "",
      rate: "",
      amount: "",
      drSlType: "",
      drSlCode: "",
      crSlType: "",
      crSlCode: "",
      gst: "",
    }
  ]);




  const handleSubmit = async () => {
    try {
      let obj = {



        documentType,
        documentNumber,
        documentDate,
        currency,
        grnType,
        grnDescription,
        grnName,
        grnNumber,
        grnDate,
        partnerCode,
        partnerName,
        billNumber,
        paymentMode,
        rekoCode,
        partnerAddress,
        narration,
        vehicleNumber,

        productsArr
      }
      console.log(obj, "asd")
      if (searchParams.get("id")) {
        let { data: res } = await updatePaymentVoucher(obj, searchParams.get("id"))
        if (res.message) {
          toastSuccess(res.message)
        }
      }

      else {
        let { data: res } = await addPaymentVoucher(obj)
        if (res.message) {
          toastSuccess(res.message)
        }
      }
    }
    catch (err) {
      toastError(err)
    }
  }




  const handleGetForUpdate = async () => {
    try {
      if (typeof searchParams?.get("id") != 'string')
        return;
      const { data: res } = await getById(searchParams?.get("id"));
      if (res.data) {
        console.log(res.data, "data")

        if (res.data?.documentType) {
          setDocumentType(res.data?.documentType);
        }
        if (res.data?.documentNumber) {
          setDocumentNumber(res.data?.documentNumber);
        }
        if (res.data?.documentDate) {
          setDocumentDate(res.data?.documentDate);
        }
        if (res.data?.currency) {
          setCurrency(res.data?.currency);
        }
        if (res.data?.grnType) {
          setGrnType(res.data?.grnType);
        }
        if (res.data?.grnDescription) {
          setGrnDescription(res.data?.grnDescription);
        }
        if (res.data?.grnName) {
          setGrnName(res.data?.grnName);
        }
        if (res.data?.grnNumber) {
          setGrnNumber(res.data?.grnNumber);
        }
        if (res.data?.grnDate) {
          setGrnDate(res.data?.grnDate);
        }
        if (res.data?.partnerCode) {
          setPartnerCode(res.data?.partnerCode);
        }
        if (res.data?.partnerName) {
          setPartnerName(res.data?.partnerName);
        }
        if (res.data?.billNumber) {
          setBillNumber(res.data?.billNumber);
        }
        if (res.data?.paymentMode) {
          setPaymentMode(res.data?.paymentMode);
        }
        if (res.data?.rekoCode) {
          setRekoCode(res.data?.rekoCode);
        }
        if (res.data?.partnerAddress) {
          setPartnerAddress(res.data?.partnerAddress);
        }
        if (res.data?.narration) {
          setNarration(res.data?.narration);
        }
        if (res.data?.vehicleNumber) {
          setVehicleNumber(res.data?.vehicleNumber);
        }
        if (res.data?.productsArr) {
          setProductsArr(res.data?.productsArr);
        }

      }

    } catch (error) {
      toastError(error)
    }

  }
  useEffect(() => {
    if (searchParams?.get("id")) {
      handleGetForUpdate()
    }

  }, [searchParams.get("id")])


  return (
    <main className="bg-white">
      <section className="user_page_sec">
        <div className="user_header">
          {/* <span className="page_name">PV Payments</span>
          <p className="breadcrumb">
            <span className="parent">Dashboard </span>{" "}
            <span>
              {" "}
              <HiChevronRight />
            </span>{" "}
            <span className="child">PV Payments</span>
          </p> */}
        </div>

        <div className="user_form_box">
          <div className="form_header">
            <h6 className="heading">PV Payments</h6>
            <span className="sub_heading"> Add PV Payments</span>
          </div>

          <div className="form_box">
            <div className="row">

              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Document Type*</p>
                  <input onChange={(e) => setDocumentType(e.target.value)} value={documentType} type="text" placeholder="Document Type" />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Document Number*</p>
                  <input onChange={(e) => setDocumentNumber(e.target.value)} value={documentNumber} type="text" placeholder="Document Number" />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Document Date*</p>
                  <input onChange={(e) => setDocumentDate(e.target.value)} value={moment(documentDate).format("YYYY-MM-DD")} type="date" placeholder="Document Date" />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Reference Document Type (GRN)*</p>
                  <input onChange={(e) => setGrnType(e.target.value)} value={grnType} type="text" placeholder="Reference Document Type (GRN)" />
                </div>
              </div>
              {/* <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Reference Document Type (GRN) Description*</p>
                  <input onChange={(e) => setGrnDescription(e.target.value)} value={grnDescription} type="text" placeholder="Reference Document Type (GRN) Description" />
                </div>
              </div> */}
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Reference Document Type (GRN) Name*</p>
                  <input onChange={(e) => setGrnName(e.target.value)} value={grnName} type="text" placeholder="Reference Document Type (GRN) Name" />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Reference Document Type (GRN) Number*</p>
                  <input onChange={(e) => setGrnNumber(e.target.value)} value={grnNumber} type="text" placeholder="Reference Document Type (GRN) Number" />
                </div>
              </div>

              {/* <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Reference Document Type (GRN) Date*</p>
                  <input onChange={(e) => setGrnDate(e.target.value)} value={moment(grnDate).format("YYYY-MM-DD")} type="date" placeholder="Reference Document Type (GRN) Date" />
                </div>
              </div> */}

              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Partner Code*</p>
                  <input onChange={(e) => setPartnerCode(e.target.value)} value={partnerCode} type="text" placeholder="Partner Code" />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Partner Name*</p>
                  <input onChange={(e) => setPartnerName(e.target.value)} value={partnerName} type="text" placeholder="Partner Name" />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Bill Number*</p>
                  <input onChange={(e) => setBillNumber(e.target.value)} value={billNumber} type="text" placeholder="Bill Number" />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Currency *</p>
                  <input onChange={(e) => setCurrency(e.target.value)} value={currency} type="text" placeholder="Currency " />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Payment Mode *</p>
                  <input onChange={(e) => setPaymentMode(e.target.value)} value={paymentMode} type="text" placeholder="Payment Mode " />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Reko Code *</p>
                  <input onChange={(e) => setRekoCode(e.target.value)} value={rekoCode} type="text" placeholder="Reko Code " />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Partner Address *</p>
                  <input onChange={(e) => setPartnerAddress(e.target.value)} value={partnerAddress} type="text" placeholder="Partner Address " />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Narration *</p>
                  <input onChange={(e) => setNarration(e.target.value)} value={narration} type="text" placeholder="Narration " />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Vehicle Number *</p>
                  <input onChange={(e) => setVehicleNumber(e.target.value)} value={vehicleNumber} type="text" placeholder="Vehicle Number " />
                </div>
              </div>

            </div>


            <PVProductDetailsTable productArr={productsArr} chats_data_value={(value) => setProductsArr(value)} />

            <div className="row">
              <button onClick={() => handleSubmit()} className="submit_buttn">Submit</button>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default PVAdd
