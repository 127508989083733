import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AiFillEdit } from "react-icons/ai";
import { MdOutlineAdd } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import userimg from "../../assets/images/avatar.png";
import { deleteProductById, downloadProductExcel, getProducts } from "../../services/product.service";
import { toastError } from "../../utils/toastUtils";
import { images } from "../Images/Images";
import { DashboardTable } from "../Utility/DashboardBox";
import { EditModal } from "../Utility/Modal";
import SearchBox from "../Utility/SearchBox";
import { toastSuccess } from "../Utility/ToastUtils";
import { generateFilePath } from "../Utility/utils";
import { debounce } from "lodash";

function ProductListPage() {
  const dispatch = useDispatch();

  const [productsArr, setProductsArr] = useState([]);



  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalBox, setModalBox] = useState(false);
  const [usersArr, setUsersArr] = useState([]);
  const [customersArr, setCustomersArr] = useState([]);
  const [retailersArr, setRetailersArr] = useState([]);
  const [wholesalersArr, setWholesalersArr] = useState([]);
  const [subAdminArr, setSubAdminArr] = useState([]);
  const userArr = useSelector((state) => state.users.users);
  const [selectedData, setSelectedData] = useState(null);
  const [query, setQuery] = useState("");
  const [displayUserArr, setDisplayUserArr] = useState([]);
  const [mainArr, setMainArr] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [customerCategoryObj, setCustomerCategoryObj] = useState({});
  const [customerCategoryId, setCustomerCategoryId] = useState("");
  const [customerCatArr, setCustomerCatArr] = useState([]); const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();





  const navigate = useNavigate();

  // useEffect(() => {
  //   handleGetProducts()
  // }, []);


  // const handleGetProducts = async () => {
  //   try {
  //     let { data: res } = await getProducts({ pageIndex: 0, pageSize: 10000000 }, "");
  //     if (res) {
  //       setProductsArr(res.data)
  //     }
  //   } catch (err) {
  //     toastError(err);
  //   }
  // };


  const handleGet = async (pageValue, limitValue, queryValue) => {
    try {
      let query = "";
      if (queryValue && queryValue != "") {
        query = `${queryValue}`
      }
      console.log(query, "query")
      let { data: res } = await getProducts({ pageIndex: pageValue, pageSize: limitValue }, { query });
      if (res.message) {
        setProductsArr(res.data);
        setTotalElements(res.total);
        setMainArr(res.data);
      }
    } catch (err) {
      toastError(err);
    }
  };
  const handleProductExcelDownload = async (queryValue) => {
    try {
      // let query = "";
      // if (queryValue && queryValue != "") {
      //   query = `${queryValue}`
      // }
      // console.log(query, "query")
      let { data: res } = await downloadProductExcel({ query });
      if (res) {
        var link = document.createElement("a");
        link.download = res.filename;
        link.href = generateFilePath(res.filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err) {
      toastError(err);
    }
  };



  const handleEdit = (id) => {
    navigate("/Product" + `?id=${id}`);
  };

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalElements, setTotalElements] = useState(0);

  const createQueryString = useCallback(
    (arr) => {
      const params = new URLSearchParams(searchParams.toString());
      for (let i = 0; i < arr.length; i++) {
        const element = arr[i];
        params.set(element.name, element.value);
      }
      console.log(params.toString(), "params.toString()");
      return params.toString();
    },
    [searchParams]
  );

  const handlePerRowsChange = (newPerPage, page) => {
    let pathname = window.location.href.split("/")[3];
    navigate(location.pathname + "?" + createQueryString([{ name: "limit", value: newPerPage }])
    );
    setLimit(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
    navigate(location.pathname + "?" + createQueryString([{ name: "page", value: page }])
    );
  };



  useEffect(() => {
    if (searchParams) {
      const query = searchParams.get("query") || "";
      const limit = parseInt(searchParams.get("limit") || "10");
      const pageValue = parseInt(searchParams.get("page") || "1");
      setLimit(limit);
      setPage(pageValue);
      setQuery(query);
      console.log(searchParams.toString(), "searchParams")
      handleGet(pageValue, limit, query);
    }
  }, [searchParams]);


  const users_columns = [
    {
      name: "Sr No.",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "8%",
    },
    {
      name: "Product Code",
      selector: (row) => `${row?.productCode}`,
    },
    {
      name: "NAME",
      selector: (row) => `${row?.name}`,
    },
    {
      name: "Action",
      cell: (row) => (
        <>

          <button className="edit_buttn" onClick={() => handleEdit(row._id)}>
            <AiFillEdit />
          </button>
          <div style={{ marginLeft: 15 }}>

            <button onClick={() => handleDelete(row._id)} className="delete_buttn">
              <RiDeleteBin5Line />
            </button>
          </div>
        </>
      ),
      width: "15%",
    },
  ];

  const [tabList, settabList] = useState([
    {
      tabName: "All",
      active: true,
    },
  ]);

  const handleDelete = async (id) => {
    try {
      if (!window.confirm("Are you sure")) {
        return
      }

      let { data: res } = await deleteProductById(id)
      if (res.message) {
        toastSuccess(res.message);
        handleGet(1, limit, query)
      }
    }
    catch (err) {
      toastError(err)
    }
  }


  const handleSearch = (queryValue) => {
    setQuery(queryValue);
    let tempArr = mainArr;
    tempArr = tempArr.filter(
      (el) =>
        `${el?.name}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
        `${el?.phone}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
        `${el?.email}`.toLowerCase().includes(`${queryValue}`.toLowerCase())
    );
    setDisplayUserArr([...tempArr]);
  };

  // const handleUpdateUserCategory = async () => {
  //   try {
  //     let obj = {
  //       customerCategoryId: customerCategoryId,
  //     };
  //     let { data: res } = await updateUserCategory(selectedUser._id, obj);
  //     if (res.message) {
  //       toastSuccess(res.message);
  //       setModalBox(false);
  //     }
  //     // dispatch(updateSpecificUser(obj, selectedUser._id))
  //   } catch (err) {
  //     toastError(err);
  //   }
  // };


  const debouncedSave = useCallback(
    debounce(
      (nextValue) => {
        navigate(location.pathname +
          "?" +
          createQueryString([
            { name: "query", value: nextValue },
            { name: "page", value: 1 },
          ])
        )

      },
      1000
    ),
    [] // will be created only once initially
  );

  // highlight-ends

  const handleChange = (event) => {
    const nextValue = event;

    setQuery(nextValue);

    // Even though handleChange is created on each render and executed

    // it references the same debouncedSave that was created initially

    debouncedSave(nextValue);
  };

  return (
    <main className="p-0">
      <section className="product-category">
        <div className="row align-items-center">
          <div className="col-xl-10">
            <div className="user_header_left">
              <div className="user_img">
                <img src={userimg} alt="" />
              </div>

              <span>Products</span>
            </div>
          </div>
          <div className="col-xl-2">
            <div className="user_header_right">
              <select>
                <option value="">All (56)</option>
                <option value="ssl">SSL</option>
                <option value="tls">TLS</option>
              </select>

              <button onClick={() => navigate("/Product")} className="">
                Add <MdOutlineAdd />
              </button>
            </div>
          </div>
        </div>
        <DashboardTable>
          <div className="d-flex align-items-center justify-content-between mb-4">
            {/* <h5 className="blue-1 m-0">All Customers</h5> */}
            <button onClick={handleProductExcelDownload} className="copy_excel_buttn">Copy to Excel</button>
            <div className="d-flex align-items-center gap-3">
              <SearchBox
                query={query}
                setQuery={handleChange}
                extraClass="bg-white"
              />
              {/* <CustomButton isLink iconName="fa-solid fa-download" btnName="Customer CSV" path="/" small roundedPill downloadAble ClickEvent={() => downloadCSV(usersArr)} /> */}
            </div>
          </div>
          <DataTable columns={users_columns} data={productsArr} pagination
            paginationPerPage={limit}
            paginationDefaultPage={page}
            paginationServer={true}
            paginationRowsPerPageOptions={[10, 20, 30, 50, 100, 200, 500]}
            paginationTotalRows={totalElements}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange} />

          {/* {handleGetselectedTable()} */}
        </DashboardTable>
      </section>

      <Modal
        open={ModalBox}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-box">
          <div className="modal-container">
            <div className="modal-header text-white">Update User Type</div>
            <div className="modal-body" style={{ width: "50vw" }}>
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-12 mb-3">
                    <label>
                      Customer Category
                      <span className="red">*</span>
                    </label>
                  </div>
                  <div className="col-12 col-md-11 mb-3">
                    <Select
                      onChange={(e) => {
                        setCustomerCategoryObj(e);
                        setCustomerCategoryId(e.value);
                      }}
                      defaultValue={customerCategoryId}
                      value={customerCategoryObj}
                      options={
                        customerCatArr && customerCatArr.length > 0
                          ? customerCatArr.map((el) => ({
                            label: el.name,
                            value: el._id,
                            ...el,
                          }))
                          : []
                      }
                    />
                  </div>
                  <div className="col-12 col-md-11 mb-3">
                    {/* <CustomButton
                      ClickEvent={() => handleUpdateUserCategory()}
                      btntype="button"
                      isBtn
                      iconName="fa-solid fa-check"
                      btnName="Save"
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </main>
  );
}

export default ProductListPage;
