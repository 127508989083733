import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "";

export const getYearWiseSaleVSCollection = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/getYearWiseSaleVSCollection?${query.toString()}`);
};
export const getAverageRateOfProductsZoneAndDateWise = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/getAverageRateOfProductsZoneAndDateWise?${query.toString()}`);
};
export const getTotalSalesOfProductsZoneAndDateWise = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/getTotalSalesOfProductsZoneAndDateWise?${query.toString()}`);
};
export const getTotalCustomersForZoneAndDateWise = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/getTotalCustomersForZoneAndDateWise?${query.toString()}`);
};
export const getTotalCustomersForZoneAndDateRangesWise = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/getTotalCustomersForZoneAndDateRangesWise?${query.toString()}`);
};
export const getDashboardForAdmin = (query) => {
    return axiosApiInstance.get(`${serverUrl}/getDashboardForAdmin?${query}`);
};
export const getDashboardAverageRate = (query) => {
    return axiosApiInstance.get(`${serverUrl}/getDashboardAverageRate?${query}`);
};
export const uploadAgingExcel = (data) => {
    return axiosApiInstance.post(`${serverUrl}/bulkUploadAging`, data);
};