// 
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/retailer";

export const addRetailer = (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getRetailer = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/?${query.toString()}`);
};
export const downloadRetailerExcel = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/download-excel?${query.toString()}`);
};


export const deleteRetailer = (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const getById = (id) => {
    return axiosApiInstance.get(`${serverUrl}/getById/${id}`);
};

export const updateRetailer = (formData, id) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};