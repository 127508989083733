import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AiFillEdit } from "react-icons/ai";
import { MdOutlineAdd } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import userimg from "../../assets/images/avatar.png";
import { deleteCategory, downloadCategoryExcel, getCategory } from "../../services/category.service";
import { toastError } from "../../utils/toastUtils";
import { images } from "../Images/Images";
import { DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import { toastSuccess } from "../Utility/ToastUtils";
import { generateFilePath } from "../Utility/utils";

function CategoryListPage() {

  const [categoryArr, setCategoryArr] = useState([]);

  const [query, setQuery] = useState("");
  const [mainArr, setMainArr] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    handleGet()
  }, []);


  const handleGet = async () => {
    try {
      let { data: res } = await getCategory();
      if (res) {
        setCategoryArr(res.data)
        setMainArr(res.data)
      }
    } catch (err) {
      toastError(err);
    }
  };


  const handleCategoryExcelDownlaod = async () => {
    try {
      let { data: res } = await downloadCategoryExcel();
      if (res) {
        var link = document.createElement("a");
        link.download = res.filename;
        link.href = generateFilePath(res.filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleEdit = async (id) => {
    navigate(`/UserCategory?id=${id}`)
  };



  const users_columns = [
    {
      name: "Sr No.",
      selector: (row, index) => index + 1,
      sortable: true,
    },

    {
      name: "NAME",
      selector: (row) => `${row?.name}`,
    },
    {
      name: "IMAGE",
      cell: (row) => <img src={(row.image && row.image != "") ? generateFilePath(row.image) : images?.customer} alt={row?.name} />,
    },

    {
      name: "Action",
      cell: (row) => (
        <>
          <button onClick={() => handleEdit(row._id)} className="edit_buttn">
            <AiFillEdit />
          </button>

          <div style={{ marginLeft: 15 }}>
            <button onClick={() => handleDelete(row._id)} className="delete_buttn">
              <RiDeleteBin5Line />
            </button>
          </div>
        </>
      ),
      width: "15%",
    },
  ];

  const handleDelete = async (id) => {
    try {
      if (!window.confirm("Are you sure")) {
        return
      }

      let { data: res } = await deleteCategory(id)
      if (res.message) {
        toastSuccess(res.message);
        handleGet()
      }
    }
    catch (err) {
      toastError(err)
    }
  }


  const handleSearch = (queryValue) => {
    setQuery(queryValue);
    let tempArr = mainArr;
    tempArr = tempArr.filter(
      (el) =>
        `${el?.name}`.toLowerCase().includes(`${queryValue}`.toLowerCase()));
    setCategoryArr([...tempArr]);
  };


  return (
    <main className="p-0">
      <section className="product-category">
        <div className="row align-items-center">
          <div className="col-xl-10">
            <div className="user_header_left">
              <div className="user_img">
                <img src={userimg} alt="" />
              </div>

              <span>Category</span>
            </div>
          </div>
          <div className="col-xl-2">
            <div className="user_header_right">
              {/* <select>
                <option value="">All (56)</option>
                <option value="ssl">SSL</option>
                <option value="tls">TLS</option>
              </select> */}

              <button onClick={() => navigate("/UserCategory")} className="">
                Add <MdOutlineAdd />
              </button>
            </div>
          </div>
        </div>
        <DashboardTable>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <button onClick={handleCategoryExcelDownlaod} className="copy_excel_buttn">Copy to Excel</button>
            <div className="d-flex align-items-center gap-3">
              <SearchBox
                query={query}
                setQuery={handleSearch}
                extraClass="bg-white"
              />
            </div>
          </div>
          <DataTable columns={users_columns} data={categoryArr} pagination />

        </DashboardTable>
      </section>
    </main>
  );
}

export default CategoryListPage;
