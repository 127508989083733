import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { HiChevronRight } from "react-icons/hi";
import Select from "react-select";
import { getComodityProductsSalesYearWise } from "../../services/dispatchingInputSaleInvoice.service";
import { getProductsByCategoryName } from "../../services/product.service";
import CustomButton from "../Utility/Button";
import { DashboardBox, DashboardChart } from "../Utility/DashboardBox";
import { toastError } from "../Utility/ToastUtils";
import { getYearWiseSaleVSCollection } from "../../services/index.service";
import { formatter } from "../Utility/utils";
import { handleReturnBarGraphColorArr } from "../Utility/barGraphColorArray";

export default function SaleAndCollectionComparision() {
  const [productsArr, setProductsArr] = useState([]);
  const [barLabels, setBarLabels] = useState([
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
  ]);
  const [yearsArr, setYearsArr] = useState([]);

  const [selectedYear, setSelectedYear] = useState("");
  const [selectedProductId, setSelectedProductId] = useState("");
  const [selectedReportType, setSelectedReportType] = useState("Monthly");

  const [reportType, setReportType] = useState([
    { label: "Quarterly", value: "Quarterly" },
    { label: "Monthly", value: "Monthly" },
  ]);

  const [displayYear, setDisplayYear] = useState("");
  const [displayProduct, setDisplayProduct] = useState("");

  const [unitOfMeasurement, setUnitOfMeasurement] = useState("");
  const [SalesArr, setRequestedYearSalesArr] = useState([]);
  const [previousYearSalesArr, setPreviousYearSalesArr] = useState([]);

  const HandleGetComodityProductsSalesYearWise = async () => {
    try {
      let query = {};
      if (selectedYear == "") {
        toastError("Please select an year");
        return;
      }

      if (selectedYear && selectedYear != "") {
        query = { ...query, selectedYear: selectedYear };
      }

      if (selectedReportType && selectedReportType != "") {
        query = { ...query, selectedReportType: selectedReportType };
      }

      setDisplayYear(selectedYear);
      setDisplayProduct(selectedProductId);

      let { data: res } = await getYearWiseSaleVSCollection(query);
      if (res.data) {
        if (selectedReportType == "Quarterly") {
          setBarLabels(["Q1", "Q2", "Q3", "Q4"]);


          setRequestedYearSalesArr(res.data.bankReceiptArr);
          setPreviousYearSalesArr(res.data.dispatchSalesInvoiceArr);
        } else {
          setBarLabels([
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
            "January",
            "February",
            "March",
          ]);
          setRequestedYearSalesArr(res.data.bankReceiptArr);
          setPreviousYearSalesArr(res.data.dispatchSalesInvoiceArr);
        }
        // setUnitOfMeasurement(res.data.unitOfMeasurement);
      }
    } catch (error) {
      toastError(error);
    }
  };

  const handleCalculateLastFiveYears = () => {
    let tempArr = [];
    let today = new Date();
    let totalYears = today.getFullYear() - 2018;

    for (let index = 0; index <= totalYears; index++) {
      let obj = {};
      let year = today.getFullYear() - index;
      obj.label = `${year}-${year + 1}`;
      obj.value = year;
      tempArr.push(obj);
    }

    setYearsArr(tempArr);
  };

  useEffect(() => {
    handleCalculateLastFiveYears();
  }, []);

  return (
    <main className="bg-white">
      <section className="dashboard-head mb-3">
        <div className="row">
          <div className="col-xl-10"></div>
          <div className="col-xl-2">
            {/* <div className="user_header">
              <p className="breadcrumb">
                <span className="parent">Dashboard </span>{" "}
                <span>
                  {" "}
                  <HiChevronRight />
                </span>{" "}
                <span className="child">Reports</span>
              </p>
            </div> */}
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-12 mb-5 p-5 border rounded">
              <div className="row">
                <h3 className="blue-1">Sale And Collection Comparision</h3>

                <div className="col-12 col-lg-3">
                  <DashboardBox className="dashboard-summary">
                    <div className="row align-items-center">
                      <div className="col-xl-9">
                        <div className="right_content_box">
                          <h5 className="blue-1">Year</h5>
                          <Select
                            options={yearsArr}
                            onChange={(e) => setSelectedYear(e.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </DashboardBox>
                </div>
                <div className="col-12 col-lg-3">
                  <DashboardBox className="dashboard-summary">
                    <div className="row align-items-center">
                      <div className="col-xl-9">
                        <div className="right_content_box">
                          <h5 className="blue-1">Type</h5>
                          <Select
                            options={reportType}
                            value={{
                              label: selectedReportType,
                              value: selectedReportType,
                            }}
                            onChange={(e) => setSelectedReportType(e.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </DashboardBox>
                </div>
                <div className="col-12 col-lg-3 mt-5">
                  <CustomButton
                    isBtn
                    btntype="button"
                    ClickEvent={HandleGetComodityProductsSalesYearWise}
                    iconName="fa-solid fa-check"
                    extraClass={"mt-3"}
                    btnName="Submit"
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-12 col-lg-6">
                  <DashboardChart>
                    {SalesArr && SalesArr.length > 0 && (
                      <>
                        <h5 className="blue-1 my-4">
                          Collection for ({displayYear})
                        </h5>

                        <Bar
                          data={{
                            labels: barLabels,
                            datasets: [
                              {
                                label: selectedYear,
                                backgroundColor: handleReturnBarGraphColorArr(SalesArr?.map((el) => el?.value)),
                                borderColor: "#2e4765",
                                data: SalesArr?.map((el) => el?.value),
                              },
                            ],
                          }}
                        />
                        <h5 className="blue-1 my-4">
                          Total Collection in ({displayYear}) is{" "}
                          {formatter.format(
                            SalesArr.reduce(
                              (acc, el, index) => acc + el.value,
                              0
                            )
                          )}
                        </h5>
                      </>
                    )}
                  </DashboardChart>
                </div>
                <div className="col-12 col-lg-6">
                  <DashboardChart>
                    {previousYearSalesArr &&
                      previousYearSalesArr.length > 0 && (
                        <>
                          <h5 className="blue-1 my-4">
                            Sale for ({displayYear})
                          </h5>
                          <Bar
                            data={{
                              labels: barLabels,
                              datasets: [
                                {
                                  label: selectedProductId,
                                  backgroundColor: handleReturnBarGraphColorArr(previousYearSalesArr?.map((el) => el?.value)),
                                  borderColor: "#2e4765",
                                  data: previousYearSalesArr?.map(
                                    (el) => el?.value
                                  ),
                                },
                              ],
                            }}
                          />
                          <h5 className="blue-1 my-4">
                            Total Sale in ({displayYear}) is{" "}
                            {formatter.format(
                              previousYearSalesArr.reduce(
                                (acc, el, index) => acc + el.value,
                                0
                              )
                            )}
                          </h5>
                        </>
                      )}
                  </DashboardChart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
