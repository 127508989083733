import React, { useEffect, useState } from "react";
import { BiLogOutCircle, BiUser } from "react-icons/bi";
import { BsBox, BsBoxes, BsCart2 } from 'react-icons/bs';
import { MdContactSupport, MdContactless } from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";
import { SiProducthunt } from 'react-icons/si';
import { TbLayoutDashboard } from "react-icons/tb";
import { Link, useLocation } from "react-router-dom";
import { images } from "../Images/Images";
import { departmentObj, rolesObj } from "../../utils/roles";
import { useSelector } from "react-redux";


function SideBar() {
  const role = useSelector((state) => state.auth.role);
  const department = useSelector((state) => state?.auth?.user?.department);

  let page = 1;
  let limit = 10;
  let location = useLocation();
  const [sidebar_item, setsidebar_item] = useState([
    {
      isrotated: true,
      active: false,
      name: "dashboard",
      path: "/",
      hasChild: false,
      icon: <TbLayoutDashboard />,
      roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
      departments: [departmentObj.FINANCE, departmentObj.OPERATIONS, departmentObj.SALES],
      children: [],
    },
    {
      isrotated: false,
      active: false,
      name: "Reports",

      hasChild: false,
      icon: <BiUser />,
      roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
      departments: [departmentObj.FINANCE, departmentObj.OPERATIONS, departmentObj.SALES],
      children: [
        {
          name: "Change in Revenue Share",
          path: "/reports/ChangeInRevenueShare",
          active: false,
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.FINANCE],
        },
        {
          name: "Year wise Sales revenue comparision",
          path: "/reports/YearWiseSaleRevenue",
          active: false,
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.FINANCE],
        },
        {
          name: "YOY Sales revenue",
          path: "/reports/YearOnYearRevenue",
          active: false,
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.FINANCE],
        },
        {
          name: "Comodity Product wise sale comparision",
          path: "/reports/ComodityProductWiseSales",
          active: false,
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.SALES],
        },
        {
          name: "Speciality Product wise sale comparision",
          path: "/reports/SpecialityProductWiseSales",
          active: false,
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.SALES],
        },
        {
          name: "Total Sales Incentive vs non-incentive",
          path: "/reports/TotalIncentiveVsNonIncentiveSales",
          active: false,
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.SALES],
        },
        {
          name: "Sales and collection comparision",
          path: "/reports/SaleAndCollectionComparision",
          active: false,
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.FINANCE, departmentObj.SALES],
        },
        {
          name: "Product wise sold quantity",
          path: "/reports/ProductWiseSoldQuantity",
          active: false,
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.SALES],
        },
        {
          name: "Segment Wise Turnover Comparision",
          path: "/reports/SegmentWiseTurnoverComparision",
          active: false,
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.SALES],
        },
        {
          name: "Zone Wise Revenue",
          path: "/reports/ZoneAndYearWiseSaleRevenue",
          active: false,
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.FINANCE],
        },
        {
          name: "Zone Wise Product Sale",
          path: "/reports/ZoneAndYearWiseIncentiveProductSale",
          active: false,
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.SALES],
        },
        {
          name: "Zone Wise Customer Count",
          path: "/reports/ZoneWiseCustomerCount",
          active: false,
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.SALES],
        },
        {
          name: "Aging",
          path: "/Aging",
          active: false,
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.FINANCE, departmentObj.OPERATIONS, departmentObj.SALES],
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Users",

      hasChild: false,
      icon: <BiUser />,
      roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
      departments: [departmentObj.FINANCE, departmentObj.OPERATIONS, departmentObj.SALES],
      children: [
        {
          name: "User Add",
          path: "/UserPage",
          active: false,
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.FINANCE, departmentObj.OPERATIONS, departmentObj.SALES],
        },
        {
          name: "User List",
          path: "/UserListPage" + "?page=" + page + "&limit=" + limit,
          active: false,
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.FINANCE, departmentObj.OPERATIONS, departmentObj.SALES],
        },
      ],
    },

    {
      isrotated: false,
      active: false,
      name: "Retailers",
      hasChild: false,
      icon: <BiUser />,
      roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
      departments: [departmentObj.FINANCE, departmentObj.OPERATIONS, departmentObj.SALES, departmentObj.LAB],
      children: [
        {
          name: "Retailer Add",
          path: "/RetailerPage",
          active: false,
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.FINANCE, departmentObj.OPERATIONS, departmentObj.SALES, departmentObj.LAB],
        },
        {
          name: "Retailer List",
          path: "/RetailerListPage" + "?page=" + page + "&limit=" + limit,
          active: false,
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.FINANCE, departmentObj.OPERATIONS, departmentObj.SALES, departmentObj.LAB],
        },
      ],
    },

    {
      isrotated: false,
      active: false,
      name: "Category",

      hasChild: false,
      roles: [rolesObj.ADMIN],
      departments: [],
      icon: <BsBoxes />,
      children: [
        {
          name: "Category",
          path: "/UserCategory",
          active: false,
          roles: [rolesObj.ADMIN],
          departments: [],
        },
        {
          name: "Category List",
          path: "/Category-list" + "?page=" + page + "&limit=" + limit,
          active: false,
          roles: [rolesObj.ADMIN],
          departments: [],
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Product",

      hasChild: false,
      icon: <BsBox />,
      roles: [rolesObj.ADMIN],
      departments: [],
      children: [
        {
          name: "Product Add",
          path: "/Product",
          active: false,
          roles: [rolesObj.ADMIN],
          departments: [],
        },
        {
          name: "Product List",
          path: "/ProductListPage" + "?page=" + page + "&limit=" + limit,
          active: false,
          roles: [rolesObj.ADMIN],
          departments: [],
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Mailing List",
      hasChild: false,
      icon: <MdContactSupport />,
      roles: [rolesObj.ADMIN],
      departments: [],
      children: [
        {
          active: false,
          name: "Mailing List Add",
          path: "/Support",
          roles: [rolesObj.ADMIN],
          departments: [],
        },
        {
          active: false,
          name: "Mailing List List",
          path: "/SupportListPage" + "?page=" + page + "&limit=" + limit,
          roles: [rolesObj.ADMIN],
          departments: [],
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Location",
      hasChild: true,
      icon: <MdContactSupport />,
      roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
      departments: [departmentObj.LAB],
      children: [

        {
          isrotated: false,
          active: false,
          name: "Zone",
          hasChild: false,
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.LAB],
          icon: <MdContactSupport />,
          children: [
            {
              active: false,
              name: "Zone Add",
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.LAB],
              path: "/ZoneAdd",
            },
            {
              active: false,
              name: "Zone List",
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.LAB],
              path: "/ZoneList" + "?page=" + page + "&limit=" + limit,
            },
          ],
        },
        {
          isrotated: false,
          active: false,
          name: "State",
          hasChild: false,
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.LAB],
          icon: <MdContactSupport />,
          children: [
            {
              active: false,
              name: "State Add",
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.LAB],
              path: "/StateAdd",
            },
            {
              active: false,
              name: "State List",
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.LAB],
              path: "/StateList" + "?page=" + page + "&limit=" + limit,
            },
          ],
        },
        {
          isrotated: false,
          active: false,
          name: "City",
          hasChild: false,
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.LAB],
          icon: <MdContactSupport />,
          children: [
            {
              active: false,
              name: "City Add",
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.LAB],
              path: "/CityAdd",
            },
            {
              active: false,
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.LAB],
              name: "City List",
              path: "/CityList" + "?page=" + page + "&limit=" + limit,
            },
          ],
        },

      ],
    },


    {
      isrotated: false,
      active: false,
      name: "Warehouse",
      hasChild: false,
      roles: [rolesObj.ADMIN],
      departments: [],
      icon: <MdContactSupport />,
      children: [
        {
          active: false,
          name: "Warehouse Add",
          roles: [rolesObj.ADMIN],
          departments: [],
          path: "/WarehouseAdd",
        },
        {
          active: false,
          name: "Warehouse List",
          roles: [rolesObj.ADMIN],
          departments: [],
          path: "/WarehouseList" + "?page=" + page + "&limit=" + limit,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Attendance",
      roles: [rolesObj.ADMIN],
      departments: [],
      hasChild: false,
      icon: <MdContactless />,
      children: [
        {
          active: false,
          name: "Attendance",
          roles: [rolesObj.ADMIN],
          departments: [],
          path: "/UserAttendance",
        },
        {
          active: false,
          name: "Attendance List",
          roles: [rolesObj.ADMIN],
          departments: [],
          path: "/UserAttendanceList" + "?page=" + page + "&limit=" + limit,
        }
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Purchase",
      roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
      departments: [departmentObj.FINANCE, departmentObj.OPERATIONS],

      hasChild: true,
      icon: <BsBoxes />,
      children: [

        {
          isrotated: false,
          active: false,
          name: "Purchase Order",
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.FINANCE, departmentObj.OPERATIONS],
          hasChild: false,
          icon: <BsBoxes />,
          children: [
            {
              active: false,
              name: "Purchase Order Add",
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.FINANCE, departmentObj.OPERATIONS],
              path: "/PurchaseOrder",
            },
            {
              active: false,
              name: "Purchase Order History",
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.FINANCE, departmentObj.OPERATIONS],
              path: "/PurchaseOrderHistory" + "?page=" + page + "&limit=" + limit,
            },
          ],
        },
        {
          isrotated: false,
          active: false,
          name: "Purchase voucher (PV)",
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.FINANCE, departmentObj.OPERATIONS],
          hasChild: false,
          icon: <BsBoxes />,
          children: [
            {
              active: false,
              name: "Purchase Voucher Add",
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.FINANCE, departmentObj.OPERATIONS],
              path: "/PVAdd",
            },
            {
              active: false,
              name: "Purchase Voucher History",
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.FINANCE, departmentObj.OPERATIONS],
              path: "/PVList" + "?page=" + page + "&limit=" + limit,
            },
          ],
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Base Associated Products",
      roles: [rolesObj.ADMIN],
      departments: [],
      hasChild: false,
      icon: <SiProducthunt />,
      children: [
        {
          name: "Base Associated Product",
          roles: [rolesObj.ADMIN],
          departments: [],
          path: "/BaseIndemandProduct",
          active: false,
        },
        {
          name: "Base Associated Product List Page",
          roles: [rolesObj.ADMIN],
          departments: [],
          path: "/BaseIndemandProductList" + "?page=" + page + "&limit=" + limit,
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Associated Product",
      roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
      departments: [departmentObj.FINANCE, departmentObj.OPERATIONS, departmentObj.SALES],
      hasChild: false,
      icon: <SiProducthunt />,
      children: [
        {
          name: "Associated Product",
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.FINANCE, departmentObj.OPERATIONS, departmentObj.SALES],
          path: "/IndemandProduct",
          active: false,
        },
        {
          name: "Associated Product List Page",
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.FINANCE, departmentObj.OPERATIONS, departmentObj.SALES],
          path: "/IndemandProductList" + "?page=" + page + "&limit=" + limit,
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Meeting",
      roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
      departments: [departmentObj.FINANCE, departmentObj.OPERATIONS, departmentObj.SALES],
      hasChild: false,
      icon: <BsBoxes />,
      children: [
        {
          name: "Meeting",
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.FINANCE, departmentObj.OPERATIONS, departmentObj.SALES],
          path: "/Meeting",
          active: false,
        },
        {
          name: "Meeting List",
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.FINANCE, departmentObj.OPERATIONS, departmentObj.SALES],
          path: "/MeetingListPage" + "?page=" + page + "&limit=" + limit,
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Order",
      roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
      departments: [departmentObj.SALES],
      hasChild: false,
      icon: <BsBoxes />,
      children: [
        {
          name: "Order",
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.SALES],
          path: "/Order",
          active: false,
        },
        {
          name: "Order List",
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.SALES],
          path: "/OrderHistory" + "?page=" + page + "&limit=" + limit,
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Location List",
      roles: [rolesObj.ADMIN],
      departments: [],
      hasChild: false,
      icon: <BsBoxes />,
      children: [
        {
          name: "Location",
          path: "/LocationPage",
          roles: [rolesObj.ADMIN],
          departments: [],
          active: false,
        },
        {
          name: "Location List",
          path: "/LocationListPage" + "?page=" + page + "&limit=" + limit,
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [],
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Requirement and approval",
      roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
      departments: [departmentObj.FINANCE, departmentObj.OPERATIONS, departmentObj.SALES],
      hasChild: true,
      icon: <BiLogOutCircle />,
      children: [
        {
          isrotated: false,
          active: false,
          name: "Leave",
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.FINANCE, departmentObj.OPERATIONS, departmentObj.SALES],
          hasChild: false,
          icon: <BiLogOutCircle />,
          children: [
            {
              name: "Leave Add",
              path: "/LeavePage",
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.FINANCE, departmentObj.OPERATIONS, departmentObj.SALES],
              active: false,
            },
            {
              name: "Leave List",
              path: "/LeaveListPage" + "?page=" + page + "&limit=" + limit,
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.FINANCE, departmentObj.OPERATIONS, departmentObj.SALES],
              active: false,
            },
          ],
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Lab Raw Material",
      roles: [rolesObj.ADMIN, rolesObj.AGM, rolesObj.AM, rolesObj.GM, rolesObj.M, rolesObj.USER],
      departments: [departmentObj.LAB],
      hasChild: false,
      icon: <BsBoxes />,
      children: [
        {
          name: "Add Lab Raw Material",
          path: "/RawMaterialAdd",
          roles: [rolesObj.ADMIN, rolesObj.AGM, rolesObj.AM, rolesObj.GM, rolesObj.M, rolesObj.USER],
          departments: [departmentObj.LAB],
          active: false,
        },
        {
          name: "Lab Raw Material List",
          path: "/RawMaterialList" + "?page=" + page + "&limit=" + limit,
          roles: [rolesObj.ADMIN, rolesObj.AGM, rolesObj.AM, rolesObj.GM, rolesObj.M, rolesObj.USER],
          departments: [departmentObj.LAB],
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Lab",
      roles: [rolesObj.ADMIN, rolesObj.AGM, rolesObj.AM, rolesObj.GM, rolesObj.M, rolesObj.USER],
      departments: [],
      hasChild: false,
      icon: <BsBoxes />,
      children: [
        {
          name: "Add Lab",
          path: "/LabAddPage",
          roles: [rolesObj.ADMIN, rolesObj.AGM, rolesObj.AM, rolesObj.GM, rolesObj.M, rolesObj.USER],
          departments: [],
          active: false,
        },
        {
          name: "Lab List",
          path: "/LabList" + "?page=" + page + "&limit=" + limit,
          roles: [rolesObj.ADMIN, rolesObj.AGM, rolesObj.AM, rolesObj.GM, rolesObj.M, rolesObj.USER],
          departments: [],
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Lab Sample",
      roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M, rolesObj.USER],
      departments: [departmentObj.LAB],
      hasChild: false,
      icon: <BsBoxes />,
      children: [
        {
          name: "Add Lab Sample",
          path: "/AddLabSample",
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M, rolesObj.USER],
          departments: [departmentObj.LAB],
          active: false,
        },
        {
          name: "Lab Sample List",
          path: "/LabSample" + "?page=" + page + "&limit=" + limit,
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M, rolesObj.USER],
          departments: [departmentObj.LAB],
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Reversal",
      roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
      departments: [departmentObj.FINANCE, departmentObj.OPERATIONS, departmentObj.SALES],
      hasChild: false,
      icon: <BsBoxes />,
      children: [
        {
          name: "Add Reversal",
          path: "/AddReversal",
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.FINANCE, departmentObj.OPERATIONS, departmentObj.SALES],
          active: false,
        },
        {
          name: "Reversal List",
          path: "/Reversal" + "?page=" + page + "&limit=" + limit,
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.FINANCE, departmentObj.OPERATIONS, departmentObj.SALES],
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Sale",
      roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
      departments: [departmentObj.SALES],
      icon: <BiLogOutCircle />,
      hasChild: true,
      children: [
        {
          isrotated: false,
          active: false,
          name: "Credit Note",
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.SALES],
          icon: <BiLogOutCircle />,
          children: [
            {
              name: "Credit Note Add",
              path: "/CreditNoteAdd",
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.SALES],
              active: false,
            },
            {
              name: "Credit Note List",
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.SALES],
              path: "/CreditNoteList" + "?page=" + page + "&limit=" + limit,
              active: false,
            },
          ],
        },
        {
          isrotated: false,
          active: false,
          name: "Sale Invoice",
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.SALES],
          icon: <BiLogOutCircle />,
          children: [
            {
              name: "Sale Invoice Add",
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.SALES],
              path: "/DispatchinginputssaleinvoiceAdd",
              active: false,
            },
            {
              name: "Sale Invoice List",
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.SALES],
              path: "/DispatchinginputssaleinvoiceList" + "?page=" + page + "&limit=" + limit,
              active: false,
            },
          ],
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "GRN (Goods Receipts note)",
      roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
      departments: [departmentObj.FINANCE, departmentObj.OPERATIONS, departmentObj.SALES],
      hasChild: false,
      icon: <BiLogOutCircle />,
      children: [
        {
          name: "GRN Add",
          path: "/MaterialInAdd",
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.FINANCE, departmentObj.OPERATIONS, departmentObj.SALES],
          active: false,
        },
        {
          name: "GRN List",
          path: "/MaterialInList" + "?page=" + page + "&limit=" + limit,
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.FINANCE, departmentObj.OPERATIONS, departmentObj.SALES],
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Finance",
      roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
      departments: [departmentObj.FINANCE],
      hasChild: true,
      icon: <BiLogOutCircle />,
      children: [
        {
          isrotated: false,
          active: false,
          name: "Bank Receipt",
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.FINANCE],

          icon: <BiLogOutCircle />,
          children: [
            {
              name: "Bank Receipt Add",
              path: "/BankReceiptAdd",
              active: false,
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.FINANCE],
            },
            {
              name: "Bank Receipt List",
              path: "/BankReceiptList" + "?page=" + page + "&limit=" + limit,
              active: false,
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.FINANCE],
            },
          ],
        },
        {
          isrotated: false,
          active: false,
          name: "Bank Payments",
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.FINANCE],

          icon: <BiLogOutCircle />,
          children: [
            {
              name: "Bank Payments Add",
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.FINANCE],
              path: "/BankPaymentsAdd",
              active: false,
            },
            {
              name: "Bank Payments List",
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.FINANCE],
              path: "/BankPaymentsList" + "?page=" + page + "&limit=" + limit,
              active: false,
            },
          ],
        },
        {
          isrotated: false,
          active: false,
          name: "JV (Journal Voucher)",
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.FINANCE],

          icon: <BiLogOutCircle />,
          children: [
            {
              name: "JV (Journal Voucher) Add",
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.FINANCE],
              path: "/JVAdd",
              active: false,
            },
            {
              name: "JV (Journal Voucher) List",
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.FINANCE],
              path: "/JVList" + "?page=" + page + "&limit=" + limit,
              active: false,
            },
          ],
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Logistics",
      roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
      departments: [departmentObj.OPERATIONS],
      hasChild: true,
      icon: <BiLogOutCircle />,
      children: [
        {
          isrotated: false,
          active: false,
          name: "Partner Port",
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.OPERATIONS],
          icon: <BiLogOutCircle />,
          children: [
            {
              name: "In Transit",
              path: "/BankReceiptAdd",
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.OPERATIONS],
              active: false,
            },
            {
              name: "In Port",
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.OPERATIONS],
              path: "/BankReceiptList" + "?page=" + page + "&limit=" + limit,
              active: false,
            },
            {
              name: "In Clearance",
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.OPERATIONS],
              path: "/BankReceiptList" + "?page=" + page + "&limit=" + limit,
              active: false,
            },
          ],
        },
        {
          isrotated: false,
          active: false,
          name: "Dispatch godown",
          roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
          departments: [departmentObj.OPERATIONS],
          icon: <BiLogOutCircle />,
          children: [
            {
              name: "Dispatch godown",
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.OPERATIONS],
              path: "/BankPaymentsAdd" + "?page=" + page + "&limit=" + limit,
              active: false,
            },
            {
              name: "Dispatch godown List",
              roles: [rolesObj.ADMIN, rolesObj?.AGM, rolesObj?.GM, rolesObj?.AM, rolesObj?.M],
              departments: [departmentObj.OPERATIONS],
              path: "/BankPaymentsList" + "?page=" + page + "&limit=" + limit,
              active: false,
            },
          ],
        },
      ],
    },
  ]);

  const isChildRotating = (i, indexNumber) => {
    let temp_array = sidebar_item.map((el, index) => {
      if (index === i) {
        el.children = el.children.map((ele, indexX) => {
          if (indexX == indexNumber) {
            ele.isrotated = !ele.isrotated;
            ele.active = true;
          }
          else {
            ele.active = false;
          }
          return ele
        })
      } else {
        el.active = false;
      }
      return el;
    });

    console.log(temp_array, "temp_array")
    setsidebar_item([...temp_array]);
  }

  const isRotating = (i) => {
    let temp_array = sidebar_item.map((el, index) => {
      if (index === i) {
        el.isrotated = !el.isrotated;
        el.active = true;
      } else {
        el.active = false;
      }
      return el;
    });
    setsidebar_item([...temp_array]);
  };

  const childActive = (i) => {
    let temp_array = sidebar_item.map((el, index) => {
      if (el.children.length > 0) {
        el.children.map((item, childIndex) => {
          if (childIndex === i) {
            item.active = true;
          } else {
            item.active = false;
          }
          return item;
        });
      }
      return el;
    });
    setsidebar_item([...temp_array]);
  };




  useEffect(() => {
    console.log(role, "role")

  }, [])



  const handleReturnRoleWiseSidebarElements = (sidebarItem) => {
    console.log(sidebarItem.roles.some(ele => ele == role), "department", department)
    if (role == rolesObj.ADMIN) {
      return true
    }
    else if (sidebarItem.roles.some(ele => ele == role) && sidebarItem.departments.some(ele => ele == department)) {
      return true
    }
    else {
      return false
    }

  }


  return (
    <div id="sidebar" className="noprint">
      <div className="sidebar_header">
        <div className="row a_i_c">
          <div className="col-3">
            <div className="main-logo">
              <img src={images.logo} alt="" />
            </div>
          </div>
          <div className="col-6">
            <h5 className="name">Venky's</h5>
          </div>
          <div className="col-3 d_f_jc_e">
            <button className="collapse_icn">
              <RxHamburgerMenu />
            </button>
          </div>
        </div>
      </div>

      <ul className="sidebar-menu" id="sidebarMenu">
        {sidebar_item.filter(el => handleReturnRoleWiseSidebarElements(el)).map((item, i) => {
          if (typeof array === "undefined" && item.children.length === 0) {
            return (
              <li key={`sidebar_item_${i}_121111`}>
                <Link
                  to={item.path}
                  className={item.active ? "active" : ""}
                  onClick={() => isRotating(i)}
                >
                  {item.icon}
                  <p className="mb-0">{item.name}</p>
                </Link>
              </li>
            );
          } else {
            return (
              <li key={`sidebar_item_${i}_2211`}>
                <Link
                  to={`#sidebar_item_children_${i}`}
                  className={
                    item.active || location === item.path ? "active" : ""
                  }
                  data-bs-toggle="collapse"
                  aria-expanded={item.active}
                  aria-controls={`sidebar_item_children_${i}`}
                  role="button"
                  onClick={() => isRotating(i)}
                >
                  {item.icon}
                  {/* <i className=></i> */}
                  <p className="mb-0">
                    {item.name}
                    {item.isrotated ? (
                      // <HiChevronRight className="pe-3" />
                      <i className="ion-arrow-up-b pe-3"></i>
                    ) : (
                      <i className="ion-arrow-down-b pe-3"></i>
                    )}
                  </p>
                </Link>
                <ul
                  className="collapse"
                  id={`sidebar_item_children_${i}`}
                  data-bs-parent="#sidebarMenu1"
                >
                  {
                    item.hasChild && item.children && item.children.length > 0 ? item.children.filter(el => el.roles.some(ele => ele == role)).map((child, index) => {
                      return (
                        <li key={`sidebar_item_child_${index}_22222`}>
                          <Link
                            to={`#sidebar_item_child_children_${index}_111111111`}
                            className={
                              child?.active || location === child?.path ? "active" : ""
                            }
                            data-bs-toggle="collapse"
                            aria-expanded={child.active}
                            aria-controls={`sidebar_item_child_children_${index}_111`}
                            role="button"
                            onClick={() => isChildRotating(i, index)}
                          >
                            {child.icon}
                            <p className="mb-0">
                              {child.name}
                              {child.isrotated ? (
                                <i className="ion-arrow-up-b pe-3"></i>
                              ) : (
                                <i className="ion-arrow-down-b pe-3"></i>
                              )}
                            </p>
                          </Link>
                          {child.isrotated && child?.children.length > 0 && child?.children.filter(el => el.roles.some(ele => ele == role))?.map((childs, index) => {
                            return (
                              <ul key={`sidebar_item_child_children2_${i}_${Math.random()}`}>
                                <li >
                                  <Link
                                    to={childs.path}
                                    className={
                                      childs.active || location === childs.path
                                        ? "active"
                                        : ""
                                    }
                                    onClick={() => childActive(index)}
                                  >
                                    {childs.name}
                                  </Link>
                                </li>
                              </ul>
                            );
                          })}
                          {/* </ul> */}
                        </li>
                      );
                    })
                      :
                      <>
                        {
                          item.children && item.children.length > 0 && item.children.filter(el => el.roles.some(ele => ele == role)).map((child, index) => {
                            return (
                              <li key={`${child.name}_${index}`}>
                                <Link
                                  to={child.path}
                                  className={
                                    child.active || location === child.path
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() => childActive(index)}
                                >
                                  {child.name}
                                </Link>
                              </li>
                            )
                          })
                        }
                      </>
                  }
                </ul>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
}

export default SideBar;