
// 
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/purchaseOrder";


export const addPurchaseOrder = (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};


export const getPurchaseOrder = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/?${query.toString()}`);
};
export const getPurchaseOrderProduct = () => {
    return axiosApiInstance.get(`${serverUrl}/getPurchaseOrderProduct`);
};


export const deletePurchaseOrder = (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};


export const getById = (id) => {
    return axiosApiInstance.get(`${serverUrl}/getById/${id}`);
};


export const updatePurchaseOrder = (formData, id) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};

