import React, { useEffect, useState } from "react";
import { MdOutlineSaveAlt } from "react-icons/md";
import { useSearchParams } from "react-router-dom";
import Select from 'react-select';
import { addOrder, getById, updateOrder } from "../../services/order.service";
import { orderTypes, paymentTypes } from "../../utils/constants";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import ProductDetailsTable from "./ProductDetailsTable";
import moment from "moment";
import { departmentObj, rolesObj } from "../../utils/roles";

function Order() {
  const [vendorCode, setVendorCode] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [orderType, setOrderType] = useState("");
  const [orderNo, setOrderNo] = useState("");
  const [orderDate, setOrderDate] = useState(new Date());
  const [orderAmount, setOrderAmount] = useState(0);
  const [currency, setCurrency] = useState("");
  const [conversionRate, setConversionRate] = useState(0);
  const [gstAmount, setGstAmount] = useState(0);
  const [billingAddress, setBillingAddress] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [freight, setFreight] = useState(0);
  const [paymentMode, setPaymentMode] = useState("");
  const [remarks, setRemarks] = useState("");
  const [productArr, setProductArr] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const [orderTypeArr, setOrderTypeArr] = useState([]);
  const [paymentModeArr, setPaymentModeArr] = useState([]);

  const [role, setRole] = useState({});
  const [zone, setZone] = useState({});
  const [zsm, setZsm] = useState({});
  const [region, setRegion] = useState({});
  const [rsm, setRsm] = useState({});
  const [department, setDepartment] = useState({});

  const [zsmArr, setZsmArr] = useState([
    {
      label: "Zsm 1",
      value: "Zsm1",
    },
    {
      label: "Zsm 2",
      value: "Zsm2",
    },
    {
      label: "Zsm 3",
      value: "Zsm3",
    },
  ]);
  const [zoneArr, setZoneArr] = useState([
    {
      label: "Zone 1",
      value: "Z1",
    },
    {
      label: "Zone 2",
      value: "Z2",
    },
    {
      label: "Zone 3",
      value: "Z3",
    },
  ]);
  const [regionArr, setRegionArr] = useState([
    {
      label: "Region 1",
      value: "R1",
    },
    {
      label: "Region 2",
      value: "R2",
    },
    {
      label: "Region 3",
      value: "R3",
    },
  ]);
  const [rsmArr, setRsmArr] = useState([
    {
      label: "Rsm 1",
      value: "Rsm1",
    },
    {
      label: "Rsm 2",
      value: "Rsm2",
    },
    {
      label: "Rsm 3",
      value: "Rsm3",
    },
  ]);




  useEffect(() => {
    let tempArr = []
    for (const [key, value] of Object.entries(orderTypes)) {
      tempArr.push({ label: key, value: key });
    }
    setOrderTypeArr(tempArr)

    let tempArr2 = []
    for (const [key, value] of Object.entries(paymentTypes)) {
      tempArr2.push({ label: key, value: key });
      console.log(`${key}: ${value}`);
    }
    setPaymentModeArr(tempArr2)
  }, [])



  const [rolesArr, setRolesArr] = useState([]);
  const [departmentsArr, setDepartmentsArr] = useState([]);



  useEffect(() => {

    let tempRolesArr = []

    for (const key in rolesObj) {
      if (Object.hasOwnProperty.call(rolesObj, key)) {
        const element = rolesObj[key];
        if (element != "ADMIN") {
          tempRolesArr.push({ label: element, value: element });
        }
      }
    }

    setRolesArr([...tempRolesArr]);



    let tempDepartmentArr = []

    for (const key in departmentObj) {
      if (Object.hasOwnProperty.call(departmentObj, key)) {
        const element = departmentObj[key];
        tempDepartmentArr.push({ label: element, value: element });
      }
    }

    setDepartmentsArr([...tempDepartmentArr]);

  }, [])



  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");



  const handleSubmit = async () => {
    try {
      if (description == "") {
        toastError("Description is mandatory !!!");
        return
      }
      if (amount <= 0) {
        toastError("Amount is mandatory !!!");
        return
      }
      if (vendorName == "") {
        toastError("Vendor Name is mandatory !!!");
        return
      }
      if (orderType == "") {
        toastError("Order Type is mandatory !!!");
        return
      }
      if (orderNo == "") {
        toastError("Order No is mandatory !!!");
        return
      }
      if (orderAmount <= 0) {
        toastError("Order amount is mandatory !!!");
        return
      }
      if (currency <= 0) {
        toastError("Currency is mandatory !!!");
        return
      }
      if (conversionRate == "") {
        toastError("Conversion Rate is mandatory !!!");
        return
      }
      if (gstAmount <= 0) {
        toastError("GST Amount is mandatory !!!");
        return
      }

      if (billingAddress == "") {
        toastError("Billing Address is mandatory !!!");
        return
      }
      if (deliveryAddress == "") {
        toastError("Delivery Address is mandatory !!!");
        return
      }
      if (paymentMode == "") {
        toastError("Payment Mode is mandatory !!!");
        return
      }
      if (freight <= 0) {
        toastError("Freight is mandatory !!!");
        return
      }

      let obj = {
        description,
        amount: parseInt(amount),
        vendorName,
        orderType,
        orderNo,
        orderDate,
        orderAmount: parseInt(orderAmount),
        currency,
        conversionRate,
        gstAmount: parseInt(gstAmount),
        billingAddress,
        deliveryAddress,
        freight: parseInt(freight),
        paymentMode,
        remarks,
        vendorCode,
        department: department.value,
        productArr: productArr.map(el => ({ ...el, productId: el.productObj.value })),
      }

      if (searchParams.get("id")) {
        let { data: res } = await updateOrder(obj, searchParams.get("id"))
        if (res.message) {
          toastSuccess(res.message)
        }
      }

      else {
        let { data: res } = await addOrder(obj);
        if (res.message) {
          toastSuccess(res.message);
        }
      }

      console.log(obj, "asdasdasd")
    }
    catch (err) {
      toastError(err);
    }
  }




  const handleGetForUpdate = async () => {
    try {
      if (typeof searchParams?.get("id") != 'string')
        return;
      const res = await getById(searchParams?.get("id"));
      if (res.data.data) {
        console.log(res.data, "data")
        if (res.data?.data?.vendorName) {
          setVendorName(res.data?.data?.vendorName)
        }
        if (res.data?.data?.orderType) {
          setOrderType(res.data?.data?.orderType)
        }
        if (res.data?.data?.orderNo) {
          setOrderNo(res.data?.data?.orderNo)
        }
        if (res.data?.data?.orderAmount) {
          setOrderAmount(res.data?.data?.orderAmount)
        }
        if (res.data?.data?.currency) {
          setCurrency(res.data?.data?.currency)
        }
        if (res.data?.data?.conversionRate) {
          setConversionRate(res.data?.data?.conversionRate)
        }
        if (res.data?.data?.gstAmount) {
          setGstAmount(res.data?.data?.gstAmount)
        }
        if (res.data?.data?.billingAddress) {
          setBillingAddress(res.data?.data?.billingAddress)
        }
        if (res.data?.data?.deliveryAddress) {
          setDeliveryAddress(res.data?.data?.deliveryAddress)
        }
        if (res.data?.data?.freight) {
          setFreight(res.data?.data?.freight)
        }
        if (res.data?.data?.paymentMode) {
          setPaymentMode(res.data?.data?.paymentMode)
        }
        if (res.data?.data?.remarks) {
          setRemarks(res.data?.data?.remarks)
        }
        if (res.data?.data?.productArr) {
          setProductArr(res.data?.data?.productArr)
        }
        if (res.data?.data?.description) {
          setDescription(res.data?.data?.description)
        }
        if (res.data?.data?.amount) {
          setAmount(res.data?.data?.amount)
        }
        if (res.data?.data?.department) {
          setDepartment({ label: res.data?.data?.department, value: res.data?.data?.department })
        }
        if (res.data?.data?.department) {
          setVendorCode(res.data?.data?.vendorCode)
        }

        // if (res.data?.data?.image) {
        //   setImage(res.data?.data?.image)
        // }
        // if (res.data?.data?.parentCategoryId) {
        //   setParentCategoryId(res.data?.data?.parentCategoryId)
        // }
        // if (res.data?.data?.parentCategoryArr) {
        // }
      }

    } catch (error) {
      toastError(error)
    }

  }
  useEffect(() => {
    if (searchParams?.get("id")) {
      handleGetForUpdate()
    }

  }, [searchParams.get("id")])
  return (
    <main className="bg-white px-25">
      <section className="user_page_sec">
        <div className="user_header">
          <span className="page_name">Order</span>
        </div>

        <div className="purchase_order_box">
          <div className="row">
            <div className="col-xl-3">
              <div className="purchase_order_ip_box">
                <p className="ip_label">Vendor Code</p>
                <input onChange={(e) => setVendorCode(e.target.value)} value={vendorCode} type="text" placeholder="Vendor" />
              </div>
            </div>
            <div className="col-xl-3">
              <div className="purchase_order_ip_box">
                <p className="ip_label">Vendor</p>
                <input onChange={(e) => setVendorName(e.target.value)} value={vendorName} type="text" placeholder="Vendor" />
              </div>
            </div>
            <div className="col-xl-3">
              <div className="purchase_order_ip_box">
                <p className="ip_label">Order Type</p>
                <input onChange={(e) => setOrderType(e.target.value)} value={orderType} type="text" placeholder="Vendor Order type" />
                {/* <Select options={orderTypeArr} onChange={(e) => setOrderType(e.value)} value={{ label: orderType, value: orderType }} /> */}
              </div>
            </div>
            <div className="col-xl-3">
              <div className="purchase_order_ip_box">
                <p className="ip_label">Order No*</p>
                <input onChange={(e) => setOrderNo(e.target.value)} value={orderNo} type="text" placeholder="Leave Cause" />
              </div>
            </div>
            <div className="col-xl-3">
              <div className="purchase_order_ip_box">
                <p className="ip_label">Order Date*</p>
                <input onChange={(e) => setOrderDate(e.target.value)} value={moment(orderDate).format("YYYY-MM-DD")} type="date" />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-3">
              <div className="purchase_order_ip_box">
                <p className="ip_label">Order Amount</p>
                <input onChange={(e) => setOrderAmount(e.target.value)} value={orderAmount} type="number" placeholder="Order Amount" />
              </div>
            </div>
            <div className="col-xl-3">
              <div className="purchase_order_ip_box">
                <p className="ip_label">Currency</p>
                <input onChange={(e) => setCurrency(e.target.value)} value={currency} type="text" placeholder="currency" />
              </div>
            </div>
            <div className="col-xl-3">
              <div className="purchase_order_ip_box">
                <p className="ip_label">Conversion Rate</p>
                <input onChange={(e) => setConversionRate(e.target.value)} value={conversionRate} type="number" placeholder="Conversion rate" />
              </div>
            </div>
            <div className="col-xl-3">
              <div className="purchase_order_ip_box">
                <p className="ip_label">GST Amount</p>
                <input onChange={(e) => setGstAmount(e.target.value)} value={gstAmount} type="number" placeholder="GST Amount" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3">
              <div className="purchase_order_ip_box">
                <p className="ip_label">Billing Address</p>
                <input onChange={(e) => setBillingAddress(e.target.value)} value={billingAddress} type="text" placeholder="Billing Address" />
              </div>
            </div>
            <div className="col-xl-3">
              <div className="purchase_order_ip_box">
                <p className="ip_label">Delivery Address</p>
                <input onChange={(e) => setDeliveryAddress(e.target.value)} value={deliveryAddress} type="text" placeholder="Delivery Address" />
              </div>
            </div>
            <div className="col-xl-3">
              <div className="purchase_order_ip_box">
                <p className="ip_label">Freight</p>
                <input onChange={(e) => setFreight(e.target.value)} value={freight} type="number" placeholder="Freight" />
              </div>
            </div>
            <div className="col-xl-3">
              <div className="purchase_order_ip_box">
                <p className="ip_label">Payment Mode</p>
                <Select options={paymentModeArr} onChange={(e) => setPaymentMode(e.value)} value={{ label: paymentMode, value: paymentMode }} />
              </div>
            </div>
            {
              (
                role.value == rolesObj?.ZSM ||
                role.value == rolesObj?.RSM ||
                role.value == rolesObj?.SM
              ) &&
              <>
                <div className="col-xl-6">
                  <div className="user_form_ip_box">
                    <p className="ip_label">Zone*</p>
                    <Select options={zoneArr} value={zone} onChange={(e) => setZone(e)} />
                  </div>
                </div>
              </>
            }
            {
              (
                role.value == rolesObj?.RSM ||
                role.value == rolesObj?.SM
              ) &&
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Zone Manager*</p>
                  <Select value={zsm} options={zsmArr} onChange={(e) => setZsm(e)} />
                </div>
              </div>
            }
            {
              (
                role.value == rolesObj?.RSM ||
                role.value == rolesObj?.SM
              ) &&
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Region (State)*</p>
                  <Select value={region} options={regionArr} onChange={(e) => setRegion(e)} />
                </div>
              </div>
            }
            {
              (
                role.value == rolesObj?.SM
              ) &&
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Region Sales Manager*</p>
                  <Select value={rsm} options={rsmArr} onChange={(e) => setRsm(e)} />
                </div>
              </div>
            }
            <div className="col-xl-6">
              <div className="purchase_order_ip_box">
                <p className="ip_label">Department*</p>
                <Select onChange={(e) => setDepartment(e)} value={department} options={departmentsArr} />
              </div>
            </div>
            <div className="col-xl-6">
            <div className="purchase_order_ip_box">
              <p className="ip_label">Remarks</p>
              <input onChange={(e) => setRemarks(e.target.value)} value={remarks} type="text" placeholder="Remarks" />
            </div>
          </div>

          </div>
        </div>

        <div className="row mt-3">
          
        </div>

      </section>



      <ProductDetailsTable productArr={productArr} chats_data_value={(value) => setProductArr(value)} />

      <div className="hr_line">

      </div>

      <section className="user_page_sec">
        <div className="user_header">
          <span className="page_name">Manage Logistics </span>
        </div>

        <div className="purchase_order_box">

          <div className="row">
            {/* <div className="col-xl-3">
              <div className="purchase_order_ip_box">
                <p className="ip_label">Product Code</p>
                <input onChange={(e) => setDescription(e.target.value)} value={description} type="text" placeholder="Description" />
              </div>
            </div> */}
            <div className="col-xl-3">
              <div className="purchase_order_ip_box">
                <p className="ip_label">Description</p>
                <input onChange={(e) => setDescription(e.target.value)} value={description} type="text" placeholder="Description" />
              </div>
            </div>
            <div className="col-xl-3">
              <div className="purchase_order_ip_box">
                <p className="ip_label">Amount</p>
                <input onChange={(e) => setAmount(e.target.value)} value={amount} type="number" placeholder="Amount" />
              </div>
            </div>
            {/* <div className="col-xl-3">
              <div className="purchase_order_ip_box">
                <p className="ip_label">Description</p>
                <input onChange={(e) => setAmount(e.target.value)} value={amount} type="text" placeholder="Description" />
              </div>
            </div>
            <div className="col-xl-3">
              <div className="purchase_order_ip_box">
                <p className="ip_label">Amount</p>
                <input onChange={(e) => setAmount(e.target.value)} value={amount} type="text" placeholder="Amount" />
              </div>
            </div> */}

          </div>

        </div>

        <div className="row">
          <div className="buttn_groups">
            {/* <button className="">Add <MdOutlineAdd /></button>
            <button>Edit <AiFillEdit /></button> */}
            <button onClick={() => handleSubmit()}>Save <MdOutlineSaveAlt /></button>
          </div>

        </div>
      </section>




    </main>
  );
}

export default Order;
