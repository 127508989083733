import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import axios from "axios";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";
import "./assets/scss/main.css";
import { persistor, Store } from "./redux/store";
import RootRouter from "./routes/RootRouter";
export const axiosApiInstance = axios.create();

const queryClient = new QueryClient();
function App() {
   return (
      <QueryClientProvider client={queryClient}>
         <Provider store={Store}>
            <PersistGate loading={<h1>Loading...</h1>} persistor={persistor}>
               <RootRouter />
               <Toaster />
            </PersistGate>
         </Provider>
      </QueryClientProvider>
   );
}

export default App;
