import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { HiChevronRight } from "react-icons/hi";
import Select from "react-select";
import {
    getSalesRevenueYearAndZoneWiseChange
} from "../../services/dispatchingInputSaleInvoice.service";
import { getProductsByCategoryName } from "../../services/product.service";
import { getZone } from "../../services/zone.service";
import CustomButton from "../Utility/Button";
import { DashboardBox, DashboardChart } from "../Utility/DashboardBox";
import { toastError } from "../Utility/ToastUtils";
import { formatter } from "../Utility/utils";
import { handleReturnBarGraphColorArr } from "../Utility/barGraphColorArray";
export default function ZoneAndYearWiseIncentiveProductSale() {
    const [productsArr, setProductsArr] = useState([]);
    const [barLabels, setBarLabels] = useState([
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
        "January",
        "February",
        "March",
    ]);

    const [selectedSpecialityProductsArr, setSelectedSpecialityProductsArr] =
        useState([]);
    const [specialityProductsArr, setSpecialityProductsArr] = useState([]);
    const [yearsArr, setYearsArr] = useState([]);
    const [selectedSecondYear, setSelectedSecondYear] = useState("");
    const [selectedYear, setSelectedYear] = useState("");
    const [selectedProductId, setSelectedProductId] = useState("");
    const [selectedReportType, setSelectedReportType] = useState("Monthly");

    const [selectedZone, setSelectedZone] = useState("");

    const [displayYear, setDisplayYear] = useState("");
    const [displayProduct, setDisplayProduct] = useState("");

    const [reportType, setReportType] = useState([
        { label: "Quarterly", value: "Quarterly" },
        { label: "Monthly", value: "Monthly" },
    ]);

    const [unitOfMeasurement, setUnitOfMeasurement] = useState("");
    const [requestedYearSalesArr, setRequestedYearSalesArr] = useState([]);
    const [previousYearSalesArr, setPreviousYearSalesArr] = useState([]);

    const [zoneArr, setZoneArr] = useState([]);

    const HandleGetComodityProductsSalesYearWise = async () => {
        try {
            let query = {};
            if (selectedYear == "") {
                toastError("Please select an year");
                return;
            }

            if (selectedYear && selectedYear != "") {
                query = { ...query, selectedYear: selectedYear };
            }
            if (selectedSpecialityProductsArr && selectedSpecialityProductsArr.length > 0) {
                query = { ...query, selectedProductsArr: selectedSpecialityProductsArr.map(el => el.value) };
            }
            if (selectedSecondYear && selectedSecondYear != "") {
                query = { ...query, selectedSecondYear: selectedSecondYear };
            }
            if (selectedZone && selectedZone != "") {
                query = { ...query, selectedZone: selectedZone };
            }
            if (selectedReportType && selectedReportType != "") {
                query = { ...query, selectedReportType: selectedReportType };
            }

            console.log("selectedSpecialityProductsArr", selectedSpecialityProductsArr);
            setDisplayYear(selectedYear);
            setDisplayProduct(selectedProductId);

            let { data: res } = await getSalesRevenueYearAndZoneWiseChange(
                query
            );
            if (res.data) {
                if (selectedReportType == "Quarterly") {
                    setBarLabels(["Q1", "Q2", "Q3", "Q4"]);

                    setRequestedYearSalesArr(res.data.requestedYearsOrderCount);
                    setPreviousYearSalesArr(res.data.previousYearsOrderCount);
                } else {
                    setBarLabels([
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December",
                        "January",
                        "February",
                        "March",
                    ]);
                    setRequestedYearSalesArr(res.data.requestedYearsOrderCount);
                    setPreviousYearSalesArr(res.data.previousYearsOrderCount);
                }
                setUnitOfMeasurement(res.data.unitOfMeasurement);
            }
        } catch (error) {
            toastError(error);
        }
    };

    const handleCalculateLastFiveYears = () => {
        let tempArr = [];
        let today = new Date();

        let totalYears = today.getFullYear() - 2018;

        for (let index = 0; index <= totalYears; index++) {
            let obj = {};
            let year = today.getFullYear() - index;
            obj.label = `${year}-${year + 1}`;
            obj.value = year;
            tempArr.push(obj);
        }

        setYearsArr(tempArr);
    };

    const HandleGetZone = async () => {
        try {
            let { data: res } = await getZone(
                { pageIndex: 0, pageSize: 10000000 },
                { forSelectInput: true }
            );
            if (res.data) {
                setZoneArr(res.data);
            }
        } catch (error) {
            toastError(error);
        }
    };

    const HandleGetSpecialityProducts = async () => {
        try {
            let query = { name: "speciality" };
            let { data: res } = await getProductsByCategoryName(query);
            if (res.data) {
                setSpecialityProductsArr(res.data);
            }
        } catch (error) {
            toastError(error);
        }
    };
    useEffect(() => {
        HandleGetZone();
        HandleGetSpecialityProducts();
        handleCalculateLastFiveYears();
    }, []);

    return (
        <main className="bg-white">
            <section className="dashboard-head mb-3">
                <div className="row">
                    <div className="col-xl-10"></div>
                    <div className="col-xl-2">
                        {/* <div className="user_header">
                            <p className="breadcrumb">
                                <span className="parent">Dashboard </span>{" "}
                                <span>
                                    {" "}
                                    <HiChevronRight />
                                </span>{" "}
                                <span className="child">Reports</span>
                            </p>
                        </div> */}
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-md-12 mb-5 p-5 border rounded">
                            <div className="row">
                                <h3 className="blue-1">
                                    Zone Wise Incentive Product Sale
                                </h3>
                                <div className="col-12 col-lg-4">
                                    <DashboardBox className="dashboard-summary">
                                        <div className="row align-items-center">
                                            <div className="col-xl-12">
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                        marginBottom: 15
                                                    }}>
                                                    <h5 className="blue-1">
                                                        Incentive Products
                                                    </h5>
                                                    <CustomButton
                                                        isBtn
                                                        btntype="button"
                                                        ClickEvent={() =>
                                                            setSelectedSpecialityProductsArr(
                                                                [
                                                                    ...specialityProductsArr,
                                                                ]
                                                            )
                                                        }
                                                        iconName="fa-solid fa-check"
                                                        extraClass={"pe-3"}
                                                        btnName="Select all"
                                                    />
                                                </div>
                                                <Select
                                                    options={
                                                        specialityProductsArr
                                                    }
                                                    isMulti
                                                    value={
                                                        selectedSpecialityProductsArr
                                                    }
                                                    closeMenuOnSelect={false}
                                                    onChange={(e) =>
                                                        setSelectedSpecialityProductsArr(
                                                            e
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </DashboardBox>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <DashboardBox className="dashboard-summary">
                                        <div className="row align-items-center">
                                            <div className="col-xl-9">
                                                <div className="right_content_box">
                                                    <h5 className="blue-1 mb-4 pb-2">
                                                        Zone
                                                    </h5>
                                                    <Select
                                                        options={zoneArr}
                                                        onChange={(e) =>
                                                            setSelectedZone(
                                                                e.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </DashboardBox>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <DashboardBox className="dashboard-summary">
                                        <div className="row align-items-center">
                                            <div className="col-xl-9">
                                                <div className="right_content_box">
                                                    <h5 className="blue-1 mb-4 pb-2">
                                                        First Year
                                                    </h5>
                                                    <Select
                                                        options={yearsArr}
                                                        onChange={(e) =>
                                                            setSelectedYear(
                                                                e.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </DashboardBox>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <DashboardBox className="dashboard-summary">
                                        <div className="row align-items-center">
                                            <div className="col-xl-9">
                                                <div className="right_content_box">
                                                    <h5 className="blue-1 mb-4 pb-2">
                                                        Second Year
                                                    </h5>
                                                    <Select
                                                        options={yearsArr}
                                                        onChange={(e) =>
                                                            setSelectedSecondYear(
                                                                e.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </DashboardBox>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <DashboardBox className="dashboard-summary">
                                        <div className="row align-items-center">
                                            <div className="col-xl-9">
                                                <div className="right_content_box">
                                                    <h5 className="blue-1 mb-4 pb-2">
                                                        Type
                                                    </h5>
                                                    <Select
                                                        options={reportType}
                                                        value={{
                                                            label: selectedReportType,
                                                            value: selectedReportType,
                                                        }}
                                                        onChange={(e) =>
                                                            setSelectedReportType(
                                                                e.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </DashboardBox>
                                </div>
                                <div className="col-12 col-lg-3 mt-5">
                                    <CustomButton
                                        isBtn
                                        btntype="button"
                                        ClickEvent={
                                            HandleGetComodityProductsSalesYearWise
                                        }
                                        iconName="fa-solid fa-check"
                                        extraClass={"mt-3"}
                                        btnName="Submit"
                                    />
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-12 col-lg-6">
                                    <DashboardChart>
                                        {requestedYearSalesArr &&
                                            requestedYearSalesArr.length >
                                            0 && (
                                                <>
                                                    <h5 className="blue-1 my-4">
                                                        First Year (
                                                        {displayYear})
                                                    </h5>

                                                    <Bar
                                                        data={{
                                                            labels: barLabels,
                                                            datasets: [
                                                                {
                                                                    label: selectedProductId,
                                                                    backgroundColor: handleReturnBarGraphColorArr(requestedYearSalesArr?.map((el) => el?.value)),
                                                                    borderColor:
                                                                        "#2e4765",
                                                                    data: requestedYearSalesArr?.map(
                                                                        (el) =>
                                                                            el?.value
                                                                    ),
                                                                },
                                                            ],
                                                        }}
                                                    />
                                                    <h5 className="blue-1 my-4">
                                                        Total Sale in (
                                                        {displayYear}) is{" "}
                                                        {formatter.format(
                                                            requestedYearSalesArr
                                                                .reduce(
                                                                    (
                                                                        acc,
                                                                        el,
                                                                        index
                                                                    ) =>
                                                                        acc +
                                                                        el.value,
                                                                    0
                                                                )
                                                                .toFixed(2)
                                                        )}{" "}
                                                    </h5>
                                                </>
                                            )}
                                    </DashboardChart>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <DashboardChart>
                                        {previousYearSalesArr &&
                                            previousYearSalesArr.length > 0 && (
                                                <>
                                                    <h5 className="blue-1 my-4">
                                                        Second Year (
                                                        {parseInt(
                                                            selectedSecondYear
                                                        )}
                                                        )
                                                    </h5>
                                                    <Bar
                                                        data={{
                                                            labels: barLabels,
                                                            datasets: [
                                                                {
                                                                    label: selectedProductId,
                                                                    backgroundColor: handleReturnBarGraphColorArr(previousYearSalesArr?.map((el) => el?.value)),
                                                                    borderColor:
                                                                        "#2e4765",
                                                                    data: previousYearSalesArr?.map(
                                                                        (el) =>
                                                                            el?.value
                                                                    ),
                                                                },
                                                            ],
                                                        }}
                                                    />
                                                    <h5 className="blue-1 my-4">
                                                        Total Sale in (
                                                        {selectedSecondYear}) is{" "}
                                                        {formatter.format(
                                                            previousYearSalesArr
                                                                .reduce(
                                                                    (
                                                                        acc,
                                                                        el,
                                                                        index
                                                                    ) =>
                                                                        acc +
                                                                        el.value,
                                                                    0
                                                                )
                                                                .toFixed(2)
                                                        )}{" "}
                                                    </h5>
                                                </>
                                            )}
                                    </DashboardChart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
