import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { CiLock } from "react-icons/ci";
import { useSearchParams } from "react-router-dom";
import { getUser } from "../../services/users.service";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { addAttendance, getById, updateAttendance } from "../../services/attendance.service";
import Select from 'react-select';
import moment from "moment";
import { attendanceTypes } from "../../utils/constants";

function UserAttendance() {

  const [searchParams, setSearchParams] = useSearchParams();

  const [userArr, setUserArr] = useState([]);
  const [userId, setUserId] = useState("");
  const [on, setOn] = useState(new Date());
  const [note, setNote] = useState("");
  const [type, setType] = useState(attendanceTypes.FULLDAY);

  const [attendanceTypeArr, setAttendanceTypeArr] = useState([]);


  useEffect(() => {
    let tempArr = []
    for (const [key, value] of Object.entries(attendanceTypes)) {
      tempArr.push({ label: key, value: key });
      console.log(`${key}: ${value}`);
    }
    setAttendanceTypeArr(tempArr)
  }, [])


  const handleGetAllUsers = async () => {
    try {
      let query = { isForSelectInput: true }
      let { data: response } = await getUser({ pageIndex: 0, pageSize: 10000000 }, query)
      if (response) {
        setUserArr(response.data)
      }
    } catch (error) {
      toastError(error)
    }
  };
  useEffect(() => {
    handleGetAllUsers();
  }, []);



  const handleSubmit = async () => {
    try {

      if (userId == "") {
        toastError("User is mandatory !!!");
        return
      }

      if (type == "") {
        toastError("type is mandatory !!!");
        return
      }




      let obj = {
        userId: userId.value,
        type,
        on,
      }
      if (searchParams.get("id")) {
        let { data: res } = await updateAttendance(obj, searchParams.get("id"))
        if (res.message) {
          toastSuccess(res.message)
        }
      }

      else {
        let { data: res } = await addAttendance(obj)
        if (res.message) {
          toastSuccess(res.message)
        }
      }
    }
    catch (err) {
      toastError(err)
    }
  }




  const handleGetForUpdate = async () => {
    try {
      if (typeof searchParams?.get("id") != 'string')
        return;
      const res = await getById(searchParams?.get("id"));
      if (res.data.data) {
        console.log(res.data, "data")
        if (res.data?.data?.userId) {
          setUserId(prev => ({ ...prev, value: res.data?.data?.userId }))
        }
        if (res.data?.data?.label) {
          setUserId(prev => ({ ...prev, label: res.data?.data?.label }))
        }
        if (res.data?.data?.type) {
          setType(res.data?.data?.type)
        }
        if (res.data?.data?.on) {
          setOn(res.data?.data?.on)
        }

      }

    } catch (error) {
      toastError(error)
    }

  }
  useEffect(() => {
    if (searchParams?.get("id")) {
      handleGetForUpdate()
    }

  }, [searchParams.get("id")])
  return (
    <main className="bg-white">
      <section className="user_page_sec">
        <div className="user_header">
          {/* <span className="page_name">Attendance</span>
          <p className="breadcrumb">
            <span className="parent">Dashboard </span>{" "}
            <span>
              {" "}
              <HiChevronRight />
            </span>{" "}
            <span className="child">Attendance</span>
          </p> */}
        </div>

        <div className="user_form_box">
          <div className="form_header">
            <h6 className="heading">Attendance</h6>
            <span className="sub_heading"> Attendance</span>
          </div>

          <div className="form_box">
            <div className="row">
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">User Name*</p>
                  <Select options={userArr} onChange={(e) => setUserId(e)} value={userId} />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Date*</p>
                  <input onChange={(e) => setOn(e.target.value)} value={moment(on).format("YYYY-MM-DD")} type="date" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Select Attendance*</p>
                  <Select options={attendanceTypeArr} onChange={(e) => setType(e.value)} value={{ label: type, value: type }} />

                </div>
              </div>
            </div>

            <div className="row">
              <button onClick={() => handleSubmit()} className="submit_buttn">Submit</button>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default UserAttendance;
