export const handleReturnBarGraphColorArr = (dataArr) => {
    let resultArr = [];
    for (let index = 0; index < dataArr.length; index++) {
        if(index == 0){
            resultArr.push("#aba9d9")
        }
        else if(dataArr[index] <dataArr[index - 1]){
            resultArr.push("#d9aba9")
        }
        else if (dataArr[index] > dataArr[index -1]){
            resultArr.push("#a9d9bc")
        }
        else{
            resultArr.push("#aba9d9")
        }
    }
    return resultArr
}



