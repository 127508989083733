import React, { useState, useEffect } from 'react';
import { getCategory } from '../../services/category.service';
import Select from 'react-select'
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { toastError } from '../../utils/toastUtils';

export default function SelectNestedCategory({ onChange, onChangeParentCategoryArr, parentCategoryIdArr = [], categoryId = '', maxDepth = 1, hideIdArr = [], showForLead = false }) {
    const [innerCategoryId, setInnerCategoryId] = useState('')
    const [innerParentCategoryIdArr, setInnerParentCategoryIdArr] = useState([])
    const [categoryArr, setCategoryArr] = useState([])
    const [selectOptions, setSelectOptions] = useState([[]])
    const [selectedArr, setSelectedArr] = useState([])

    useEffect(() => {
        // console.log(parentCategoryIdArr, "parentCategoryIdArr")
        setDataOnInit()
    }, [parentCategoryIdArr])




    const setDataOnInit = async () => {
        try {
            if (parentCategoryIdArr.length == 0) {
                const arr = await getCategoryData()
                setSelectOptions([[...arr]])
                console.log({ arr })
            }
            else { // loop and get for update
                let selectOptions = []
                setSelectedArr(parentCategoryIdArr.map(el => el.categoryId))
                const arrParent = await getCategoryData()
                selectOptions.push(arrParent)
                console.log(parentCategoryIdArr, "parentCategoryIdArr", parentCategoryIdArr.entries())
                for (let [index, val] of (parentCategoryIdArr).entries()) {
                    try {
                        console.log("LOOPING???",)
                        const arr = await getCategoryData(index + 2, val?.categoryId)
                        selectOptions.push(arr)
                    } catch (error) {
                        console.error(error)
                    }
                }

                setSelectOptions(selectOptions)
            }

        } catch (error) {
            console.error(error)
        }
    }

    const getSelected = (index) => {
        if (index < selectedArr?.length && index < selectOptions?.length) {
            let obj = selectOptions[index].find(el => el._id == selectedArr[index])
            if (obj) {
                return { value: obj._id, label: obj.name }
            }
        }
        return { label: '', value: '' }
    }

    const onChangeSelect = async (index, value) => {
        try {
            setSelectedArr(prev => [...prev.filter((el, i) => i < index), value])
            if (onChange)
                onChange(value, index + 1, selectOptions[index]?.find(el => el._id == value))
            if (onChangeParentCategoryArr) {
                let arr = selectOptions[index]
                let obj = arr.find(el => el?._id == value)
                if (obj?.parentCategoryArr?.length >= 0) {
                    onChangeParentCategoryArr([...obj?.parentCategoryArr.map((el) => ({ categoryId: el.categoryId })), { categoryId: value }])
                }
            }
            setInnerCategoryId(value);
            const arr = await getCategoryData(index + 2, value)
            console.log({ arr })
            // setSelectOptions(prev => [...prev.filter((el, i) => i <= index), arr])

            // to reset the value of react select
            setSelectOptions(prev => [...prev.filter((el, i) => i <= index)])
            setTimeout(() => {
                setSelectOptions(prev => [...prev, arr])
            }, 100)

        } catch (error) {
            console.error(error)
        }
    }



    const getCategoryData = async (level = 1, categoryId = undefined) => {
        try {
            let query = `level=${level}`;
            let obj = {
                level: level
            }
            if (categoryId) {
                query = query + `&parentCategoryId=${categoryId}`
                obj.parentCategoryId = categoryId
            }
            if (showForLead) {
                obj.showForLead = true
            }

            const res = await getCategory({ pageIndex: 0, pageSize: 10000000 }, obj);
            // console.log(res?.data)
            if (res?.data?.data) {
                return (res?.data?.data)
            }
        } catch (error) {
            console.error(error);
            toastError(error)
        }
        return ([])
    }





    return (
        <Box sx={{ mt: 1 }}>
            <Typography>
                Select Category:
            </Typography>
            {selectOptions.filter((el, i) => i < maxDepth).map(((el, i) => (
                <Box key={i} sx={{ pt: 1, position: 'relative', zIndex: 30 + maxDepth - i }}>
                    <Select options={el.filter(el => !hideIdArr.some(elx => el._id == elx)).map(ele => ({ label: ele?.name, value: ele?._id }))} value={getSelected(i)} onChange={(val) => onChangeSelect(i, val?.value)} />
                </Box>
            )))}


        </Box>
    )

}
