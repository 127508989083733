import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AiFillEdit } from "react-icons/ai";
import { MdOutlineAdd } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import userimg from "../../assets/images/avatar.png";
import { deleteReversal, downloadReversalExcel, getReversal } from "../../services/reversal.service";
import { toastError } from "../../utils/toastUtils";
import { DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import { toastSuccess } from "../Utility/ToastUtils";
import moment from "moment";
import { debounce } from "lodash";
import { generateFilePath } from "../Utility/utils";

function ReversalList() {

  const [query, setQuery] = useState("");
  const [displayUserArr, setDisplayUserArr] = useState([]);
  const [mainArr, setMainArr] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const location = useLocation();


  const handleEdit = (row) => {
    navigate("/AddReversal" + `?id=${row}`);
  };

  const users_columns = [
    {
      name: "Sr No.",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Document Type",
      selector: (row) => `${row?.documentType ? row?.documentType : "NA"}`,
    },
    {
      name: "Document Number",
      selector: (row) => row?.documentNumber,
      width: "10%",
    },
    {
      name: "Partner Code",
      selector: (row) => row?.partCode,
      width: "10%",
    },
    {
      name: "Document Total",
      selector: (row) => "₹ " + String(row?.documentTotal),
      width: "10%",
    },
    {
      name: "Ref DocNumber",
      selector: (row) => row?.refDocNumber,
      width: "10%",
    },
    {
      name: "Line count",
      selector: (row) => row?.itemsArr ? (row?.itemsArr.length > 1 ? row?.itemsArr.length + " lines" : row?.itemsArr.length + "line") : "0 lines",
      width: "10%",
    },
    {
      name: "Document Date",
      selector: (row) => moment(row?.documentDate).format("DD-MM-YYYY"),
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button onClick={() => handleEdit(row._id)} className="edit_buttn">
            <AiFillEdit />
          </button>
          <div style={{ marginLeft: 15 }}>
            <button onClick={() => handleDelete(row._id)} className="delete_buttn">
              <RiDeleteBin5Line />
            </button>
          </div>
        </>
      ),
      width: "10%",
    },
  ];

  // const handleGet = async () => {
  //   try {
  //     let { data: res } = await getReversal({ pageIndex: 0, pageSize: 10000000 }, "");
  //     if (res.message) {
  //       setDisplayUserArr(res.data);
  //       setMainArr(res.data);
  //     }
  //   } catch (err) {
  //     toastError(err);
  //   }
  // };

  const handleGet = async (pageValue, limitValue, queryValue) => {
    try {
      let query = "";
      if (queryValue && queryValue != "") {
        query = `${queryValue}`
      }
      console.log(query, "query")
      let { data: res } = await getReversal({ pageIndex: pageValue, pageSize: limitValue }, { query });
      if (res.message) {
        setDisplayUserArr(res.data);
        setTotalElements(res.total);
        setMainArr(res.data);
      }
    } catch (err) {
      toastError(err);
    }
  };
  const handleReversalDownloadExcel = async (queryValue) => {
    try {
      let query = "";
      if (queryValue && queryValue != "") {
        query = `${queryValue}`
      }
      console.log(query, "query")
      let { data: res } = await downloadReversalExcel({ query });
      if (res) {
        var link = document.createElement("a");
        link.download = res.filename;
        link.href = generateFilePath(res.filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err) {
      toastError(err);
    }
  };


  // useEffect(() => {
  //   handleGet()
  // }, [])

  const handleDelete = async (id) => {
    try {
      if (!window.confirm("Are you sure")) {
        return
      }
      let { data: res } = await deleteReversal(id)
      if (res.message) {
        toastSuccess(res.message);
        handleGet(1, limit, query)
      }
    }
    catch (err) {
      toastError(err)
    }
  }


  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalElements, setTotalElements] = useState(0);

  const createQueryString = useCallback(
    (arr) => {
      const params = new URLSearchParams(searchParams.toString());
      for (let i = 0; i < arr.length; i++) {
        const element = arr[i];
        params.set(element.name, element.value);
      }
      console.log(params.toString(), "params.toString()");
      return params.toString();
    },
    [searchParams]
  );

  const handlePerRowsChange = (newPerPage, page) => {
    let pathname = window.location.href.split("/")[3];
    navigate(location.pathname + "?" + createQueryString([{ name: "limit", value: newPerPage }])
    );
    setLimit(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
    navigate(location.pathname + "?" + createQueryString([{ name: "page", value: page }])
    );
  };


  useEffect(() => {
    if (searchParams) {
      const query = searchParams.get("query") || "";
      const limit = parseInt(searchParams.get("limit") || "10");
      const pageValue = parseInt(searchParams.get("page") || "1");
      setLimit(limit);
      setPage(pageValue);
      setQuery(query);
      console.log(searchParams.toString(), "searchParams")
      handleGet(pageValue, limit, query);
    }
  }, [searchParams]);

  const handleSearch = (queryValue) => {
    setQuery(queryValue);
    let tempArr = mainArr;
    tempArr = tempArr.filter(
      (el) =>
        `${el?.name}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
        `${el?.phone}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
        `${el?.email}`.toLowerCase().includes(`${queryValue}`.toLowerCase())
    );
    setDisplayUserArr([...tempArr]);
  };

  const debouncedSave = useCallback(
    debounce(
      (nextValue) => {
        navigate(location.pathname +
          "?" +
          createQueryString([
            { name: "query", value: nextValue },
            { name: "page", value: 1 },
          ])
        )

      },
      1000
    ),
    [] // will be created only once initially
  );

  // highlight-ends

  const handleChange = (event) => {
    const nextValue = event;
    setQuery(nextValue);
    // Even though handleChange is created on each render and executed
    // it references the same debouncedSave that was created initially
    debouncedSave(nextValue);
  };



  return (
    <main className="p-0">
      <section className="product-category">
        <div className="row align-items-center">
          <div className="col-xl-10">
            <div className="user_header_left">
              <div className="user_img">
                <img src={userimg} alt="" />
              </div>

              <span>Reversal</span>
            </div>
          </div>
          <div className="col-xl-2">
            <div className="user_header_right">
              <button onClick={() => navigate("/AddReversal")} className="">
                Add <MdOutlineAdd />
              </button>
            </div>
          </div>
        </div>
        <DashboardTable>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <button onClick={handleReversalDownloadExcel} className="copy_excel_buttn">Copy to Excel</button>
            <div className="d-flex align-items-center gap-3">
              <SearchBox
                query={query}
                setQuery={handleChange}
                extraClass="bg-white"
              />
            </div>
          </div>

          <DataTable columns={users_columns} data={displayUserArr} pagination
            paginationPerPage={limit}
            paginationDefaultPage={page}
            paginationServer={true}
            paginationRowsPerPageOptions={[10, 20, 30, 50, 100, 200, 500]}
            paginationTotalRows={totalElements}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange} />
        </DashboardTable>
      </section>
    </main>
  );
}

export default ReversalList;
