import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { addCategory, getCategoryById, updateCategory } from "../../services/category.service";
import { useParams, useSearchParams } from "react-router-dom";
import SelectNestedCategory from "../Utility/SelectNestedCategory";
import { generateFilePath } from "../Utility/utils";

function UserCategory() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [parentCategoryId, setParentCategoryId] = useState("");
  const [prevParentCategoryArr, setPrevParentCategoryArr] = useState([])
  const [parentCategoryLevel, setParentCategoryLevel] = useState(0);

  const [addSubCategory, setaddSubCategory] = useState(false);


  const handleGetCategoryForUpdate = async () => {
    try {
      if (typeof searchParams?.get("id") != 'string')
        return;
      const res = await getCategoryById(searchParams?.get("id"));
      if (res.data.data) {
        console.log(res.data, "data")
        if (res.data?.data?.name) {
          setName(res.data?.data?.name)
        }
        // if (res.data?.data?.sortOrder)
        //     setSortOrder(res.data?.data?.sortOrder)


        if (res.data?.data?.image) {
          setImage(res.data?.data?.image)
          // setaddSubCategory(true)
        }
        if (res.data?.data?.parentCategoryId) {
          setParentCategoryId(res.data?.data?.parentCategoryId)
          setaddSubCategory(true)
        }
        if (res.data?.data?.parentCategoryArr) {
          setPrevParentCategoryArr(res.data?.data?.parentCategoryArr)
          setParentCategoryLevel(res.data?.data?.parentCategoryArr?.length)
        }
      }

    } catch (error) {
      toastError(error)
    }

  }
  useEffect(() => {
    if (searchParams?.get("id")) {
      handleGetCategoryForUpdate()
    }

  }, [searchParams.get("id")])






  const handleAdd = async () => {
    try {

      if (name == "") {
        toastError("Name is mandatory !!!");
        return
      }

      if (image == "") {
        toastError("Image is mandatory !!!");
        return
      }

      let obj = {
        name,
        image
      }
      if (parentCategoryId) {
        obj.parentCategoryId = parentCategoryId
      }
      if (searchParams.get("id")) {
        let { data: res } = await updateCategory(obj, searchParams.get("id"))
        if (res.message) {
          toastSuccess(res.message)
        }
      }
      else {
        let { data: res } = await addCategory(obj)
        if (res.message) {
          toastSuccess(res.message)
        }
      }
    }
    catch (err) {
      toastError(err)
    }
  }



  // const [file, setFile] = useState("");

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      // console.log('Error: ', error)
    };
  };
  const handleFileSelection = (event) => {
    if (event.target.files[0]) {
      getBase64(event.target.files[0], (result) => {
        // setImage(event.target.files[0]);
        setImage(result);
      });
    }
  };
  return (
    <main className="bg-white">
      <section className="user_page_sec">
        {/* <div className="user_header">
          <span className="page_name">Category</span>
          <p className="breadcrumb">
            <span className="parent">Dashboard </span>{" "}
            <span>
              {" "}
              <HiChevronRight />
            </span>{" "}
            <span className="child">Category</span>
          </p>
        </div> */}

        <div className="user_form_box">
          <div className="form_header">
            <h6 className="heading">User</h6>
            <span className="sub_heading">Create Category</span>
          </div>

          <div className="form_box">
            <div className="row">
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Name*</p>
                  <input onChange={(e) => setName(e.target.value)} value={name} type="text" placeholder='Enter Name' />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_img_box">
                  <p className="ip_label">Image*</p>
                  <div>
                    {
                      image && image.includes("base64") ?
                        <img src={image} style={{ height: 100, width: 100 }} alt="" srcset="" />
                        :
                        <img src={generateFilePath(image)} style={{ height: 100, width: 100 }} alt="" srcset="" />
                    }
                  </div>
                  <button className="choose_img_buttn">Choose File</button>
                  <input onChange={(e) => handleFileSelection(e)} type="file" placeholder='No file Chosen' />
                </div>
              </div>

              <input
                className="form-check-input"
                type="checkbox"
                name="category-status"
                value="option1"
                id="add-as-sub-category"
                checked={addSubCategory}
                onChange={(e) => {
                  if (e.target.checked) {
                    setaddSubCategory(true);
                  } else {
                    setaddSubCategory(false);
                  }
                }}
              />
              <label className="form-check-label fs-14 pointer" htmlFor="add-as-sub-category">
                Add as Sub Category
              </label>
              {addSubCategory && <SelectNestedCategory onChange={(val, level) => { setParentCategoryId(val); setParentCategoryLevel(level) }} maxDepth={3} parentCategoryIdArr={prevParentCategoryArr} hideIdArr={searchParams.get("id") ? [searchParams.get("id")] : []} />}
            </div>


            <div className="row">
              <button onClick={() => handleAdd()} className="submit_buttn">Submit</button>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default UserCategory;
