import React, { useEffect, useState } from 'react';
import { CiLock } from 'react-icons/ci';
import { HiChevronRight } from 'react-icons/hi';
import { useSearchParams } from 'react-router-dom';
import { getNestedCategories } from '../../services/category.service';
import { addProduct, getProductById, updateProductById } from '../../services/product.service';
import SelectNestedCategory from '../Utility/SelectNestedCategory';
import { toastError, toastSuccess } from '../Utility/ToastUtils';
import { generateFilePath } from '../Utility/utils';

function Product() {
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [prevParentCategoryArr, setPrevParentCategoryArr] = useState([])
  const [categoryArr, setCategoryArr] = useState([])
  const [categoryId, setCategoryId] = useState("");
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [productCode, setProductCode] = useState("");

  const [parentCategoryId, setParentCategoryId] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([])


  const [searchParams, setSearchParams] = useSearchParams();


  const [rekoCode, setRekoCode] = useState("");

  const handleGetCategory = async () => {
    try {
      let { data: res } = await getNestedCategories()
      if (res.data) {
        setCategoryArr(res.data);
      }
    }
    catch (err) {
      toastError(err)
    }
  }

  useEffect(() => {
    handleGetCategory()
  }, [])

  const handleRegister = async () => {
    try {

      if (name == "") {
        toastError("Name is mandatory !!!");
        return
      }

      if (image == "") {
        toastError("Image is mandatory !!!");
        return
      }
      if (productCode == "") {
        toastError("Product Code is mandatory !!!");
        return
      }

      if (selectedCategory == "") {
        toastError("Category is mandatory !!!");
        return
      }
      if (description == "") {
        toastError("Description is mandatory !!!");
        return
      }

      let obj = {
        categoryId: selectedCategory,
        name,
        image,
        description,
        rekoCode,
        productCode,
      }
      if (searchParams.get("id")) {
        let { data: res } = await updateProductById(obj, searchParams.get("id"))
        if (res.message) {
          toastSuccess(res.message)
        }
      }

      else {
        let { data: res } = await addProduct(obj)
        if (res.message) {
          toastSuccess(res.message)
        }
      }
    }
    catch (err) {
      toastError(err)
    }
  }




  const handleGetCategoryForUpdate = async () => {
    try {
      if (typeof searchParams?.get("id") != 'string')
        return;
      const res = await getProductById(searchParams?.get("id"));
      if (res.data.data) {
        console.log(res.data, "data")
        if (res.data?.data?.name) {
          setName(res.data?.data?.name)
        }
        if (res.data?.data?.categoryId) {
          setSelectedCategory(res.data?.data?.categoryId)
        }
        if (res.data?.data?.categoryArr) {
          setPrevParentCategoryArr(res.data?.data?.categoryArr)
        }
        if (res.data?.data?.description) {
          setDescription(res.data?.data?.description)
        }

        if (res.data?.data?.image) {
          setImage(res.data?.data?.image)
        }
        if (res.data?.data?.parentCategoryId) {
          setParentCategoryId(res.data?.data?.parentCategoryId)
        }
        if (res.data?.data?.rekoCode) {
          setRekoCode(res.data?.data?.rekoCode);
        }
        if (res.data?.data?.productCode) {
          setProductCode(res.data?.data?.productCode);
        }
      }

    } catch (error) {
      toastError(error)
    }

  }
  useEffect(() => {
    if (searchParams?.get("id")) {
      handleGetCategoryForUpdate()
    }

  }, [searchParams.get("id")])




  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      // console.log('Error: ', error)
    };
  };
  const handleFileSelection = (event) => {
    if (event.target.files[0]) {
      getBase64(event.target.files[0], (result) => {
        setImage(result);
      });
    }
  };
  return (
    <main className='bg-white'>
      <section className="user_page_sec">
        {/* <div className="user_header">
          <span className='page_name'>Product</span>
          <p className="breadcrumb">
            <span className='parent'>Dashboard </span> <span> <HiChevronRight /></span> <span className='child'>Product</span>
          </p>
        </div> */}

        <div className="user_form_box">
          <div className="form_header">
            <h6 className="heading">Product</h6>
            <span className='sub_heading'>Create Product</span>
          </div>

          <div className="form_box">
            <div className="row">

              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Product Code*</p>
                  <input onChange={(e) => setProductCode(e.target.value)} value={productCode} type="text" placeholder='Enter Product Code' />

                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Name*</p>
                  <input onChange={(e) => setName(e.target.value)} value={name} type="text" placeholder='Enter Name' />

                </div>
              </div>

              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Description*</p>
                  <input onChange={(e) => setDescription(e.target.value)} value={description} type="text" placeholder='Description' />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">GL (REKO) Code *</p>
                  <input onChange={(e) => setRekoCode(e.target.value)} value={rekoCode} type="text" placeholder='GL (REKO) Code' />
                  <CiLock className='lock_icn' />
                </div>
              </div>

              <div className="row">
                <div className="col-xl-6">
                  <div className="user_form_img_box">
                    <p className="ip_label">Image*</p>
                    <div>
                      {
                        image && image.includes("base64") ?
                          <img src={image} style={{ height: 100, width: 100 }} alt="" srcset="" />
                          :
                          <img src={generateFilePath(image)} style={{ height: 100, width: 100 }} alt="" srcset="" />
                      }
                    </div>
                    <button className="choose_img_buttn">Choose File</button>
                    <input onChange={(e) => handleFileSelection(e)} type="file" placeholder='No file Chosen' />
                  </div>
                </div>

                <div className="col-xl-6">
                  {
                    prevParentCategoryArr && prevParentCategoryArr.length > 0 ?
                      <SelectNestedCategory onChange={(val) => setSelectedCategory(val)} maxDepth={3} parentCategoryIdArr={prevParentCategoryArr} hideIdArr={[]} />
                      :
                      <SelectNestedCategory onChange={(val) => setSelectedCategory(val)} maxDepth={3} parentCategoryIdArr={categoryArr} hideIdArr={[]} />
                  }
                </div>
              </div>

              <div className="row">

                <button onClick={() => handleRegister()} className="submit_buttn">Submit</button>
              </div>



            </div>
          </div>




        </div>
      </section>



    </main>
  )
}

export default Product