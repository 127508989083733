import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { MdOutlineAdd } from "react-icons/md";
import { DashboardTable } from "../Utility/DashboardBox";
import { toastError } from "../Utility/ToastUtils";
import { getProducts } from "../../services/product.service";
import Select from 'react-select';

export default function JVProductDetailsTable({ productArr, chats_data_value }) {
  const [query, setQuery] = useState("");

  const [warehouseArr, setWarehouseArr] = useState([]);

  const chats_columns = [
    {
      name: "SR.No",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "5%",
    },
    {
      name: "Type (DR/CR)",
      cell: (row, index) => <div style={{ flex: 1 }}>
        <Select options={[
          {
            label: "Debit",
            value: "DEBIT"
          },
          {
            label: "Credit",
            value: "CREDIT"
          }
        ]} name="productObj" onChange={(e) => handleChangeValue(e, "itemType", index)} value={{ label: row.itemType, value: row.itemType }} />
      </div>,
      width: "10%",
    },
    {
      name: "ACNO code",
      cell: (row, index) => <input className="form-control" type="text" name="acnoCode" onChange={(e) => handleChangeValue(e, "acnoCode", index)} value={row?.acnoCode} />,
      grow: 0,
      width: "15%",
    },
    {
      name: "ACNO Desc",
      cell: (row, index) => <input className="form-control" type="text" name="acnoDesc" onChange={(e) => handleChangeValue(e, "acnoDesc", index)} value={row?.acnoDesc} />,
      grow: 0,
      width: "15%",
    },
    {
      name: "Sub Ledger code",
      cell: (row, index) => <input className="form-control" type="text" name="slType" onChange={(e) => handleChangeValue(e, "slType", index)} value={row?.slType} />,
      grow: 0,
      width: "15%",
    },
    {
      name: "Sub Ledger Description",
      grow: 0,
      cell: (row, index) => <input className="form-control" type="text" name="slDescription" onChange={(e) => handleChangeValue(e, "slDescription", index)} value={row?.slDescription} />,
      width: "15%",
    },
    {
      name: "Amount",
      cell: (row, index) => <input className="form-control" type="number" name="amount" onChange={(e) => handleChangeValue(e, "amount", index)} value={row?.amount} />,
    },
    {
      name: "Remark",
      grow: 0,
      cell: (row, index) => <input className="form-control" type="text" name="description" onChange={(e) => handleChangeValue(e, "description", index)} value={row?.description} />,
      width: "15%",
    },
  ];
  const [chats_data, setChats_data] = useState([]);
  useEffect(() => {
    console.log(productArr, "productArr")
    setChats_data([...productArr])
  }, [productArr])


 

  const handleChangeValue = (e, name, index) => {
    // let tempArr = chats_data;
    console.log(e,e?.target?.value,e?.target?.name, "event1111", name)


    // tempArr[index][e.target.name] = e.target.value;
    setChats_data(prev => {
      let tempArr = prev
      if(name == "itemType"){
        prev[index][name] = e.value
      }
      else{
        prev[index][e.target.name] = e.target.value
      }
      return [...tempArr]
    })
    chats_data_value(chats_data)
  }









  const handleAddItem = () => {
    setChats_data(prev => [...prev, { category: "", productObj: {}, description: "", price: 0, quantity: 0, subTotal: 0 }])
  }

  return (
    <section className="product-category" style={{ minHeight: "" }}>
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-12">
            <DashboardTable>
              <DataTable
                columns={chats_columns}
                data={chats_data}
              />
              <div className="row f_j_c_e">
                <button onClick={() => handleAddItem()} className="add_item_buttn">
                  Add item <MdOutlineAdd />
                </button>
              </div>
            </DashboardTable>
          </div>
        </div>
      </div>
    </section>
  );
}
