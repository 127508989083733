import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import { getCity } from "../../services/city.service";
import { getState } from "../../services/state.service";
import { addWarehouse, getById, updateWarehouse } from "../../services/warehouse.service";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { getZone } from "../../services/zone.service";
import moment from "moment";

function WarehouseAddPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [name, setName] = useState("");
  const [cmpId, setCmpId] = useState("");
  const [storeCode, setStoreCode] = useState("");
  const [unitCode, setUnitCode] = useState("");
  const [unitName, setUnitName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine3, setAddressLine3] = useState("");
  const [pincode, setPincode] = useState("");
  const [cstNo, setCstNo] = useState("");
  const [cstIssueDate, setCstIssueDate] = useState(new Date());
  const [mobile, setMobile] = useState("");
  const [gstNo, setGstNo] = useState("");

  const [statesArr, setStatesArr] = useState([]);
  const [citiesArr, setCitiesArr] = useState([]);
  const [zoneArr, setZoneArr] = useState([]);


  const [stateId, setStateId] = useState("");
  const [cityId, setCityId] = useState("");
  const [zoneId, setZoneId] = useState("");
  const handleSubmit = async () => {
    try {

      if (name == "") {
        toastError("Name is mandatory !!!");
        return
      }

      if (zoneId == "") {
        toastError("Zone is mandatory !!!");
        return
      }

      if (cmpId == "") {
        toastError("CMP Id is mandatory !!!");
        return
      }


      if (storeCode == "") {
        toastError("Store Code is mandatory !!!");
        return
      }


      // if (unitCode == "") {
      //   toastError("Unit Code is mandatory !!!");
      //   return
      // }


      // if (unitName == "") {
      //   toastError("Unit Name is mandatory !!!");
      //   return
      // }


      // if (addressLine1 == "") {
      //   toastError("Address Line 1 is mandatory !!!");
      //   return
      // }


      // if (addressLine2 == "") {
      //   toastError("Address Line 2 is mandatory !!!");
      //   return
      // }


      // if (addressLine3 == "") {
      //   toastError("Address Line 3 is mandatory !!!");
      //   return
      // }


      // if (pincode == "") {
      //   toastError("Pin code is mandatory !!!");
      //   return
      // }


      // if (cstNo == "") {
      //   toastError("CST No is mandatory !!!");
      //   return
      // }


      // if (cstIssueDate == "") {
      //   toastError("CST Issue Date is mandatory !!!");
      //   return
      // }


      // if (mobile == "") {
      //   toastError("Mobile is mandatory !!!");
      //   return
      // }


      // if (gstNo == "") {
      //   toastError("GST No is mandatory !!!");
      //   return
      // }


      // if (stateId == "") {
      //   toastError("State is mandatory !!!");
      //   return
      // }

      // if (cityId == "") {
      //   toastError("City is mandatory !!!");
      //   return
      // }

      let obj = {
        name,
        cmpId,
        storeCode,
        unitCode,
        unitName,
        addressLine1,
        addressLine2,
        addressLine3,
        pincode,
        cstNo,
        cstIssueDate,
        mobile,
        gstNo,
        zoneId: zoneId,
      }



      if (stateId && stateId != "") {
        obj.stateId = stateId
      }
      if (cityId && cityId != "") {
        obj.cityId = cityId
      }


      if (searchParams.get("id")) {
        let { data: res } = await updateWarehouse(obj, searchParams.get("id"))
        if (res.message) {
          toastSuccess(res.message)
        }
      }
      else {
        let { data: res } = await addWarehouse(obj)
        if (res.message) {
          toastSuccess(res.message)
        }
      }
    }
    catch (err) {
      toastError(err)
    }
  }




  const handleGetForUpdate = async () => {
    try {
      if (typeof searchParams?.get("id") != 'string')
        return;
      const res = await getById(searchParams?.get("id"));
      if (res.data.data) {
        console.log(res.data.data, "res.data.data")

        if (res.data?.data?.zoneId) {
          setZoneId(res.data?.data?.zoneId);
          HandleGetStates(res.data?.data?.zoneId)
        }



        if (res.data?.data?.cmpId) {
          setCmpId(res.data?.data?.cmpId);

        }


        if (res.data?.data?.storeCode) {
          setStoreCode(res.data?.data?.storeCode);

        }


        if (res.data?.data?.unitCode) {
          setUnitCode(res.data?.data?.unitCode);

        }


        if (res.data?.data?.unitName) {
          setUnitName(res.data?.data?.unitName);

        }


        if (res.data?.data?.addressLine1) {
          setAddressLine1(res.data?.data?.addressLine1);

        }


        if (res.data?.data?.addressLine2) {
          setAddressLine2(res.data?.data?.addressLine2);

        }


        if (res.data?.data?.addressLine3) {
          setAddressLine3(res.data?.data?.addressLine3);

        }


        if (res.data?.data?.pincode) {
          setPincode(res.data?.data?.pincode);

        }


        if (res.data?.data?.cstNo) {
          setCstNo(res.data?.data?.cstNo);

        }


        if (res.data?.data?.cstIssueDate) {
          setCstIssueDate(res.data?.data?.cstIssueDate);

        }


        if (res.data?.data?.mobile) {
          setMobile(res.data?.data?.mobile);

        }


        if (res.data?.data?.gstNo) {
          setGstNo(res.data?.data?.gstNo);

        }
        if (res.data?.data?.gstNo) {
          setGstNo(res.data?.data?.gstNo);

        }

        if (res.data?.data?.stateId) {
          setStateId(res.data?.data?.stateId);
          // setStateId({ label: res.data?.data?.stateName, value: res.data?.data?.stateId });
          HandleGetCities(res.data?.data?.stateId)
        }
        if (res.data?.data?.cityName && res.data?.data?.cityId) {
          setCityId(res.data?.data?.cityId);
          // setCityId({ label: res.data?.data?.cityName, value: res.data?.data?.cityId });
        }
        if (res.data?.data?.name) {
          setName(res.data?.data?.name);
        }
      }
    } catch (error) {
      toastError(error)
    }

  }



  const HandleGetStates = async (ZoneIdValue) => {
    try {
      let { data: res } = await getState({}, { forSelectInput: true, zoneId: ZoneIdValue });
      if (res.data) {
        console.log(res.data, "data")
        setStatesArr(res.data);
      }
    }
    catch (err) {
      toastError(err)
    }
  }
  const HandleGetCities = async (stateId) => {
    try {
      setCitiesArr([]);
      setCityId("")
      let { data: res } = await getCity({}, { forSelectInput: true, stateId: stateId });
      if (res.data) {
        console.log(res.data, "data")
        setCitiesArr(res.data);
      }
    }
    catch (err) {
      toastError(err)
    }
  }

  const handleGetZones = async () => {
    try {
      const { data: res } = await getZone({ pageIndex: 0, pageSize: 10000000 }, { forSelectInput: true });
      if (res?.data) {
        console.log(res.data, "data")
        setZoneArr([{ label: "Please select a zone", value: "" }, ...res?.data]);
      }
    } catch (error) {
      toastError(error)
    }

  }

  useEffect(() => {
    handleGetZones()
    // HandleGetCities();
  }, [])






  useEffect(() => {
    if (searchParams?.get("id")) {
      handleGetForUpdate()
    }

  }, [searchParams.get("id")])



  return (
    <main className="bg-white">
      <section className="user_page_sec">
        <div className="user_header">
          {/* <span className="page_name">Warehouse</span>
          <p className="breadcrumb">
            <span className="parent">Dashboard </span>{" "}
            <span>
              {" "}
              <HiChevronRight />
            </span>{" "}
            <span className="child">Warehouse</span>
          </p> */}
        </div>

        <div className="user_form_box">
          <div className="form_header">
            <h6 className="heading">Warehouse</h6>
            <span className="sub_heading"> Add Warehouse</span>
          </div>

          <div className="form_box">
            <div className="row">
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">CMP ID*</p>
                  <input onChange={(e) => setCmpId(e.target.value)} value={cmpId} type="text" placeholder="CMP Id" />
                </div>
              </div>


              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Store Code*</p>
                  <input onChange={(e) => setStoreCode(e.target.value)} value={storeCode} type="text" placeholder="Store Code" />
                </div>
              </div>


              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Unit Code*</p>
                  <input onChange={(e) => setUnitCode(e.target.value)} value={unitCode} type="text" placeholder="Unit Code" />
                </div>
              </div>


              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Unit name*</p>
                  <input onChange={(e) => setUnitName(e.target.value)} value={unitName} type="text" placeholder="Unit Name" />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Address line 1*</p>
                  <input onChange={(e) => setAddressLine1(e.target.value)} value={addressLine1} type="text" placeholder="Address Line 1" />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Address line 2*</p>
                  <input onChange={(e) => setAddressLine2(e.target.value)} value={addressLine2} type="text" placeholder="Address Line 2" />
                </div>
              </div>


              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Address line 3*</p>
                  <input onChange={(e) => setAddressLine3(e.target.value)} value={addressLine3} type="text" placeholder="Address Line 3" />
                </div>
              </div>


              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Pin Code*</p>
                  <input onChange={(e) => setPincode(e.target.value)} value={pincode} type="text" placeholder="Pin Code" />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Zone*</p>
                  <Select options={zoneArr} onChange={(e) => { setZoneId(e.value); HandleGetStates(e.value); setCitiesArr([]); setCityId(""); setStateId("") }} value={zoneArr.find(el => el.value == zoneId)} />
                </div>
              </div>
              {
                statesArr && statesArr.length > 0 &&
                <div className="col-xl-6">
                  <div className="user_form_ip_box">
                    <p className="ip_label">State*</p>
                    <Select options={statesArr} onChange={(e) => { setStateId(e); HandleGetCities(e.value); setCitiesArr([]); setCityId(""); }} value={statesArr.find(el => el.value == stateId)} />
                  </div>
                </div>
              }

              {
                citiesArr && citiesArr.length > 0 &&
                <div className="col-xl-6">
                  <div className="user_form_ip_box">
                    <p className="ip_label">City*</p>
                    <Select options={citiesArr} onChange={(e) => setCityId(e)} value={citiesArr.find(el => el.value == cityId)} />
                  </div>
                </div>
              }

              {/* <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">City*</p>
                  <input onChange={(e) => setCiy(e.target.value)} value={name} type="text" placeholder="Name" />
                </div>
              </div> */}


              {/* <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">State Code*</p>
                  <input onChange={(e) => setName(e.target.value)} value={name} type="text" placeholder="Name" />
                </div>
              </div> */}


              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">CST No.*</p>
                  <input onChange={(e) => setCstNo(e.target.value)} value={cstNo} type="text" placeholder="CST No." />
                </div>
              </div>



              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">CST Issue Date*</p>
                  <input onChange={(e) => setCstIssueDate(e.target.value)} value={moment(cstIssueDate).format("YYYY-MM-DD")} type="date" placeholder="CST Issue Date" />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Mobile*</p>
                  <input onChange={(e) => setMobile(e.target.value)} value={mobile} type="text" placeholder="Mobile" />
                </div>
              </div>



              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">GSTIN No*</p>
                  <input onChange={(e) => setGstNo(e.target.value)} value={gstNo} type="text" placeholder="GSTIN NO." />
                </div>
              </div>


              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Name*</p>
                  <input onChange={(e) => setName(e.target.value)} value={name} type="text" placeholder="Name" />
                </div>
              </div>


            </div>

            <div className="row">
              <button onClick={() => handleSubmit()} className="submit_buttn">Submit</button>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default WarehouseAddPage
