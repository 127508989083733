import React, { useEffect, useState, useCallback } from "react";
import { MdOutlineAdd, MdOutlineDelete } from "react-icons/md";
import { DashboardTable } from "../Utility/DashboardBox";
import _ from "lodash";
import Select from "react-select";
import { getRawMaterial } from "../../services/rawMaterial.service";
import { toastError } from "../Utility/ToastUtils";

const LabSampleRow = React.memo(({ data, index, rawMaterialsArr, handleChangeValue, handleDeleteItem }) => {
   return (
      <div key={index} style={{ display: "flex", flexDirection: "row" }}>
         <div className="scrollbar_item">
            <div className="user_form_ip_box">
               <p className="ip_label">Material*</p>
               <Select
               classNamePrefix='filter'
                  onChange={(e) => handleChangeValue(e, "material", index)}
                  value={data.material}
                  options={rawMaterialsArr}
               />
               {/*
                                                <input
                                                   type="text"
                                                   placeholder="Material"
                                                /> */}
            </div>
         </div>
         <div className="scrollbar_item">
            <div className="user_form_ip_box">
               <p className="ip_label">Remarks*</p>
               <input
                  onChange={(e) => handleChangeValue(e, "remarks", index)}
                  value={data.remarks}
                  type="text"
                  placeholder="Remarks"
               />
            </div>
         </div>
         <div className="scrollbar_item">
            <div className="user_form_ip_box">
               <p className="ip_label">Vehicle No*</p>
               <input
                  onChange={(e) => handleChangeValue(e, "vehicleNo", index)}
                  value={data.vehicleNo}
                  type="text"
                  placeholder="Vehicle No"
               />
            </div>
         </div>
         <div className="scrollbar_item">
            <div className="user_form_ip_box">
               <p className="ip_label">REF NO*</p>
               <input
                  onChange={(e) => handleChangeValue(e, "refNo", index)}
                  value={data.refNo}
                  type="text"
                  placeholder="REF NO"
               />
            </div>
         </div>
         <div className="scrollbar_item">
            <div className="user_form_ip_box">
               <p className="ip_label">Moisture*</p>
               <input
                  onChange={(e) => handleChangeValue(e, "moisture", index)}
                  value={data.moisture}
                  type="text"
                  placeholder="Moisture"
               />
            </div>
         </div>
         <div className="scrollbar_item">
            <div className="user_form_ip_box">
               <p className="ip_label">C protein*</p>
               <input
                  onChange={(e) => handleChangeValue(e, "cProtein", index)}
                  value={data.cProtein}
                  type="text"
                  placeholder="C protein"
               />
            </div>
         </div>
         <div className="scrollbar_item">
            <div className="user_form_ip_box">
               <p className="ip_label">C Fat*</p>
               <input
                  onChange={(e) => handleChangeValue(e, "cFat", index)}
                  value={data.cFat}
                  type="text"
                  placeholder="C Fat"
               />
            </div>
         </div>
         <div className="scrollbar_item">
            <div className="user_form_ip_box">
               <p className="ip_label">C Fibre*</p>
               <input
                  onChange={(e) => handleChangeValue(e, "cFibre", index)}
                  value={data.cFibre}
                  type="text"
                  placeholder="C Fibre"
               />
            </div>
         </div>
         <div className="scrollbar_item">
            <div className="user_form_ip_box">
               <p className="ip_label">T. Ash*</p>
               <input
                  onChange={(e) => handleChangeValue(e, "tAsh", index)}
                  value={data.tAsh}
                  type="text"
                  placeholder="T. Ash"
               />
            </div>
         </div>
         <div className="scrollbar_item">
            <div className="user_form_ip_box">
               <p className="ip_label">Sand Silica*</p>
               <input
                  onChange={(e) => handleChangeValue(e, "sandSilica", index)}
                  value={data.sandSilica}
                  type="text"
                  placeholder="Sand Silica"
               />
            </div>
         </div>
         <div className="scrollbar_item">
            <div className="user_form_ip_box">
               <p className="ip_label">Calcium*</p>
               <input
                  onChange={(e) => handleChangeValue(e, "calcium", index)}
                  value={data.calcium}
                  type="text"
                  placeholder="Calcium"
               />
            </div>
         </div>
         <div className="scrollbar_item">
            <div className="user_form_ip_box">
               <p className="ip_label">T Phosphorus*</p>
               <input
                  onChange={(e) => handleChangeValue(e, "tphosphorus", index)}
                  value={data.tphosphorus}
                  type="text"
                  placeholder="T Phosphorus"
               />
            </div>
         </div>
         <div className="scrollbar_item">
            <div className="user_form_ip_box">
               <p className="ip_label">Salt*</p>
               <input
                  onChange={(e) => handleChangeValue(e, "salt", index)}
                  value={data.salt}
                  type="text"
                  placeholder="Salt"
               />
            </div>
         </div>
         <div className="scrollbar_item">
            <div className="user_form_ip_box">
               <p className="ip_label">Solu. Protein*</p>
               <input
                  onChange={(e) => handleChangeValue(e, "soluProtein", index)}
                  value={data.soluProtein}
                  type="text"
                  placeholder="Solu. Protein"
               />
            </div>
         </div>
         <div className="scrollbar_item">
            <div className="user_form_ip_box">
               <p className="ip_label">Sediment*</p>
               <input
                  onChange={(e) => handleChangeValue(e, "sediment", index)}
                  value={data.sediment}
                  type="text"
                  placeholder="Sediment"
               />
            </div>
         </div>
         <div className="scrollbar_item">
            <div className="user_form_ip_box">
               <p className="ip_label">Purity*</p>
               <input
                  onChange={(e) => handleChangeValue(e, "purity", index)}
                  value={data.purity}
                  type="text"
                  placeholder="Purity"
               />
            </div>
         </div>
         <div className="scrollbar_item">
            <div className="user_form_ip_box">
               <p className="ip_label">MAGN.*</p>
               <input
                  onChange={(e) => handleChangeValue(e, "magn", index)}
                  value={data.magn}
                  type="text"
                  placeholder="MAGN."
               />
            </div>
         </div>
         <div className="scrollbar_item">
            <div className="user_form_ip_box">
               <p className="ip_label">Salt*</p>
               <input
                  onChange={(e) => handleChangeValue(e, "salt", index)}
                  value={data.salt}
                  type="text"
                  placeholder="Salt"
               />
            </div>
         </div>
         <div className="scrollbar_item">
            <div className="user_form_ip_box">
               <p className="ip_label">Thirium*</p>
               <input
                  onChange={(e) => handleChangeValue(e, "thirium", index)}
                  value={data.thirium}
                  type="text"
                  placeholder="Thirium"
               />
            </div>
         </div>
         <div className="scrollbar_item">
            <div className="user_form_ip_box">
               <p className="ip_label">F.F.A.*</p>
               <input
                  onChange={(e) => handleChangeValue(e, "ffa", index)}
                  value={data.ffa}
                  type="text"
                  placeholder="F.F.A."
               />
            </div>
         </div>
         <div className="scrollbar_item">
            <div className="user_form_ip_box">
               <p className="ip_label">UA*</p>
               <input
                  onChange={(e) => handleChangeValue(e, "ua", index)}
                  value={data.ua}
                  type="text"
                  placeholder="UA"
               />
            </div>
         </div>
         <div className="scrollbar_item" style={{ width: 650 }}>
            <div className="user_form_ip_box" style={{ width: 620 }}>
               <p
                  className="ip_label"
                  style={{
                     width: "100%",
                  }}
               >
                  Toxins*
               </p>
               <div
                  style={{
                     display: "flex",
                     flexDirection: "row",
                  }}
               >
                  <div className="scrollbar_item">
                     <div className="user_form_ip_box">
                        <p className="ip_label">AFL*</p>
                        <input
                           onChange={(e) => handleChangeValue(e, "afl", index)}
                           value={data.afl}
                           type="text"
                           placeholder="AFL"
                        />
                     </div>
                  </div>
                  <div className="scrollbar_item">
                     <div className="user_form_ip_box">
                        <p className="ip_label">OCR*</p>
                        <input
                           onChange={(e) => handleChangeValue(e, "ocr", index)}
                           value={data.ocr}
                           type="text"
                           placeholder="OCR"
                        />
                     </div>
                  </div>
                  <div className="scrollbar_item">
                     <div className="user_form_ip_box">
                        <p className="ip_label">T2*</p>
                        <input
                           onChange={(e) => handleChangeValue(e, "t2", index)}
                           value={data.t2}
                           type="text"
                           placeholder="T2"
                        />
                     </div>
                  </div>
                  <div className="scrollbar_item">
                     <div className="user_form_ip_box">
                        <p className="ip_label">ZEA*</p>
                        <input
                           onChange={(e) => handleChangeValue(e, "zea", index)}
                           value={data.zea}
                           type="text"
                           placeholder="ZEA"
                        />
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="scrollbar_item">
            <div className="user_form_ip_box">
               <p className="ip_label">Delete</p>

               <button onClick={() => handleDeleteItem(index)} className="add_item_buttn" style={{ width: "100%" }}>
                  Delete
                  <MdOutlineDelete />
               </button>
            </div>
         </div>
      </div>
   );
});

const LabSampleTable = ({ productArr, updateProductArr }) => {
   const [chats_data, setChats_data] = useState([]);
   const [rawMaterialsArr, setRawMaterialsArr] = useState([]);

   useEffect(() => {
      setChats_data([...productArr]);
   }, [productArr]);

   const handleChangeValue = useCallback(
      (e, name, index) => {
         setChats_data((prev) => {
            const newData = [...prev];
            if (name === "productObj") {
               newData[index][name] = e;
               newData[index].productId = e.value;
               newData[index].productName = e.label;
               newData[index].rekoCode = e.rekoCode;
            } else if (name === "material") {
               newData[index][name] = e;
            } else {
               newData[index][name] = e.target.value;
            }
            updateProductArr([...newData]);
            return newData;
         });
      },
      [updateProductArr],
   );

   const handleAddItem = () => {
      const newItem = {
         material: "",
         remarks: "",
         refNo: "",
         moisture: "",
         cProtein: "",
         cFat: "",
         cFibre: "",
         tAsh: "",
         sandSilica: "",
         salt: "",
         tphosphorus: "",
         calcium: "",
         vehicleNo: "",
         soluProtein: "",
         sediment: "",
         purity: "",
         magn: "",
         thirium: "",
         ffa: "",
         ua: "",
         afl: "",
         ocr: "",
         t2: "",
         zea: "",
      };
      setChats_data((prev) => [...prev, newItem]);
      updateProductArr((prev) => [...prev, newItem]);
   };

   const handleDeleteItem = useCallback(
      (index) => {
         setChats_data((prev) => {
            if (prev.length > 1) {
               const newData = prev.filter((el, i) => index !== i);
               updateProductArr(newData);
               return newData;
            }
            return prev;
         });
      },
      [updateProductArr],
   );

   const handleGetRawMaterials = useCallback(async () => {
      try {
         const { data: res } = await getRawMaterial();
         if (res.data) {
            setRawMaterialsArr(res.data.map((el) => ({ label: el.name, value: el._id })));
         }
      } catch (error) {
         toastError(error);
      }
   }, []);

   useEffect(() => {
      handleGetRawMaterials();
   }, [handleGetRawMaterials]);

   return (
      <section className="product-category" style={{ minHeight: "90vh" }}>
         <div className="container-fluid p-0">
            <div className="row">
               <div className="col-12">
                  <DashboardTable>
                     <div className="bottom_scrollbar" style={{ display: "flex", flexDirection: "column", paddingBottom:100 }}>
                        {chats_data.map((el, index) => (
                           <LabSampleRow
                              key={index}
                              data={el}
                              index={index}
                              rawMaterialsArr={rawMaterialsArr}
                              handleChangeValue={handleChangeValue}
                              handleDeleteItem={handleDeleteItem}
                           />
                        ))}
                     </div>
                     <div className="row f_j_c_e">
                        <button onClick={handleAddItem} className="add_item_buttn">
                           Add item <MdOutlineAdd />
                        </button>
                     </div>
                  </DashboardTable>
               </div>
            </div>
         </div>
      </section>
   );
};

export default LabSampleTable;

// import React, { useEffect, useState } from "react";
// import { MdOutlineAdd, MdOutlineDelete } from "react-icons/md";
// import { DashboardTable } from "../Utility/DashboardBox";
// import _ from "lodash";
// import Select from "react-select";
// import { getRawMaterial } from "../../services/rawMaterial.service";
// import { toastError } from "../Utility/ToastUtils";
// export default function LabSampleTable({ productArr, chats_data_value }) {
//    const [query, setQuery] = useState("");

//    const [chats_data, setChats_data] = useState([]);
//    const [rawMaterialsArr, setRawMaterialsArr] = useState([]);
//    useEffect(() => {
//       setChats_data([...productArr]);
//    }, [productArr]);

//    const handleChangeValue = (e, name, index) => {
//       // let tempArr = chats_data;
//       // tempArr[index][e.target.name] = e.target.value;
//       setChats_data((prev) => {
//          if (name == "productObj") {
//             prev[index][name] = e;
//             prev[index].productId = e.value;
//             prev[index].productName = e.label;
//             prev[index].rekoCode = e.rekoCode;
//          } else if (name == "material") {
//             prev[index][name] = e;
//          } else {
//             prev[index][name] = e.target.value;
//          }
//          chats_data_value([...prev]);
//          return [...prev];
//       });
//    };

//    const handleAddItem = () => {
//       let tempArr = [
//          ...chats_data,
//          {
//             material: "",
//             remarks: "",
//             refNo: "",
//             moisture: "",
//             cProtein: "",
//             cFat: "",
//             cFibre: "",
//             tAsh: "",
//             sandSilica: "",
//             salt: "",
//             tphosphorus: "",
//             calcium: "",
//             vehicleNo: "",
//             soluProtein: "",
//             sediment: "",
//             purity: "",
//             magn: "",
//             thirium: "",
//             ffa: "",
//             ua: "",
//             afl: "",
//             ocr: "",
//             t2: "",
//             zea: "",
//          },
//       ];
//       setChats_data([...tempArr]);
//       chats_data_value([...tempArr]);
//    };

//    const handleDeleteItem = (index) => {
//       let tempArr = _.cloneDeep(chats_data);
//       if (tempArr.length > 1) {
//          let internalTempArr = tempArr.filter((el, i) => index != i);
//          chats_data_value([internalTempArr]);
//          setChats_data(_.cloneDeep(internalTempArr));
//       }
//    };

//    const handleGetRawMaterials = async () => {
//       try {
//          let { data: res } = await getRawMaterial();
//          if (res.data) {
//             console.log(res.data, "asdad");
//             setRawMaterialsArr(res.data.map((el) => ({ label: el.name, value: el._id })));
//          }
//       } catch (error) {
//          toastError(error);
//       }
//    };

//    useEffect(() => {
//       handleGetRawMaterials();
//    }, []);
//    return (
//       <section className="product-category" style={{ minHeight: "" }}>
//          <div className="container-fluid p-0">
//             <div className="row">
//                <div className="col-12">
//                   <DashboardTable>
//                      <div className="bottom_scrollbar" style={{ display: "flex", flexDirection: "column" }}>
//                         {chats_data &&
//                            chats_data.length > 0 &&
//                            chats_data.map((el, index) => {
//                               return (
//                                  <div key={index} style={{ display: "flex", flexDirection: "row" }}>
//                                     <div className="scrollbar_item">
//                                        <div className="user_form_ip_box">
//                                           <p className="ip_label">Material*</p>
//                                           <Select
//                                              onChange={(e) => handleChangeValue(e, "material", index)}
//                                              value={el.material}
//                                              options={rawMaterialsArr}
//                                           />
//                                           {/*
//                                           <input
//                                              type="text"
//                                              placeholder="Material"
//                                           /> */}
//                                        </div>
//                                     </div>
//                                     <div className="scrollbar_item">
//                                        <div className="user_form_ip_box">
//                                           <p className="ip_label">Remarks*</p>
//                                           <input
//                                              onChange={(e) => handleChangeValue(e, "remarks", index)}
//                                              value={el.remarks}
//                                              type="text"
//                                              placeholder="Remarks"
//                                           />
//                                        </div>
//                                     </div>
//                                     <div className="scrollbar_item">
//                                        <div className="user_form_ip_box">
//                                           <p className="ip_label">Vehicle No*</p>
//                                           <input
//                                              onChange={(e) => handleChangeValue(e, "vehicleNo", index)}
//                                              value={el.vehicleNo}
//                                              type="text"
//                                              placeholder="Vehicle No"
//                                           />
//                                        </div>
//                                     </div>
//                                     <div className="scrollbar_item">
//                                        <div className="user_form_ip_box">
//                                           <p className="ip_label">REF NO*</p>
//                                           <input
//                                              onChange={(e) => handleChangeValue(e, "refNo", index)}
//                                              value={el.refNo}
//                                              type="text"
//                                              placeholder="REF NO"
//                                           />
//                                        </div>
//                                     </div>
//                                     <div className="scrollbar_item">
//                                        <div className="user_form_ip_box">
//                                           <p className="ip_label">Moisture*</p>
//                                           <input
//                                              onChange={(e) => handleChangeValue(e, "moisture", index)}
//                                              value={el.moisture}
//                                              type="text"
//                                              placeholder="Moisture"
//                                           />
//                                        </div>
//                                     </div>
//                                     <div className="scrollbar_item">
//                                        <div className="user_form_ip_box">
//                                           <p className="ip_label">C protein*</p>
//                                           <input
//                                              onChange={(e) => handleChangeValue(e, "cProtein", index)}
//                                              value={el.cProtein}
//                                              type="text"
//                                              placeholder="C protein"
//                                           />
//                                        </div>
//                                     </div>
//                                     <div className="scrollbar_item">
//                                        <div className="user_form_ip_box">
//                                           <p className="ip_label">C Fat*</p>
//                                           <input
//                                              onChange={(e) => handleChangeValue(e, "cFat", index)}
//                                              value={el.cFat}
//                                              type="text"
//                                              placeholder="C Fat"
//                                           />
//                                        </div>
//                                     </div>
//                                     <div className="scrollbar_item">
//                                        <div className="user_form_ip_box">
//                                           <p className="ip_label">C Fibre*</p>
//                                           <input
//                                              onChange={(e) => handleChangeValue(e, "cFibre", index)}
//                                              value={el.cFibre}
//                                              type="text"
//                                              placeholder="C Fibre"
//                                           />
//                                        </div>
//                                     </div>
//                                     <div className="scrollbar_item">
//                                        <div className="user_form_ip_box">
//                                           <p className="ip_label">T. Ash*</p>
//                                           <input
//                                              onChange={(e) => handleChangeValue(e, "tAsh", index)}
//                                              value={el.tAsh}
//                                              type="text"
//                                              placeholder="T. Ash"
//                                           />
//                                        </div>
//                                     </div>
//                                     <div className="scrollbar_item">
//                                        <div className="user_form_ip_box">
//                                           <p className="ip_label">Sand Silica*</p>
//                                           <input
//                                              onChange={(e) => handleChangeValue(e, "sandSilica", index)}
//                                              value={el.sandSilica}
//                                              type="text"
//                                              placeholder="Sand Silica"
//                                           />
//                                        </div>
//                                     </div>
//                                     <div className="scrollbar_item">
//                                        <div className="user_form_ip_box">
//                                           <p className="ip_label">Calcium*</p>
//                                           <input
//                                              onChange={(e) => handleChangeValue(e, "calcium", index)}
//                                              value={el.calcium}
//                                              type="text"
//                                              placeholder="Calcium"
//                                           />
//                                        </div>
//                                     </div>
//                                     <div className="scrollbar_item">
//                                        <div className="user_form_ip_box">
//                                           <p className="ip_label">T Phosphorus*</p>
//                                           <input
//                                              onChange={(e) => handleChangeValue(e, "tphosphorus", index)}
//                                              value={el.tphosphorus}
//                                              type="text"
//                                              placeholder="T Phosphorus"
//                                           />
//                                        </div>
//                                     </div>
//                                     <div className="scrollbar_item">
//                                        <div className="user_form_ip_box">
//                                           <p className="ip_label">Salt*</p>
//                                           <input
//                                              onChange={(e) => handleChangeValue(e, "salt", index)}
//                                              value={el.salt}
//                                              type="text"
//                                              placeholder="Salt"
//                                           />
//                                        </div>
//                                     </div>
//                                     <div className="scrollbar_item">
//                                        <div className="user_form_ip_box">
//                                           <p className="ip_label">Solu. Protein*</p>
//                                           <input
//                                              onChange={(e) => handleChangeValue(e, "soluProtein", index)}
//                                              value={el.soluProtein}
//                                              type="text"
//                                              placeholder="Solu. Protein"
//                                           />
//                                        </div>
//                                     </div>
//                                     <div className="scrollbar_item">
//                                        <div className="user_form_ip_box">
//                                           <p className="ip_label">Sediment*</p>
//                                           <input
//                                              onChange={(e) => handleChangeValue(e, "sediment", index)}
//                                              value={el.sediment}
//                                              type="text"
//                                              placeholder="Sediment"
//                                           />
//                                        </div>
//                                     </div>
//                                     <div className="scrollbar_item">
//                                        <div className="user_form_ip_box">
//                                           <p className="ip_label">Purity*</p>
//                                           <input
//                                              onChange={(e) => handleChangeValue(e, "purity", index)}
//                                              value={el.purity}
//                                              type="text"
//                                              placeholder="Purity"
//                                           />
//                                        </div>
//                                     </div>
//                                     <div className="scrollbar_item">
//                                        <div className="user_form_ip_box">
//                                           <p className="ip_label">MAGN.*</p>
//                                           <input
//                                              onChange={(e) => handleChangeValue(e, "magn", index)}
//                                              value={el.magn}
//                                              type="text"
//                                              placeholder="el.MAGN."
//                                           />
//                                        </div>
//                                     </div>
//                                     <div className="scrollbar_item">
//                                        <div className="user_form_ip_box">
//                                           <p className="ip_label">Salt*</p>
//                                           <input
//                                              onChange={(e) => handleChangeValue(e, "salt", index)}
//                                              value={el.salt}
//                                              type="text"
//                                              placeholder="Salt"
//                                           />
//                                        </div>
//                                     </div>
//                                     <div className="scrollbar_item">
//                                        <div className="user_form_ip_box">
//                                           <p className="ip_label">Thirium*</p>
//                                           <input
//                                              onChange={(e) => handleChangeValue(e, "thirium", index)}
//                                              value={el.thirium}
//                                              type="text"
//                                              placeholder="Thirium"
//                                           />
//                                        </div>
//                                     </div>
//                                     <div className="scrollbar_item">
//                                        <div className="user_form_ip_box">
//                                           <p className="ip_label">F.F.A.*</p>
//                                           <input
//                                              onChange={(e) => handleChangeValue(e, "ffa", index)}
//                                              value={el.ffa}
//                                              type="text"
//                                              placeholder="el.F.F.A."
//                                           />
//                                        </div>
//                                     </div>
//                                     <div className="scrollbar_item">
//                                        <div className="user_form_ip_box">
//                                           <p className="ip_label">UA*</p>
//                                           <input
//                                              onChange={(e) => handleChangeValue(e, "ua", index)}
//                                              value={el.ua}
//                                              type="text"
//                                              placeholder="UA"
//                                           />
//                                        </div>
//                                     </div>
//                                     <div className="scrollbar_item" style={{ width: 650 }}>
//                                        <div className="user_form_ip_box" style={{ width: 620 }}>
//                                           <p
//                                              className="ip_label"
//                                              style={{
//                                                 width: "100%",
//                                              }}
//                                           >
//                                              Toxins*
//                                           </p>
//                                           <div
//                                              style={{
//                                                 display: "flex",
//                                                 flexDirection: "row",
//                                              }}
//                                           >
//                                              <div className="scrollbar_item">
//                                                 <div className="user_form_ip_box">
//                                                    <p className="ip_label">AFL*</p>
//                                                    <input
//                                                       onChange={(e) => handleChangeValue(e, "afl", index)}
//                                                       value={el.afl}
//                                                       type="text"
//                                                       placeholder="AFL"
//                                                    />
//                                                 </div>
//                                              </div>
//                                              <div className="scrollbar_item">
//                                                 <div className="user_form_ip_box">
//                                                    <p className="ip_label">OCR*</p>
//                                                    <input
//                                                       onChange={(e) => handleChangeValue(e, "ocr", index)}
//                                                       value={el.ocr}
//                                                       type="text"
//                                                       placeholder="OCR"
//                                                    />
//                                                 </div>
//                                              </div>
//                                              <div className="scrollbar_item">
//                                                 <div className="user_form_ip_box">
//                                                    <p className="ip_label">T2*</p>
//                                                    <input
//                                                       onChange={(e) => handleChangeValue(e, "t2", index)}
//                                                       value={el.t2}
//                                                       type="text"
//                                                       placeholder="T2"
//                                                    />
//                                                 </div>
//                                              </div>
//                                              <div className="scrollbar_item">
//                                                 <div className="user_form_ip_box">
//                                                    <p className="ip_label">ZEA*</p>
//                                                    <input
//                                                       onChange={(e) => handleChangeValue(e, "zea", index)}
//                                                       value={el.zea}
//                                                       type="text"
//                                                       placeholder="ZEA"
//                                                    />
//                                                 </div>
//                                              </div>
//                                           </div>
//                                        </div>
//                                     </div>
//                                     <div className="scrollbar_item">
//                                        <div className="user_form_ip_box">
//                                           <p className="ip_label">Delete</p>

//                                           <button
//                                              onClick={() => handleDeleteItem(index)}
//                                              className="add_item_buttn"
//                                              style={{ width: "100%" }}
//                                           >
//                                              Delete
//                                              <MdOutlineDelete />
//                                           </button>
//                                        </div>
//                                     </div>
//                                  </div>
//                               );
//                            })}
//                      </div>
//                      <div className="row f_j_c_e">
//                         <button onClick={() => handleAddItem()} className="add_item_buttn">
//                            Add item <MdOutlineAdd />
//                         </button>
//                      </div>
//                   </DashboardTable>
//                </div>
//             </div>
//          </div>
//       </section>
//    );
// }
