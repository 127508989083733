// 
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/reversal";

export const addReversal = (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getReversal = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/?${query.toString()}`);
};
export const downloadReversalExcel = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/download-excel?${query.toString()}`);
};
export const deleteReversal = (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const getById = (id) => {
    return axiosApiInstance.get(`${serverUrl}/getById/${id}`);
};
export const getSalesOrderProduct = () => {
    return axiosApiInstance.get(`${serverUrl}/getSalesOrderProduct`);
};

export const updateReversal = (formData, id) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};