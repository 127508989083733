import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AiFillEdit, AiFillFilePdf } from "react-icons/ai";
import { MdOutlineAdd } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import {
   bulkUploadLabSample,
   deleteLabSample,
   downloadLabExcel,
   downloadPDFById,
   getLabSample,
} from "../../services/labSample.service";
import { DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { generateFilePath } from "../Utility/utils";

function LabSample() {
   const [query, setQuery] = useState("");
   const [displayArr, setDisplayArr] = useState([]);
   const [mainArr, setMainArr] = useState([]);

   const [loading, setLoading] = useState(false);
   const [ModalBox, setModalBox] = useState(false);

   const [excel, setExcel] = useState(null);
   const navigate = useNavigate();

   const handleEdit = (row) => {
      navigate("/AddLabSample" + `?id=${row}`);
   };


   const handleDownloadLabExcel = async () => {
      console.log('vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv')
      try {
         let { data: res } = await downloadLabExcel({ pageIndex: 0, pageSize: 10000000 }, "");
         if (res) {
            var link = document.createElement("a");
            link.download = res.filename;
            link.href = generateFilePath(res.filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
         }
         // dispatch(updateSpecificUser(obj, selectedUser._id))
      } catch (err) {
         toastError(err);
      }
   };
   const users_columns = [
      {
         name: "SrNo.",
         selector: (row, index) => index + 1,
         sortable: true,
         width: "5%",
      },
      {
         name: "Test No",
         selector: (row) => `${row?.testNo}`,
      },
      {
         name: "Sample Received By",
         selector: (row) => `${row?.sampleReceivedBy}`,
      },
      {
         name: "Sample Collected By",
         selector: (row) => row?.sampleBy,
      },

      {
         name: "Received Date",
         selector: (row) => moment(row?.receivedDate).format("YYYY-MM-DD"),
      },
      {
         name: "Analysis Started",
         selector: (row) => moment(row?.analysisStarted).format("YYYY-MM-DD"),
      },

      {
         name: "Completed On",
         selector: (row) => moment(row?.completedOn).format("YYYY-MM-DD"),
      },

      {
         name: "Action",
         cell: (row) => (
            <>
               {/* <button onClick={() => navigate(`/LabPDF/?id=${row._id}`)} className="edit_buttn mr-3 me-3">
            <AiFillFilePdf />
          </button> */}
               <button onClick={() => handleDownload(row._id)} className="edit_buttn mr-3 me-3">
                  <AiFillFilePdf />
               </button>

               <button onClick={() => handleEdit(row._id)} className="edit_buttn">
                  <AiFillEdit />
               </button>

               <div style={{ marginLeft: 15 }}>
                  <button onClick={() => handleDelete(row._id)} className="delete_buttn">
                     <RiDeleteBin5Line />
                  </button>
               </div>
            </>
         ),
         width: "10%",
      },
   ];

   const handleGet = async () => {
      try {
         let { data: res } = await getLabSample({ pageIndex: 0, pageSize: 10000000 }, "");
         if (res.message) {
            setDisplayArr(res.data);
            setMainArr(res.data);
         }
         // dispatch(updateSpecificUser(obj, selectedUser._id))
      } catch (err) {
         toastError(err);
      }
   };

   const handleDownload = async (id) => {
      try {
         let { data: res } = await downloadPDFById(id);
         if (res.message) {
            console.log(res);

            const link = document.createElement("a");
            link.href = res.data;
            link.target = "__blank";

            link.download = `${Math.round(Math.random() * 100)}.pdf`;
            // link.download = pdf?.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // setDisplayArr(res.data);
            // setMainArr(res.data);
         }
         // dispatch(updateSpecificUser(obj, selectedUser._id))
      } catch (err) {
         toastError(err);
      }
   };

   useEffect(() => {
      handleGet();
   }, []);

   const handleDelete = async (id) => {
      try {
         if (!window.confirm("Are you sure")) {
            return;
         }

         let { data: res } = await deleteLabSample(id);
         if (res.message) {
            toastSuccess(res.message);
            handleGet();
         }
      } catch (err) {
         toastError(err);
      }
   };

   const handleSearch = (queryValue) => {
      setQuery(queryValue);
      let tempArr = mainArr;
      tempArr = tempArr.filter(
         (el) =>
            `${el?.name}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
            `${el?.phone}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
            `${el?.email}setDisplayArr`.toLowerCase().includes(`${queryValue}`.toLowerCase()),
      );
      setDisplayArr([...tempArr]);
   };

   const handleUploadLabExcel = async () => {
      try {
         setLoading(true)
         if (!excel) {
            toastError("Please Upload an excel file to proceed !!!!");

            setLoading(false)
            return;
         }

         let formData = new FormData();
         formData.append("excel", excel);

         let { data: res } = await bulkUploadLabSample(formData);

         if (res.message) {
            setLoading(false)
            handleGet()
            toastSuccess(res.message);
            setModalBox(false)
         }
      } catch (error) {
         setLoading(false)
         toastError(error);
      }
   };
   if (loading) {
      return <div> <h3 className="py-5 text-center">Loading please wait....</h3> </div>
   }
   return (
      <main className="p-0">
         <section className="product-category">
            <DashboardTable>
               <div className="row align-items-center border_with_color mx-0">
                  <div className="col-xl-10">
                     <div className="user_header_left_text">
                        <h6>Lab Sample</h6>
                     </div>
                  </div>
                  <div className="col-xl-2">
                     <div className="user_header_right">
                        <button onClick={() => navigate("/AddLabSample")} className="">
                           Add <MdOutlineAdd />
                        </button>
                     </div>
                  </div>
               </div>
               <div className="d-flex align-items-center justify-content-between mb-0 border_with_color_1">
                  <button className="copy_excel_buttn" onClick={() => handleDownloadLabExcel()}>
                     Download Excel
                  </button>
                  <div className="d-flex align-items-center gap-3">
                     <SearchBox query={query} setQuery={handleSearch} extraClass="bg-white" />
                  </div>
               </div>

               <Modal open={ModalBox} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                  <Box className="modal-box">
                     <div className="modal-container">
                        <div className="modal-header text-white" style={{ backgroundColor: "" }}>Upload lab sample</div>
                        <div className="modal-body" style={{ width: "50vw" }}>
                           <div className="container">
                              <div className="row">
                                 <div className="col-12 col-md-12 mb-3">
                                    <label>
                                       Lab sample Excel
                                       <span className="red">*</span>
                                    </label>
                                 </div>
                                 <div className="col-12 col-md-11 mb-3">
                                    <input type={"file"} onChange={(e) => setExcel(e.target.files[0])} />
                                 </div>
                                 <div className="col-12 col-md-11 mb-3">
                                    <button className="copy_excel_buttn me-4" onClick={() => setModalBox(false)}>
                                       Close
                                    </button>
                                    <button className="copy_excel_buttn" onClick={() => handleUploadLabExcel()}>
                                       Upload Now
                                    </button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </Box>
               </Modal>
               <DataTable columns={users_columns} data={displayArr} pagination />
            </DashboardTable>
         </section>
      </main>
   );
}

export default LabSample;
