export const rolesObj = {
        ADMIN: "ADMIN",
        USER: "USER",
        GM: "GM",
        AGM: "AGM",
        M: "M",
        AM: "AM",
};



export const departmentObj = {
        OPERATIONS: "OPERATIONS",
        FINANCE: "FINANCE",
        SALES: "SALES",
        LAB: "LAB",
        // OPERATIONSUSER: "OPERATIONS USER",
        // FINANCEUSER: "FINANCE USER",
};

export const discountStatus = {
        PERCENTAGE: "PERCENTAGE",
        FLATOFF: "FLATOFF",
};

