import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { HiChevronRight } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  usersGet
} from "../../redux/actions/Users/users.actions";
import {
  StatusChange,
  updateUserCategory
} from "../../services/users.service";
import { rolesObj } from "../../utils/roles";
import { toastError } from "../../utils/toastUtils";
import CustomButton from "../Utility/Button";
import { DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import { toastSuccess } from "../Utility/ToastUtils";
import { deleteLocation, getLocation } from "../../services/location.service";
import { MdOutlineAdd } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { RiDeleteBin5Line } from "react-icons/ri";

function LocationListPage() {
  const dispatch = useDispatch();
  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalBox, setModalBox] = useState(false);
  const [usersArr, setUsersArr] = useState([]);
  const [customersArr, setCustomersArr] = useState([]);
  const [retailersArr, setRetailersArr] = useState([]);
  const [wholesalersArr, setWholesalersArr] = useState([]);
  const [subAdminArr, setSubAdminArr] = useState([]);
  const userArr = useSelector((state) => state.users.users);
  const [selectedData, setSelectedData] = useState(null);
  const [query, setQuery] = useState("");
  const [displayUserArr, setDisplayUserArr] = useState([]);
  const [mainArr, setMainArr] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [customerCategoryObj, setCustomerCategoryObj] = useState({});
  const [customerCategoryId, setCustomerCategoryId] = useState("");
  const [customerCatArr, setCustomerCatArr] = useState([]);

  const navigate = useNavigate();



  const handleEdit = (row) => {
    navigate("/LocationPage" + `?id=${row}`);
  };







  const handleGet = async () => {
    try {

      let { data: res } = await getLocation({ pageIndex: 0, pageSize: 10000000 }, "");
      if (res.message) {
        setDisplayUserArr(res.data);
        setMainArr(res.data);
      }
      // dispatch(updateSpecificUser(obj, selectedUser._id))
    } catch (err) {
      toastError(err);
    }
  };
  useEffect(() => {
    handleGet()
  }, [])


  const users_columns = [
    {
      name: "Sr No.",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "5%",
    },

    {
      name: "User NAME",
      selector: (row) => `${row?.name}`,
    },
    {
      name: "Longtitude",
      selector: (row) => row?.longitude,
    },
    {
      name: "Latitude",
      selector: (row) => row?.latitude,
    },
    {
      name: "Description of Product",
      selector: (row) => row?.description,
    },
    {
      name: "Contact",
      selector: (row) => row?.contact,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button onClick={() => handleEdit(row._id)} className="edit_buttn">
            <AiFillEdit />
          </button>

          <div style={{ marginLeft: 15 }}>
            <button onClick={() => handleDelete(row._id)} className="delete_buttn">
              <RiDeleteBin5Line />
            </button>
          </div>

        </>
      ),
      width: "10%",
    },
  ];



  const handleDelete = async (id) => {
    try {
      if (!window.confirm("Are you sure")) {
        return
      }

      let { data: res } = await deleteLocation(id)
      if (res.message) {
        toastSuccess(res.message);
        handleGet()
      }
    }
    catch (err) {
      toastError(err)
    }
  }




  const handleSearch = (queryValue) => {
    setQuery(queryValue);
    let tempArr = mainArr;
    tempArr = tempArr.filter(
      (el) =>
        `${el?.name}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
        `${el?.longitude}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
        `${el?.latitude}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
        `${el?.description}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
        `${el?.contact}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
        `${el?.email}`.toLowerCase().includes(`${queryValue}`.toLowerCase())
    );





    setDisplayUserArr([...tempArr]);
  };



  return (
    <main className="p-0">
      <section className="product-category">
        <div className="user_header">
          <span className="page_name">Location</span>
          {/* <p className="breadcrumb">
            <span className="parent">Dashboard </span>{" "}
            <span>
              {" "}
              <HiChevronRight />
            </span>{" "}
            <span className="child">Location</span>
          </p> */}
        </div>

        <DashboardTable>
          <div className="row align-items-center border_with_color mx-0">
            <div className="col-xl-10">
              <div className="user_header_left_text">
                <h6>Location</h6>

                <span>Location List</span>
              </div>
            </div>
            <div className="col-xl-2">
              <div className="user_header_right">
                {/* <select>
                  <option value="">All (56)</option>
                  <option value="ssl">SSL</option>
                  <option value="tls">TLS</option>
                </select> */}

                <button onClick={() => navigate("/LocationPage")} className="">
                  Add <MdOutlineAdd />
                </button>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-0 border_with_color_1">

            <button className="copy_excel_buttn">Copy to Excel</button>
            <div className="d-flex align-items-center gap-3">
              <SearchBox
                query={query}
                setQuery={handleSearch}
                extraClass="bg-white"
              />

            </div>
          </div>

          <DataTable columns={users_columns} data={displayUserArr} pagination />
        </DashboardTable>
      </section>


    </main>
  );
}

export default LocationListPage;
