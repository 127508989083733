import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { addZone, updateZone, getById } from "../../services/zone.service";

function ZoneAddPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [name, setName] = useState("");

  const [zsmName, setZsmName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = async () => {
    try {

      if (name == "") {
        toastError("Name is mandatory !!!");
        return
      }

      let obj = {
        name,
      }
      if (searchParams.get("id")) {
        let { data: res } = await updateZone(obj, searchParams.get("id"))
        if (res.message) {
          toastSuccess(res.message)
        }
      }
      else {
        let { data: res } = await addZone(obj)
        if (res.message) {
          toastSuccess(res.message)
        }
      }
    }
    catch (err) {
      toastError(err)
    }
  }




  const handleGetForUpdate = async () => {
    try {
      if (typeof searchParams?.get("id") != 'string')
        return;
      const res = await getById(searchParams?.get("id"));
      if (res.data.data) {
        console.log(res.data, "data")
        if (res.data?.data?.name) {
          setName(res.data?.data?.name);
        }
      }

    } catch (error) {
      toastError(error)
    }

  }
  useEffect(() => {
    if (searchParams?.get("id")) {
      handleGetForUpdate()
    }

  }, [searchParams.get("id")])


  return (
    <main className="bg-white">
      <section className="user_page_sec">
        <div className="user_header">
          {/* <span className="page_name">Zone</span>
          <p className="breadcrumb">
            <span className="parent">Dashboard </span>{" "}
            <span>
              {" "}
              <HiChevronRight />
            </span>{" "}
            <span className="child">Zone</span>
          </p> */}
        </div>

        <div className="user_form_box">
          <div className="form_header">
            <h6 className="heading">Zone</h6>
            <span className="sub_heading"> Add Zone</span>
          </div>

          <div className="form_box">
            <div className="row">
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Name*</p>
                  <input onChange={(e) => setName(e.target.value)} value={name} type="text" placeholder="Name" />
                </div>
              </div>
            </div>

            <div className="row">
              <button onClick={() => handleSubmit()} className="submit_buttn">Submit</button>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ZoneAddPage
