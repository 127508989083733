import { debounce } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AiFillEdit } from "react-icons/ai";
import { MdOutlineAdd } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import userimg from "../../assets/images/avatar.png";
import {
  deleteDispatchingInputSaleInvoice,
  getDispatchingInputSaleInvoice,
} from "../../services/dispatchingInputSaleInvoice.service";
import { toastError } from "../../utils/toastUtils";
import { DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import { toastSuccess } from "../Utility/ToastUtils";

function DispatchinginputssaleinvoiceList() {
  const [query, setQuery] = useState("");
  const [displayUserArr, setDisplayUserArr] = useState([]);
  const [mainArr, setMainArr] = useState([]);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const handleEdit = (row) => {
    navigate("/DispatchinginputssaleinvoiceAdd" + `?id=${row}`);
  };
  const location = useLocation();

  const users_columns = [
    {
      name: "Sr No.",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Document Type",
      selector: (row) => `${row?.documentType}`,
    },
    {
      name: "Document Number",
      selector: (row) => row?.documentNumber,
    },
    {
      name: "Customer Code",
      selector: (row) => row?.customerCode,
    },
    {
      name: "Customer Name",
      selector: (row) => row?.customerName,
    },
    {
      name: "ON",
      selector: (row) => moment(row?.on).format("DD-MM-YYYY"),
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button onClick={() => handleEdit(row._id)} className="edit_buttn">
            <AiFillEdit />
          </button>
          <div style={{ marginLeft: 15 }}>
            <button
              onClick={() => handleDelete(row._id)}
              className="delete_buttn"
            >
              <RiDeleteBin5Line />
            </button>
          </div>
        </>
      ),
      width: "10%",
    },
  ];

  const handleGet = async (pageValue, limitValue, queryValue) => {
    try {
      let query = "";
      if (queryValue && queryValue != "") {
        query = `${queryValue}`
      }
      console.log(query, "query")
      let { data: res } = await getDispatchingInputSaleInvoice({ pageIndex: pageValue, pageSize: limitValue }, { query });
      if (res.message) {
        setDisplayUserArr(res.data);
        setTotalElements(res.total);
        setMainArr(res.data);
      }
    } catch (err) {
      toastError(err);
    }
  };



  const handleDelete = async (id) => {
    try {
      if (!window.confirm("Are you sure")) {
        return;
      }
      let { data: res } = await deleteDispatchingInputSaleInvoice(id);
      if (res.message) {
        toastSuccess(res.message);
        setPage(1);
        handleGet(1, limit, query);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalElements, setTotalElements] = useState(0);

  const createQueryString = useCallback(
    (arr) => {
      const params = new URLSearchParams(searchParams.toString());
      for (let i = 0; i < arr.length; i++) {
        const element = arr[i];
        params.set(element.name, element.value);
      }
      console.log(params.toString(), "params.toString()");
      return params.toString();
    },
    [searchParams]
  );

  const handlePerRowsChange = (newPerPage, page) => {
    let pathname = window.location.href.split("/")[3];
    navigate(location.pathname + "?" + createQueryString([{ name: "limit", value: newPerPage }])
    );
    setLimit(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
    navigate(location.pathname + "?" + createQueryString([{ name: "page", value: page }])
    );
  };

  useEffect(() => {
    if (searchParams) {
      const query = searchParams.get("query") || "";
      const limit = parseInt(searchParams.get("limit") || "10");
      const pageValue = parseInt(searchParams.get("page") || "1");
      setLimit(limit);
      setPage(pageValue);
      setQuery(query);
      console.log(searchParams.toString(), "searchParams")
      handleGet(pageValue, limit, query);
    }
  }, [searchParams]);

  // const handleSearch = (queryValue) => {
  //   setQuery(queryValue);
  //   let tempArr = mainArr;
  //   tempArr = tempArr.filter(
  //     (el) =>
  //       `${el?.name}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
  //       `${el?.psrName}`
  //         .toLowerCase()
  //         .includes(`${queryValue}`.toLowerCase()) ||
  //       `${el?.phone}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
  //       `${el?.email}`.toLowerCase().includes(`${queryValue}`.toLowerCase())
  //   );
  //   setDisplayUserArr([...tempArr]);
  // };


  const debouncedSave = useCallback(
    debounce(
      (nextValue) => {
        navigate(location.pathname +
          "?" +
          createQueryString([
            { name: "query", value: nextValue },
            { name: "page", value: 1 },
          ])
        )

      },
      1000
    ),
    [] // will be created only once initially
  );

  // highlight-ends

  const handleChange = (event) => {
    const nextValue = event;

    setQuery(nextValue);

    // Even though handleChange is created on each render and executed

    // it references the same debouncedSave that was created initially

    debouncedSave(nextValue);
  };
  return (
    <main className="p-0">
      <section className="product-category">
        <div className="row align-items-center">
          <div className="col-xl-10">
            <div className="user_header_left">
              <div className="user_img">
                <img src={userimg} alt="" />
              </div>
              <span>Dispatching Input Sale Invoice</span>
            </div>
          </div>
          <div className="col-xl-2">
            <div className="user_header_right">
              {/* <select>
                <option value="">All (56)</option>
                <option value="ssl">SSL</option>
                <option value="tls">TLS</option>
              </select> */}

              <button
                onClick={() => navigate("/DispatchinginputssaleinvoiceAdd")}
                className=""
              >
                Add <MdOutlineAdd />
              </button>
            </div>
          </div>
        </div>
        <DashboardTable>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <button className="copy_excel_buttn">Copy to Excel</button>
            <div className="d-flex align-items-center gap-3">
              <SearchBox
                query={query}
                setQuery={handleChange}
                extraClass="bg-white"
              />
            </div>
          </div>
          <DataTable
            columns={users_columns}
            data={displayUserArr}
            pagination
            paginationPerPage={limit}
            paginationDefaultPage={page}
            paginationServer={true}
            paginationRowsPerPageOptions={[10, 20, 30, 50, 100, 200, 500]}
            paginationTotalRows={totalElements}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </DashboardTable>
      </section>
    </main>
  );
}

export default DispatchinginputssaleinvoiceList;
