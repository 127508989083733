import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";
import { getProducts } from "../../services/product.service";
import {
  addBaseInDemandProducts,
  getById,
  updateBaseInDemandProducts,
} from "../../services/baseInDemandProducts.service";
import Select from "react-select";
import { toastError, toastSuccess } from "../Utility/ToastUtils";

function BaseIndemandProduct() {
  const [name, setName] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const handleSubmit = async () => {
    try {
      if (name == "") {
        toastError("Name is mandatory !!!");
        return;
      }

      let obj = {
        name,
      };
      if (searchParams.get("id")) {
        let { data: res } = await updateBaseInDemandProducts(
          obj,
          searchParams.get("id")
        );
        if (res.message) {
          toastSuccess(res.message);
        }
      } else {
        let { data: res } = await addBaseInDemandProducts(obj);
        if (res.message) {
          toastSuccess(res.message);
        }
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleGetForUpdate = async () => {
    try {
      if (typeof searchParams?.get("id") != "string") return;
      const res = await getById(searchParams?.get("id"));
      if (res.data.data) {
        console.log(res.data, "data");
        if (res.data?.data?.name) {
          setName(res.data?.data?.name);
        }
      }
    } catch (error) {
      toastError(error);
    }
  };
  useEffect(() => {
    if (searchParams?.get("id")) {
      handleGetForUpdate();
    }
  }, [searchParams.get("id")]);

  return (
    <main className="bg-white">
      <section className="user_page_sec">
        <div className="user_header">
          <span className="page_name">Base Associated product</span>
          {/* <p className="breadcrumb">
            <span className="parent">Dashboard </span>{" "}
            <span>
              {" "}
              <HiChevronRight />
            </span>{" "}
            <span className="child">Base Associated product</span>
          </p> */}
        </div>

        <div className="user_form_box">
          <div className="form_header">
            <h6 className="heading">Base Associated product</h6>
            <span className="sub_heading">Create Base Associated product</span>
          </div>

          <div className="form_box">
            <div className="row">
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Name*</p>
                  <input
                    type="text"
                    placeholder="Name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </div>
              </div>
              <div className="row">
                <button onClick={() => handleSubmit()} className="submit_buttn">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default BaseIndemandProduct;
