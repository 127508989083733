import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
// import { addRawMaterial, updateRawMaterial, getById } from "../../services/rawMaterial.service";
import { addLab, getLab, getById, updateLab } from "../../services/lab.service";
import { generateFilePath } from "../Utility/utils";
// import <FileUpload2></FileUpload2> from "../Utility/FileUpload";
import FileUpload from "../Utility/FileUpload";
function LabAddPage() {
    const [searchParams, setSearchParams] = useSearchParams();

    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState();
    const [imageUrl, setImageUrl] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [signatureUrl, setSignatureUrl] = useState("");
    const [imageStr, setImageStr] = useState("");
    const [signatureStr, setSignatureStr] = useState("");
    const [email, setEmail] = useState("");

    // const handleUpdate = async (id) => {
    //     const res = await getById(id);
    // }

    const handleSubmit = async () => {
        try {

            if (name == "") {
                toastError("Name is mandatory !!!");
                return
            }
            if (phoneNumber == "") {
                toastError("phoneNumber is mandatory !!!");
                return
            }
            if (imageStr == "") {
                toastError("image is mandatory !!!");
                return
            }
            if (addressLine1 == "") {
                toastError("address is mandatory !!!");
                return
            }
            if (signatureStr == "") {
                toastError("signature is mandatorylkjh.jhk !!!");
                return
            }
            if (email == "") {
                toastError("Email is mandatorylkjh.jhk !!!");
                return
            }
            let obj = {
                name,
                phoneNumber,
                emailId: email,
                imageUrl: imageStr,
                addressLine1,
                addressLine2,
                signatureUrl: signatureStr
            }
            if (searchParams.get("id")) {
                let { data: res } = await updateLab(obj, searchParams.get("id"))
                if (res.message) {
                    setName("");
                    setPhoneNumber("");
                    setAddressLine1("");
                    setAddressLine2("");
                    setImageStr("");
                    setSignatureStr("");
                    setEmail("");
                    handleGetForUpdate()
                    toastSuccess(res.message)

                }
            }
            else {
                let { data: res } = await addLab(obj)
                if (res.message) {
                    console.log(obj, "objjjjjjjj")
                    setName("");
                    setPhoneNumber("");
                    setAddressLine1("");
                    setAddressLine2("");
                    setImageStr("");
                    setSignatureStr("");
                    setEmail("");
                    handleGetForUpdate()
                    toastSuccess(res.message)
                }
            }
        }
        catch (err) {
            toastError(err)
        }
    }

    const handleFileSet = (value) => {
        // console.log(value);
        setImageStr(value) || setSignatureStr(value);
    };




    const handleGetForUpdate = async () => {
        try {
            if (typeof searchParams?.get("id") != 'string')
                return;
            const res = await getById(searchParams?.get("id"));
            if (res.data.data) {
                console.log(res.data, "data")
                if (res.data?.data?.name) {
                    setName(res.data?.data?.name);
                }
                if (res.data?.data?.phoneNumber) {
                    setPhoneNumber(res.data?.data?.phoneNumber);
                }
                if (res.data?.data?.addressLine1) {
                    setAddressLine1(res.data?.data?.addressLine1);
                }
                if (res.data?.data?.addressLine2) {
                    setAddressLine2(res.data?.data?.addressLine2);
                }
                if (res.data?.data?.imageUrl) {
                    setImageStr(res.data?.data?.imageUrl);
                }
                if (res.data?.data?.signatureUrl) {
                    setSignatureStr(res.data?.data?.signatureUrl);
                }
                if (res.data?.data?.emailId) {
                    setEmail(res.data?.data?.emailId);
                }
            }

        } catch (error) {
            toastError(error)
        }

    }
    useEffect(() => {
        if (searchParams?.get("id")) {
            handleGetForUpdate()
        }

    }, [searchParams.get("id")])


    return (
        <main className="bg-white">
            <section className="user_page_sec">
                <div className="user_header">
                    <span className="page_name">Lab</span>
                    {/* <p className="breadcrumb">
            <span className="parent">Dashboard </span>{" "}
            <span>
              {" "}
              <HiChevronRight />
            </span>{" "}
            <span className="child">Raw Material</span>
          </p> */}
                </div>

                <div className="user_form_box">
                    <div className="form_header">
                        <h6 className="heading">Lab</h6>
                        <span className="sub_heading"> Add Lab</span>
                    </div>

                    <div className="form_box">
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">Name*</p>
                                    <input onChange={(e) => setName(e.target.value)} value={name} type="text" placeholder="Name" />
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">Phone Number*</p>
                                    <input onChange={(e) => setPhoneNumber(e.target.value)} value={phoneNumber} type="number" placeholder="Phone Number" />
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">Email*</p>
                                    <input onChange={(e) => setEmail(e.target.value)} value={email} type="email" placeholder="Email" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">AddressLine 1*</p>
                                    <input onChange={(e) => setAddressLine1(e.target.value)} value={addressLine1} type="text" placeholder="Address Line 1" />
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">AddressLine 1</p>
                                    <input onChange={(e) => setAddressLine2(e.target.value)} value={addressLine2} type="text" placeholder="Address Line 2" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">Image*</p>
                                    {/* <FileUpload onFileChange={setImageUrl} /> */}
                                    <div>
                                    {   

                                        imageStr && imageStr.includes("base64")?
                                        <img style={{height:100, width:100}} src={imageStr} alt="" />
                                        :
                                        <img style={{height:100, width:100}} src={generateFilePath(imageStr)} alt="" />
                                    }
                                    </div>
                                    <FileUpload onFileChange={(value)=> setImageStr(value)} />
                                    {/* <FileUpload onFileChange={(e) => setImageUrl(e)} /> */}
                                    {/* <input onChange={(e) => handleFileSelection(e, "imageUrl")} value={imageUrl} type="file" placeholder="Select Image" /> */}
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="user_form_ip_box">
                                    <p className="ip_label">Signature*</p>
                                    {   

                                        signatureStr && signatureStr.includes("base64")?
                                        <img style={{height:100, width:100}} src={signatureStr} alt="" />
                                        :
                                        <img style={{height:100, width:100}} src={generateFilePath(signatureStr)} alt="" />
                                    }
                                    {/* <FileUpload onFileChange={setSignatureUrl} /> */}
                                    <FileUpload onFileChange={(value)=> setSignatureStr(value)} />

                                    {/* <input onChange={(e) => handleFileSelection(e, "signatureUrl")} value={signatureUrl} type="file" placeholder="Signature" /> */}
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <button onClick={() => handleSubmit()} className="submit_buttn">Submit</button>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default LabAddPage;
