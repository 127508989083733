import React, { useEffect, useState } from 'react'
import { HiChevronRight } from 'react-icons/hi'
import { CiLock } from 'react-icons/ci'
import { toastError, toastSuccess } from "../../utils/toastUtils"
// import { addUser, getUser, updateUser } from "../../services/users.service"
import { useSearchParams } from 'react-router-dom'
import { generateFilePath } from '../Utility/utils'
import { departmentObj, rolesObj } from '../../utils/roles'
import Select from 'react-select'
import { getZone } from '../../services/zone.service'
import { getState } from '../../services/state.service'
import { getWarehouse } from '../../services/warehouse.service'
import { addRetailer, getById, updateRetailer } from '../../services/retailers.service'
import { getCity } from '../../services/city.service'


function RetailerPage() {
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  // 
  const [zone, setZone] = useState({});
  const [zsm, setZsm] = useState({});
  const [region, setRegion] = useState({});
  // 
  const [farmCapacity, setFarmCapacity] = useState("");
  const [pincode, Setpincode] = useState("");
  const [contactPersonName, setContactPersonName] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [clientId, setClientId] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [aadharNumber, setAadharNumber] = useState("");
  const [description, setDescription] = useState("");
  const [password, setPassword] = useState("");
  const [manageRetailer, setManageRetailer] = useState(false);
  const [manageProducts, setManageProducts] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [role, setRole] = useState({});
  const [department, setDepartment] = useState({});

  const [storeCodeArr, setStoreCodeArr] = useState([])

  const [displayStoreCodeArr, setDisplayStoreCodeArr] = useState([])

  const [rolesArr, setRolesArr] = useState([]);
  const [departmentsArr, setDepartmentsArr] = useState([]);
  const [statesArr, setStatesArr] = useState([]);
  const [cityArr, setCityArr] = useState([]);
  const [stateId, setStateId] = useState("");

  const [zoneArr, setZoneArr] = useState([]);
  // console.log(zoneArr,"zone check" )
  // console.log(statesArr,"stata check" )
  // console.log(cityArr,"city check" )

  const [zoneId, setZoneId] = useState("");
  const [cityId, setCityId] = useState("");


  // const [zsmArr, setZsmArr] = useState([
  //   {
  //     label: "Zsm 1",
  //     value: "Zsm1",
  //   },
  //   {
  //     label: "Zsm 2",
  //     value: "Zsm2",
  //   },
  //   {
  //     label: "Zsm 3",
  //     value: "Zsm3",
  //   },
  // ]);
  // const [zoneArr, setZoneArr] = useState([
  //   {
  //     label: "Zone 1",
  //     value: "Z1",
  //   },
  //   {
  //     label: "Zone 2",
  //     value: "Z2",
  //   },
  //   {
  //     label: "Zone 3",
  //     value: "Z3",
  //   },
  // ]);
  // const [regionArr, setRegionArr] = useState([
  //   {
  //     label: "Region 1",
  //     value: "R1",
  //   },
  //   {
  //     label: "Region 2",
  //     value: "R2",
  //   },
  //   {
  //     label: "Region 3",
  //     value: "R3",
  //   },
  // ]);
  // const [rsmArr, setRsmArr] = useState([
  //   {
  //     label: "Rsm 1",
  //     value: "Rsm1",
  //   },
  //   {
  //     label: "Rsm 2",
  //     value: "Rsm2",
  //   },
  //   {
  //     label: "Rsm 3",
  //     value: "Rsm3",
  //   },
  // ]);


  // user model code for reference


  // let User = mongoose.Schema(
  //   {
  //       isActive: Boolean,
  //       role: String,
  //       department: String,
  //       image: String,
  //       name: String,
  //       email: String,
  //       phone: String,
  //       password: String,
  //       gmId: mongoose.Types.ObjectId,
  //       agmId: mongoose.Types.ObjectId,
  //       mId: mongoose.Types.ObjectId,
  //       amId: mongoose.Types.ObjectId,
  //       storeCodeArr:[
  //           {
  //               storeCode:String
  //           }
  //       ],
  //       department: String,
  //       accessObj: {
  //           manageUsers: { default: false, type: Boolean },
  //           manageCategory: { default: false, type: Boolean },
  //           manageProducts: { default: false, type: Boolean },
  //       },
  //   },
  //   { timestamps: true }
  // );





  useEffect(() => {
    handleGetWarehouseForStoreCodes()
    let tempRolesArr = []

    for (const key in rolesObj) {
      if (Object.hasOwnProperty.call(rolesObj, key)) {
        const element = rolesObj[key];
        if (element != "ADMIN") {
          tempRolesArr.push({ label: element, value: element });
        }
      }
    }

    setRolesArr([...tempRolesArr]);



    let tempDepartmentArr = []

    for (const key in departmentObj) {
      if (Object.hasOwnProperty.call(departmentObj, key)) {
        const element = departmentObj[key];
        tempDepartmentArr.push({ label: element, value: element });
      }
    }

    setDepartmentsArr([...tempDepartmentArr]);

  }, [])





  const handleRegister = async () => {
    try {

      if (name == "") {
        toastError("Name is mandatory !!!");
        return
      }

      // if (email == "") {
      //   toastError("Email is mandatory !!!");
      //   return
      // }
      // if (!searchParams.get("id")) {
      //   if (password == "") {
      //     toastError("Password is mandatory !!!");
      //     return
      //   }
      // }

      // if (phone == "") {
      //   toastError("Phone is mandatory !!!");
      //   return
      // }

      // if (image == "") {
      //   toastError("Image is mandatory !!!");
      //   return
      // }

      // jsut for reference nothing to do with this code....

      // userId: mongoose.Types.ObjectId,
      //   name: String, $
      //   code:String, pending
      //   phone: String, $ 
      //   farmCapacity: String, 
      //   email: String, $ 
      //   addressLine1: String,
      //   addressLine2: String,
      //   cityId: mongoose.Types.ObjectId,
      //   stateId: mongoose.Types.ObjectId,
      //   zoneId: mongoose.Types.ObjectId,
      //   pincode: String,
      //   contactPersonName: String,
      //   businessType: String,
      //   panNumber: String,
      //   gstNumber: String,
      //   aadharNumber: String,
      //   description: String,


      let obj = {
        name,
        email,
        phone,
        image,
        farmCapacity,
        pincode,
        contactPersonName,
        businessType,
        panNumber,
        gstNumber,
        description,
        stateId,
        cityId,
        zoneId,
        clientId,
        code,
        aadharNumber,
        role: role.value,
        department: department.value,
      
      

      }
      console.log(obj)





      if (searchParams.get("id")) {


        let { data: res } = await updateRetailer(obj, searchParams.get("id"))
        if (res.message) {
          toastSuccess(res.message)
        }
      }
      else {
        let { data: res } = await addRetailer(obj)
        if (res.message) {
          toastSuccess(res.message)
        }
      }
    }
    catch (err) {
      toastError(err)
    }
  }


  const HandleGetByIdOnInit = async (id) => {
    try {
      let { data: res } = await getById(id)
      console.log(res.data,"update respone data");
      if (res.data) {
        setName(res.data.name);
        setEmail(res.data.email);
        setPhone(res.data.phone);
        setImage(res.data.image);
        setAadharNumber(res.data.aadharNumber)
        setDescription(res.data.description);
        setGstNumber(res.data.gstNumber)
        setPanNumber(res.data.panNumber);
        setContactPersonName(res.data.contactPersonName);
        Setpincode(res.data.pincode);
        setCode(res.data.code);
        setBusinessType(res.data.businessType);
        setFarmCapacity(res.data.farmCapacity);
        setZoneId(res.data.zoneId);
        setCityId(res.data.cityId);
        setStateId(res.data.stateId);
        setClientId(res.data.clientId);
        console.log(res?.data, "res?.data?.accessObj?.manageRetailer")
        setManageRetailer(res?.data?.accessObj?.manageRetailer ? res?.data?.accessObj?.manageRetailer : false);
        setManageProducts(res?.data?.accessObj?.manageProducts ? res?.data?.accessObj?.manageProducts : false);
        setRole({ label: res.data.role, value: res.data.role })
        if (res.data.department) {
          setDepartment({ label: res.data.department, value: res.data.department })
        }

        // TODO: need to refactor this to be more efficient
        if (res.data?.storeCodeArr?.length) {
          let { data: warehouseRes } = await getWarehouse({ pageIndex: 0, pageSize: 10000000 }, "");
          if (warehouseRes.data) {
            let finalArr = res.data?.storeCodeArr.map(el => {
              let data = warehouseRes.data.find(ele => ele.storeCode === el.storeCode)
              if (data) {
                return { label: data.name, value: data.storeCode }
              }
              return null
            }).filter(el => el)
            setStoreCodeArr(finalArr)
          }
        }




      }
    }
    catch (err) {
      toastError(err)
    }
  }


  const handleGetWarehouseForStoreCodes = async () => {
    try {
      let { data: res } = await getWarehouse({ pageIndex: 0, pageSize: 10000000 }, "");
      if (res.message) {
        setDisplayStoreCodeArr(res.data.map(el => ({ label: el.name, value: el.storeCode })))
        console.log(res.data, "warehouses")
      }
    } catch (err) {
      toastError(err);
    }
  };





  useEffect(() => {
    if (searchParams.get("id")) {
      HandleGetByIdOnInit(searchParams.get("id"))
    }

  }, [searchParams.get("id")])



  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
    };
  };
  const handleFileSelection = (event) => {
    if (event.target.files[0]) {
      getBase64(event.target.files[0], (result) => {
        // setImage(event.target.files[0]);
        setImage(result);
      });
    }
  };





  const handleGetZones = async () => {
    try {
      const { data: res } = await getZone({ pageIndex: 0, pageSize: 10000000 }, { forSelectInput: true });
      if (res?.data) {
        console.log(res.data, " zone data")
        setZoneArr([{ label: "Please select a zone", value: "" }, ...res?.data]);
      }
    } catch (error) {
      toastError(error)
    }

  }


  const HandleGetStates = async (value) => {
    try {
      if(zoneId){
        let { data: res } = await getState({}, { forSelectInput: true, zoneId: zoneId });
        console.log(res.data, "states data here of exiting zoneId")
        setStatesArr([...res.data]);
        return
      }
      let { data: res } = await getState({}, { forSelectInput: true, zoneId: value });
      // console.log(res.data, "states data here before if statement")
      if (res.data) {
        console.log(res.data, "states data here")
        setStatesArr([...res.data]);
      }
    }
    catch (err) {
      toastError(err)
    }
  }

  const HandleGetCity = async (value) => {
    try {
      if(stateId){
        let { data: res } = await getCity({}, { forSelectInput: true, zoneId: zoneId , stateId: stateId});
        // console.log(res.data, "states data here of exiting zoneId")
        setCityArr([...res.data]);
        return
      }
      let { data: res } = await getCity({}, { forSelectInput: true, zoneId: zoneId, stateId: value });
      // console.log(res.data, "city data before if statment")
      if (res.data) {
        console.log(res.data, "city data")
        setCityArr([...res.data]);
      }
    }
    catch (err) {
      toastError(err)
    }
  }


  const handleSelectZone = (value) => {
    setZoneId(value);
    HandleGetStates(value);
  }


  const handleSelectState = (value) => {
    setStateId(value);
    // HandleGetStates(value);
    HandleGetCity(value);
  }

  const handleSelectCity = (value) => {
    setCityId(value);
    // handleSelectState(value);
  }

  useEffect(() => {
    handleGetZones()
    // handleSelectState()
    // handleSelectCity()
  }, [])
  useEffect(() => {
    if(zoneId){
      HandleGetStates()
    }
    
  }, [zoneId])
  useEffect(() => {
    if(stateId){
      HandleGetCity()
    }
    
  }, [stateId])

  return (
    <main className='bg-white'>
      <section className="user_page_sec">
        {/* <div className="user_header">
          <span className='page_name'>User</span>
          <p className="breadcrumb">
            <span className='parent'>Dashboard </span> <span> <HiChevronRight /></span> <span className='child'>User</span>
          </p>
        </div> */}

        <div className="user_form_box">
          <div className="form_header">
            <h6 className="heading">Retailer</h6>
            <span className='sub_heading'>Create Retailer</span>
          </div>

          <div className="form_box">
            <div className="row">
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Name*</p>
                  <input onChange={(e) => setName(e.target.value)} value={name} type="text" placeholder='Enter Name' />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Email</p>
                  <input onChange={(e) => setEmail(e.target.value)} value={email} type="text" placeholder='Enter Email' />
                </div>
              </div>

            </div>
            <div className="row">
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Mobile no</p>
                  <input onChange={(e) => setPhone(e.target.value)} value={phone} type="text" placeholder='Enter Mobile no' />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">GL CODE</p>
                  <input onChange={(e) => setCode(e.target.value)} value={code} type="text" placeholder='Enter Customer Code' />
                </div>
              </div>
              {/* <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Password</p>
                  <input onChange={(e) => setPassword(e.target.value)} value={password} type="password" placeholder='Enter Password' />
                  <CiLock className='lock_icn' />
                </div>
              </div> */}
              {/* <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Role*</p>
                  <Select onChange={(e) => setRole(e)} value={role} options={rolesArr} />
                </div>
              </div>

              {
                (
                  role.value === rolesObj.AGM
                  || role.value === rolesObj.M
                  || role.value === rolesObj.AM
                  || role.value === rolesObj.USER
                ) &&
                <div className="col-xl-6">
                  <div className="user_form_ip_box">
                    <p className="ip_label">Locations*</p>
                    <Select
                      onChange={(e) => {
                        setStoreCodeArr(e)
                        console.log(e)
                      }}
                      value={storeCodeArr}
                      isMulti
                      closeMenuOnSelect={false}
                      options={displayStoreCodeArr}
                    />
                  </div>
                </div>
              } */}

              {/* <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Department*</p>
                  <Select
                    onChange={(e) => setDepartment(e)}
                    value={department}
                    options={departmentsArr}
                  />
                </div>
              </div> */}
              <div className="row">
                <div className="col-xl-6">
                  <div className="user_form_ip_box">
                    <p className="ip_label">Aadhar Number</p>
                    <input onChange={(e) => setAadharNumber(e.target.value)} value={aadharNumber} type="text" placeholder='Enter Aadhar Number' />
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="user_form_ip_box">
                    <p className="ip_label">Pan Number</p>
                    <input onChange={(e) => setPanNumber(e.target.value)} value={panNumber} type="text" placeholder='Enter Pan Number' />
                  </div>
                </div>

              </div>
              <div className="row">
                <div className="col-xl-6">
                  <div className="user_form_ip_box">
                    <p className="ip_label">Customer Code</p>
                    <input onChange={(e) => setClientId(e.target.value)} value={clientId} type="text" placeholder='Enter Clinet ID' />
                  </div>
                </div>
               

              </div>
              <div className="row">
                <div className="col-xl-6">
                  <div className="user_form_ip_box">
                    <p className="ip_label">Gst Number</p>
                    <input onChange={(e) => setGstNumber(e.target.value)} value={gstNumber} type="text" placeholder='Enter Gst Number' />
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="user_form_ip_box">
                    <p className="ip_label">contact Person Name</p>
                    <input onChange={(e) => setContactPersonName(e.target.value)} value={contactPersonName} type="text" placeholder='Enter Contact Person Name' />
                  </div>
                </div>

              </div>
              <div className="row">
                <div className="col-xl-6">
                  <div className="user_form_ip_box">
                    <p className="ip_label">Bussiness Type</p>
                    <input onChange={(e) => setBusinessType(e.target.value)} value={businessType} type="text" placeholder='Enter Bussiness Type' />
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="user_form_ip_box">
                    <p className="ip_label">Pincode</p>
                    <input onChange={(e) => Setpincode(e.target.value)} value={pincode} type="text" placeholder='Enter Pincode' />
                  </div>
                </div>

              </div>
              <div className="row">
                <div className="col-xl-6">
                  <div className="user_form_ip_box">
                    <p className="ip_label">Description</p>
                    <input onChange={(e) => setDescription(e.target.value)} value={description} type="text" placeholder='Enter Description' />
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="user_form_ip_box">
                    <p className="ip_label">Farm Capacity</p>
                    <input onChange={(e) => setFarmCapacity(e.target.value)} value={farmCapacity} type="text" placeholder='Enter Farm Capacity' />
                  </div>
                </div>

                <div className="col-xl-6">
                  <div className="user_form_ip_box">
                    <p className="ip_label">Zone</p>
                    <Select options={zoneArr} value={zoneArr.find(el => el.value == zoneId)} onChange={(e) => handleSelectZone(e.value)} />
                  </div>
                </div>




                <div className="col-xl-6">
                  <div className="user_form_ip_box">
                    <p className="ip_label">State</p>
                    <Select value={statesArr.find(el => el.value == stateId)} options={statesArr} onChange={(e) => handleSelectState(e.value)} />
                  </div>
                </div>




                <div className="col-xl-6">
                  <div className="user_form_ip_box">
                    <p className="ip_label">City</p>
                    <Select value={cityArr.find(el => el.value == cityId)} options={cityArr} onChange={(e) => handleSelectCity(e.value)} />
                  </div>
                </div>

              </div>
            </div>
            {/* <div className="col-xl-6">
              <div className="user_form_ip_box">
                <p className="ip_label">Zone*</p>
                <Select options={zoneArr} value={zone} onChange={(e) => setZone(e)} />
              </div>
            </div> */}




            {

              // <div className="col-xl-6">
              //   <div className="user_form_ip_box">
              //     <p className="ip_label">Region (State)*</p>
              //     <Select value={region} options={regionArr} onChange={(e) => setRegion(e)} />
              //   </div>
              // </div>
            }
            {/* {
              (
                role.value == rolesObj?.SM
              ) &&
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Region Sales Manager*</p>
                  <Select value={rsm} options={rsmArr} onChange={(e) => setRsm(e)} />
                </div>
              </div>
            } */}

            <div className="row">
              <div className="col-xl-6">
                <div className="user_form_img_box">
                  <p className="ip_label">Image</p>
                  <div>
                    {
                      image && image.includes("base64") ?
                        <img src={image} style={{ height: 100, width: 100 }} alt="" srcset="" />
                        :
                        <img src={generateFilePath(image)} style={{ height: 100, width: 100 }} alt="" srcset="" />
                    }
                  </div>
                  <button className="choose_img_buttn">Choose File</button>
                  <input onChange={(e) => handleFileSelection(e)} type="file" placeholder='No file Chosen' />
                </div>
              </div>


            </div>

           
            <div className="row">

              <button onClick={() => handleRegister()} className="submit_buttn">Submit</button>
            </div>



          </div>




        </div>
      </section>



    </main>
  )
}

export default RetailerPage
