import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { HiChevronRight } from "react-icons/hi";
import Select from "react-select";
import { getProductsSalesRevenueYearWiseChange } from "../../services/dispatchingInputSaleInvoice.service";
import { getProductsByCategoryName } from "../../services/product.service";
import CustomButton from "../Utility/Button";
import { DashboardBox, DashboardChart } from "../Utility/DashboardBox";
import { toastError } from "../Utility/ToastUtils";
import { formatter } from "../Utility/utils";
import { handleReturnBarGraphColorArr } from "../Utility/barGraphColorArray";
import moment from "moment";
import { returnFormatedDateTypeObject } from "../../utils/DateUtils";
export default function YearWiseSaleRevenue() {
  const [productsArr, setProductsArr] = useState([]);

  const [barLabels, setBarLabels] = useState([
    "",
  ]);

  const [secondBarLabels, setSecondBarLabels] = useState([
    "",
  ]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [secondStartDate, setSecondStartDate] = useState("");
  const [secondEndDate, setSecondEndDate] = useState("");


  const [yearsArr, setYearsArr] = useState([]);
  const [selectedSecondYear, setSelectedSecondYear] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedProductId, setSelectedProductId] = useState("");
  const [selectedReportType, setSelectedReportType] = useState("Monthly");

  const [displayYear, setDisplayYear] = useState("");
  const [displayProduct, setDisplayProduct] = useState("");

  const [reportType, setReportType] = useState([
    { label: "Quarterly", value: "Quarterly" },
    { label: "Monthly", value: "Monthly" },
  ]);

  const [unitOfMeasurement, setUnitOfMeasurement] = useState("");
  const [requestedYearSalesArr, setRequestedYearSalesArr] = useState([]);
  const [previousYearSalesArr, setPreviousYearSalesArr] = useState([]);

  const HandleGetComodityProducts = async () => {
    try {
      let query = {};
      let { data: res } = await getProductsByCategoryName(query);
      if (res.data) {
        setProductsArr(res.data);
      }
    } catch (error) {
      toastError(error);
    }
  };

  const HandleGetComodityProductsSalesYearWise = async () => {
    try {
      let query = {};
      if (startDate == "") {
        toastError("Please select a Start Date");
        return
      }

      if (endDate == "") {
        toastError("Please select a End Date");
        return
      }

      if (secondStartDate == "") {
        toastError("Please select a Second Start Date");
        return
      }

      if (secondEndDate == "") {
        toastError("Please select a Second End Date");
        return
      }

      if (startDate && startDate != "") {
        let strtDate = returnFormatedDateTypeObject(startDate)
        query = { ...query, startDate: strtDate.toISOString() };
      }

      if (endDate && endDate != "") {
        let edDate = returnFormatedDateTypeObject(endDate)
        edDate.setDate(edDate.getDate() + 1)
        edDate.setTime(edDate.getTime() - 1)

        query = { ...query, endDate: edDate.toISOString() };
      }

      if (secondStartDate && secondStartDate != "") {
        let strtDate = returnFormatedDateTypeObject(secondStartDate)
        query = { ...query, secondStartDate: strtDate.toISOString() };
      }

      if (secondEndDate && secondEndDate != "") {
        let edDate = returnFormatedDateTypeObject(secondEndDate)
        edDate.setDate(edDate.getDate() + 1)
        edDate.setTime(edDate.getTime() - 1)

        query = { ...query, secondEndDate: edDate.toISOString() };
      }
      // if (selectedYear && selectedYear != "") {
      //   query = { ...query, selectedYear: selectedYear };
      // }
      // if (selectedSecondYear && selectedSecondYear != "") {
      //   query = { ...query, selectedSecondYear: selectedSecondYear };
      // }
      if (selectedReportType && selectedReportType != "") {
        query = { ...query, selectedReportType: selectedReportType };
      }

      setDisplayYear(selectedYear);
      setDisplayProduct(selectedProductId);

      let { data: res } = await getProductsSalesRevenueYearWiseChange(query);
      console.log(res.data)
      if (res.data) {

        setUnitOfMeasurement(res.data.unitOfMeasurement);

        setBarLabels(res.data.requestedYearsOrderCount.map(el => el.label));
        setSecondBarLabels(res.data.previousYearsOrderCount.map(el => el.label));
        setRequestedYearSalesArr(res.data.requestedYearsOrderCount);
        setPreviousYearSalesArr(res.data.previousYearsOrderCount);



        // if (selectedReportType == "Quarterly") {
        //   setBarLabels(["Q1", "Q2", "Q3", "Q4"]);
        //   // let requestedYearQuartersArr = [
        //   //   { value: 0 },
        //   //   { value: 0 },
        //   //   { value: 0 },
        //   //   { value: 0 },
        //   // ];
        //   // let previousYearQuartersArr = [
        //   //   { value: 0 },
        //   //   { value: 0 },
        //   //   { value: 0 },
        //   //   { value: 0 },
        //   // ];
        //
        //   // for (let i = 0; i < res.data.requestedYearsOrderCount.length; i++) {
        //   //   if (
        //   //     res.data.requestedYearsOrderCount[i].month == "January" ||
        //   //     res.data.requestedYearsOrderCount[i].month == "February" ||
        //   //     res.data.requestedYearsOrderCount[i].month == "March"
        //   //   ) {
        //   //     requestedYearQuartersArr[0].value +=
        //   //       res.data.requestedYearsOrderCount[i].value;
        //   //   }
        //   //   if (
        //   //     res.data.requestedYearsOrderCount[i].month == "April" ||
        //   //     res.data.requestedYearsOrderCount[i].month == "May" ||
        //   //     res.data.requestedYearsOrderCount[i].month == "June"
        //   //   ) {
        //   //     requestedYearQuartersArr[1].value +=
        //   //       res.data.requestedYearsOrderCount[i].value;
        //   //   }
        //   //   if (
        //   //     res.data.requestedYearsOrderCount[i].month == "July" ||
        //   //     res.data.requestedYearsOrderCount[i].month == "August" ||
        //   //     res.data.requestedYearsOrderCount[i].month == "September"
        //   //   ) {
        //   //     requestedYearQuartersArr[2].value +=
        //   //       res.data.requestedYearsOrderCount[i].value;
        //   //   }
        //   //   if (
        //   //     res.data.requestedYearsOrderCount[i].month == "October" ||
        //   //     res.data.requestedYearsOrderCount[i].month == "November" ||
        //   //     res.data.requestedYearsOrderCount[i].month == "December"
        //   //   ) {
        //   //     requestedYearQuartersArr[3].value +=
        //   //       res.data.requestedYearsOrderCount[i].value;
        //   //   }
        //   // }
        //   // for (let i = 0; i < res.data.previousYearsOrderCount.length; i++) {
        //   //   if (
        //   //     res.data.previousYearsOrderCount[i].month == "January" ||
        //   //     res.data.previousYearsOrderCount[i].month == "February" ||
        //   //     res.data.previousYearsOrderCount[i].month == "March"
        //   //   ) {
        //   //     previousYearQuartersArr[0].value +=handleReturnBarGraphColorArr(requestedYearSalesArr?.map((el) =>el?.value))
        //   //       res.data.previousYearsOrderCount[i].value;
        //   //   }
        //   //   if (
        //   //     res.data.previousYearsOrderCount[i].month == "April" ||
        //   //     res.data.previousYearsOrderCount[i].month == "May" ||
        //   //     res.data.previousYearsOrderCount[i].month == "June"
        //   //   ) {
        //   //     previousYearQuartersArr[1].value +=
        //   //       res.data.previousYearsOrderCount[i].value;
        //   //   }
        //   //   if (
        //   //     res.data.previousYearsOrderCount[i].month == "July" ||
        //   //     res.data.previousYearsOrderCount[i].month == "August" ||
        //   //     res.data.previousYearsOrderCount[i].month == "September"
        //   //   ) {
        //   //     previousYearQuartersArr[2].value +=
        //   //       res.data.previousYearsOrderCount[i].value;
        //   //   }
        //   //   if (
        //   //     res.data.previousYearsOrderCount[i].month == "October" ||
        //   //     res.data.previousYearsOrderCount[i].month == "November" ||
        //   //     res.data.previousYearsOrderCount[i].month == "December"
        //   //   ) {
        //   //     previousYearQuartersArr[3].value +=
        //   //       res.data.previousYearsOrderCount[i].value;
        //   //   }
        //   // }
        //
        //   // setRequestedYearSalesArr(requestedYearQuartersArr);
        //
        //   // setPreviousYearSalesArr(previousYearQuartersArr);
        //
        //   setRequestedYearSalesArr(res.data.requestedYearsOrderCount);
        //   setPreviousYearSalesArr(res.data.previousYearsOrderCount);
        // } else {
        //   setBarLabels([
        //     "April",
        //     "May",
        //     "June",
        //     "July",
        //     "August",
        //     "September",
        //     "October",
        //     "November",
        //     "December",
        //     "January",
        //     "February",
        //     "March",
        //   ]);
        //   setRequestedYearSalesArr(res.data.requestedYearsOrderCount);
        //   setPreviousYearSalesArr(res.data.previousYearsOrderCount);
        // }
        //
        // setUnitOfMeasurement(res.data.unitOfMeasurement);

      }
    } catch (error) {
      toastError(error);
    }
  };

  const handleCalculateLastFiveYears = () => {
    let tempArr = [];
    let today = new Date();

    let totalYears = today.getFullYear() - 2018;

    for (let index = 0; index <= totalYears; index++) {
      let obj = {};
      let year = today.getFullYear() - index;
      obj.label = `${year}-${year + 1}`;
      obj.value = year;
      tempArr.push(obj);
    }

    setYearsArr(tempArr);
  };

  useEffect(() => {
    HandleGetComodityProducts();
    handleCalculateLastFiveYears();
  }, []);

  return (
    <main className="bg-white">
      <section className="dashboard-head mb-3">
        <div className="row">
          <div className="col-xl-10"></div>
          <div className="col-xl-2">
            {/* <div className="user_header">
              <p className="breadcrumb">
                <span className="parent">Dashboard </span>{" "}
                <span>
                  {" "}
                  <HiChevronRight />
                </span>{" "}
                <span className="child">Reports</span>
              </p>
            </div> */}
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-12 mb-5 p-5 border rounded">
              <div className="row">
                <h3 className="blue-1">Year wise Sales revenue comparision</h3>
              </div>
              <div className="row d-flex justify-content-between">
                <div className="col-12 col-lg-3  border rounded py-3" style={{ display: "grid", placeItems: "center" }}>
                  <div className="row align-items-center">
                    <div className="col-xl-12">
                      <div className="right_content_box">
                        <h5 className="blue-1">First Range</h5>
                        {/* <Select
                            options={yearsArr}
                            onChange={(e) => setSelectedYear(e.value)}
                          /> */}
                        <div className="row d-flex">


                          <div className="col-6 mt-1">
                            <label className="form-label">Start Date</label>
                            <input type="date"className="form-control" value={moment(startDate).format("YYYY-MM-DD")} onChange={(e) => setStartDate(e.target.value)} />
                          </div>

                          <div className="col-6 mt-1">
                            <label className="form-label">End Date</label>
                            <input type="date"className="form-control" value={moment(endDate).format("YYYY-MM-DD")} onChange={(e) => setEndDate(e.target.value)} />
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-3  border rounded py-3" style={{ display: "grid", placeItems: "center" }}>
                  <div className="row align-items-center">
                    <div className="col-xl-12">
                      <div className="right_content_box">
                        <h5 className="blue-1">Second Range</h5>
                        {/* <Select
                            options={yearsArr}
                            onChange={(e) => setSelectedSecondYear(e.value)}
                          /> */}
                        <div className="row d-flex">

                          <div className="col-6 mt-1">
                            <label className="form-label">Second Start Date</label>
                            <input type="date"className="form-control" value={moment(secondStartDate).format("YYYY-MM-DD")} onChange={(e) => setSecondStartDate(e.target.value)} />
                          </div>

                          <div className="col-6 mt-1">
                            <label className="form-label">Second End Date</label>
                            <input type="date"className="form-control" value={moment(secondEndDate).format("YYYY-MM-DD")} onChange={(e) => setSecondEndDate(e.target.value)} />
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-3 border rounded py-3" style={{ display: "grid", placeItems: "center" }}>
                  <div className="col-xl-12">
                    <h5 className="blue-1">Type</h5>
                    <Select
                      options={reportType}
                      value={{
                        label: selectedReportType,
                        value: selectedReportType,
                      }}
                      onChange={(e) => setSelectedReportType(e.value)}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-1 mt-5">
                  <CustomButton
                    isBtn
                    btntype="button"
                    ClickEvent={HandleGetComodityProductsSalesYearWise}
                    iconName="fa-solid fa-check"
                    extraClass={"mt-3"}
                    btnName="Submit"
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-12 col-lg-6">
                  <DashboardChart>
                    {requestedYearSalesArr &&
                      requestedYearSalesArr.length > 0 && (
                        <>
                          <h5 className="blue-1 my-4">
                            First Range (

                            {
                              !!startDate &&
                              <>{moment(startDate).format('DD/MM/YYYY')}</>
                            }
                            {" "}-{" "}
                            {
                              !!endDate &&
                              <>{moment(endDate).format('DD/MM/YYYY')}</>
                            }
                            )
                          </h5>

                          <Bar
                            data={{
                              labels: barLabels,
                              datasets: [
                                {
                                  label: selectedProductId,
                                  backgroundColor: handleReturnBarGraphColorArr(requestedYearSalesArr?.map((el) => el?.value)),
                                  data: requestedYearSalesArr?.map(
                                    (el) => el?.value
                                  ),
                                },
                              ],
                            }}
                          />
                          <h5 className="blue-1 my-4">
                            Total Sale in ({
                              !!startDate &&
                              <>{moment(startDate).format('DD/MM/YYYY')}</>
                            }
                            {" "}-{" "}
                            {
                              !!endDate &&
                              <>{moment(endDate).format('DD/MM/YYYY')}</>
                            }) is{" "}
                            {formatter.format(requestedYearSalesArr.reduce(
                              (acc, el, index) => acc + el.value,
                              0
                            ).toFixed(2))}{" "}

                          </h5>
                        </>
                      )}

                  </DashboardChart>
                </div>
                <div className="col-12 col-lg-6">
                  <DashboardChart>
                    {previousYearSalesArr &&
                      previousYearSalesArr.length > 0 && (
                        <>
                          <h5 className="blue-1 my-4">
                            Second Range (
                            {
                              !!secondStartDate &&
                              <>{moment(secondStartDate).format('DD/MM/YYYY')}</>
                            }
                            {" "}-{" "}
                            {
                              !!secondEndDate &&
                              <>{moment(secondEndDate).format('DD/MM/YYYY')}</>
                            }
                            )


                          </h5>
                          <Bar
                            data={{
                              labels: secondBarLabels,
                              datasets: [
                                {
                                  label: selectedProductId,
                                  backgroundColor: handleReturnBarGraphColorArr(previousYearSalesArr?.map((el) => el?.value)),
                                  data: previousYearSalesArr?.map(
                                    (el) => el?.value
                                  ),
                                },
                              ],
                            }}
                          />
                          <h5 className="blue-1 my-4">
                            Total Sale in ({
                              !!secondStartDate &&
                              <>{moment(secondStartDate).format('DD/MM/YYYY')}</>
                            }
                            {" "}-{" "}
                            {
                              !!secondEndDate &&
                              <>{moment(secondEndDate).format('DD/MM/YYYY')}</>
                            }) is{" "}
                            {formatter.format(previousYearSalesArr.reduce(
                              (acc, el, index) => acc + el.value,
                              0
                            ).toFixed(2))}{" "}
                          </h5>
                        </>
                      )}
                  </DashboardChart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
