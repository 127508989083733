// 
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/materialIn";

export const addMaterialIn = (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getMaterialIn = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/?${query.toString()}`);
};

export const deleteMaterialIn = (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const getById = (id) => {
    return axiosApiInstance.get(`${serverUrl}/getById/${id}`);
};

export const updateMaterialIn = (formData, id) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};