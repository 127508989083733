import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AiFillEdit } from "react-icons/ai";
import { MdOutlineAdd } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import userimg from "../../assets/images/avatar.png";
import {
  StatusChange
} from "../../services/users.service";
import { toastError } from "../../utils/toastUtils";
import { images } from "../Images/Images";
import { DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import { deleteMeeting, downloadExcel, downloadFilteredExcel, getMeeting } from "../../services/meeting.service";
import moment from "moment";
import { toastSuccess } from "../Utility/ToastUtils";
import { generateFilePath } from "../Utility/utils";

function MeetingListPage() {
  const [query, setQuery] = useState("");
  const [displayUserArr, setDisplayUserArr] = useState([]);
  const [mainArr, setMainArr] = useState([]);



  const navigate = useNavigate();


  const handleEdit = (row) => {
    navigate("/Meeting" + `?id=${row}`);
  };

  const handleGet = async () => {
    try {
      let { data: res } = await getMeeting({ pageIndex: 0, pageSize: 10000000 }, "");
      if (res) {
        setDisplayUserArr([...res.data]);
        setMainArr([...res.data]);
      }
    } catch (err) {
      toastError(err);
    }
  };
  const handleDelete = async (id) => {
    try {
      if (!window.confirm("Are you sure")) {
        return
      }

      let { data: res } = await deleteMeeting(id)
      if (res.message) {
        toastSuccess(res.message);
        handleGet()
      }
    }
    catch (err) {
      toastError(err)
    }
  }

  const handleDownload = async () => {
    try {
      let { data: res } = await downloadExcel({ pageIndex: 0, pageSize: 10000000 }, "");
      console.log(res, "hello vivek")
      // if (res) {
      //   setDisplayUserArr([...res.data]);
      //   setMainArr([...res.data]);
      //   // console.log(setMainArr, "array data")
      // }
      // const { data: response } = await downloadExcel(obj)
      console.log(res.filename, "response data here");
      if (res) {
        var link = document.createElement("a");
        link.download = res.filename;
        link.href = generateFilePath(res.filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err) {
      toastError(err);
    }
  };
  const handleFilteredDownload = async () => {
    try {
      let { data: res } = await downloadFilteredExcel({ pageIndex: 0, pageSize: 10000000 }, "");
      console.log(res, "hello vivek")
      // if (res) {
      //   setDisplayUserArr([...res.data]);
      //   setMainArr([...res.data]);
      //   // console.log(setMainArr, "array data")
      // }
      // const { data: response } = await downloadExcel(obj)
      console.log(res.filename, "response data here");
      if (res) {
        var link = document.createElement("a");
        link.download = res.filename;
        link.href = generateFilePath(res.filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    handleGet()
  }, [])

  const users_columns = [
    {
      name: "Sr No.",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "NAME",
      selector: (row) => `${row?.name}`,
    },
    {
      name: "Retailer",
      selector: (row) => row?.retailerName,
    },
    {
      name: "Date And time",
      selector: (row) => moment(row?.on).format("YYYY-MM-DD, HH:mm"),
    },
    {
      name: "Notes",
      selector: (row) => `${row?.note}`,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button onClick={() => handleEdit(row._id)} className="edit_buttn">
            <AiFillEdit />
          </button>

          <div style={{ marginLeft: 15 }}>
            <button onClick={() => handleDelete(row._id)} className="delete_buttn">
              <RiDeleteBin5Line />
            </button>
          </div>

        </>
      ),
      width: "10%",
    },
  ];




  const handleSearch = (queryValue) => {
    setQuery(queryValue);
    let tempArr = mainArr;
    tempArr = tempArr.filter(
      (el) =>
        `${el?.name}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
        `${el?.phone}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
        `${el?.email}`.toLowerCase().includes(`${queryValue}`.toLowerCase())
    );
    setDisplayUserArr([...tempArr]);
  };



  return (
    <main className="p-0">
      <section className="product-category">
        <div className="row align-items-center">
          <div className="col-xl-10">
            <div className="user_header_left">
              <div className="user_img">
                <img src={userimg} alt="" />
              </div>

              <span>Meeting</span>
            </div>
          </div>
          <div className="col-xl-2">
            <div className="user_header_right">
              {/* <select>
                <option value="">All (56)</option>
                <option value="ssl">SSL</option>
                <option value="tls">TLS</option>
              </select> */}

              <button onClick={() => navigate("/Meeting")} className="">
                Add <MdOutlineAdd />
              </button>
            </div>
          </div>
        </div>
        <DashboardTable>
          <div className="d-flex align-items-center justify-content-between mb-4">
            {/* <h5 className="blue-1 m-0">All Customers</h5> */}
            <button className="copy_excel_buttn" onClick={handleDownload}>Copy to Excel</button>
            <button className="copy_excel_buttn" onClick={handleFilteredDownload}>Copy Meeting Per Day Excel</button>
            <div className="d-flex align-items-center gap-3">
              <SearchBox
                query={query}
                setQuery={handleSearch}
                extraClass="bg-white"
              />
              {/* <CustomButton isLink iconName="fa-solid fa-download" btnName="Customer CSV" path="/" small roundedPill downloadAble ClickEvent={() => downloadCSV(usersArr)} /> */}
            </div>
          </div>

          <DataTable columns={users_columns} data={displayUserArr} pagination />
        </DashboardTable>
      </section>

    </main>
  );
}

export default MeetingListPage;
