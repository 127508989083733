import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { HiChevronRight } from "react-icons/hi";
import Select from "react-select";
import { getComodityProductsSalesYearWise } from "../../services/dispatchingInputSaleInvoice.service";
import { getProductsByCategoryName } from "../../services/product.service";
import CustomButton from "../Utility/Button";
import { DashboardBox, DashboardChart } from "../Utility/DashboardBox";
import { toastError } from "../Utility/ToastUtils";
import { quantityFormatter } from "../Utility/utils";
import { handleReturnBarGraphColorArr } from "../Utility/barGraphColorArray";
import moment from "moment";
import { returnFormatedDateTypeObject } from "../../utils/DateUtils";
export default function SpecialityProductWiseSales() {
   const [productsArr, setProductsArr] = useState([]);

   const [barLabels, setBarLabels] = useState([""]);

   const [secondBarLabels, setSecondBarLabels] = useState([""]);

   const [yearsArr, setYearsArr] = useState([]);

   const [selectedSecondYear, setSelectedSecondYear] = useState("");
   const [selectedYear, setSelectedYear] = useState("");

   const [startDate, setStartDate] = useState("");
   const [endDate, setEndDate] = useState("");
   const [secondStartDate, setSecondStartDate] = useState("");
   const [secondEndDate, setSecondEndDate] = useState("");

   const [selectedProductId, setSelectedProductId] = useState("");
   const [selectedReportType, setSelectedReportType] = useState("Monthly");

   const [reportType, setReportType] = useState([
      { label: "Quarterly", value: "Quarterly" },
      { label: "Monthly", value: "Monthly" },
   ]);

   const [displayYear, setDisplayYear] = useState("");
   const [displayProduct, setDisplayProduct] = useState("");

   const [unitOfMeasurement, setUnitOfMeasurement] = useState("");
   const [requestedYearSalesArr, setRequestedYearSalesArr] = useState([]);
   const [previousYearSalesArr, setPreviousYearSalesArr] = useState([]);

   const HandleGetComodityProducts = async () => {
      try {
         let query = { name: "speciality" };
         let { data: res } = await getProductsByCategoryName(query);
         if (res.data) {
            setProductsArr(res.data);
         }
      } catch (error) {
         toastError(error);
      }
   };

   const HandleGetComodityProductsSalesYearWise = async () => {
      try {
         let query = {};

         if (startDate == "") {
            toastError("Please select a Start Date");
            return;
         }

         if (endDate == "") {
            toastError("Please select a End Date");
            return;
         }

         if (secondStartDate == "") {
            toastError("Please select a Second Start Date");
            return;
         }

         if (secondEndDate == "") {
            toastError("Please select a Second End Date");
            return;
         }

         if (selectedProductId == "") {
            toastError("Please select a Product");
            return;
         }

         if (startDate && startDate != "") {
            let strtDate = returnFormatedDateTypeObject(startDate);
            query = { ...query, startDate: strtDate.toISOString() };
         }

         if (endDate && endDate != "") {
            let edDate = returnFormatedDateTypeObject(endDate);
            edDate.setDate(edDate.getDate() + 1);
            edDate.setTime(edDate.getTime() - 1);

            query = { ...query, endDate: edDate.toISOString() };
         }

         if (secondStartDate && secondStartDate != "") {
            let strtDate = returnFormatedDateTypeObject(secondStartDate);
            query = { ...query, secondStartDate: strtDate.toISOString() };
         }

         if (secondEndDate && secondEndDate != "") {
            let edDate = returnFormatedDateTypeObject(secondEndDate);
            edDate.setDate(edDate.getDate() + 1);
            edDate.setTime(edDate.getTime() - 1);

            query = { ...query, secondEndDate: edDate.toISOString() };
         }

         if (selectedProductId && selectedProductId != "") {
            query = { ...query, selectedProductId: selectedProductId };
         }
         if (selectedReportType && selectedReportType != "") {
            query = { ...query, selectedReportType: selectedReportType };
         }

         setDisplayProduct(selectedProductId);

         let { data: res } = await getComodityProductsSalesYearWise(query);
         if (res.data) {
            console.log(res.data, "res.data");

            setUnitOfMeasurement(res.data.unitOfMeasurement);

            setBarLabels(res.data.requestedYearsOrderCount.map((el) => el.label));
            setSecondBarLabels(res.data.previousYearsOrderCount.map((el) => el.label));
            setRequestedYearSalesArr(res.data.requestedYearsOrderCount);
            setPreviousYearSalesArr(res.data.previousYearsOrderCount);

            // if (selectedReportType == "Quarterly") {
            //
            //   setBarLabels(["Q1", "Q2", "Q3", "Q4"]);
            //   setRequestedYearSalesArr(res.data.requestedYearsOrderCount);
            //   setPreviousYearSalesArr(res.data.previousYearsOrderCount);
            // } else{
            //   setBarLabels([
            //     "April",
            //     "May",
            //     "June",
            //     "July",
            //     "August",
            //     "September",
            //     "October",
            //     "November",
            //     "December",
            //     "January",
            //     "February",
            //     "March",
            //   ])
            //   setRequestedYearSalesArr(res.data.requestedYearsOrderCount);
            //   setPreviousYearSalesArr(res.data.previousYearsOrderCount);
            // }
            //   setUnitOfMeasurement(res.data.unitOfMeasurement);
         }
      } catch (error) {
         toastError(error);
      }
   };

   const handleCalculateLastFiveYears = () => {
      let tempArr = [];
      let today = new Date();

      let totalYears = today.getFullYear() - 2017;

      for (let index = 0; index < totalYears; index++) {
         let obj = {};
         let year = today.getFullYear() - index;
         obj.label = `${year}-${year + 1}`;
         obj.value = year;
         tempArr.push(obj);
      }

      setYearsArr(tempArr);
   };

   useEffect(() => {
      HandleGetComodityProducts();
      handleCalculateLastFiveYears();
   }, []);

   return (
      <main className="bg-white">
         <section className="dashboard-head mb-3">
            <div className="row">
               <div className="col-xl-10"></div>
               <div className="col-xl-2">
                  {/* <div className="user_header">
                     <p className="breadcrumb">
                        <span className="parent">Dashboard </span>{" "}
                        <span>
                           {" "}
                           <HiChevronRight />
                        </span>{" "}
                        <span className="child">Reports</span>
                     </p>
                  </div> */}
               </div>
            </div>
         </section>

         <section>
            <div className="container-fluid">
               <div className="row">
                  <div className="col-12 col-md-12 mb-5 p-5 border rounded">
                     <div className="row">
                        <h3 className="blue-1">Speciality Product Wise Sales Comparision</h3>
                     </div>
                     <div className="row d-flex justify-content-between">
                        <div
                           className="col-12 col-lg-2 border rounded py-3"
                           style={{ display: "grid", placeItems: "center" }}
                        >
                           <div className="col-xl-12">
                              <div className="right_content_box">
                                 <h5 className="blue-1">Products</h5>
                                 <Select options={productsArr} onChange={(e) => setSelectedProductId(e.value)} />
                              </div>
                           </div>
                        </div>
                        <div
                           className="col-12 col-lg-3 border rounded py-3"
                           style={{ display: "grid", placeItems: "center" }}
                        >
                           <div className="row align-items-center">
                              <div className="col-xl-12">
                                 <div className="right_content_box">
                                    <h5 className="blue-1">First Range</h5>

                                    {/* <Select
                            options={yearsArr}
                            onChange={(e) => {
                              setSelectedYear(e.value)
                              setStartDate(`${Number(e.value)}-01-01`)
                              setEndDate(`${Number(e.value)}-12-31`)
                            }}
                          /> */}
                                    <div className="row d-flex">
                                       <div className="col-6 mt-1">
                                          <label className="form-label">Start Date</label>
                                          <input
                                             type="date"
                                            className="form-control"
                                             value={startDate}
                                             onChange={(e) => setStartDate(e.target.value)}
                                          />
                                       </div>

                                       <div className="col-6 mt-1">
                                          <label className="form-label">End Date</label>
                                          <input
                                             type="date"
                                            className="form-control"
                                             value={endDate}
                                             onChange={(e) => setEndDate(e.target.value)}
                                          />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div
                           className="col-12 col-lg-3 border rounded py-3"
                           style={{ display: "grid", placeItems: "center" }}
                        >
                           <div className="row align-items-center">
                              <div className="col-xl-12">
                                 <div className="right_content_box">
                                    <h5 className="blue-1">Second Range</h5>
                                    {/* <Select
                            options={yearsArr}
                            onChange={(e) => setSelectedSecondYear(e.value)}
                          /> */}
                                    <div className="row d-flex">
                                       <div className="col-6 mt-1">
                                          <label className="form-label">Second Start Date</label>
                                          <input
                                             type="date"
                                            className="form-control"
                                             value={secondStartDate}
                                             onChange={(e) => setSecondStartDate(e.target.value)}
                                          />
                                       </div>

                                       <div className="col-6 mt-1">
                                          <label className="form-label">Second End Date</label>
                                          <input
                                             type="date"
                                            className="form-control"
                                             value={secondEndDate}
                                             onChange={(e) => setSecondEndDate(e.target.value)}
                                          />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div
                           className="col-12 col-lg-2 border rounded py-3"
                           style={{ display: "grid", placeItems: "center" }}
                        >
                           <div className="col-xl-12">
                              <div className="right_content_box">
                                 <h5 className="blue-1">Type</h5>
                                 <Select
                                    options={reportType}
                                    value={{
                                       label: selectedReportType,
                                       value: selectedReportType,
                                    }}
                                    onChange={(e) => setSelectedReportType(e.value)}
                                 />
                              </div>
                           </div>
                        </div>
                        <div className="col-12 col-lg-1 mt-5">
                           <CustomButton
                              isBtn
                              btntype="button"
                              ClickEvent={HandleGetComodityProductsSalesYearWise}
                              iconName="fa-solid fa-check"
                              extraClass={"mt-3"}
                              btnName="Submit"
                           />
                        </div>
                     </div>

                     <div className="row mt-5">
                        <div className="col-12 col-lg-6">
                           <DashboardChart>
                              {requestedYearSalesArr && requestedYearSalesArr.length > 0 && (
                                 <>
                                    <h5 className="blue-1 my-4">
                                       First Range ({!!startDate && <>{moment(startDate).format("DD/MM/YYYY")}</>} -{" "}
                                       {!!endDate && <>{moment(endDate).format("DD/MM/YYYY")}</>})
                                    </h5>

                                    <Bar
                                       data={{
                                          labels: barLabels,
                                          datasets: [
                                             {
                                                label: selectedProductId,
                                                backgroundColor: handleReturnBarGraphColorArr(
                                                   requestedYearSalesArr?.map((el) => el?.value),
                                                ),
                                                borderColor: "#2e4765",
                                                data: requestedYearSalesArr?.map((el) => el?.value),
                                             },
                                          ],
                                       }}
                                    />
                                    <h5 className="blue-1 my-4">
                                       Total Sale in ({!!startDate && <>{moment(startDate).format("DD/MM/YYYY")}</>}{" "}
                                       - {!!endDate && <>{moment(endDate).format("DD/MM/YYYY")}</>}) is{" "}
                                       {quantityFormatter.format(
                                          requestedYearSalesArr.reduce((acc, el, index) => acc + el.value, 0),
                                       )}{" "}
                                       {unitOfMeasurement}
                                    </h5>
                                 </>
                              )}
                           </DashboardChart>
                        </div>
                        <div className="col-12 col-lg-6">
                           <DashboardChart>
                              {previousYearSalesArr && previousYearSalesArr.length > 0 && (
                                 <>
                                    <h5 className="blue-1 my-4">
                                       Second Range (
                                       {!!secondStartDate && <>{moment(secondStartDate).format("DD/MM/YYYY")}</>} -{" "}
                                       {!!secondEndDate && <>{moment(secondEndDate).format("DD/MM/YYYY")}</>})
                                    </h5>
                                    <Bar
                                       data={{
                                          labels: secondBarLabels,
                                          datasets: [
                                             {
                                                label: selectedProductId,
                                                backgroundColor: handleReturnBarGraphColorArr(
                                                   previousYearSalesArr?.map((el) => el?.value),
                                                ),
                                                borderColor: "#2e4765",
                                                data: previousYearSalesArr?.map((el) => el?.value),
                                             },
                                          ],
                                       }}
                                    />
                                    <h5 className="blue-1 my-4">
                                       Total Sale in (
                                       {!!secondStartDate && <>{moment(secondStartDate).format("DD/MM/YYYY")}</>} -{" "}
                                       {!!secondEndDate && <>{moment(secondEndDate).format("DD/MM/YYYY")}</>}) is{" "}
                                       {quantityFormatter.format(
                                          previousYearSalesArr.reduce((acc, el, index) => acc + el.value, 0),
                                       )}{" "}
                                       {unitOfMeasurement}
                                    </h5>
                                 </>
                              )}
                           </DashboardChart>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </main>
   );
}
