import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { HiChevronRight } from "react-icons/hi";
import { MdOutlineAdd } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import { deleteInDemandProducts, downloadDemandExcel, getInDemandProducts } from "../../services/InDemandProducts.service";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { RiDeleteBin5Line } from "react-icons/ri";
import { AiFillEdit } from "react-icons/ai";
import { generateFilePath } from "../Utility/utils";

function IndemandProductList() {
  const userArr = useSelector((state) => state.users.users);
  const [query, setQuery] = useState("");
  const [displayUserArr, setDisplayUserArr] = useState([]);
  const [mainArr, setMainArr] = useState([]);



  const navigate = useNavigate();

  useEffect(() => {
    if (userArr) {
      setDisplayUserArr([...userArr]);
      setMainArr([...userArr]);
    }
  }, [userArr]);

  const handleEdit = (row) => {
    navigate("/IndemandProduct" + `?id=${row}`);
  };

  const handleDownloadDemandExcel = async () => {
    try {
      let { data: res } = await downloadDemandExcel({ pageIndex: 0, pageSize: 10000000 }, "");
      if (res) {
        var link = document.createElement("a");
        link.download = res.filename;
        link.href = generateFilePath(res.filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err) {
      toastError(err);
    }
  };



  const handleget = async () => {
    try {
      let { data: res } = await getInDemandProducts({ pageIndex: 0, pageSize: 10000000 }, "");
      if (res.message) {
        setDisplayUserArr(res.data);
        setMainArr(res.data);
      }
    } catch (err) {
      toastError(err);
    }
  };


  const handleDelete = async (id) => {
    try {
      if (!window.confirm("Are you sure")) {
        return
      }

      let { data: res } = await deleteInDemandProducts(id)
      if (res.message) {
        toastSuccess(res.message);
        handleget()
      }
    }
    catch (err) {
      toastError(err)
    }
  }
  useEffect(() => {
    handleget()
  }, [])


  const users_columns = [
    {
      name: "Sr No.",
      selector: (row, index) => index + 1,
      sortable: true,
    },

    {
      name: "Product Name",
      selector: (row) => `${row?.name}`,
    },
    {
      name: "Info source",
      selector: (row) => `${row?.infoSource}`,
    },
    {
      name: "Trend",
      selector: (row) => `${row?.trend}`,
    },
    {
      name: "Price",
      selector: (row) => `${row?.price}`,
    },
    {
      name: "Action",
      cell: (row) => (
        <>

          <button className="edit_buttn" onClick={() => handleEdit(row._id)}>
            <AiFillEdit />
          </button>
          <div style={{ marginLeft: 15 }}>

            <button onClick={() => handleDelete(row._id)} className="delete_buttn">
              <RiDeleteBin5Line />
            </button>
          </div>
        </>
      ),
      width: "15%",
    },
  ];






  const handleSearch = (queryValue) => {
    setQuery(queryValue);
    let tempArr = mainArr;
    tempArr = tempArr.filter(
      (el) =>
        `${el?.name}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
        `${el?.infoSource}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
        `${el?.trend}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
        `${el?.price}`.toLowerCase().includes(`${queryValue}`.toLowerCase())
    );
    setDisplayUserArr([...tempArr]);
  };



  return (
    <main className="p-0">
      <section className="product-category">
        <div className="user_header">
          <span className="page_name">Associated Product</span>
          {/* <p className="breadcrumb">
            <span className="parent">Dashboard </span>{" "}
            <span>
              {" "}
              <HiChevronRight />
            </span>{" "}
            <span className="child">Associated Products List</span>
          </p> */}
        </div>

        <DashboardTable>
          <div className="row align-items-center border_with_color mx-0">
            <div className="col-xl-10">
              <div className="user_header_left_text">
                <h6>Associated Product</h6>

                <span>Associated Product</span>
              </div>
            </div>
            <div className="col-xl-2">
              <div className="user_header_right">
                {/* <select>
                  <option value="">All (56)</option>
                  <option value="ssl">SSL</option>
                  <option value="tls">TLS</option>
                </select> */}

                <button onClick={() => navigate("/IndemandProduct")} className="">
                  Add <MdOutlineAdd />
                </button>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-0 border_with_color_1">
            {/* <h5 className="blue-1 m-0">All Customers</h5> */}
            <button onClick={handleDownloadDemandExcel} className="copy_excel_buttn">Copy to Excel</button>
            <div className="d-flex align-items-center gap-3">
              <SearchBox
                query={query}
                setQuery={handleSearch}
                extraClass="bg-white"
              />
            </div>
          </div>

          <DataTable columns={users_columns} data={displayUserArr} pagination />
        </DashboardTable>
      </section>

    </main>
  );
}

export default IndemandProductList;
