import React, { useEffect, useState } from 'react'
import { HiChevronRight } from 'react-icons/hi'
import { CiLock } from 'react-icons/ci'
import { addLocation, getById, updateLocation } from '../../services/location.service';
import { useSearchParams } from 'react-router-dom';
import { toastError, toastSuccess } from '../Utility/ToastUtils';

function LocationPage() {

  const [searchParams, setSearchParams] = useSearchParams();

  const [name, setName] = useState("");
  const [longitude, setLongitude] = useState("");
  const [contact, setContact] = useState("");
  const [latitude, setLatitude] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");



  const handleSubmit = async () => {
    try {

      if (name == "") {
        toastError("Name is mandatory !!!");
        return
      }

      if (longitude == "") {
        toastError("Longitude is mandatory !!!");
        return
      }


      if (latitude == "") {
        toastError("Latitude is mandatory !!!");
        return
      }
      if (contact == "") {
        toastError("Contact is mandatory !!!");
        return
      }
      if (address == "") {
        toastError("Address is mandatory !!!");
        return
      }
      if (description == "") {
        toastError("Description is mandatory !!!");
        return
      }


      let obj = {
        name,
        longitude,
        contact,
        latitude,
        address,
        description,
      }
      if (searchParams.get("id")) {
        let { data: res } = await updateLocation(obj, searchParams.get("id"))
        if (res.message) {
          toastSuccess(res.message)
        }
      }

      else {
        let { data: res } = await addLocation(obj)
        if (res.message) {
          toastSuccess(res.message)
        }
      }
    }
    catch (err) {
      toastError(err)
    }
  }




  const handleGetForUpdate = async () => {
    try {
      if (typeof searchParams?.get("id") != 'string')
        return;
      const res = await getById(searchParams?.get("id"));
      if (res.data.data) {
        console.log(res.data, "data")

        if (res.data?.data?.name) {
          setName(res.data?.data?.name)
        }
        if (res.data?.data?.longitude) {
          setLongitude(res.data?.data?.longitude)
        }
        if (res.data?.data?.latitude) {
          setLatitude(res.data?.data?.latitude)
        }
        if (res.data?.data?.contact) {
          setContact(res.data?.data?.contact)
        }
        if (res.data?.data?.address) {
          setAddress(res.data?.data?.address)
        }
        if (res.data?.data?.description) {
          setDescription(res.data?.data?.description)
        }

      }

    } catch (error) {
      toastError(error)
    }

  }
  useEffect(() => {
    if (searchParams?.get("id")) {
      handleGetForUpdate()
    }

  }, [searchParams.get("id")])




  return (
    <main className='bg-white'>
      <section className="user_page_sec">
        <div className="user_header">
          <span className='page_name'>Location</span>
          {/* <p className="breadcrumb">
            <span className='parent'>Dashboard </span> <span> <HiChevronRight /></span> <span className='child'>Location</span>
          </p> */}
        </div>

        <div className="user_form_box">
          <div className="form_header">
            <h6 className="heading">Location</h6>
            <span className='sub_heading'>Add Location</span>
          </div>

          <div className="form_box">
            <div className="row">
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">User Name*</p>
                  <input onChange={(e) => setName(e.target.value)} value={name} type="text" placeholder='Enter Name' />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Longtitude*</p>
                  <input onChange={(e) => setLongitude(e.target.value)} value={longitude} type="text" placeholder='Enter Longtitude' />
                </div>
              </div>

            </div>
            <div className="row">
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Latitude*</p>
                  <input onChange={(e) => setLatitude(e.target.value)} value={latitude} type="text" placeholder='Latitude' />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Contact*</p>
                  <input onChange={(e) => setContact(e.target.value)} value={contact} type="text" placeholder='Enter Contact' />
                  <CiLock className='lock_icn' />
                </div>
              </div>

            </div>
            <div className="row">
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Address*</p>
                  <input onChange={(e) => setAddress(e.target.value)} value={address} type="text" placeholder='Addresss' />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Description*</p>
                  <input onChange={(e) => setDescription(e.target.value)} value={description} type="text" placeholder='Description' />
                  {/* <CiLock className='lock_icn'/> */}
                </div>
              </div>

            </div>




            <div className="row">

              <button onClick={() => handleSubmit()} className="submit_buttn">Submit</button>
            </div>



          </div>




        </div>
      </section>



    </main>
  )
}

export default LocationPage