import Switch from '@mui/material/Switch';
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill"; // ES6
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getAllNestedCategories } from "../../../redux/actions/Category/Category.actions";
import { PRODUCTAdd, PRODUCTGet, PRODUCTUpdate } from "../../../redux/actions/Product/Product.actions";
import CustomButton from "../../Utility/Button";
import { DashboardBox } from "../../Utility/DashboardBox";
import FileUpload from "../../Utility/FileUpload";
import { toastError } from "../../Utility/ToastUtils";
import { generateFilePath } from "../../Utility/utils";

function GeneralProduct() {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [sku, setSku] = useState("");
  const [mainCategoryArr, setMainCategoryArr] = useState([]);
  const [internalModelNumber, setItnernalModelNumber] = useState("");

  const [modelNumber, setModelNumber] = useState("");
  const [tags, setTags] = useState("");
  const [mainAttributesArr, setMainAttributesArr] = useState([]);
  const [internalDescription, setInternalDescription] = useState("");
  const [stock, setStock] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);
  const [gst, setgst] = useState("");
  const [selectedDiscountType, setSelectedDiscountType] = useState("");
  const [despcription, setdespcription] = useState("");
  const [specifications, setSpecifications] = useState("");
  const [hasUsCurrency, setHasUsCurrency] = useState(false);
  const [productImageStr, setProductImageStr] = useState("");
  const [price, setPrice] = useState(0);
  const [priceInUsd, setPriceInUsd] = useState(0);
  const [imageArr, setImageArr] = useState([{ image: "", imageAlt: "" }]);
  const [variantsArr, setVariantsArr] = useState([{ image: "", brandObj: {}, brandId: "", price: 0 }]);
  const [selectedCategoryArr, setSelectedCategoryArr] = useState([]);
  const [productCategoryId, setProductCategoryId] = useState("");
  const [productCategoryObj, setProductCategoryObj] = useState([]);
  const [displayAttributesArr, setDisplayAttributesArr] = useState([]);
  const [modelNumberArr, setModelNumberArr] = useState([{ modelNumber: "" }]);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaImage, setMetaImage] = useState("");
  const [height, setHeight] = useState(0);
  const [od_top, setOd_top] = useState(0);
  const [od_bottom, setOd_bottom] = useState(0);
  const [id_top, setId_top] = useState(0);
  const [id_bottom, setId_bottom] = useState(0);
  const [productDescription, setProductDescription] = useState("");
  const [complaintsAndFeedBack, setComplaintsAndFeedBack] = useState("");
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [globalProductsArr, setGlobalProductsArr] = useState([]);



  const [noOfHoles, setNoOfHoles] = useState(0);
  const [capHeight, setCapHeight] = useState(0);
  const [pcd, setPcd] = useState(0);
  const [threadSize, setThreadSize] = useState("");
  const [topORingSize, setTopORingSize] = useState("");
  const [bottomORingSize, setBottomORingSize] = useState("");
  const [stepId, setStepId] = useState(0);


  //////new Fields starts here
  const [step_Od, setStep_Od] = useState("");
  const [capToCapHeight, setCapToCapHeight] = useState("");
  const [typeOfEndCap, setTypeOfEndCap] = useState("");
  const [holeDieya, setHoleDieya] = useState("");
  const [noOfOddHole, setNoOfOddHole] = useState("");
  const [maleThread, setMaleThread] = useState("");
  const [femaleThread, setFemaleThread] = useState("");
  const [reverseThread, setReverseThread] = useState("");
  const [media, setMedia] = useState("");
  //////new Fields ends here



  const [recoCode, setRecoCode] = useState("");




  const [mrp, setMrp] = useState(0);

  const brands = useSelector((state) => state.brand.brands);
  const categoryArr = useSelector((state) => state.category.categories);
  const productObj = useSelector((state) => state.product.productObj);
  const productsArr = useSelector((state) => state.product.products);
  const attributes = useSelector((state) => state.attribute.attributes);
  const productCategoryArr = useSelector((state) => state.productCategory.productCategories);

  useEffect(() => {
    dispatch(getAllNestedCategories());
    dispatch(PRODUCTGet());
    // dispatch(ATTRIBUTEGet());
  }, []);

  useEffect(() => {
    if (categoryArr && categoryArr.length > 0) {
      setMainCategoryArr([...categoryArr]);
    }
  }, [categoryArr]);

  useEffect(() => {
    if (productsArr && productsArr.length > 0) {
      let tempArr = productsArr
      if (productObj && productObj?._id) {
        tempArr = tempArr.filter(el => el._id != productObj?._id)
      }
      setGlobalProductsArr([...tempArr]);
    }
  }, [productsArr]);

  const handleBrandSelection = (obj, index) => {
    console.log(obj);
    let tempArr = variantsArr
    tempArr[index].brandId = obj.value
    tempArr[index].brandObj = obj


    console.log(tempArr, "tempArr")
    setVariantsArr([...tempArr])
  };


  const handleFileSet = (value, index) => {

    let tempArr = imageArr;


    tempArr[index].image = value
    setImageArr([...tempArr]);
  };
  const handleVariantPriceSet = (value, index) => {

    let tempArr = variantsArr;


    tempArr[index].price = value
    setVariantsArr([...tempArr]);
  };

  useEffect(() => {
    if (categoryArr && categoryArr.length > 0) {
      setMainCategoryArr([...categoryArr]);
    }
  }, [categoryArr]);

  useEffect(() => {
    if (attributes && attributes.length > 0) {
      setMainAttributesArr([...attributes]);
    }
  }, [attributes]);

  const returnSelectedCategories = (arr) => {
    let new_selected_arr = arr.filter(el => el.checked)
    let subCategories = arr.reduce((acc, el) => [...acc, ...el.subCategoryArr.filter(el => el.checked)], [])
    if (subCategories?.length) {
      return [...new_selected_arr, ...returnSelectedCategories(subCategories)]
    }
    else {
      return [...new_selected_arr]
    }
  }

  const handleRenderNestedCategory = (arr, id, value) => {
    let tempArr = arr.map(el => {
      if (el._id == id) {
        el.checked = value
        return el
      }
      else {
        if (el.subCategoryArr && el.subCategoryArr.length > 0) {
          handleRenderNestedCategory(el.subCategoryArr, id, value)
        }
        else {
          return el
        }
      }
    })
    return tempArr;
  }




  const handleNestedCategoryCheckBoxEvent = (id, value) => {
    let tempCategoryArr = categoryArr.map(el => {
      if (el._id == id) {
        el.checked = value
        return el
      }
      else {
        if (el.subCategoryArr && el.subCategoryArr.length > 0) {
          el.subAttributesArr = handleRenderNestedCategory(el.subCategoryArr, id, value)
          return el
        }
        else {
          return el
        }
      }
    });
    setMainCategoryArr([...tempCategoryArr])
  }


  const handleRenderCheckboxCategory = (obj) => {
    return (
      <div className="col-12 mb-3" key={obj._id} style={{ marginLeft: `${obj.level + 5}px` }}>
        <input className="form-check-input pointer" checked={obj.checked} value={tags} onChange={(event) => handleNestedCategoryCheckBoxEvent(obj._id, event.target.checked)} type="checkbox" />
        <label style={{ paddingLeft: 5 }}>
          {obj.name}
        </label>
        {
          obj.checked && obj.subCategoryArr && obj.subCategoryArr.length > 0 && obj.subCategoryArr.map((el) => {
            return (
              handleRenderCheckboxCategory(el)
            )
          })
        }
      </div>
    )
  }







  const handleVariantFileSet = (value, index) => {
    console.log(value, "value")
    let tempArr = variantsArr;
    tempArr[index].image = value
    setVariantsArr([...tempArr]);
  };

  const handleFileUpload = (value) => {
    setMetaImage(value)
  }

  const handleSubmit = () => {
    if (name == "") {
      toastError("Name is mandatory")
      return
    }

    else if (price == 0) {
      toastError("Price For Retailer is mandatory")
      return
    }

    else if (priceInUsd == 0 && hasUsCurrency) {
      toastError("USD Price For Retailer is mandatory")
      return
    }
    else if (despcription == "") {
      toastError("Description is mandatory")
      return
    }
    else if (specifications == "") {
      toastError("Specification is mandatory")
      return
    }
    else if (internalDescription == "") {
      toastError("Internal Description is mandatory")
      return
    }
    else if (internalModelNumber == "") {
      toastError("Internal Model Number is mandatory")
      return
    }
    else if (productCategoryId == "") {
      toastError("Product Category is mandatory")
      return
    }
    else if (!(modelNumberArr && modelNumberArr.length > 0)) {
      toastError("Model Number is mandatory")
      return
    }
    else if ((modelNumberArr && modelNumberArr.some(el => el.modelNumber == ""))) {
      toastError("Model Number cannot be empty")
      return
    }

    let cat_arr = returnSelectedCategories(mainCategoryArr);

    let tempCategoryArr = handleFilterChecked(mainCategoryArr)
    // let tempCategoryArr = handleFilterChecked(mainCategoryArr)
    console.log(selectedCategoryArr, "selectedCategoryArr")
    // console.log(selectedCategoryArr, "selectedCategoryArr")
    let obj = {
      categoryArr: cat_arr.map(el => { return { categoryId: el._id } }),
      categoryId: cat_arr[cat_arr.length - 1]._id,
      name,
      modelNumber,
      description: despcription,
      specification: specifications,
      productImageStr,
      sku,
      tags,
      metaTitle,
      metaDescription,
      metaImage,
      height,
      od_top,
      od_bottom,
      id_top,
      id_bottom,
      modelNumberArr,
      internalDescription,
      noOfHoles,
      capHeight,
      recoCode,
      pcd,
      threadSize,
      topORingSize,
      step_Od,
      capToCapHeight,
      typeOfEndCap,
      holeDieya,
      noOfOddHole,
      maleThread,
      femaleThread,
      reverseThread,
      media,
      bottomORingSize,
      stepId,
      internalModelNumber,
      productCategoryId,
      price: parseInt(price),
      priceInUsd: parseInt(priceInUsd),
      hasUsdOption: hasUsCurrency,
      imageArr,
      complaintsAndFeedBack,
      variantsArr,
      productDescription,
      relatedProductsArr: relatedProducts.map(el => ({ productId: el._id }))
    };
    // if (displayAttributesArr && displayAttributesArr.length > 0) {
    //   obj.attributesArr = displayAttributesArr
    // }
    // else {
    obj.mrp = parseInt(mrp);
    obj.stock = parseInt(stock)
    // }

    if (productObj) {
      dispatch(PRODUCTUpdate(obj, productObj._id));
    }
    else {
      dispatch(PRODUCTAdd(obj));
    }
  };

  useEffect(() => {
    if (productObj && productObj?._id) {
      setName(productObj?.name);
      setModelNumber(productObj?.modelNumber);
      setSku(productObj?.sku);
      setdespcription(productObj?.description);
      setSpecifications(productObj?.specification);
      setMetaTitle(productObj?.metaTitle);
      setMetaDescription(productObj?.metaDescription);
      setMetaImage(productObj?.metaImage);
      setPrice(productObj?.price);
      setTags(productObj?.tags);
      setPriceInUsd(productObj?.priceInUsdForRetailer);
      setHasUsCurrency(productObj?.hasUsdOption);
      setRecoCode(productObj?.recoCode);
      setImageArr(productObj?.imageArr);
      setVariantsArr(productObj?.variantsArr)
      setDiscountValue(productObj?.discountValue);
      setModelNumberArr(productObj.modelNumberArr ? productObj.modelNumberArr : [{ modelNumber: "" }]);
      // console.log(productObj, "productObj")
      // console.log(productObj.modelNumberArr)
      // console.log(productObj.modelNumberArr ? productObj.modelNumberArr : [{ modelNumber: "" }], 'productObj.modelNumberArr ? productObj.modelNumberArr : [{ modelNumber: "" }]')
      setHeight(productObj?.height)
      setOd_top(productObj?.od_top)
      setOd_bottom(productObj?.od_bottom)
      setId_top(productObj?.id_top)
      setId_bottom(productObj?.id_bottom)
      setStep_Od(productObj?.step_Od)
      setCapToCapHeight(productObj?.capToCapHeight)
      setTypeOfEndCap(productObj?.typeOfEndCap)
      setHoleDieya(productObj?.holeDieya)
      setNoOfOddHole(productObj?.noOfOddHole)
      setMaleThread(productObj?.maleThread)
      setFemaleThread(productObj?.femaleThread)
      setReverseThread(productObj?.reverseThread)
      setMedia(productObj?.media)
      setProductCategoryId(productObj?.productCategoryId);
      setProductCategoryObj(productObj?.productCategoryObj);
      setInternalDescription(productObj?.internalDescription);
      setItnernalModelNumber(productObj?.internalModelNumber);
      setNoOfHoles(productObj?.noOfHoles)
      setCapHeight(productObj?.capHeight)
      setPcd(productObj?.pcd)
      setThreadSize(productObj?.threadSize)
      setTopORingSize(productObj?.topORingSize)
      setBottomORingSize(productObj?.bottomORingSize)
      setStepId(productObj?.stepId)
      setComplaintsAndFeedBack(productObj?.complaintsAndFeedBack)
      setProductDescription(productObj?.productDescription)
      {
        productObj?.relatedProductsArr && productObj?.relatedProductsArr.length > 0 &&
          setRelatedProducts(productObj?.relatedProductsArr.map(el => ({ ...el, label: el.name, value: el._id })))
      }
    }
  }, [productObj]);




  // useEffect(() => {
  //   if (attributes && attributes.length > 0 && productObj && productObj.attributesArr && productObj.attributesArr.length > 0) {
  //     let temp = handleAttributesArrSelectionOnInit(attributes, productObj?.attributesArr);
  //     // setDisplayAttributesArr([...temp])
  //     setDisplayAttributesArr(productObj.attributesArr);
  //     console.log(temp, "temp");
  //   }
  // }, [productObj, attributes]);
  // const handleAttributesArrSelectionOnInit = (existingAttributesArr, attributesArrFromDb) => {
  //   for (const el of existingAttributesArr) {
  //     if (el.attributeValueArr && el.attributeValueArr.length > 0) {
  //       for (const ele of el.attributeValueArr) {
  //         if (attributesArrFromDb && attributesArrFromDb?.some((elx) => elx?.attributeValueArr?.some((elm) => elm.attributeId == ele._id))) {
  //           ele.checked = true;
  //           el.checked = true;
  //         }
  //       }
  //     }
  //   }

  //   return existingAttributesArr;
  // };

  const handleCategorySelectOnInit = (selectedCategoryArrFromDB, categoryArr) => {
    let tempArr = categoryArr.map((el) => {
      if (selectedCategoryArrFromDB?.some((ele) => ele.categoryId == el._id)) {
        el.checked = true;
      }
      if (el.subCategoryArr) {
        handleCategorySelectOnInit(selectedCategoryArrFromDB, el.subCategoryArr);
      }
      return el;
    });
  };
  useEffect(() => {
    if (productObj && categoryArr) {
      handleCategorySelectOnInit(productObj.categoryArr, categoryArr);
    }
  }, [categoryArr, productObj]);




  useEffect(() => {
    if (productObj && categoryArr) {
      handleCategorySelectOnInit(productObj.categoryArr, categoryArr);
    }
  }, [categoryArr, productObj]);


  const handleImageObjAdd = () => {
    let tempArr = imageArr
    tempArr.push({ image: "", imageAlt: "" })
    console.log(tempArr, "asdas")
    setImageArr([...tempArr])
  }
  const handleImageObjRemove = () => {
    if (imageArr.length > 1) {
      let tempArr = imageArr.filter((el, index) => index != imageArr.length - 1);
      setImageArr([...tempArr])
    }
  }

  const handleModelNumberObjAdd = () => {
    let tempArr = modelNumberArr
    tempArr.push({ modelNumber: "" })
    console.log(tempArr, "asdas")
    setModelNumberArr([...tempArr])
  }
  const handleModelNumberObjRemove = () => {
    if (modelNumberArr.length > 1) {
      let tempArr = modelNumberArr.filter((el, index) => index != modelNumberArr.length - 1);
      setModelNumberArr([...tempArr])
    }
  }



  const handleVariantObjAdd = () => {
    let tempArr = variantsArr
    tempArr.push({ image: "", brandObj: {}, brandId: "", price: 0 })
    console.log(tempArr, "asdas")
    setVariantsArr([...tempArr])
  }
  const handleVariantObjRemove = () => {
    if (variantsArr.length > 1) {
      let tempArr = variantsArr.filter((el, index) => index != variantsArr.length - 1);
      setVariantsArr([...tempArr])
    }
  }
  ////////////p n c generator
  const generateCombinations = (tempattributeArr) => {
    const combine = ([head, ...[headTail, ...tailTail]]) => {
      // https://stackoverflow.com/a/57015870
      if (!headTail) {
        return head?.map((el) => ({
          name: el.name,
          attributeValueArr: [...el.attributeValueArr],
        }))
      }
      const combined = headTail?.reduce((acc, x) => {
        return acc.concat(
          head.map((h) => ({
            name: `${h.name}-${x.name}`,
            attributeValueArr: [...h.attributeValueArr, ...x.attributeValueArr],
            price: 0,
          }))
        )
      }, [])
      return combine([combined, ...tailTail])
    }
    let finalArr = []
    tempattributeArr.forEach((el) => {
      if (el.checked) {
        let tempArr = el.attributeValueArr.filter((elx) => elx.checked)
        if (tempArr.length) {
          finalArr.push(
            tempArr.map((el) => ({
              name: el.name,
              attributeValueArr: [
                {
                  attributeId: el._id,
                },
              ],
              price: 0,
            }))
          )
        }
      }
    })
    if (finalArr.length) {
      finalArr = combine(finalArr)
      // setCombinationArr([...finalArr])
    } else {
      setDisplayAttributesArr([])
      // setCombinationArr = []
    }

    // finalArr
    if (finalArr.length) {
      // console.log(el)
      setDisplayAttributesArr([
        ...finalArr.map((el) => ({
          ...el,
          price: 0,
          discount: 0,
          currentStock: 0,
          stockNo: 0,
        })),
      ])
    }
  }


  const handleDiscountTypeChange = (obj) => {
    setSelectedDiscountType(obj.value);
  };

  const handleModelNumberEntry = (value, index) => {
    console.log(value, index)
    let tempArr = modelNumberArr;
    tempArr[index].modelNumber = value
    setModelNumberArr([...tempArr]);
  };
  const handleproductImageAltEntry = (value, index) => {
    console.log(value, index)
    let tempArr = imageArr;
    tempArr[index].imageAlt = value
    setImageArr([...tempArr]);
  };

  const handleFilterChecked = (arr) => {
    if (arr.length > 0 && arr) {
      return arr.map(el => {
        if (el.subCategoryArr && el.subCategoryArr.length > 0 && el.checked) {
          let tempArr = selectedCategoryArr;
          if (tempArr.some(el => el != el._id)) {
            tempArr.push(el._id)
          }
          setSelectedCategoryArr([...tempArr])
          return { ...el, categoryId: el._id, subCategoryArr: handleFilterChecked(el.subCategoryArr) };
        }
        else {
          if (el.checked) {
            let tempArr = selectedCategoryArr;
            if (tempArr.some(el => el != el._id)) {
              tempArr.push(el._id)
            }
            setSelectedCategoryArr([...tempArr])
            return { ...el, categoryId: el._id }
          }
        }
      })
    }
    else {
      return arr
    }
  }




  return (
    <form className="form">
      <div className="row">
        <div className="col-12 col-md-8">
          <DashboardBox>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Product Information</h5>

              <div className="col-12 col-md-6 mb-3">
                <label>
                  Name <span className="red">*</span>
                </label>
                <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>
                  PRODUCT SKU <span className="red">*</span>
                </label>
                <input value={sku} onChange={(event) => setSku(event.target.value)} type="text" className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>
                  PRODUCT DESCRIPTION<span className="red">*</span>
                </label>
                <input value={productDescription} onChange={(event) => setProductDescription(event.target.value)} type="text" className="form-control" />
              </div>
              <div className="row d-flex">

                <h5 className="blue-1 mb-3">OEM PART NUMBER</h5>
                <div className="row">
                  <div className="col-2 me-5">
                    <CustomButton btntype="button" ClickEvent={handleModelNumberObjAdd} isBtn noIcon btnName="+" />
                  </div>
                  <div className="col-2">
                    <CustomButton btntype="button" ClickEvent={handleModelNumberObjRemove} isBtn noIcon btnName="-" />
                  </div>
                </div>
              </div>
              {
                modelNumberArr && modelNumberArr.length > 0 && modelNumberArr.map((el, index) => {
                  return (
                    <div key={index} className="col-12 col-md-6 mb-3">
                      <label>OEM PART NUMBER ({index + 1})</label>

                      <input type="text" value={el.modelNumber} onChange={(event) => handleModelNumberEntry(event.target.value, index)} className="form-control" />
                    </div>
                  )
                })
              }
            </div>
            <div className="row d-flex">
              <div className="col-12 col-md-6 mb-3">
                <label>INTERNAL MODEL NUMBER</label>
                <input type="text" value={internalModelNumber} onChange={(event) => setItnernalModelNumber(event.target.value)} className="form-control" />
              </div>





              <div className="col-12 col-md-6 mb-3">
                <label>COMPLETE HEIGHT</label>
                <input type="number" value={height} onChange={(event) => setHeight(event.target.value)} className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>OD TOP</label>
                <input type="number" value={od_top} onChange={(event) => setOd_top(event.target.value)} className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Height (cap to cap)</label>
                <input type="number" value={capHeight} onChange={(event) => setCapHeight(event.target.value)} className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>No Of Holes</label>
                <input type="number" value={noOfHoles} onChange={(event) => setNoOfHoles(event.target.value)} className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Thread Size</label>
                <input type="text" value={threadSize} onChange={(event) => setThreadSize(event.target.value)} className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Pcd in MM</label>
                <input type="number" value={pcd} onChange={(event) => setPcd(event.target.value)} className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Top O Ring Size</label>
                <input type="text" value={topORingSize} onChange={(event) => setTopORingSize(event.target.value)} className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Bottom O Ring Size</label>
                <input type="text" value={bottomORingSize} onChange={(event) => setBottomORingSize(event.target.value)} className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Step Id</label>
                <input type="text" value={stepId} onChange={(event) => setStepId(event.target.value)} className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Step Od</label>
                <input type="text" value={step_Od} onChange={(event) => setStep_Od(event.target.value)} className="form-control" />
              </div>

              <div className="col-12 col-md-6 mb-3">
                <label>OD BOTTOM</label>
                <input type="number" value={od_bottom} onChange={(event) => setOd_bottom(event.target.value)} className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>ID TOP</label>
                <input type="number" value={id_top} onChange={(event) => setId_top(event.target.value)} className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>ID BOTTOM</label>
                <input type="text" value={id_bottom} onChange={(event) => setId_bottom(event.target.value)} className="form-control" />
              </div>


              <div className="col-12 col-md-6 mb-3">
                <label>Reverse Thread</label>
                <input type="text" value={reverseThread} onChange={(event) => setReverseThread(event.target.value)} className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Male Thread</label>
                <input type="text" value={maleThread} onChange={(event) => setMaleThread(event.target.value)} className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Female Thread</label>
                <input type="text" value={femaleThread} onChange={(event) => setFemaleThread(event.target.value)} className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>No Of Odd Hole</label>
                <input type="text" value={noOfOddHole} onChange={(event) => setNoOfOddHole(event.target.value)} className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Hole Dieya</label>
                <input type="text" value={holeDieya} onChange={(event) => setHoleDieya(event.target.value)} className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Type Of End Cap</label>
                <input type="text" value={typeOfEndCap} onChange={(event) => setTypeOfEndCap(event.target.value)} className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Cap To Cap Height</label>
                <input type="text" value={capToCapHeight} onChange={(event) => setCapToCapHeight(event.target.value)} className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Media</label>
                <input type="text" value={media} onChange={(event) => setMedia(event.target.value)} className="form-control" />
              </div>


              <div className="col-12 col-md-6 mb-3">
                <label>PRODUCT CATEGORY</label>
                <Select onChange={(value) => { setProductCategoryId(value.value); setProductCategoryObj(value) }} defaultValue={productCategoryId} value={productCategoryObj} options={productCategoryArr && productCategoryArr.length > 0 && productCategoryArr.map((ele) => ({ ...ele, label: ele.name, value: ele._id }))} />
              </div>


              <div className="col-12 mb-3">
                <div className="row d-flex">

                  <h5 className="blue-1 mb-3">Product Variants Info</h5>
                  <div className="row">
                    <div className="col-2 me-5">
                      <CustomButton btntype="button" ClickEvent={handleVariantObjAdd} isBtn noIcon btnName="+" />
                    </div>
                    <div className="col-2">
                      <CustomButton btntype="button" ClickEvent={handleVariantObjRemove} isBtn noIcon btnName="-" />
                    </div>
                  </div>
                </div>
                {variantsArr && variantsArr.length > 0 && variantsArr.map((el, index) => {
                  return (
                    <div key={index} style={{ marginTop: 20, border: "grey 1px solid", borderRadius: 10, paddingBottom: 15 }} className="row">
                      <div className="col-12">
                        <label>
                          Product variant  {index + 1} Image<span className="red">*</span>
                        </label>
                        {
                          el.image != "" &&
                          <>
                            <br />
                            <br />
                            <img src={`${el.image}`.includes("base64") ? el.image : generateFilePath(el.image)} style={{ height: 80 }} />
                            <br />
                            <br />
                          </>
                        }
                        <FileUpload onFileChange={(val) => handleVariantFileSet(val, index)} />
                      </div>
                      <div className="col-12">
                        <label>
                          Product Price <span className="red">*</span>
                        </label>
                        <input onChange={(e) => handleVariantPriceSet(parseInt(e.target.value), index)} value={`${el?.price}`} type="number" className="form-control" />
                      </div>
                      <Select onChange={(value) => handleBrandSelection(value, index)} defaultValue={el.brandId} value={el.brandObj} options={brands && brands.length > 0 && brands.map((ele) => ({ ...ele, label: ele.name, value: ele._id }))} />


                    </div>
                  )
                })}


              </div>



              {/* <div className="col-12 col-md-3 mb-3">
                <label>
                  Attributes<span className="red">*</span>
                </label>
                {mainAttributesArr &&
                  mainAttributesArr.length > 0 &&
                  mainAttributesArr.map((el, index) => {
                    return (
                      <div
                        key={el._id}
                      >
                        <input checked={el.checked} onChange={(event) => handleAttributeVariantChange(index)} className="form-check-input pointer" type="checkbox" />
                        <label style={{ paddingLeft: 5 }}>
                          {el.name}
                        </label>
                        {el.checked && (
                          <>
                            {el.attributeValueArr.length > 0 &&
                              el.attributeValueArr.map(
                                (ele, index2) => {
                                  return (
                                    <div key={index2} style={{ marginLeft: 20 }}>
                                      <input checked={ele.checked} onChange={(event) => handleInnerAttributeVariantChange(index, index2)} className="form-check-input pointer" type="checkbox" />
                                      <label style={{ paddingLeft: 5 }}>
                                        {ele.label}
                                      </label>
                                    </div>
                                  )
                                }
                              )}
                          </>
                        )}
                      </div>
                    )
                  })}
              </div> */}

              {/* <div className="border-bottom pb-3 mb-4 row">
                <h5 className="blue-1 mb-4">Tags (Comma Separated) <span className="red">*</span> </h5>
                <div className="col-12 mb-3">
                  <input value={tags} onChange={(event) => setTags(event.target.value)} type="text" className="form-control" />
                </div>
              </div> */}
              <div className="border-bottom pb-3 mb-4 row">
                <h5 className="blue-1 mb-4">Main Category <span className="red">*</span></h5>
                {
                  mainCategoryArr && mainCategoryArr.length > 0 && mainCategoryArr.map(el => {
                    return (
                      handleRenderCheckboxCategory(el)
                    )
                  })
                }
              </div>

              <div className="border-bottom pb-3 mb-4 row">
                <h5 className="blue-1 mb-4">Pair Product<span className="red">*</span></h5>
                <Select onChange={(value) => setRelatedProducts(value)} value={relatedProducts} isMulti options={globalProductsArr && globalProductsArr.length > 0 && globalProductsArr.map((ele) => ({ ...ele, label: ele.name, value: ele._id }))} />

              </div>




            </div>






            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Price Info</h5>
              <div className="blue-1 mb-4">
                <Switch onChange={() => setHasUsCurrency(prev => !prev)} checked={hasUsCurrency} />
                <label>
                  has USD as option <span className="red">*</span>
                </label>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>
                  PRICE <span className="red">*</span>
                </label>
                <input type="number" min={0} value={price} onChange={(event) => setPrice(event.target.value)} className="form-control" />
              </div>
              {
                hasUsCurrency &&
                <>
                  <div className="col-12 col-md-6 mb-3">
                    <label>
                      PRICE IN USD<span className="red">*</span>
                    </label>
                    <input type="number" min={0} value={priceInUsd} onChange={(event) => setPriceInUsd(event.target.value)} className="form-control" />
                  </div>
                </>
              }

            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Description  (App)</h5>
              <div className="col-12 mb-3">
                <ReactQuill value={despcription != "" && despcription ? despcription : ""} theme="snow" onChange={(e) => setdespcription(e)} />
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Internal Specifications (Internal)</h5>
              <div className="col-12 mb-3">
                <ReactQuill value={internalDescription != "" && internalDescription ? internalDescription : ""} theme="snow" onChange={(e) => setInternalDescription(e)} />
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Procurement Details (Internal)</h5>
              <div className="col-12 mb-3">
                <ReactQuill value={specifications != "" && specifications ? specifications : ""} onChange={(e) => setSpecifications(e)} />
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Complaints and feedbacks (Internal)</h5>
              <div className="col-12 mb-3">
                <ReactQuill value={complaintsAndFeedBack != "" && complaintsAndFeedBack ? complaintsAndFeedBack : ""} onChange={(e) => setComplaintsAndFeedBack(e)} />
              </div>
            </div>
            <div className="row">
              <h5 className="blue-1 mb-4">SEO info</h5>
              <div className="col-12 mb-3">
                <label>META TITLE</label>
                <input onChange={(e) => setMetaTitle(e.target.value)} value={metaTitle} type="text" className="form-control" />
              </div>
              <div className="col-12 mb-3">
                <label>META DESCRIPTION</label>
                <textarea onChange={(e) => setMetaDescription(e.target.value)} value={metaDescription} name="META DESCRIPTION" className="form-control" rows="3"></textarea>
              </div>
              <div className="col-12 mb-3">
                <label>META IMAGE (300X300)PX</label>

                {
                  metaImage != "" &&
                  <>
                    <br />
                    <br />
                    <img src={`${metaImage}`.includes("base64") ? metaImage : generateFilePath(metaImage)} style={{ height: 80 }} />
                    <br />
                    <br />
                  </>
                }

                <FileUpload onFileChange={handleFileUpload} />
              </div>
              <div className="col-12">
                <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
              </div>
            </div>
          </DashboardBox>
        </div>
        <div className="col-12 col-md-4">
          <DashboardBox>
            <div className="col-12 mb-3">
              <div className="row d-flex">

                <h5 className="blue-1 mb-3">Product Image Info</h5>
                <div className="row">
                  <div className="col-2 me-5">
                    <CustomButton btntype="button" ClickEvent={handleImageObjAdd} isBtn noIcon btnName="+" />
                  </div>
                  <div className="col-2">
                    <CustomButton btntype="button" ClickEvent={handleImageObjRemove} isBtn noIcon btnName="-" />
                  </div>
                </div>
              </div>
              {imageArr && imageArr.length > 0 && imageArr.map((el, index) => {
                return (
                  <div key={index} style={{ marginTop: 20, borderBottom: "grey 1px solid", paddingBottom: 15 }} className="row">
                    <div className="col-12">
                      <label>
                        Product Image<span className="red">*</span>
                      </label>
                      {
                        el.image != "" &&
                        <>
                          <br />
                          <br />
                          <img src={`${el.image}`.includes("base64") ? el.image : generateFilePath(el.image)} style={{ height: 80 }} />
                          <br />
                          <br />
                        </>
                      }


                      <FileUpload onFileChange={(val) => handleFileSet(val, index)} />
                    </div>
                    <div className="col-12">
                      <label>
                        Product Image alt<span className="red">*</span>
                      </label>
                      <input onChange={(e) => handleproductImageAltEntry(e.target.value, index)} value={el?.imageAlt} type="text" className="form-control" />
                    </div>
                  </div>
                )
              })}


            </div>

            {/* <div className="border-bottom pb-3 mb-4 row">
                <h5 className="blue-1 mb-3">Product Video Info</h5>
                <div className="col-12 mb-3">
                  <label>VIDEO PROVIDER</label>
                  <Select options={options} />
                </div>
                <div className="col-12 mb-3">
                  <label>VIDEO LINK</label>
                  <input type="url" className="form-control" />
                </div>
              </div> */}

          </DashboardBox>
        </div>
      </div >
    </form >
  );
}

export default GeneralProduct;
