import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { HiChevronRight } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  usersGet
} from "../../redux/actions/Users/users.actions";
import {
  StatusChange,
  updateUserCategory
} from "../../services/users.service";
import { rolesObj } from "../../utils/roles";
import { toastError } from "../../utils/toastUtils";
import CustomButton from "../Utility/Button";
import { DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import { toastSuccess } from "../Utility/ToastUtils";

function CartPage1() {
  const dispatch = useDispatch();
  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalBox, setModalBox] = useState(false);
  const [usersArr, setUsersArr] = useState([]);
  const [customersArr, setCustomersArr] = useState([]);
  const [retailersArr, setRetailersArr] = useState([]);
  const [wholesalersArr, setWholesalersArr] = useState([]);
  const [subAdminArr, setSubAdminArr] = useState([]);
  const userArr = useSelector((state) => state.users.users);
  const [selectedData, setSelectedData] = useState(null);
  const [query, setQuery] = useState("");
  const [displayUserArr, setDisplayUserArr] = useState([]);
  const [mainArr, setMainArr] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [customerCategoryObj, setCustomerCategoryObj] = useState({});
  const [customerCategoryId, setCustomerCategoryId] = useState("");
  const [customerCatArr, setCustomerCatArr] = useState([]);
  const customerCategoryArr = useSelector(
    (state) => state.customerCategory.customerCategory
  );
  const handleGetOnInit = () => {
    // dispatch(CustomerCategoryGet());
  };

  useEffect(() => {
    setCustomerCatArr(customerCategoryArr);
  }, [customerCategoryArr]);

  const navigate = useNavigate();

  useEffect(() => {
    if (userArr) {
      setDisplayUserArr([...userArr]);
      setMainArr([...userArr]);
    }
  }, [userArr]);

  const handleEdit = (e, row) => {
    e.preventDefault();
    // dispatch(SetCustomerCategoryObj(row));
    navigate("/Customer-Create");
  };

  const handleChangeApproval = async (id, isApproved) => {
    try {
      let { data: res } = await StatusChange(id, { isApproved: !isApproved });
      if (res) {
        if (!isApproved == true) {
          setModalBox(true);
        }
        toastSuccess(res.message);
        handleGetAllUsers();
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleModalSet = (e, row) => {
    e.preventDefault();
    setModalBox(true);
    setModalType("customer-detail");
    setModalName("Customer Information");
    setSelectedData(row);
  };

  const users_columns = [
    {
      name: "Sr No.",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "10%",
    },

    {
      name: "User NAME",
      selector: (row) => `${row?.name}`,
      width: "25%",
    },
    {
      name: "Retailer Name",
      selector: (row) => row?.email,
      width: "25%",
    },
    {
      name: "Quantity",
      selector: (row) => row?.email,
      width: "15%",
    },
    {
      name: "Price",
      selector: (row) => row?.email,
      width: "15%",
    },
    {
      name: "Order Date",
      selector: (row) => row?.name,
      width: "10%",
    },
    // {
    //   name: "PHONE",
    //   selector: (row) => row?.phone,
    //   width: "20%",
    // },
    // {
    //   name: "IMAGE",
    //   cell: (row) => <img src={images?.customer} alt={row?.name} />,
    //   width: "10%",
    // },

    // {
    //   name: "Status",
    //   selector: (row) => (
    //     <Switch
    //       checked={row?.isApproved}
    //       onChange={() => {
    //         handleChangeApproval(row?._id, row?.isApproved);
    //         setSelectedUser(row);
    //       }}
    //     />
    //   ),
    //   width: "10%",
    // },
    // {
    //   name: "ROLE",
    //   selector: (row) => row?.role,
    //   width: "10%",
    // },

  ];

  const [tabList, settabList] = useState([
    {
      tabName: "All",
      active: true,
    },
    // {
    //   tabName: "Customers",
    //   active: false,
    // },
    // {
    //   tabName: "Retailers",
    //   active: false,
    // },
    // {
    //   tabName: "Wholesalers",
    //   active: false,
    // },
    // {
    //   tabName: "Sub-Admins",
    //   active: false,
    // },
  ]);

  const handleGetAllUsers = () => {
    dispatch(usersGet());
  };
  useEffect(() => {
    if (userArr && userArr.length) {
      setUsersArr(userArr);
      // setDisplayUsersArr(userArr);
      setCustomersArr(usersArr.filter((el) => el.role == rolesObj.CUSTOMER));
      setRetailersArr(usersArr.filter((el) => el.role == rolesObj.RETAILER));
      setWholesalersArr(
        usersArr.filter((el) => el.role == rolesObj.WHOLESALER)
      );
      setSubAdminArr(usersArr.filter((el) => el.role == rolesObj.SUBADMIN));
    }
  }, [userArr]);

  useEffect(() => {
    handleGetAllUsers();
    handleGetOnInit();
  }, []);

  const handleGetselectedTable = () => {
    if (tabList.filter((el) => el.active)[0].tabName == "All") {
      return (
        <DataTable columns={users_columns} data={displayUserArr} pagination />
      );
    } else if (tabList.filter((el) => el.active)[0].tabName == "Customers") {
      return (
        <DataTable columns={users_columns} data={customersArr} pagination />
      );
    } else if (tabList.filter((el) => el.active)[0].tabName == "Retailers") {
      return (
        <DataTable columns={users_columns} data={retailersArr} pagination />
      );
    } else if (tabList.filter((el) => el.active)[0].tabName == "Wholesalers") {
      return (
        <DataTable columns={users_columns} data={wholesalersArr} pagination />
      );
    } else {
      return (
        <DataTable columns={users_columns} data={subAdminArr} pagination />
      );
    }
  };

  const handleSearch = (queryValue) => {
    setQuery(queryValue);
    let tempArr = mainArr;
    tempArr = tempArr.filter(
      (el) =>
        `${el?.name}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
        `${el?.phone}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
        `${el?.email}`.toLowerCase().includes(`${queryValue}`.toLowerCase())
    );
    setDisplayUserArr([...tempArr]);
  };

  const handleUpdateUserCategory = async () => {
    try {
      let obj = {
        customerCategoryId: customerCategoryId,
      };
      let { data: res } = await updateUserCategory(selectedUser._id, obj);
      if (res.message) {
        toastSuccess(res.message);
        setModalBox(false);
      }
      // dispatch(updateSpecificUser(obj, selectedUser._id))
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <main className="p-0">
      <section className="product-category">
        <div className="user_header">
          <span className="page_name">Cart</span>

          <p className="breadcrumb">
            <span className="parent">Dashboard </span>{" "}
            <span>
              {" "}
              <HiChevronRight />
            </span>{" "}
            <span className="child">Cart</span>
          </p>
        </div>

        <div className="row">
          <div className="cart_date_box">
            <div className="cart_start_area">
              <div className="row">
                <div className="col-xl-4">
                  <p className="cart_desc">Select The Start Ansd Date For Generate Order Report
                    Start Date:</p>
                </div>
              </div>

              <div className="row mt_25">
                <div className="col-xl-6">
                  <div className="user_form_ip_box">
                    <p className="ip_label">Start Date*</p>
                    <input type="date" />
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="user_form_ip_box">
                    <p className="ip_label">End Date*</p>
                    <input type="date" />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="export_buttn_groups">
                  <button className="pdf_buttn">Export to PDF</button>
                  <button className="pdf_buttn">Export to Excel</button>
                </div>


              </div>



            </div>
          </div>
        </div>
        <DashboardTable>
          <div className="row align-items-center border_with_color mx-0">
            <div className="col-xl-10">
              <div className="user_header_left_text">
                <h6>Cart</h6>

                <span>Cart List</span>
              </div>
            </div>
            {/* <div className="col-xl-2">
              <div className="user_header_right">
                <select>
                  <option value="">All (56)</option>
                  <option value="ssl">SSL</option>
                  <option value="tls">TLS</option>
                </select>

                <button className="">
                  Add <MdOutlineAdd />
                </button>
              </div>
            </div> */}
          </div>
          <div className="d-flex align-items-center justify-content-between mb-0 border_with_color_1">

            <button className="copy_excel_buttn">Copy to Excel</button>
            <div className="d-flex align-items-center gap-3">
              <SearchBox
                query={query}
                setQuery={handleSearch}
                extraClass="bg-white"
              />

            </div>
          </div>

          {handleGetselectedTable()}
        </DashboardTable>
      </section>

      <Modal
        open={ModalBox}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-box">
          <div className="modal-container">
            <div className="modal-header text-white">Update User Type</div>
            <div className="modal-body" style={{ width: "50vw" }}>
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-12 mb-3">
                    <label>
                      Customer Category
                      <span className="red">*</span>
                    </label>
                  </div>
                  <div className="col-12 col-md-11 mb-3">
                    <Select
                      onChange={(e) => {
                        setCustomerCategoryObj(e);
                        setCustomerCategoryId(e.value);
                      }}
                      defaultValue={customerCategoryId}
                      value={customerCategoryObj}
                      options={
                        customerCatArr && customerCatArr.length > 0
                          ? customerCatArr.map((el) => ({
                            label: el.name,
                            value: el._id,
                            ...el,
                          }))
                          : []
                      }
                    />
                  </div>
                  <div className="col-12 col-md-11 mb-3">
                    <CustomButton
                      ClickEvent={() => handleUpdateUserCategory()}
                      btntype="button"
                      isBtn
                      iconName="fa-solid fa-check"
                      btnName="Save"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </main>
  );
}

export default CartPage1
