import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/support";

export const addSupport = (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getSupport = (query) => {
    return axiosApiInstance.get(`${serverUrl}/?${query}`);
};
export const downloadSupportExcel = (query) => {
    return axiosApiInstance.get(`${serverUrl}/download-excel?${query}`);
};

export const deleteSupport = (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const getById = (id) => {
    return axiosApiInstance.get(`${serverUrl}/getById/${id}`);
};

export const updateSupport = (formData, id) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};