import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/dispatchingInputSaleInvoice";

export const addDispatchingInputSaleInvoice = (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getDispatchingInputSaleInvoice = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/?${query.toString()}`);
};
export const getComodityProductsSalesYearWise = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/getComodityProductsSalesYearWise?${query.toString()}`);
};
export const getProductsSalesRevenueYearWiseChange = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/getProductsSalesRevenueYearWiseChange?${query.toString()}`);
};

export const getSalesRevenueYearAndZoneWiseChange = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/getSalesRevenueYearAndZoneWiseChange?${query.toString()}`);
};
export const ZoneWiseCustomerCountApi = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/ZoneWiseCustomerCount?${query.toString()}`);
};

export const getYearOnYearSalesRevenue = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/getYearOnYearSalesRevenue?${query.toString()}`);
};
export const getProductWiseSoldQuantity = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/getProductWiseSoldQuantity?${query.toString()}`);
};
export const getSegmentWiseTurnoverComparision = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/getSegmentWiseTurnoverComparision?${query.toString()}`);
};



export const getComodityVsSpecialityProductsSalesYearWise = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
    let query = new URLSearchParams({ ...obj, ...match });
    return axiosApiInstance.get(`${serverUrl}/getComodityVsSpecialityProductsSalesYearWise?${query.toString()}`);
};

export const deleteDispatchingInputSaleInvoice = (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const getById = (id) => {
    return axiosApiInstance.get(`${serverUrl}/getById/${id}`);
};

export const updateDispatchingInputSaleInvoice = (formData, id) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};
export const DownloadSaleDataToExcel = () => {
    return axiosApiInstance.post(`${serverUrl}/bulkExport`);
};