import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/product";

export const addProduct = (formData) => {
        return axiosApiInstance.post(serverUrl + "/addProduct", formData);
};


export const bulkUpload = (formData) => {
        return axiosApiInstance.post(serverUrl + "/bulkUpload", formData);
};
export const getProductById = (id) => {
        return axiosApiInstance.get(`${serverUrl}/getById/${id}`);
};


export const bulkUpdateModelNo = (formData) => {
        return axiosApiInstance.post(serverUrl + "/bulkUpdateModelNo", formData);
};

export const getProducts = (obj = { pageIndex: 1, pageSize: 10000000 }, match) => {
        let query = new URLSearchParams({ ...obj, ...match });
        return axiosApiInstance.get(`${serverUrl}/getProducts?${query.toString()}`);
};
export const downloadProductExcel = (obj = { pageIndex: 1, pageSize: 10000000 }, match) => {
        let query = new URLSearchParams({ ...obj, ...match });
        return axiosApiInstance.get(`${serverUrl}/download-excel?${query.toString()}`);
};
export const getProductsByCategoryName = (obj = { pageIndex: 1, pageSize: 10000000 }, match) => {
        let query = new URLSearchParams({ ...obj, ...match });
        return axiosApiInstance.get(`${serverUrl}/getProductsByCategoryName?${query.toString()}`);
};



export const getProductCount = (query) => {
        return axiosApiInstance.get(`${serverUrl}/getProductCount?${query}`);
};
export const getProductsForSelect = (query) => {
        return axiosApiInstance.get(`${serverUrl}/getProductsForSelect?${query}`);
};

export const deleteProductById = (id) => {
        return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateProductById = (formData, id) => {
        return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};
