import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { HiChevronRight } from "react-icons/hi";
import Select from "react-select";
import {
  getProductWiseSoldQuantity, getSegmentWiseTurnoverComparision
} from "../../services/dispatchingInputSaleInvoice.service";
import { getProductsByCategoryName } from "../../services/product.service";
import CustomButton from "../Utility/Button";
import { DashboardBox, DashboardChart } from "../Utility/DashboardBox";
import { toastError } from "../Utility/ToastUtils";
import { quantityFormatter } from "../Utility/utils";
export default function SegmentWiseTurnoverComparision() {
  const [productsArr, setProductsArr] = useState([]);
  const [barLabels, setBarLabels] = useState([
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
  ]);

  const [yearsArr, setYearsArr] = useState([]);

  const [selectedYear, setSelectedYear] = useState("");
  const [selectedSegmentId, setSelectedSegmentType] = useState("");
  const [selectedReportType, setSelectedReportType] = useState("Monthly");

  const [displayYear, setDisplayYear] = useState("");
  const [displayProduct, setDisplayProduct] = useState("");

  const [reportType, setReportType] = useState([
    { label: "Quarterly", value: "Quarterly" },
    { label: "Monthly", value: "Monthly" },
  ]);

  const [selectedSecondYear, setSelectedSecondYear] = useState("");
  const [unitOfMeasurement, setUnitOfMeasurement] = useState("");
  const [requestedYearSalesArr, setRequestedYearSalesArr] = useState([]);
  const [previousYearSalesArr, setPreviousYearSalesArr] = useState([]);

  const HandleGetComodityProducts = async () => {
    try {
      let query = {};
      let { data: res } = await getProductsByCategoryName(query);
      if (res.data) {
        setProductsArr(res.data);
      }
    } catch (error) {
      toastError(error);
    }
  };

  const HandleGetComodityProductsSalesYearWise = async () => {
    try {
      let query = {};
      if (selectedYear == "") {
        toastError("Please select an year");
        return
      }

      if (selectedSegmentId == "") {
        toastError("Please select a Segment");
        return
      }

      if (selectedYear && selectedYear != "") {
        query = { ...query, selectedYear: selectedYear };
      }
      if (selectedSecondYear && selectedSecondYear != "") {
        query = { ...query, selectedSecondYear: selectedSecondYear };
      }
      if (selectedSegmentId && selectedSegmentId != "") {
        query = { ...query, selectedSegmentId: selectedSegmentId };
      }
      if (selectedReportType && selectedReportType != "") {
        query = { ...query, selectedReportType: selectedReportType };
      }

      setDisplayYear(selectedYear);
      setDisplayProduct(selectedSegmentId);

      let { data: res } = await getSegmentWiseTurnoverComparision(query);
      if (res.data) {
        if (selectedReportType == "Quarterly") {
          setBarLabels(["Q1", "Q2", "Q3", "Q4"]);

          setRequestedYearSalesArr(res.data.requestedYearsOrderCount);
          setPreviousYearSalesArr(res.data.previousYearsOrderCount);
        } else {
          setBarLabels([
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
            "January",
            "February",
            "March",
          ])
          setRequestedYearSalesArr(res.data.requestedYearsOrderCount);
          setPreviousYearSalesArr(res.data.previousYearsOrderCount);
        }
        setUnitOfMeasurement(res.data.unitOfMeasurement);
      }
    } catch (error) {
      toastError(error);
    }
  };

  const handleCalculateLastFiveYears = () => {
    let tempArr = [];
    let today = new Date();

    let totalYears = today.getFullYear() - 2018;

    for (let index = 0; index <= totalYears; index++) {
      let obj = {};
      let year = today.getFullYear() - index;
      obj.label = `${year}-${year + 1}`;
      obj.value = year;
      tempArr.push(obj);
    }

    setYearsArr(tempArr);
  };

  useEffect(() => {
    HandleGetComodityProducts();
    handleCalculateLastFiveYears();
  }, []);

  return (
    <main className="bg-white">
      <section className="dashboard-head mb-3">
        <div className="row">
          <div className="col-xl-10"></div>
          <div className="col-xl-2">
            {/* <div className="user_header">
              <p className="breadcrumb">
                <span className="parent">Dashboard </span>{" "}
                <span>
                  {" "}
                  <HiChevronRight />
                </span>{" "}
                <span className="child">Reports</span>
              </p>
            </div> */}
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-12 mb-5 p-5 border rounded">
              <div className="row">
                <h3 className="blue-1">
                  Segment Wise Turnover Comparision (Quarter/Month) wise
                </h3>

                <div className="col-12 col-lg-3">
                  <DashboardBox className="dashboard-summary">
                    <div className="row align-items-center">
                      <div className="col-xl-9">
                        <div className="right_content_box">
                          <h5 className="blue-1">Segment</h5>
                          <Select
                            options={[{
                              label: "Speciality",
                              value: "Speciality"
                            },
                            {
                              label: "Commodity",
                              value: "Commodity"

                            }]}
                            onChange={(e) => setSelectedSegmentType(e.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </DashboardBox>
                </div>
                <div className="col-12 col-lg-3">
                  <DashboardBox className="dashboard-summary">
                    <div className="row align-items-center">
                      <div className="col-xl-9">
                        <div className="right_content_box">
                          <h5 className="blue-1">First Year</h5>
                          <Select
                            options={yearsArr}
                            onChange={(e) => setSelectedYear(e.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </DashboardBox>
                </div>
                <div className="col-12 col-lg-3">
                  <DashboardBox className="dashboard-summary">
                    <div className="row align-items-center">
                      <div className="col-xl-9">
                        <div className="right_content_box">
                          <h5 className="blue-1">Second Year</h5>
                          <Select
                            options={yearsArr}
                            onChange={(e) => setSelectedSecondYear(e.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </DashboardBox>
                </div>
                <div className="col-12 col-lg-3">
                  <DashboardBox className="dashboard-summary">
                    <div className="row align-items-center">
                      <div className="col-xl-9">
                        <div className="right_content_box">
                          <h5 className="blue-1">Type</h5>
                          <Select
                            options={reportType}
                            value={{
                              label: selectedReportType,
                              value: selectedReportType,
                            }}
                            onChange={(e) => setSelectedReportType(e.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </DashboardBox>
                </div>
                <div className="col-12 col-lg-3 mt-5">
                  <CustomButton
                    isBtn
                    btntype="button"
                    ClickEvent={HandleGetComodityProductsSalesYearWise}
                    iconName="fa-solid fa-check"
                    extraClass={"mt-3"}
                    btnName="Submit"
                  />
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-12 col-lg-6">
                  <DashboardChart>
                    {requestedYearSalesArr &&
                      requestedYearSalesArr.length > 0 && (
                        <>
                          <h5 className="blue-1 my-4">
                            First Year ({displayYear})
                          </h5>

                          <Bar
                            data={{
                              labels: barLabels,
                              datasets: [
                                {
                                  label: selectedSegmentId,
                                  backgroundColor: "#2e4765",
                                  borderColor: "#2e4765",
                                  data: requestedYearSalesArr?.map(
                                    (el) => el?.value
                                  ),
                                },
                              ],
                            }}
                          />
                          <h5 className="blue-1 my-4">
                            Total Sale in ({displayYear}) is{" "}
                            {quantityFormatter.format(requestedYearSalesArr.reduce(
                              (acc, el, index) => acc + el.value,
                              0
                            ))}{" "}
                            {unitOfMeasurement}
                          </h5>
                        </>
                      )}
                  </DashboardChart>
                </div>
                <div className="col-12 col-lg-6">
                  <DashboardChart>
                    {previousYearSalesArr &&
                      previousYearSalesArr.length > 0 && (
                        <>
                          <h5 className="blue-1 my-4">
                            Second Year ({selectedSecondYear})
                          </h5>
                          <Bar
                            data={{
                              labels: barLabels,
                              datasets: [
                                {
                                  label: selectedSegmentId,
                                  backgroundColor: "#2e4765",
                                  borderColor: "#2e4765",
                                  data: previousYearSalesArr?.map(
                                    (el) => el?.value
                                  ),
                                },
                              ],
                            }}
                          />
                          <h5 className="blue-1 my-4">
                            Total Sale in ({parseInt(displayYear) - 1}) is{" "}
                            {quantityFormatter.format(previousYearSalesArr.reduce(
                              (acc, el, index) => acc + el.value,
                              0
                            ))}{" "}
                            {unitOfMeasurement}
                          </h5>
                        </>
                      )}
                  </DashboardChart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
