import React, { useEffect, useState } from 'react'
import { getZone } from '../../services/zone.service';
import { getProductsByCategoryName } from '../../services/product.service';
import { DashboardBox } from '../Utility/DashboardBox';
import CustomButton from '../Utility/Button';
import Select from "react-select";
import { toastError } from '../Utility/ToastUtils';
import { getAverageRateOfProductsZoneAndDateWise, getTotalCustomersForZoneAndDateWise, getTotalSalesOfProductsZoneAndDateWise } from '../../services/index.service';
import moment from 'moment';
import Loading from '../Loading/Loading';

export default function TotalCustomers() {
    const [yearsArr, setYearsArr] = useState([]);
    const [endDate, setEndDate] = useState(undefined);
    const [startDate, setStartDate] = useState(undefined);
    const [selectedProducts, setSelectedProducts] = useState("");
    const [selectedReportType, setSelectedReportType] = useState("Monthly");
    const [zoneArr, setZoneArr] = useState([]);
    const [loading, setLoading] = useState(false);




    const [selectedZone, setSelectedZone] = useState("");
    const [productsArr, setProductsArr] = useState([]);
    const [data, setData] = useState([]);
    const [currentYearData, setCurrentYearData] = useState([]);

    const HandleGetComodityProducts = async () => {
        try {
            let query = {};
            let { data: res } = await getProductsByCategoryName(query);
            if (res.data) {
                setProductsArr(res.data);
            }
        } catch (error) {
            toastError(error);
        }
    };
    const handleCalculateLastFiveYears = () => {
        let tempArr = [];
        let today = new Date();

        let totalYears = today.getFullYear() - 2018;

        for (let index = 0; index < totalYears; index++) {
            let obj = {};
            let year = today.getFullYear() - index;
            obj.label = `${year}-${year+1}`;
            obj.value = year;
            tempArr.push(obj);
        }

        setYearsArr(tempArr);
    };

    const HandleGetZone = async () => {
        try {
            let { data: res } = await getZone(
                { pageIndex: 0, pageSize: 10000000 },
                { forSelectInput: true }
            );
            if (res.data) {
                setZoneArr(res.data);
            }
        } catch (error) {
            toastError(error);
        }
    };

    useEffect(() => {
        let today = new Date();
        today.setDate(1);
        today.setMonth(0);
        today.setFullYear(2022);
        
        let end = new Date();
        end.setDate(30);
        end.setMonth(0);
        end.setFullYear(2022);
        setStartDate(today)
        setEndDate(end)

      
        HandleGetZone();
        HandleGetComodityProducts();
        handleCalculateLastFiveYears();
    }, []);





    const HandleGetAverageRateOfProductsZoneAndDateWise = async () => {
        try {
            setLoading(true);
            let query = {};
            
            if (selectedProducts && selectedProducts.length > 0) {
                query = {
                    ...query,
                    selectedProducts: selectedProducts.map((el) => el.value),
                };
            }
            if (startDate && startDate != "") {
                let tempStartDate = startDate;
                tempStartDate.setHours(0,0,0,0);
                query = { ...query,  startDate:tempStartDate.toISOString() };
            }
            else{
                toastError("Please select a start date");
                return;
            }
            if (endDate && endDate != "") {
                let tempEndDate = endDate;
                tempEndDate.setHours(23,59,59,59);
                query = { ...query, endDate:tempEndDate.toISOString() };
            }
            else{
                toastError("Please select an end date");
                return;
            }
            if (selectedZone && selectedZone.length > 0) {
                query = {
                    ...query,
                    selectedZone: selectedZone.map((el) => el.value),
                };
            }
            
            
            // setDisplayYear(selectedYear);
            // setDisplayProduct(selectedProducts);
            
            let { data: res } = await getTotalCustomersForZoneAndDateWise(query);
            if (res.data) {
                setLoading(false);
                console.log(res.data, "res.datares.data");
                setData(res.data);
                // setCurrentYearData(res.data.requestedYearsOrderCount);
            }
        } catch (error) {
            setLoading(false);
            toastError(error);
        }
    };



  return (
    <section>
     {
        loading && 
        <Loading/>
    }
    <div className="container-fluid">
        <div className="row">
            <div className="col-12 col-md-12 mb-5 p-5 border rounded">
                <div className="row">
                    <h3 className="blue-1">
                        Total Customers
                    </h3>
                    <div className="col-12 col-lg-12">
                        <DashboardBox className="dashboard-summary">
                            <div className="row align-items-center">
                                <div className="col-xl-12">
                                    <div className="right_content_box">
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection:
                                                    "row",
                                                alignItems:
                                                    "center",
                                                justifyContent:
                                                    "space-between",
                                                marginBottom: 15,
                                            }}>
                                            <h5 className="blue-1">
                                                Products
                                            </h5>
                                            <CustomButton
                                                isBtn
                                                btntype="button"
                                                ClickEvent={() =>
                                                    setSelectedProducts(
                                                        [
                                                            ...productsArr,
                                                        ]
                                                    )
                                                }
                                                iconName="fa-solid fa-check"
                                                extraClass={"pe-3"}
                                                btnName="Select all"
                                            />
                                        </div>
                                        <Select
                                            options={productsArr}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            value={selectedProducts}
                                            onChange={(e) =>
                                                setSelectedProducts(
                                                    e
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </DashboardBox>
                    </div>
                    <div className="col-12 col-lg-12">
                        <DashboardBox className="dashboard-summary">
                            <div className="row align-items-center">
                                <div className="col-xl-12">
                                    <div className="right_content_box">
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection:
                                                    "row",
                                                alignItems:
                                                    "center",
                                                justifyContent:
                                                    "space-between",
                                                marginBottom: 15,
                                            }}>
                                            <h5 className="blue-1">
                                                Zone
                                            </h5>
                                            <CustomButton
                                                isBtn
                                                btntype="button"
                                                ClickEvent={() =>
                                                    setSelectedZone(
                                                        [...zoneArr]
                                                    )
                                                }
                                                iconName="fa-solid fa-check"
                                                extraClass={"pe-3"}
                                                btnName="Select all"
                                            />
                                        </div>
                                        <Select
                                            options={zoneArr}
                                            value={selectedZone}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            onChange={(e) =>
                                                setSelectedZone(e)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </DashboardBox>
                    </div>
                    <div className="col-12 col-lg-5">
                        <DashboardBox className="dashboard-summary">
                            <div className="row align-items-center">
                                <div className="col-xl-12">
                                    <div className="right_content_box">
                                        <h5 className="blue-1 mb-4 pb-2">
                                          Start Date
                                        </h5>
                                            <input type='date' className='form-control' onChange={(e)=> setStartDate(new Date(`${ e.target.value}`.split("-").reduce((acc, el,index)=> acc + `${el} ${ e.target.value.split("-").length != index ?",":""}`, "")))} value={moment(startDate).format("YYYY-MM-DD")}/>
                                       
                                    </div>
                                </div>
                            </div>
                        </DashboardBox>
                    </div>
                    <div className="col-12 col-lg-5">
                        <DashboardBox className="dashboard-summary">
                            <div className="row align-items-center">
                                <div className="col-xl-12">
                                    <div className="right_content_box">
                                        <h5 className="blue-1 mb-4 pb-2">
                                           End Date
                                        </h5>
                                            <input type='date' className='form-control' onChange={(e)=> setEndDate(new Date(`${ e.target.value}`.split("-").reduce((acc, el,index)=> acc + `${el} ${ e.target.value.split("-").length != index ?",":""}`, "")))} value={moment(endDate).format("YYYY-MM-DD")}/>
                                        
                                    </div>
                                </div>
                            </div>
                        </DashboardBox>
                    </div>
                    <div className="col-12 col-lg-1 mt-5">
                        <CustomButton
                            isBtn
                            btntype="button"
                            ClickEvent={
                                HandleGetAverageRateOfProductsZoneAndDateWise
                            }
                            iconName="fa-solid fa-check"
                            extraClass={"mt-3"}
                            btnName="Submit"
                        />
                    </div>
                </div>

                {data && data.length > 0 && (
                    <div className="mt-5">
                    
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                <div
                                    style={{
                                        width: 160,
                                        maxWidth: 160,
                                        fontSize: 11,
                                        textAlign: "center",
                                        border: "solid 1px grey",
                                        display: "grid",
                                        placeItems: "center",
                                    }}>
                                    Product Category
                                </div>
                                <div
                                    style={{
                                        width: 160,
                                        maxWidth: 160,
                                        fontSize: 11,
                                        textAlign: "center",
                                        border: "solid 1px grey",
                                        display: "grid",
                                        placeItems: "center",
                                    }}>
                                    Grand Total
                                </div>
                                    

                                {selectedZone &&
                                    selectedZone.length &&
                                    selectedZone &&
                                    selectedZone
                                        .length > 0 &&
                                    selectedZone.map(
                                        (ele, indexX) => {
                                            return (
                                                <div
                                                    style={{
                                                        width: 160,
                                                        
                                                        maxWidth: 160,
                                                        fontSize: 11,
                                                        textAlign:
                                                            "center",
                                                        border: "solid 1px grey",
                                                        display:
                                                            "grid",
                                                        placeItems:
                                                            "center",
                                                    }}
                                                    key={indexX}>
                                                    {ele?.label}
                                                </div>
                                            );
                                        }
                                    )}
                            </div>
                        </div>
                        <div>
                        
                            {data &&
                                data?.length &&
                                data?.map((el, index) => {
                                    return (
                                        <div
                                            key={index}
                                            style={{
                                                display: "flex",
                                                flexDirection:
                                                    "column",
                                            }}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection:
                                                        "row",
                                                }}>
                                                <div
                                                    style={{
                                                        width: 160,
                                                        fontSize: 11,
                                                        textAlign:
                                                            "center",
                                                        border: "solid 1px grey",
                                                        display:
                                                            "grid",
                                                            wordBreak:"break-all",
                                                        placeItems:
                                                            "center",
                                                    }}>
                                                    {el.name}
                                                </div>
                                                <div
                                                    style={{
                                                        width: 160,
                                                        fontSize: 11,
                                                        textAlign:
                                                            "center",
                                                        border: "solid 1px grey",
                                                        display:
                                                            "grid",
                                                            wordBreak:"break-all",
                                                        placeItems:
                                                            "center",
                                                    }}>
                                                   {el.zonesArr.reduce((acc,el)=> acc + el.quantity, 0)}
                                                </div>
                                                {el.zonesArr &&
                                                    el.zonesArr
                                                        ?.length >
                                                        0 &&
                                                    el.zonesArr.map(
                                                        (
                                                            ele,
                                                            indexX
                                                        ) => {
                                                            return (
                                                                <div
                                                                    style={{
                                                                        width: 160,
                                                                        fontSize: 11,
                                                                        textAlign:
                                                                            "center",
                                                                        border: "solid 1px grey",
                                                                        display:
                                                                            "grid",
                                                                        placeItems:
                                                                            "center",
                                                                    }}
                                                                    key={
                                                                        indexX
                                                                    }>
                                                                    {
                                                                        ele?.quantity
                                                                    }
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                )}



               
            </div>
        </div>
    </div>
</section>
  )
}
