import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AiFillEdit } from "react-icons/ai";
import { HiChevronRight } from "react-icons/hi";
import { MdOutlineAdd } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { deletePurchaseOrder, getPurchaseOrder } from "../../services/purchaseOrder.service";
import { toastError } from "../../utils/toastUtils";
import { DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import { debounce } from "lodash";

function OrderHistory() {
  const [query, setQuery] = useState("");
  const [displayArr, setDisplayArr] = useState([]);
  const [mainArr, setMainArr] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const location = useLocation();


  const handleEdit = (row) => {
    navigate("/PurchaseOrder" + `?id=${row}`);
  };



  // const handleget = async () => {
  //   try {
  //     let { data: res } = await getPurchaseOrder();
  //     if (res) {
  //       setDisplayArr([...res.data]);
  //       setMainArr([...res.data]);
  //     }
  //   } catch (err) {
  //     toastError(err);
  //   }
  // };

  const handleGet = async (pageValue, limitValue, queryValue) => {
    try {
      let query = "";
      if (queryValue && queryValue != "") {
        query = `${queryValue}`
      }
      console.log(query, "query")
      let { data: res } = await getPurchaseOrder({ pageIndex: pageValue, pageSize: limitValue }, { query });
      if (res.message) {
        setDisplayArr(res.data);
        setTotalElements(res.total);
        setMainArr(res.data);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleDelete = async (id) => {
    try {
      if (window.confirm("Are you sure ?")) {
        let { data: res } = await deletePurchaseOrder(id);
        if (res) {
          handleGet(1, limit, query)
        }
      }
    } catch (err) {
      toastError(err);
    }
  };


  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalElements, setTotalElements] = useState(0);

  const createQueryString = useCallback(
    (arr) => {
      const params = new URLSearchParams(searchParams.toString());
      for (let i = 0; i < arr.length; i++) {
        const element = arr[i];
        params.set(element.name, element.value);
      }
      console.log(params.toString(), "params.toString()");
      return params.toString();
    },
    [searchParams]
  );

  const handlePerRowsChange = (newPerPage, page) => {
    let pathname = window.location.href.split("/")[3];
    navigate(location.pathname + "?" + createQueryString([{ name: "limit", value: newPerPage }])
    );
    setLimit(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
    navigate(location.pathname + "?" + createQueryString([{ name: "page", value: page }])
    );
  };


  // useEffect(() => {
  //   handleget()
  // }, [])



  useEffect(() => {
    if (searchParams) {
      const query = searchParams.get("query") || "";
      const limit = parseInt(searchParams.get("limit") || "10");
      const pageValue = parseInt(searchParams.get("page") || "1");
      setLimit(limit);
      setPage(pageValue);
      setQuery(query);
      console.log(searchParams.toString(), "searchParams")
      handleGet(pageValue, limit, query);
    }
  }, [searchParams]);

  const users_columns = [
    {
      name: "Sr No.",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "15%",
    },

    {
      name: "Vendor Name",
      selector: (row) => `${row?.vendorName}`,
      // width: "20%",
    },



    {
      name: "Order No.",
      selector: (row) => row?.orderNo,
      // width: "20%",
    },
    {
      name: "Order Type",
      selector: (row) => row?.orderType,
      // width: "20%",
    },
    {
      name: "Order Date",
      selector: (row) => moment(row?.orderDate).format("YYYY-MM-DD"),
      // width: "20%",
    },
    {
      name: "Amount",
      selector: (row) => `${row?.orderAmount}`,
      // width: "30%",
    },
    {
      name: "Action",
      cell: (row) => (
        <>


          <button onClick={() => handleEdit(row._id)} className="edit_buttn">
            <AiFillEdit />
          </button>

          <div style={{ marginLeft: 15 }}>


            <button onClick={() => handleDelete(row._id)} className="delete_buttn">
              <RiDeleteBin5Line />
            </button>
          </div>

        </>
      ),
      width: "15%",
    },
  ];


  const handleSearch = (queryValue) => {
    setQuery(queryValue);
    let tempArr = mainArr;
    tempArr = tempArr.filter(
      (el) =>
        `${el?.orderAmount}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
        `${el?.vendorName}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
        `${el?.orderType}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
        `${el?.orderNo}`.toLowerCase().includes(`${queryValue}`.toLowerCase()) ||
        `${el?.email}`.toLowerCase().includes(`${queryValue}`.toLowerCase())
    );




    setDisplayArr([...tempArr]);
  };

  const debouncedSave = useCallback(
    debounce(
      (nextValue) => {
        navigate(location.pathname +
          "?" +
          createQueryString([
            { name: "query", value: nextValue },
            { name: "page", value: 1 },
          ])
        )

      },
      1000
    ),
    [] // will be created only once initially
  );

  // highlight-ends

  const handleChange = (event) => {
    const nextValue = event;

    setQuery(nextValue);

    // Even though handleChange is created on each render and executed

    // it references the same debouncedSave that was created initially

    debouncedSave(nextValue);
  };

  return (
    <main className="p-0">
      <section className="product-category">
        <div className="user_header">
          {/* <span className="page_name">Purchase Order</span>
          <p className="breadcrumb">
            <span className="parent">Dashboard </span>{" "}
            <span>
              {" "}
              <HiChevronRight />
            </span>{" "}
            <span className="child">Purchase Order</span>
          </p> */}
        </div>

        <DashboardTable>
          <div className="row align-items-center border_with_color mx-0">
            <div className="col-xl-10">
              <div className="user_header_left_text">
                <h6>Purchase Order</h6>

                <span>Purchase Order List</span>
              </div>
            </div>
            <div className="col-xl-2">
              <div className="user_header_right">
                {/* <select>
                  <option value="">All (56)</option>
                  <option value="ssl">SSL</option>
                  <option value="tls">TLS</option>
                </select> */}

                <button onClick={() => navigate("/PurchaseOrder")} className="">
                  Add <MdOutlineAdd />
                </button>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-0 border_with_color_1">
            {/* <h5 className="blue-1 m-0">All Customers</h5> */}
            <button className="copy_excel_buttn">Copy to Excel</button>
            <div className="d-flex align-items-center gap-3">
              <SearchBox
                query={query}
                setQuery={handleChange}
                extraClass="bg-white"
              />
            </div>
          </div>

          <DataTable columns={users_columns} data={displayArr} pagination
            paginationPerPage={limit}
            paginationDefaultPage={page}
            paginationServer={true}
            paginationRowsPerPageOptions={[10, 20, 30, 50, 100, 200, 500]}
            paginationTotalRows={totalElements}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange} />
        </DashboardTable>
      </section>

    </main>
  );
}

export default OrderHistory;
