import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { MdOutlineAdd, MdOutlineDelete } from "react-icons/md";
import { DashboardTable } from "../Utility/DashboardBox";
import { toastError } from "../Utility/ToastUtils";
import { getProducts } from "../../services/product.service";
import Select from 'react-select';
import _ from "lodash";

export default function ProductDetailsTable({ productArr, chats_data_value }) {
  const chats_columns = [
    {
      name: "SR.No",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "5%",
    },
    // {
    //   name: "Category",
    //   cell: (row, index) => <input className="form-control" name="category" onChange={(e) => handleChangeValue(e, "", index)} value={row?.category} />,
    // },
    {
      name: "Product Code",
      cell: (row, index) => <div style={{ flex: 1 }}>
        <Select options={productsArr} name="productObj" onChange={(e) => handleChangeValue(e, "productObj", index)} value={row.productObj} />
      </div>,
      width: "25%",

      // <input className="form-control" name="name" onChange={(e) => handleChangeValue(e, index)} value={row?.name} />,
    },
    {
      name: "Product Name",
      cell: (row, index) => <input className="form-control" disabled name="description" onChange={(e) => handleChangeValue(e, "", index)} value={row?.name} />,
    },
    {
      name: "Price",
      cell: (row, index) => <input className="form-control" type="number" name="price" onChange={(e) => handleChangeValue(e, "", index)} value={row?.price} />,
    },
    {
      name: "Quantity",
      grow: 0,
      cell: (row, index) => <input className="form-control" type="number" name="quantity" onChange={(e) => handleChangeValue(e, "", index)} value={row?.quantity} />,
    },
    {
      name: "Subtotal",
      grow: 0,
      cell: (row, index) => <input className="form-control" type="number" disabled name="subTotal" onChange={(e) => handleChangeValue(e, "", index)} value={row?.subTotal} />,
      width: "15%",
    },
  ];
  const [chats_data, setChats_data] = useState([]);


  useEffect(() => {
    console.log(productArr, "productArr")
    setChats_data([...productArr])
  }, [productArr])


  const [productsArr, setProductsArr] = useState([]);

  const handleGetProducts = async () => {
    try {
      let query = { isForSelectInput: true }
      let { data: res } = await getProducts({ pageIndex: 1, pageSize: 10000000 }, query)
      if (res.data) {
        setProductsArr(res.data);
      }
    }
    catch (err) {
      toastError(err)
    }
  }

  useEffect(() => {
    handleGetProducts()
  }, [])

  const handleChangeValue = (e, name, index) => {
    // let tempArr = chats_data;
    console.log(e, "event", name)


    // tempArr[index][e.target.name] = e.target.value;
    setChats_data(prev => {
      let tempArr = prev
      if (name == "productObj") {
        prev[index][name] = e
        prev[index].name = e?.label
      }
      else if (e.target.name == "price" || e.target.name == "quantity") {
        prev[index][e.target.name] = e.target.value
        prev[index]["subTotal"] = (prev[index].quantity * prev[index].price)
      }
      else {
        prev[index][e.target.name] = e.target.value
      }
      return [...tempArr]
    })
    chats_data_value(chats_data)
  }









  const handleAddItem = () => {
    setChats_data(prev => [...prev, { category: "", productObj: {}, description: "", price: 0, quantity: 0, subTotal: 0 }])
  }
  const handleRemoveItem = () => {
    let tempArr  = _.cloneDeep(chats_data);
    if(tempArr.length > 1){
      tempArr = tempArr.filter((el, index) => index != (tempArr.length -1))
      setChats_data(_.cloneDeep(tempArr))
    }
  }

  return (
    <section className="product-category" style={{ minHeight: "" }}>
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-12">
            <DashboardTable>
              <DataTable
                columns={chats_columns}
                data={chats_data}
              />
              <div className="row f_j_c_e">
                <button onClick={() => handleAddItem()} className="add_item_buttn">
                  Add item <MdOutlineAdd />
                </button>
                <button onClick={() => handleRemoveItem()} className="add_item_buttn ms-3">
                  Remove item <MdOutlineDelete />
                </button>
              </div>
            </DashboardTable>
          </div>
        </div>
      </div>
    </section>
  );
}
