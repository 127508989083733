import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/category";

export const addCategory = (formData) => {
        return axiosApiInstance.post(serverUrl + "/addCategory", formData);
};

export const getCategory = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
        let query = new URLSearchParams({ ...obj, ...match });
        return axiosApiInstance.get(`${serverUrl}/getCategory?${query.toString()}`);
};
export const downloadCategoryExcel = (obj = { pageIndex: 0, pageSize: 10000000 }, match) => {
        let query = new URLSearchParams({ ...obj, ...match });
        return axiosApiInstance.get(`${serverUrl}/download-excel?${query.toString()}`);
};


export const getCategoryById = (id) => {
        return axiosApiInstance.get(`${serverUrl}/getById/${id}`);
};
export const getParentCategoriesById = (id) => {
        return axiosApiInstance.get(`${serverUrl}/getParentCategoriesById/${id}`);
};

export const deleteCategory = (id) => {
        return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateCategory = (formData, id) => {
        return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};

export const getNestedCategories = () => {
        return axiosApiInstance.get(`${serverUrl}/getNestedCategories`);
};
