import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { getUser } from "../../services/users.service";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import Select from 'react-select';
import { useSearchParams } from "react-router-dom";
import { addMeeting, getById, updateMeeting } from "../../services/meeting.service";
import moment from "moment";

function Meeting() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [userArr, setUserArr] = useState([]);
  const [userId, setUserId] = useState("");
  const [retailerName, setRetailerName] = useState("");
  const [on, setOn] = useState(new Date());
  const [note, setNote] = useState("");

  const handleGetAllUsers = async () => {
    try {
      let query = { isForSelectInput: true }
      let { data: response } = await getUser({ pageIndex: 0, pageSize: 10000000 }, query)
      if (response) {
        setUserArr(response.data)
      }
    } catch (error) {
      toastError(error)
    }
  };
  useEffect(() => {
    handleGetAllUsers();
  }, []);



  const handleSubmit = async () => {
    try {

      if (userId == "") {
        toastError("User is mandatory !!!");
        return
      }

      if (retailerName <= 0) {
        toastError("Retailer Name is mandatory !!!");
        return
      }


      if (note == "") {
        toastError("Note is mandatory !!!");
        return
      }

      let obj = {
        userId: userId.value,
        retailerName,
        on,
        note,
      }
      if (searchParams.get("id")) {
        let { data: res } = await updateMeeting(obj, searchParams.get("id"))
        if (res.message) {
          toastSuccess(res.message)
        }
      }

      else {
        let { data: res } = await addMeeting(obj)
        if (res.message) {
          toastSuccess(res.message)
        }
      }
    }
    catch (err) {
      toastError(err)
    }
  }




  const handleGetForUpdate = async () => {
    try {
      if (typeof searchParams?.get("id") != 'string')
        return;
      const res = await getById(searchParams?.get("id"));
      if (res.data.data) {
        console.log(res.data, "data")
        if (res.data?.data?.userId) {
          setUserId(prev => ({ ...prev, value: res.data?.data?.userId }))
        }
        if (res.data?.data?.label) {
          setUserId(prev => ({ ...prev, label: res.data?.data?.label }))
        }
        if (res.data?.data?.retailerName) {
          setRetailerName(res.data?.data?.retailerName)
        }
        if (res.data?.data?.on) {
          setOn(res.data?.data?.on)
        }
        if (res.data?.data?.note) {
          setNote(res.data?.data?.note)
        }

      }

    } catch (error) {
      toastError(error)
    }

  }
  useEffect(() => {
    if (searchParams?.get("id")) {
      handleGetForUpdate()
    }

  }, [searchParams.get("id")])

  return (
    <main className="bg-white">
      <section className="user_page_sec">
        <div className="user_header">
          <span className="page_name">Meeting</span>
          {/* <p className="breadcrumb">
            <span className="parent">Dashboard </span>{" "}
            <span>
              {" "}
              <HiChevronRight />
            </span>{" "}
            <span className="child">Meeting</span>
          </p> */}
        </div>

        <div className="user_form_box">
          <div className="form_header">
            <h6 className="heading">Meeting</h6>
            <span className="sub_heading"> Add Meeting</span>
          </div>

          <div className="form_box">
            <div className="row">
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">User Name*</p>
                  <Select options={userArr} onChange={(e) => setUserId(e)} value={userId} />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Retailer Name*</p>
                  <input onChange={(e) => setRetailerName(e.target.value)} value={retailerName} type="text" placeholder="Enter Product Name" />
                </div>
              </div>

              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Date*</p>
                  <input onChange={(e) => setOn(e.target.value)} value={moment(on).format('YYYY-MM-DDTHH:mm')} type="datetime-local" />
                </div>
              </div>


              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Note*</p>
                  <input onChange={(e) => setNote(e.target.value)} value={note} type="text" placeholder="Note" />
                </div>
              </div>
            </div>

            <div className="row">
              <button onClick={() => handleSubmit()} className="submit_buttn">Submit</button>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Meeting;
