import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { MdOutlineAdd } from "react-icons/md";
import { DashboardTable } from "../Utility/DashboardBox";
import { toastError } from "../Utility/ToastUtils";
import { getProducts } from "../../services/product.service";
import Select from 'react-select';
import { getWarehouse } from "../../services/warehouse.service";

export default function JVProductDetailsTable({ productArr, chats_data_value }) {
  const [query, setQuery] = useState("");


  const chats_columns = [
    {
      name: "SR.No",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "5%",
    },
    {
      name: "Warehouse (Unit)",
      cell: (row, index) => <div style={{ flex: 1 }}>
        <Select options={warehouseArr} name="warehouseObj" onChange={(e) => handleChangeValue(e, "warehouseObj", index)} value={row?.warehouseObj} />
      </div>,
      width:"15%"

    },
    {
      name: "Product Name",
      cell: (row, index) => <input className="form-control" name="productName" onChange={(e) => handleChangeValue(e, "", index)} value={row?.productName} />,
      width:"10%"
    },
    {
      name: "GL (REKO) Code",
      cell: (row, index) => <input className="form-control" name="rekoCode" onChange={(e) => handleChangeValue(e, "rekoCode", index)} value={row?.rekoCode} />,
    },
    {
      name: "GL (REKO) Code Description",
      cell: (row, index) => <input className="form-control" name="rekoCodeDescription" onChange={(e) => handleChangeValue(e, "rekoCodeDescription", index)} value={row?.rekoCodeDescription} />,
    },
    {
      name: "Quantity",
      grow: 0,
      cell: (row, index) => <input className="form-control" type="number" name="quantity" onChange={(e) => handleChangeValue(e, "quantity", index)} value={row?.quantity} />,
    },
    {
      name: "Rate",
      grow: 0,
      cell: (row, index) => <input className="form-control" type="number" name="rate" onChange={(e) => handleChangeValue(e, "rate", index)} value={row?.rate} />,
    },
    {
      name: "Amount",
      cell: (row, index) => <input className="form-control" type="number" name="amount" onChange={(e) => handleChangeValue(e, "amount", index)} value={row?.amount} />,
    },
    {
      name: "Dr SL Type",
      grow: 0,
      cell: (row, index) => <input className="form-control" type="text" name="drSlType" onChange={(e) => handleChangeValue(e, "drSlType", index)} value={row?.drSlType} />,
    },
    {
      name: "Cr SL Type",
      grow: 0,
      cell: (row, index) => <input className="form-control" type="text" name="crSlType" onChange={(e) => handleChangeValue(e, "crSlType", index)} value={row?.crSlType} />,
    },
    {
      name: "Dr SL Code",
      grow: 0,
      cell: (row, index) => <input className="form-control" type="text" name="drSlCode" onChange={(e) => handleChangeValue(e, "drSlCode", index)} value={row?.drSlCode} />,
    },
    {
      name: "Cr SL Code",
      grow: 0,
      cell: (row, index) => <input className="form-control" type="text" name="crSlCode" onChange={(e) => handleChangeValue(e, "crSlCode", index)} value={row?.crSlCode} />,
    },
    {
      name: "GST",
      grow: 0,
      cell: (row, index) => <input className="form-control" type="number" name="gst" onChange={(e) => handleChangeValue(e, "gst", index)} value={row?.gst} />,
    },
  ];
  const [chats_data, setChats_data] = useState([]);


  useEffect(() => {
    console.log(productArr, "productArr")
    setChats_data([...productArr])
  }, [productArr])


  const [productsArr, setProductsArr] = useState([]);
  const [warehouseArr, setWarehouseArr] = useState([]);

  const handleGetProducts = async () => {
    try {
      let query = { isForSelectInput: true }
      let { data: res } = await getProducts({ pageIndex: 0, pageSize: 10000000 }, query)
      if (res.data) {
        console.log(res.data, "products arr")
        setProductsArr(res.data);
      }
    }
    catch (err) {
      toastError(err)
    }
  }
  const handleGetWarehouse = async () => {
    try {
      let query = { forSelectInput: true }
      let { data: res } = await getWarehouse({ pageIndex: 0, pageSize: 10000000 }, query)
      if (res.data) {
        console.log(res.data, "warehouse arr")
        setWarehouseArr(res.data);
      }
    }
    catch (err) {
      toastError(err)
    }
  }

  useEffect(() => {
    handleGetProducts()
    handleGetWarehouse()
  }, [])

  const handleChangeValue = (e, name, index) => {
    // let tempArr = chats_data;
    console.log(e, "event", name)


    // tempArr[index][e.target.name] = e.target.value;
    setChats_data(prev => {
      let tempArr = prev
      if (name == "warehouseObj") {
        prev[index][name] = e
        prev[index].warehouseId = e.value
        // prev[index].productName = e.label
      }
      else if (name == "productObj") {
        prev[index][name] = e
        prev[index].productId = e.value
        prev[index].rekoCode = e.rekoCode
        prev[index].productName = e.label
      }
      else if (e.target.name == "price" || e.target.name == "quantity") {
        prev[index][e.target.name] = e.target.value
        prev[index]["subTotal"] = (prev[index].quantity * prev[index].price)
      }
      else {
        prev[index][e.target.name] = e.target.value
      }
      return [...tempArr]
    })
    chats_data_value(chats_data)
  }









  const handleAddItem = () => {
    setChats_data(prev => [...prev, { category: "", productObj: {}, description: "", price: 0, quantity: 0, subTotal: 0 }])
  }

  return (
    <section className="product-category" style={{ minHeight: "" }}>
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-12">
            <DashboardTable>
              <DataTable
                columns={chats_columns}
                data={chats_data}
              />
              <div className="row f_j_c_e">
                <button onClick={() => handleAddItem()} className="add_item_buttn">
                  Add item <MdOutlineAdd />
                </button>
              </div>
            </DashboardTable>
          </div>
        </div>
      </div>
    </section>
  );
}
