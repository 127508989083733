import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { HiChevronRight } from "react-icons/hi";
import Select from "react-select";
import {
    getProductsSalesRevenueYearWiseChange,
    getYearOnYearSalesRevenue,
} from "../../services/dispatchingInputSaleInvoice.service";
import { getProductsByCategoryName } from "../../services/product.service";
import CustomButton from "../Utility/Button";
import { DashboardBox, DashboardChart } from "../Utility/DashboardBox";
import { toastError } from "../Utility/ToastUtils";
import { formatter } from "../Utility/utils";
import { handleReturnBarGraphColorArr } from "../Utility/barGraphColorArray";
export default function YearOnYearRevenue() {
    const [productsArr, setProductsArr] = useState([]);
    const [barLabels, setBarLabels] = useState([]);

    const [yearsArr, setYearsArr] = useState([]);

    const [selectedYear, setSelectedYear] = useState("");
    const [selectedProductId, setSelectedProductId] = useState("");
    const [selectedReportType, setSelectedReportType] = useState("Monthly");

    const [displayYear, setDisplayYear] = useState("");
    const [displayProduct, setDisplayProduct] = useState("");

    const [unitOfMeasurement, setUnitOfMeasurement] = useState("");
    const [salesArr, setSalesArr] = useState([]);
    const [previousYearSalesArr, setPreviousYearSalesArr] = useState([]);

    const HandleGetComodityProducts = async () => {
        try {
            let query = {};
            let { data: res } = await getProductsByCategoryName(query);
            if (res.data) {
                setProductsArr(res.data);
            }
        } catch (error) {
            toastError(error);
        }
    };

    const HandleGetComodityProductsSalesYearWise = async () => {
        try {
            let { data: res } = await getYearOnYearSalesRevenue();
            if (res.data) {
                console.log(res.data, "data");
                setBarLabels(res.data.map((el) => `${el.month} - ${parseInt(el.month) + 1}`));
                setSalesArr(res.data);
            }
        } catch (error) {
            toastError(error);
        }
    };

    useEffect(() => {
        HandleGetComodityProductsSalesYearWise();
    }, []);

    return (
        <main className="bg-white">
            <section className="dashboard-head mb-3">
                <div className="row">
                    <div className="col-xl-10"></div>
                    <div className="col-xl-2">
                        {/* <div className="user_header">
                            <p className="breadcrumb">
                                <span className="parent">Dashboard </span>{" "}
                                <span>
                                    {" "}
                                    <HiChevronRight />
                                </span>{" "}
                                <span className="child">Reports</span>
                            </p>
                        </div> */}
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-md-12 mb-5 p-5 border rounded">
                            <h3 className="blue-1">Year On Year Revenue</h3>
                            <div className="row mt-5">
                                <div className="col-12 col-lg-5">
                                    <DashboardChart>
                                        {salesArr && salesArr.length > 0 && (
                                            <>
                                                <Bar
                                                    data={{
                                                        labels: barLabels,
                                                        datasets: [
                                                            {
                                                                label: "",
                                                                backgroundColor: handleReturnBarGraphColorArr(salesArr?.map((el) => el?.value)),
                                                                borderColor:
                                                                    "#2e4765",
                                                                data: salesArr?.map(
                                                                    (el) =>
                                                                        el?.value
                                                                ),
                                                            },
                                                        ],
                                                    }}
                                                />
                                                <h5 className="blue-1 my-4">
                                                    Total Sales are of{" "}
                                                    {formatter.format(
                                                        salesArr
                                                            .reduce(
                                                                (
                                                                    acc,
                                                                    el,
                                                                    index
                                                                ) =>
                                                                    acc +
                                                                    el.value,
                                                                0
                                                            )
                                                            .toFixed(2)
                                                    )}{" "}
                                                </h5>
                                            </>
                                        )}
                                    </DashboardChart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
