export const DisplayDate = (value, format) => {
    if (format) {
        if (`${format}`.toLowerCase() == "dd/mm/yyyy".toLowerCase()) {
            return `${new Date(value).getDate()}/${new Date(value).getMonth() + 1}/${new Date(value).getFullYear()}`
        }
        else if (`${format}`.toLowerCase() == "dd-mm-yyyy".toLowerCase()) {
            return `${new Date(value).getDate()}-${new Date(value).getMonth() + 1}-${new Date(value).getFullYear()}`
        }
        else if (`${format}`.toLowerCase() == "mm-dd-yyyy".toLowerCase()) {
            return `${new Date(value).getMonth() + 1}-${new Date(value).getDate()}-${new Date(value).getFullYear()}`
        }
        else if (`${format}`.toLowerCase() == "mm/dd/yyyy".toLowerCase()) {
            return `${new Date(value).getMonth() + 1}/${new Date(value).getDate()}/${new Date(value).getFullYear()}`
        }
    }
    else {
        return `${new Date(value).getDate()}/${new Date(value).getMonth() + 1}/${new Date(value).getFullYear()}`
    }
}

export const handleGetMonthFromIndex =(index)=> {
    const barLabels = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];



      return barLabels[index-1]
}




export const returnFormatedDateTypeObject = (dateString = "") => {
    
    if(dateString.includes("-") && dateString != ""){
        let tempDateString = dateString.split("-").map((el, index) => (index == 1 ? parseInt(el) -1 : parseInt(el)))
        console.log(tempDateString,"tempDateString")
        return new Date(...tempDateString)
    }
}   