import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { CiLock } from "react-icons/ci";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import { addUser, getById, updateUser } from "../../services/users.service";
import { useSearchParams } from "react-router-dom";
import { generateFilePath } from "../Utility/utils";
function AddLogistics() {
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const [manageUser, setManageUser] = useState(false);
  const [manageProducts, setManageProducts] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleRegister = async () => {
    try {
      if (name == "") {
        toastError("Name is mandatory !!!");
        return;
      }

      if (email == "") {
        toastError("Email is mandatory !!!");
        return;
      }
      if (!searchParams.get("id")) {
        if (password == "") {
          toastError("Password is mandatory !!!");
          return;
        }
      }

      if (phone == "") {
        toastError("Phone is mandatory !!!");
        return;
      }

      if (image == "") {
        toastError("Image is mandatory !!!");
        return;
      }

      let obj = {
        name,
        email,
        phone,
        image,
        accessObj: {
          manageUsers: manageUser,
          manageProducts: manageProducts,
        },
      };

      if (password && password != "") {
        obj.password = password;
      }

      if (searchParams.get("id")) {
        let { data: res } = await updateUser(obj, searchParams.get("id"));
        if (res.message) {
          toastSuccess(res.message);
        }
      } else {
        let { data: res } = await addUser(obj);
        if (res.message) {
          toastSuccess(res.message);
        }
      }
    } catch (err) {
      toastError(err);
    }
  };

  const HandleGetByIdOnInit = async (id) => {
    try {
      let { data: res } = await getById(id);
      if (res.data) {
        setName(res.data.name);
        setEmail(res.data.email);
        setPhone(res.data.phone);
        setImage(res.data.image);
        console.log(
          res?.data?.accessObj?.manageUsers,
          "res?.data?.accessObj?.manageUser"
        );
        setManageUser(
          res?.data?.accessObj?.manageUsers
            ? res?.data?.accessObj?.manageUsers
            : false
        );
        setManageProducts(
          res?.data?.accessObj?.manageProducts
            ? res?.data?.accessObj?.manageProducts
            : false
        );
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    if (searchParams.get("id")) {
      HandleGetByIdOnInit(searchParams.get("id"));
    }
  }, [searchParams.get("id")]);

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) { };
  };
  const handleFileSelection = (event) => {
    if (event.target.files[0]) {
      getBase64(event.target.files[0], (result) => {
        // setImage(event.target.files[0]);
        setImage(result);
      });
    }
  };

  return (
    <main className="bg-white">
      <section className="user_page_sec">
        <div className="user_header">
          <span className="page_name">Logistics</span>
          {/* <p className="breadcrumb">
            <span className="parent">Dashboard </span>{" "}
            <span>
              {" "}
              <HiChevronRight />
            </span>{" "}
            <span className="child">Logistics</span>
          </p> */}
        </div>

        <div className="user_form_box">
          <div className="form_header">
            <h6 className="heading">Logistics</h6>
            <span className="sub_heading">Create Logistics</span>
          </div>

          <div className="form_box">
            <div className="row">
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Ordered quantity *</p>
                  <input
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    type="text"
                    placeholder="Enter Ordered quantity"
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Port Quantity *</p>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    type="text"
                    placeholder="Enter Port Quantity "
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Cleared quantity*</p>
                  <input
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                    type="text"
                    placeholder="Enter Cleared quantity"
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Bay Quantity *</p>
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    type="text"
                    placeholder="Enter Bay Quantity"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Godown quantity *</p>
                  <input
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                    type="text"
                    placeholder="Enter Godown quantity"
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Clearance cost *</p>
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    type="text"
                    placeholder="Enter Password"
                  />
                  <CiLock className="lock_icn" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6">
                <div className="user_form_ip_box">
                  <p className="ip_label">Road cost *</p>
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    type="text"
                    placeholder="Enter Road cost "
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <button onClick={() => handleRegister()} className="submit_buttn">
                Submit
              </button>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default AddLogistics;
